import t from 'Components/Helpers/translate';
import entityName from 'Components/Helpers/EntityName';

export const getConfirmationTitle = (
	entity: string,
	numSelected: number,
	name?: string,
	allSelected?: boolean,
	notificationEntityNameExtension?: string
) => {
	const entityText = t(entityName(notificationEntityNameExtension || entity, numSelected)).toLowerCase();
	if (allSelected) {
		return t('admin.modal.deleteAllEntities', { entity: entityText });
	}
	const confirmationTitle = t('admin.modal.confirmDeleteAccount');

	return name
		? `${confirmationTitle}${name}`
		: numSelected === 1
		? `${confirmationTitle}${entityText}`
		: `${confirmationTitle}${numSelected} ${entityText}`;
};
