import { setAccountSelf } from 'App/babel/store/actions/AppActions';
import {
	getActivityTypesFromState,
	getAppointmentTypesFromState,
	getCategoriesFromState,
	getJourneyStepsFromState,
	getRolesFromState,
	getStagesFromState,
	getUsersFromState,
	getSupportUsersFromState,
	getTicketTypesFromState,
	getProductCategoriesFromState,
	getIntegrationsFromState,
	getOrderRelationActiveFromState,
	getPriceListsFromState
} from 'Store/selectors/AppSelectors';
import { AppState } from 'Store/reducers/AppReducer';
import useAppDispatch from './useAppDispatch';
import useSelector from './useSelector';

export const useJourneySteps = (all?: boolean) => {
	const App = useSelector(({ App }) => App);
	return getJourneyStepsFromState(App, all);
};

type StageType = AppState['stages'];
type StageTypes = keyof Omit<StageType, 'excludedIds'>;
export const useStages = (
	type: StageTypes = 'all',
	ignoreAccessRights: boolean = false,
	includeExcluded: boolean = true
) => {
	const App = useSelector(({ App }) => App);
	return getStagesFromState(App, type, ignoreAccessRights, includeExcluded);
};

export const useUsers = (
	type: string,
	includeApiUsers: boolean = false,
	includeSupportUsers: boolean = false,
	excludeGhosts: boolean = false
) => {
	const App = useSelector(({ App }) => App);
	return getUsersFromState(App, type, includeApiUsers, includeSupportUsers, excludeGhosts);
};

export const useSupportUsers = (type: string, excludeGhosts: boolean = false) => {
	const App = useSelector(({ App }) => App);
	return getSupportUsersFromState(App, type, excludeGhosts);
};

export const useRoles = (type: string) => {
	const App = useSelector(({ App }) => App);
	return getRolesFromState(App, type);
};

export const useCategories = (type: string, skipAuth: boolean = false) => {
	const App = useSelector(({ App }) => App);
	return getCategoriesFromState(App, type, skipAuth);
};

export const useSelf = () => {
	return useSelector(({ App }) => App.self);
};

export const useAccountSelf = () => {
	return useSelector(({ App }) => App.accountSelf);
};

export const useActivityTypes = (rights = false) => {
	const App = useSelector(({ App }) => App);
	return getActivityTypesFromState(App, rights);
};

export const useAppointmentTypes = (rights = false) => {
	const App = useSelector(({ App }) => App);
	return getAppointmentTypesFromState(App, rights);
};

export const useCustomFields = (type: keyof AppState['customFields']) => {
	return useSelector(({ App }) => App.customFields[type] || []);
};

export const useStaticValues = (type: keyof AppState['staticValues']) => {
	return useSelector(({ App }) => App.staticValues[type] || []);
};

export const useMetadata = () => {
	return useSelector(({ App }) => App.metadata);
};

export const useTicketTypes = () => {
	const App = useSelector(({ App }) => App);
	return getTicketTypesFromState(App);
};

export const useProductCategories = (skipAuth?: boolean) => {
	const App = useSelector(({ App }) => App);
	return getProductCategoriesFromState(App, skipAuth);
};

export const useIntegrations = () => {
	const App = useSelector(({ App }) => App);
	return getIntegrationsFromState(App);
};

export const useOrderRelationActive = () => {
	const App = useSelector(({ App }) => App);
	return getOrderRelationActiveFromState(App);
};

export const usePriceLists = () => {
	const App = useSelector(({ App }) => App);
	return getPriceListsFromState(App);
};

/** Setters */

export const useSetAccountSelf = () => {
	const dispatch = useAppDispatch();

	return (accountSelf: AppState['accountSelf']) => dispatch(setAccountSelf(accountSelf));
};
