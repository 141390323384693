import { DrawerHeader, Row, Button, Icon, Text, AssistChip, Tooltip, Toggle, Flex } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useReducer } from 'react';
import T from 'Components/Helpers/translate';
import './ProductBundleDrawer.scss';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import ProductResource from 'Resources/Product';
import Product from 'App/resources/Model/Product';
import { isTieredTotalOrderRow, getTierFromQuantity, getTierPriceByCurrency } from 'Helpers/order';
import ProductTypeSelect from './ProductTypeSelect';
import BundleSettings from './BundleSettings';
import BundleProducts from './BundleProducts';
import LZString from 'lz-string';
import BundleCustomFields from './BundleCustomFields';
import CustomField, { EntityCustomField } from 'App/resources/Model/CustomField';
import { getDefaultPriceList } from 'Store/selectors/AppSelectors';
import { useMetadata } from '../hooks/appHooks';
import PriceList from 'App/resources/Model/PriceList';
import { useSoftDeployAccess } from '../hooks';

export enum PriceAdjustmentTypes {
	UNCHANGED,
	DECREASE,
	INCREASE
}

export enum ProductTypes {
	ONEOFF,
	RECURRING
}

export type SelectOption<T> = T & { title: string; id: number };

export type ProductRow = {
	product: SelectOption<Product> | null;
	quantity: number;
	unitPrice: number;
	value: number;
	annualValue: number;
	monthlyValue: number;
	totalPurchaseCost: number;
	adjustable?: boolean;
};

type BundleSummary = {
	gross: number;
	net: number;
	recurringValue: number;
	contributionMargin: number;
	purchaseCost: number;
};

export type BundleState = {
	id: number | null;
	active: number;
	articleNo: string | null;
	name: string;
	roles: { id: number; title: string }[];
	category: { id: number; title: string } | null;
	custom: EntityCustomField[];
	priceAdjustmentType: PriceAdjustmentTypes;
	priceAdjustment: number;
	priceAdjustmentPercent: number;
	fixedPrice: boolean;
	editable: boolean;
	productRows: ProductRow[];
	productType: ProductTypes | null;
	tmpProductType: ProductTypes | null;
	canSave: boolean;
	saving: boolean;
	showProductTypeSelect: boolean;
	summary: BundleSummary;
	useContributionMargin: boolean;
	isMRR: boolean;
	initialHash: string;
	articleNoRequired: boolean;
	articleNoActive: boolean;
	customFields: CustomField[];
	description: string | null;
	descriptionRequired: boolean;
	descriptionActive: boolean;
	maxDiscount: number | null;
	currencies: Product['currencies'];
	selectedPriceList: PriceList;
	recurringInterval: number | null;
	errorMessages: string;
};

export type Props = {
	close: () => void;
	className: string;
	bundle?: Product;
};

const ProductBundleDrawer = (props: Props) => {
	const classes = new BemClass('ProductBundleDrawer', props.className);
	const selectAnchor = document.querySelector('.ProductBundleDrawer__wrapper');
	const cf = new CurrencyFormat();
	const hasSubscriptions = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
	const hasSwitchBundleProducts = useSoftDeployAccess('SWITCH_BUNDLE_PRODUCTS');
	const { params, standardFields, customerCurrencies } = useMetadata() ?? {};
	const { SalesModel, SalesModelOption } = params ?? {};
	const defaultPriceList = getDefaultPriceList();
	const initialBundleState: BundleState = {
		id: null,
		active: 1,
		articleNo: null,
		name: '',
		roles: [],
		category: null,
		custom: [],
		priceAdjustmentType: PriceAdjustmentTypes.UNCHANGED,
		priceAdjustment: 1,
		priceAdjustmentPercent: 0,
		fixedPrice: false,
		editable: false,
		productRows: [],
		productType: null,
		tmpProductType: null,
		canSave: false,
		saving: false,
		showProductTypeSelect: hasSubscriptions,
		summary: { gross: 0, net: 0, recurringValue: 0, contributionMargin: 0, purchaseCost: 0 },
		useContributionMargin: SalesModel === 'cm',
		isMRR: SalesModel === 'rr' && SalesModelOption === 'mrr',
		initialHash: '',
		articleNoRequired: false,
		articleNoActive: false,
		customFields: [],
		description: null,
		descriptionRequired: false,
		descriptionActive: false,
		maxDiscount: null,
		currencies: [],
		selectedPriceList: defaultPriceList,
		recurringInterval: 12,
		errorMessages: ''
	};

	const getHash = (bundle: BundleState) => {
		const {
			active,
			articleNo,
			name,
			roles,
			category,
			custom,
			priceAdjustmentType,
			priceAdjustment,
			productRows,
			productType,
			description,
			maxDiscount,
			editable,
			fixedPrice,
			currencies,
			recurringInterval
		} = bundle;
		return LZString.compressToBase64(
			JSON.stringify({
				active,
				articleNo,
				name,
				roles,
				category,
				custom,
				priceAdjustmentType,
				priceAdjustment,
				productRows,
				productType,
				description,
				maxDiscount,
				editable,
				fixedPrice,
				currencies,
				recurringInterval
			})
		);
	};

	const calculateSummary = (
		productRows: BundleState['productRows'],
		priceAdjustment: BundleState['priceAdjustment'],
		fixedPrice: BundleState['fixedPrice'],
		currencies: BundleState['currencies'],
		priceList: BundleState['selectedPriceList']
	) => {
		if (fixedPrice) {
			const masterCurrency = customerCurrencies?.find(({ masterCurrency }) => masterCurrency);
			const { price } = currencies.find(
				c => c.currency === masterCurrency?.iso && c.priceListId === priceList.id
			) ?? { price: 0 };
			const purchaseCost = productRows.reduce((acc, row) => acc + row.totalPurchaseCost, 0);

			return {
				gross: price,
				net: price,
				recurringValue: 0,
				contributionMargin: price - purchaseCost,
				purchaseCost
			};
		} else {
			return productRows.reduce(
				(
					summary: {
						gross: number;
						net: number;
						recurringValue: number;
						contributionMargin: number;
						purchaseCost: number;
					},
					row
				) => {
					summary.gross += row.value;
					summary.net += row.value * priceAdjustment;
					summary.purchaseCost += row.totalPurchaseCost;
					summary.contributionMargin += row.value * priceAdjustment - row.totalPurchaseCost;

					if (row.product?.isRecurring && row.product.recurringInterval) {
						summary.recurringValue +=
							(row.monthlyValue ?? 0) * priceAdjustment * (initialBundleState.isMRR ? 1 : 12);
					}

					return summary;
				},
				{ gross: 0, net: 0, recurringValue: 0, contributionMargin: 0, purchaseCost: 0 }
			);
		}
	};

	const calculateProductRowValues = (row: ProductRow) => {
		const product = row.product;
		if (product) {
			const priceObj = product?.currencies?.find(
				c => c.currency === cf.currency && c.priceListId === defaultPriceList.id
			);

			row.unitPrice = priceObj?.price ?? product.listPrice;
			const unitPurchaseCost = priceObj?.purchaseCost ?? product.purchaseCost;
			row.totalPurchaseCost = unitPurchaseCost * row.quantity;
			row.value = row.unitPrice * row.quantity;

			const productTiers = product.tiers.filter(tier => tier.priceListId === defaultPriceList.id);
			if (productTiers) {
				const tier = getTierFromQuantity(productTiers, row.quantity);
				if (tier?.tier) {
					row.unitPrice = getTierPriceByCurrency(tier.tier, cf.currency);
					if (tier.tier.isTotalPrice) {
						row.value = row.unitPrice;
					} else {
						row.value = row.unitPrice * row.quantity;
					}
				}
			}
			if (product.isRecurring && product.recurringInterval && product.recurringInterval > 0) {
				const monthlyPrice = (row.value || 0) / product.recurringInterval;
				row.monthlyValue = monthlyPrice;
				row.annualValue = monthlyPrice * 12;
			}
		}

		return row;
	};

	const validateBundle = (bundle: BundleState) => {
		const errors: string[] = [];

		if (!bundle.name.trim().length) {
			errors.push(T('admin.products.bundles.missingBundleName'));
		}
		if (
			bundle.articleNoActive &&
			bundle.articleNoRequired &&
			(bundle.articleNo === null || !bundle.articleNo.length)
		) {
			errors.push(T('admin.products.bundles.missingArticleNo'));
		}

		if (
			bundle.descriptionActive &&
			bundle.descriptionRequired &&
			(bundle.description === null || !bundle.description.length)
		) {
			errors.push(T('admin.products.bundles.missingDescription'));
		}

		if (!bundle.productRows.filter(row => row.product).length) {
			errors.push(T('admin.products.bundles.missingProducts'));
		}

		if (bundle.customFields.length) {
			for (const field of bundle.customFields) {
				if (field.obligatoryField) {
					const matchingField = bundle.custom.find(cf => cf.fieldId === field.id);

					const requiresNoLengthCheck =
						field.datatype === 'Boolean' ||
						field.datatype === 'Date' ||
						Number.isInteger(matchingField?.value);

					if (
						!matchingField ||
						!matchingField.value ||
						(!requiresNoLengthCheck && !matchingField.value.length)
					) {
						errors.push(T('admin.products.bundles.missingCustomField', { fieldName: field.name }));
					}
				}
			}
		}
		return errors;
	};

	const initProductRows = () => {
		return props.bundle && props.bundle.bundle.length
			? props.bundle.bundle.map(bundleProduct => {
					const row = {
						product: { ...bundleProduct.product, title: bundleProduct.product.name },
						quantity: bundleProduct.tierQuantity,
						unitPrice: 0,
						value: 0,
						monthlyValue: 0,
						annualValue: 0,
						totalPurchaseCost: 0,
						adjustable: bundleProduct.adjustable ?? false
					};
					return calculateProductRowValues(row);
			  })
			: [];
	};

	const init = (bundle: BundleState) => {
		if (props.bundle) {
			bundle.id = props.bundle.id;
			bundle.active = props.bundle.active;
			bundle.articleNo = props.bundle.articleNo;
			bundle.description = props.bundle.description;
			bundle.name = props.bundle.name;
			bundle.roles = props.bundle.roles.map(({ id, name }) => ({ id, title: name }));
			bundle.category = props.bundle.category
				? { id: props.bundle.category.id, title: props.bundle.category.name }
				: null;
			bundle.custom = props.bundle.custom;
			bundle.priceAdjustmentType =
				props.bundle?.bundlePriceAdjustment != null
					? props.bundle.bundlePriceAdjustment < 1
						? PriceAdjustmentTypes.DECREASE
						: props.bundle.bundlePriceAdjustment > 1
						? PriceAdjustmentTypes.INCREASE
						: PriceAdjustmentTypes.UNCHANGED
					: PriceAdjustmentTypes.UNCHANGED;
			bundle.priceAdjustment = props.bundle.bundlePriceAdjustment ?? 1;
			bundle.priceAdjustmentPercent = parseFloat(Math.abs((bundle.priceAdjustment - 1) * 100).toPrecision(4));
			bundle.fixedPrice = props.bundle.bundleFixedPrice ?? false;
			bundle.editable = props.bundle.bundleEditable ?? false;
			bundle.productRows = initProductRows();
			bundle.productType = props.bundle.isRecurring ? ProductTypes.RECURRING : ProductTypes.ONEOFF;
			bundle.showProductTypeSelect = false;
			bundle.currencies = props.bundle.currencies;
			bundle.recurringInterval = props.bundle.recurringInterval ?? 12;
			bundle.summary = calculateSummary(
				bundle.productRows,
				bundle.priceAdjustment,
				bundle.fixedPrice,
				bundle.currencies,
				bundle.selectedPriceList
			);
			bundle.maxDiscount = props.bundle.currencies[0]?.maxDiscount ?? null;
			bundle.initialHash = getHash(bundle);
		} else {
			if (!hasSubscriptions) {
				bundle.productType = ProductTypes.ONEOFF;
			}
		}
		const productStandardFields = standardFields?.Product;
		if (productStandardFields) {
			bundle.articleNoActive =
				productStandardFields.ArticleNo.active && Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
			bundle.articleNoRequired = productStandardFields.ArticleNo.required;

			bundle.descriptionActive = productStandardFields.Description.active;
			bundle.descriptionRequired = productStandardFields.Description.required;
		}

		bundle.customFields = Tools.AppService.getCustomFields('product').filter(
			(field: CustomField) => field.$hasAccess && field.visible
		);

		return bundle;
	};

	const getPriceAdjustment = (type: PriceAdjustmentTypes, percent: number) => {
		if (type === PriceAdjustmentTypes.DECREASE) {
			return Math.round((1 - percent / 100) * 10000) / 10000;
		} else if (type === PriceAdjustmentTypes.INCREASE) {
			return Math.round((1 + percent / 100) * 10000) / 10000;
		} else {
			return 1;
		}
	};

	const [bundle, updateBundle] = useReducer(
		(prev: BundleState, next: Partial<BundleState>) => {
			const updatedBundle = { ...prev, ...next };
			const shouldUpdatePriceAdjustment =
				next.priceAdjustmentPercent !== undefined || next.priceAdjustmentType !== undefined;
			const shouldUpdateSummary =
				next.productRows || shouldUpdatePriceAdjustment || next.currencies || next.fixedPrice !== undefined;
			const shouldCalculateRowValues = shouldUpdatePriceAdjustment;

			if (shouldUpdatePriceAdjustment) {
				updatedBundle.priceAdjustment = getPriceAdjustment(
					updatedBundle.priceAdjustmentType,
					updatedBundle.priceAdjustmentPercent
				);
			}

			if (next.productType !== null) {
				updatedBundle.showProductTypeSelect = false;
			}

			if (next.showProductTypeSelect) {
				updatedBundle.tmpProductType = prev.productType;
			} else {
				if (updatedBundle.tmpProductType !== null && updatedBundle.tmpProductType !== next.productType) {
					updatedBundle.productRows = [];
				}
				updatedBundle.tmpProductType = null;
			}

			if (shouldCalculateRowValues) {
				updatedBundle.productRows = prev.productRows.map(calculateProductRowValues);
			}

			if (shouldUpdateSummary) {
				updatedBundle.summary = calculateSummary(
					updatedBundle.productRows,
					updatedBundle.priceAdjustment,
					updatedBundle.fixedPrice,
					updatedBundle.currencies,
					updatedBundle.selectedPriceList
				);

				const masterCurrency = customerCurrencies?.find(({ masterCurrency }) => masterCurrency);
				if (masterCurrency && !updatedBundle.fixedPrice) {
					updatedBundle.currencies = updatedBundle.currencies?.length
						? updatedBundle.currencies.map(c => {
								if (c.currency === masterCurrency.iso && c.priceListId === defaultPriceList.id) {
									return {
										...c,
										price: updatedBundle.summary.net,
										purchaseCost: updatedBundle.summary.purchaseCost,
										priceListId: defaultPriceList.id,
										maxDiscount: updatedBundle.maxDiscount
									};
								}
								return { ...c, maxDiscount: updatedBundle.maxDiscount };
						  })
						: [
								{
									currency: masterCurrency.iso,
									price: updatedBundle.summary.net,
									purchaseCost: updatedBundle.summary.purchaseCost,
									priceListId: defaultPriceList.id,
									maxDiscount: updatedBundle.maxDiscount
								}
						  ];
				}
			}

			// We always need to update the max discount, even if no products was changed
			if (next.maxDiscount !== undefined) {
				updatedBundle.currencies = updatedBundle.currencies.map(c => ({ ...c, maxDiscount: next.maxDiscount }));
			}

			const validationErrors = validateBundle(updatedBundle);

			updatedBundle.canSave = validationErrors.length === 0 && getHash(updatedBundle) !== prev.initialHash;
			updatedBundle.errorMessages = validationErrors.join('\n');

			return updatedBundle;
		},
		initialBundleState,
		init
	);

	const addProductRow = () => {
		updateBundle({
			productRows: [
				...bundle.productRows,
				{
					product: null,
					quantity: 1,
					unitPrice: 0,
					value: 0,
					totalPurchaseCost: 0,
					monthlyValue: 0,
					annualValue: 0,
					adjustable: false
				}
			]
		});
	};
	const deleteProductRow = (index: number) => {
		const rows = [...bundle.productRows];
		rows.splice(index, 1);
		updateBundle({ productRows: rows });
	};
	const updateProductRow = (index: number, newRow: ProductRow) => {
		newRow = calculateProductRowValues(newRow);
		const rows = [...bundle.productRows];
		rows.splice(index, 1, newRow);
		updateBundle({ productRows: rows });
	};

	const save = async () => {
		if (!bundle.saving) {
			updateBundle({ saving: true });
			const product = {
				id: props.bundle?.id,
				articleNo: bundle.articleNo,
				description: bundle.description,
				name: bundle.name,
				active: bundle.active,
				roles: bundle.roles.map(({ id }) => ({ id })),
				category: bundle.category ? { id: bundle.category.id } : null,
				custom: bundle.custom,
				bundle: bundle.productRows
					.filter(row => row.product !== null)
					.map(row => ({
						productId: row.product?.id,
						quantity: isTieredTotalOrderRow(row) ? 1 : row.quantity,
						tierQuantity: row.quantity,
						adjustable: bundle.fixedPrice ? false : row.adjustable
					})),
				bundlePriceAdjustment: bundle.fixedPrice ? 1 : bundle.priceAdjustment,
				bundleFixedPrice: bundle.fixedPrice,
				bundleEditable: bundle.fixedPrice && bundle.editable,
				isRecurring: bundle.productType === ProductTypes.RECURRING,
				recurringInterval: bundle.productType === ProductTypes.RECURRING ? bundle.recurringInterval : null,
				currencies: bundle.currencies
			};

			try {
				await ProductResource.save(product, { params: { usePriceLists: 'true' } });
				props.close();
				updateBundle({ saving: false });
			} catch {
				updateBundle({ saving: false });
			}
		}
	};

	const { id, productType, canSave, showProductTypeSelect, saving } = bundle;
	return (
		<div className={classes.b()}>
			<div className={classes.elem('wrapper').b()}>
				<DrawerHeader
					onHide={props.close}
					title={
						(id === null ? T('default.create') : T('default.edit')) +
						' ' +
						T('admin.products.bundle').toLowerCase()
					}
				>
					{!showProductTypeSelect ? (
						<>
							<Button type="link" className={classes.elem('cancel-btn').b()} onClick={props.close}>
								<Text>{T('default.cancel')}</Text>
							</Button>
							<Tooltip title={bundle.errorMessages} disabled={!bundle.errorMessages}>
								<Button
									className={classes.elem('save-btn').b()}
									onClick={save}
									disabled={!canSave}
									loading={saving}
								>
									<Text size="lg" bold color="white">
										{T('default.save')} <Icon space="mlm" color="super-light-green" name="check" />
									</Text>
								</Button>
							</Tooltip>
						</>
					) : null}
				</DrawerHeader>
				<div className={classes.elem('content').b()}>
					{showProductTypeSelect ? (
						<ProductTypeSelect bundle={bundle} updateBundle={updateBundle} classes={classes} />
					) : (
						<>
							<BundleSettings
								classes={classes}
								bundle={bundle}
								updateBundle={updateBundle}
								selectAnchor={selectAnchor}
							/>
							<BundleProducts
								classes={classes}
								bundle={bundle}
								selectAnchor={selectAnchor}
								addProductRow={addProductRow}
								deleteProductRow={deleteProductRow}
								updateProductRow={updateProductRow}
							/>
							<Flex direction="column" gap="u3" space="mtl">
								{hasSwitchBundleProducts && bundle.fixedPrice ? (
									<Flex gap="u2" alignItems="center">
										<Toggle
											size="lg"
											checked={bundle.editable}
											onChange={() => updateBundle({ editable: !bundle.editable })}
										/>
										<Text>{T('admin.products.allowBundleEdit')}</Text>
									</Flex>
								) : null}

								<Flex gap="u2" alignItems="center">
									<Toggle
										size="lg"
										checked={!!bundle.active}
										onChange={() => updateBundle({ active: bundle.active ? 0 : 1 })}
									/>
									<Text>{T('default.active')}</Text>
								</Flex>
							</Flex>
							{hasSubscriptions ? (
								<AssistChip
									icon="info"
									title={
										<Row>
											<Text color="medium-blue">
												{T('admin.products.bundleCanOnlyContain') +
													' ' +
													(productType === ProductTypes.ONEOFF
														? T('admin.products.oneOffProducts').toLowerCase()
														: T('admin.products.recurringProducts').toLowerCase()) +
													'.'}
											</Text>
											<Row
												className={classes.elem('change-type-link').b()}
												onClick={() =>
													updateBundle({
														showProductTypeSelect: true,
														productType: null
													})
												}
											>
												<Text
													color="medium-blue"
													className={classes.elem('underlined-text').b()}
												>
													{T('default.change')}
												</Text>
												<Icon name="times" />
											</Row>
										</Row>
									}
								/>
							) : null}
							<BundleCustomFields classes={classes} bundle={bundle} updateBundle={updateBundle} />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductBundleDrawer;
