import ClientResource from 'App/resources/Client';
import logError from 'App/babel/helpers/logError';

import type Client from 'App/resources/Model/Client';

export const removeAsSubAccount = (client: Client) => {
	ClientResource.save({ id: client.id, operationalAccount: null })
		.then(() => Tools.$rootScope.$broadcast('account.subaccountsDeleted'))
		.catch(error => logError(error));
};
