import Banner from './Banner';
import BemClass from '@upsales/components/Utils/bemClass';
import ClientAttributes from 'App/babel/attributes/Client';
import ClientResource from 'App/resources/Client';
import DefaultNoData from 'App/components/ListView/DefaultNoData';
import ListRow from './ListRow';
import React, { useState, useEffect, useMemo } from 'react';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import useGetData from 'App/components/hooks/useGetData';
import { PrimaryButton } from '@upsales/components/Buttons';
import {
	Title,
	Input,
	Link,
	Block,
	Paginator,
	Card,
	Table,
	TableHeader,
	Flex,
	Loader,
	TableRow,
	TableColumn
} from '@upsales/components';
import { SubaccountDrawerView } from './SubaccountDrawer';
import { useIsFirstRender } from 'App/components/hooks';

import type Client from 'App/resources/Model/Client';
import type { DrawerClient } from './SubaccountDrawer';
import PendingPurchaseBanner, { PendingPurchaseType } from './PendingPurchaseBanner';

type Props = {
	className?: string;
	client: DrawerClient;
	goToAccount: (client: Pick<Client, 'id'>) => void;
	setIncludeSubaccount: (includeSubaccountData: boolean) => void;
	setView: (view: SubaccountDrawerView) => void;
	includeSubaccountData: boolean;
	subaccounts: Pick<Client, 'id' | 'name'>[];
	isLoadingBranchOffices?: boolean;
	pendingPurchase?: PendingPurchaseType | null;
};

const fetcher = (
	rb: RequestBuilder,
	{ clientId, searchString, sort }: { clientId: number; searchString: string; sort: { field: string; asc: boolean } }
) => {
	rb.addFilter(ClientAttributes.operationalAccount.attr.id, comparisonTypes.Equals, clientId);
	if (searchString.length > 0) {
		const orFilter = rb.orBuilder();
		orFilter.next();
		orFilter.addFilter(ClientAttributes.name, comparisonTypes.Search, searchString);
		orFilter.next();
		orFilter.addFilter(ClientAttributes.users.attr.name, comparisonTypes.Search, searchString);
		orFilter.next();
		orFilter.addFilter({ field: 'address.city' }, comparisonTypes.Search, searchString);
		orFilter.done();
	}
	rb.addSort(sort, sort.asc);
	return ClientResource.find(rb.build());
};

const LIMIT = 10;

const List = ({
	className,
	client,
	goToAccount,
	setIncludeSubaccount,
	setView,
	includeSubaccountData,
	subaccounts,
	isLoadingBranchOffices,
	pendingPurchase
}: Props) => {
	const classes = new BemClass('SubaccountDrawer__List', className);
	const lang = useMemo(
		() => ({
			accountManager: T('default.accountManager'),
			add: T('SubaccountDrawer.add'),
			client: T('default.account'),
			noDataSubtitle1: T('SubaccountDrawer.List.noDataSubtitle1'),
			noDataSubtitle2: T('SubaccountDrawer.List.noDataSubtitle2'),
			noDataTitle: T('SubaccountDrawer.List.noDataTitle'),
			subaccounts: T('account.subaccounts'),
			searchPlaceholder: T('SubaccountDrawer.List.searchPlaceholder')
		}),
		[]
	);

	const [searchString, setSearchString] = useState('');
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState({ field: 'name', asc: true });

	const { loading, data, metadata, refetch } = useGetData({
		broadcastTypes: ['account.updated', 'account.deleted', 'account.subaccountsAdded'],
		broadcastWaitForIndex: true, // IDK why I need this as the client endpoint is running index.update with safe and refresh... but it sometimes doesn't work without it
		fetcher,
		fetcherProps: {
			sort,
			searchString,
			clientId: client.id
		},
		limit: LIMIT,
		offset
	});

	const isFirstRender = useIsFirstRender();
	// Refetch directly on sort and offset change
	useEffect(() => {
		// All useEffects are run on mount, only refetch values changes after that
		if (!isFirstRender) {
			refetch();
		}
	}, [sort, offset]);
	// Debounce searchString changes
	useEffect(() => {
		// All useEffects are run on mount, only refetch values changes after that
		if (!isFirstRender) {
			const timeoutID = setTimeout(() => {
				refetch();
			}, 250);

			return () => clearTimeout(timeoutID);
		}
	}, [searchString]);

	const columns = [{ title: lang.client, sort: 'name' }, { title: lang.accountManager }, { title: '' }];

	return (
		<Flex className={classes.b()} direction="column">
			<Banner
				client={client}
				setIncludeSubaccount={setIncludeSubaccount}
				includeSubaccountData={includeSubaccountData}
				subaccounts={subaccounts}
			/>
			<Block className={classes.elem('Content').b()}>
				<PendingPurchaseBanner pendingPurchase={pendingPurchase} space="mbl" />
				<Flex alignItems="center" justifyContent="space-between" space="mbl">
					<Title>{lang.subaccounts}</Title>
					<Flex>
						{subaccounts.length > 0 ? (
							<Block space="mrl">
								<Input
									icon="search"
									onChange={event => setSearchString(event.target.value ?? '')}
									onClear={() => setSearchString('')}
									placeholder={lang.searchPlaceholder}
									value={searchString}
								/>
							</Block>
						) : null}
						<PrimaryButton
							data-testid="AddButton"
							icon="plus"
							onClick={() => setView(SubaccountDrawerView.Add)}
							loading={isLoadingBranchOffices}
						>
							{lang.add}
						</PrimaryButton>
					</Flex>
				</Flex>
				<Block space="pbxl">
					<Card border="ts rs bs ls" borderColor="grey-4" borderRadius>
						<Table>
							<TableHeader
								asc={sort.asc}
								color="white"
								columns={columns}
								onSortChange={setSort}
								sorting={sort.field}
							/>
							{loading ? (
								<TableRow key={0}>
									<TableColumn colSpan={5} size="lg">
										<Flex justifyContent="center">
											<Loader size="md" />
										</Flex>
									</TableColumn>
								</TableRow>
							) : data.length ? (
								data.map(client => (
									<ListRow key={client.id} client={client} goToAccount={goToAccount} />
								))
							) : (
								<DefaultNoData
									colSpan={3}
									formatNoData={() => lang.noDataTitle}
									subtitle={
										<>
											{lang.noDataSubtitle1}
											<Link onClick={() => setView(SubaccountDrawerView.Add)}>
												{lang.noDataSubtitle2}
											</Link>
										</>
									}
								/>
							)}
						</Table>
					</Card>
					{!loading && metadata.total > LIMIT ? (
						<Flex alignItems="center" flex={1} justifyContent="center" space="mtl">
							<Paginator
								align="center"
								limit={LIMIT}
								offset={offset}
								onChange={setOffset}
								total={metadata.total}
							/>
						</Flex>
					) : null}
				</Block>
			</Block>
		</Flex>
	);
};

export default List;
