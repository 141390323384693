import React from 'react';
import { OrderRow, getReCalculatedNumbers, useOrderRowsSelector } from 'App/components/OrderRows/Context/OrderContext';
import { Text, Block, Title, Row } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { getOrderSummary, DisplaySettings } from './helpers';

type SidebarSummarySectionProps = { title: string; rows: { title: string; value: string }[] };
const SidebarSummarySection = ({ title, rows }: SidebarSummarySectionProps) => {
	return (
		<Block>
			<Title space="mbl">{title}</Title>
			{rows.map((row, index, { length }) => {
				const isLastRow = index === length - 1;

				return (
					<Row key={row.title} align="space-between">
						<Text size={isLastRow ? 'md' : 'sm'} bold={isLastRow}>
							{row.title}
						</Text>
						<Text size={isLastRow ? 'md' : 'sm'} bold={isLastRow}>
							{row.value}
						</Text>
					</Row>
				);
			})}
		</Block>
	);
};

type OrderSummaryProps = { displaySettings: DisplaySettings; currency: string; orderRows: OrderRow[] };
export const OrderSummary = ({ displaySettings, currency, orderRows }: OrderSummaryProps) => {
	const { t } = useTranslation();
	const data = getReCalculatedNumbers(orderRows);
	const rows = getOrderSummary(displaySettings, currency, data);

	return <SidebarSummarySection title={t('default.theOpportunity')} rows={rows} />;
};

type BundleSummaryProps = {
	displaySettings: DisplaySettings;
	currency: string;
	isFixedPriceBundle?: boolean;
	priceData?: { totalAmount: number; totalRecurringValue: number };
};
export const BundleSummary = ({ displaySettings, currency, isFixedPriceBundle, priceData }: BundleSummaryProps) => {
	const { t } = useTranslation();
	const data = useOrderRowsSelector(s => ({
		totalAmount: priceData?.totalAmount ?? s.totalAmount,
		totalDiscount: s.totalDiscount,
		totalRecurringValue: priceData?.totalRecurringValue ?? s.totalRecurringValue,
		totalContributionMargin:
			priceData?.totalAmount !== undefined
				? priceData.totalAmount - s.totalPurchaseCost
				: s.totalContributionMargin
	}));

	const rows = getOrderSummary(displaySettings, currency, data, true, isFixedPriceBundle);

	return <SidebarSummarySection title={t('bundle.sum')} rows={rows} />;
};
