import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import { MultiAction } from 'App/components/ListViewActions';
import { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import Attribute from 'App/babel/attributes/Attribute';
import updateContacts from './updateContacts';
import updateCompany from './updateCompany';
import updateOrder from './updateOrder';
import archiveTickets from './archiveTickets';
import restoreTickets from './restoreTickets';
import removePromise from './removePromise';
import planPhonecalls from './planPhonecalls';
import createActivity from './createActivity';
import createMailCampaign from './createMailCampaign';
import anonymizeContacts from './anonymizeContacts';
import resetScore from './resetScore';
import sendMail from './sendMail';
import addContactsToSegment from './addContactsToSegment';
import addLabel from './addLabel';
import removeLabel from './removeLabel';
import { IconName } from '@upsales/components';
import updateActivities from './updateActivities';
import { MultiSelectContext } from 'App/components/MultiselectProvider/MultiselectProvider';
import { ListView, ListViewFilter } from 'App/resources/AllIWant';
import closeActivities from './closeActivities';
import updateAppointments from './updateAppointments';
import updateTasks from './updateTasks';

export type { MultiAction };

export enum MultiActions {
	UPDATE_APPOINTMENT = 'updateAppointment',
	CLOSE_ACTIVITY = 'closeActivity',
	UPDATE_ACTIVITY = 'updateActivity',
	UPDATE_CONTACT = 'updateContact',
	UPDATE_CONTACTS = 'updateContacts',
	UPDATE_CLIENT = 'updateClient',
	UPDATE_ORDER = 'updateOrder',
	UPDATE_OPPORTUNITY = 'updateOpportunity',
	CREATE_ACTIVITY = 'createActivity',
	CREATE_MAIL_CAMPAIGN = 'createMailCampaign',
	PLAN_PHONECALL = 'planPhonecall',
	UPDATE_TASKS = 'tasks',
	EXPORT = 'export',
	REMOVE = 'remove',
	ANONYMIZE = 'anonymize',
	SEND_MAIL = 'sendMail',
	ADD_CONTACTS_TO_SEGMENT = 'addContactsToSegment',
	RESET_SCORE = 'resetScore',
	ADD_LABEL = 'addLabel',
	REMOVE_LABEL = 'removeLabel',
	ARCHIVE_TICKETS = 'archiveTickets',
	RESTORE_TICKETS = 'restoreTickets'
}

type BaseMultiAction = { id: MultiActions; title: string; icon: IconName; available?: boolean };
type DisableableMultiAction = BaseMultiAction & {
	disabled: boolean | ((selected: number, allSelected: boolean) => boolean);
	disabledTooltip: string;
};
type MultiActionDefinition = BaseMultiAction | DisableableMultiAction;

export const getMultiAction = (
	action: MultiActions,
	hasExportRights: boolean,
	entity: string
): MultiActionDefinition => {
	switch (action) {
		case MultiActions.UPDATE_APPOINTMENT:
			return {
				id: MultiActions.UPDATE_APPOINTMENT,
				title: T('default.update') + ' ' + T('default.appointment').toLowerCase(),
				icon: 'check-square-o'
			};
		case MultiActions.CLOSE_ACTIVITY:
			return { id: MultiActions.CLOSE_ACTIVITY, title: 'default.closeActivities', icon: 'check-square-o' };
		case MultiActions.UPDATE_TASKS:
			return {
				id: MultiActions.UPDATE_TASKS,
				title: T('default.update') + ' ' + T('default.activities').toLowerCase(),
				icon: 'edit'
			};
		case MultiActions.UPDATE_ACTIVITY:
			return {
				id: MultiActions.UPDATE_ACTIVITY,
				title: T('default.update') + ' ' + T('default.activities').toLowerCase(),
				icon: 'edit'
			};
		case MultiActions.UPDATE_CONTACT:
			return { id: MultiActions.UPDATE_CONTACT, title: 'default.updateContacts', icon: 'user' };
		case MultiActions.UPDATE_CONTACTS:
			return {
				id: MultiActions.UPDATE_CONTACTS,
				title: T('default.updateClientContacts'),
				icon: 'user'
			};
		case MultiActions.UPDATE_CLIENT:
			return { id: MultiActions.UPDATE_CLIENT, title: 'default.updateAccounts', icon: 'home' };
		case MultiActions.UPDATE_ORDER:
			return {
				id: MultiActions.UPDATE_ORDER,
				title: 'default.updateOrders',
				icon: 'dollar'
			};
		case MultiActions.UPDATE_OPPORTUNITY:
			return {
				id: MultiActions.UPDATE_ORDER,
				title: 'default.updateOpportunities',
				icon: 'dollar'
			};
		case MultiActions.PLAN_PHONECALL:
			return {
				id: MultiActions.PLAN_PHONECALL,
				title: 'todo.planPhonecalls',
				available: Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST'),
				icon: 'phone'
			};
		case MultiActions.CREATE_ACTIVITY:
			return {
				id: MultiActions.CREATE_ACTIVITY,
				title: 'accountGrowth.quickCreateActivities',
				available:
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
					!(
						Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
						Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					),
				icon: 'activity'
			};
		case MultiActions.EXPORT:
			return {
				id: MultiActions.EXPORT,
				title: 'default.export',
				icon: 'table',
				disabled: !hasExportRights,
				disabledTooltip: 'default.noExportRights'
			};
		case MultiActions.REMOVE:
			return { id: MultiActions.REMOVE, title: 'default.delete', icon: 'trash' };
		case MultiActions.CREATE_MAIL_CAMPAIGN:
			return {
				id: MultiActions.CREATE_MAIL_CAMPAIGN,
				title: 'default.sendGroupMail',
				icon: 'paper-plane',
				disabledTooltip: T('mailCampaign.cantCreate'),
				disabled: (selected: number, allSelected: boolean) =>
					(entity === 'mail' && allSelected) || selected > 1000,
				available: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.GROUP_MAIL)
			};
		case MultiActions.ANONYMIZE:
			return {
				id: MultiActions.ANONYMIZE,
				title: 'admin.anonymization.startProcess',
				icon: 'fa-user-secret' as IconName,
				available: Tools.AppService.getSelf().administrator
			};
		case MultiActions.SEND_MAIL:
			return {
				id: MultiActions.SEND_MAIL,
				title: 'default.sendEmail',
				icon: 'fa-envelope' as IconName,
				disabledTooltip: 'multi.sendMail.maxContacts',
				available: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EMAIL),
				disabled: selected => selected > 100
			};
		case MultiActions.ADD_CONTACTS_TO_SEGMENT:
			return {
				id: MultiActions.ADD_CONTACTS_TO_SEGMENT,
				title: 'default.addToSegment',
				icon: 'fa-code-fork' as IconName,
				disabledTooltip: 'multi.addContactsToSegment.maxContacts',
				disabled: selected => selected > 100,
				available: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SEGMENT)
			};
		case MultiActions.RESET_SCORE:
			return { id: MultiActions.RESET_SCORE, title: 'default.resetScore', icon: 'thumbs-down' };
		case MultiActions.ADD_LABEL:
			return { id: MultiActions.ADD_LABEL, title: 'file.addLabel', icon: 'tag' };
		case MultiActions.REMOVE_LABEL:
			return { id: MultiActions.REMOVE_LABEL, title: 'file.label.remove', icon: 'minus-square' };
		case MultiActions.ARCHIVE_TICKETS:
			return { id: MultiActions.ARCHIVE_TICKETS, title: 'default.archive', icon: 'archive' };
		case MultiActions.RESTORE_TICKETS:
			return { id: MultiActions.RESTORE_TICKETS, title: 'default.restoreFromArchive', icon: 'undo' };
		default:
			throw new Error('multiActionRunnerHelpers.getMultiAction - Action not found: ' + action);
	}
};

export const getMultiActions = (actions: MultiActions[], hasExportRights = false, entity: string) =>
	actions
		.map(action => getMultiAction(action, hasExportRights, entity))
		.filter(action => action.available || typeof action.available !== 'boolean');

export type FilterGet = (rb: RequestBuilder, multiselect: MultiSelect) => RequestBuilder;

export const defaultFilterGetter: FilterGet = (requestBuilder, multiselect) => {
	if (multiselect.allSelected) {
		return requestBuilder;
	}
	const rb = new RequestBuilder();
	const idAttribute = Attribute({
		title: 'default.id',
		field: 'id',
		type: Attribute.types.Number
	});
	rb.addFilter(idAttribute, comparisonTypes.Equals, multiselect.selectedIds);
	return rb;
};

type RunMultiActionType = {
	action: MultiAction;
	provided: {
		requestBuilder: RequestBuilder | null;
		selectedView: ListView | null;
		filters: { [filterName: string]: ListViewFilter };
	};
	multiselect: MultiSelectContext;
	getFilters?: FilterGet;
	entityType: string;
	notifyEntity: string;
	extraParams?: { [key: string]: any };
	options?: { [key: string]: any };
	name?: string | null;
	justExport?: boolean;
	notificationEntityNameExtension?: string;
};

export const runMultiAction = ({
	action,
	provided,
	multiselect,
	entityType,
	notifyEntity,
	getFilters = defaultFilterGetter,
	extraParams = undefined,
	options,
	name = null,
	justExport = true,
	notificationEntityNameExtension = undefined
}: RunMultiActionType) => {
	const { requestBuilder, selectedView, filters } = provided;
	if (!requestBuilder) {
		return;
	}

	switch (action.id) {
		case MultiActions.UPDATE_APPOINTMENT:
			return updateAppointments(getFilters(requestBuilder, multiselect), entityType, multiselect);
		case MultiActions.CLOSE_ACTIVITY:
			return closeActivities(getFilters(requestBuilder, multiselect), entityType, multiselect);
		case MultiActions.UPDATE_TASKS:
			return updateTasks(getFilters(requestBuilder, multiselect), entityType, multiselect);
		case MultiActions.UPDATE_ACTIVITY:
			return updateActivities(getFilters(requestBuilder, multiselect), entityType, multiselect);
		case MultiActions.UPDATE_CONTACT:
			return updateContacts(getFilters(requestBuilder, multiselect), false, entityType, extraParams, multiselect);
		case MultiActions.UPDATE_CONTACTS:
			return updateContacts(getFilters(requestBuilder, multiselect), true, entityType, extraParams, multiselect);
		case MultiActions.UPDATE_CLIENT:
			return updateCompany(getFilters(requestBuilder, multiselect), entityType, extraParams, multiselect);
		case MultiActions.CREATE_ACTIVITY:
			return createActivity(
				getFilters(requestBuilder, multiselect),
				entityType,
				name,
				extraParams,
				multiselect.selected
			);
		case MultiActions.PLAN_PHONECALL:
			return planPhonecalls(
				getFilters(requestBuilder, multiselect),
				entityType,
				multiselect.selected,
				multiselect.selectedIds
			);
		case MultiActions.EXPORT: {
			const exportType = notifyEntity === 'accounts' ? 'accounts' : entityType.toLowerCase();
			// Fields should not have any values at this point, because we do not yet know which fields will be chosen
			// Without this some fields would be empty when default exporting in AccountGrowth
			requestBuilder.fields = [];

			return Tools.$upModal.open('ExportData', {
				justExport,
				columns: selectedView?.columns || [],
				optionsByType: true,
				filters: getFilters(requestBuilder, multiselect).build(),
				entity: entityType,
				exportType: exportType,
				sort: { attribute: 'id', ascending: true },
				options,
				selectedIds: multiselect.selectedIds,
				allSelected: multiselect.allSelected
			});
		}
		case MultiActions.REMOVE:
			return removePromise(
				getFilters(requestBuilder, multiselect),
				entityType,
				notifyEntity,
				name,
				multiselect.allSelected ? [] : multiselect.selectedIds,
				multiselect.selected,
				notificationEntityNameExtension
			);
		case MultiActions.UPDATE_ORDER:
			return updateOrder(getFilters(requestBuilder, multiselect), entityType, multiselect);
		case MultiActions.CREATE_MAIL_CAMPAIGN:
			return createMailCampaign(filters, multiselect.allSelected, multiselect.selectedIds, entityType);
		case MultiActions.SEND_MAIL:
			return sendMail(getFilters(requestBuilder, multiselect));
		case MultiActions.ANONYMIZE:
			return anonymizeContacts(requestBuilder, multiselect);
		case MultiActions.ADD_CONTACTS_TO_SEGMENT:
			return addContactsToSegment(
				getFilters(requestBuilder, multiselect),
				multiselect.allSelected,
				multiselect.selectedIds
			);
		case MultiActions.RESET_SCORE: {
			return resetScore(getFilters(requestBuilder, multiselect), entityType, extraParams);
		}
		case MultiActions.ADD_LABEL: {
			return addLabel(getFilters(requestBuilder, multiselect), entityType, multiselect.selected);
		}
		case MultiActions.REMOVE_LABEL: {
			return removeLabel(getFilters(requestBuilder, multiselect), entityType, multiselect.selected);
		}
		case MultiActions.ARCHIVE_TICKETS:
			return archiveTickets(getFilters(requestBuilder, multiselect), entityType, multiselect.selected);
		case MultiActions.RESTORE_TICKETS:
			return restoreTickets(getFilters(requestBuilder, multiselect), entityType, multiselect.selected);
	}
};
