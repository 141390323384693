import { getFrameName, handleIntegrationIframeMessage, postObjectChangeToFrame } from 'App/helpers/uiElementsHelper';
import React, { useCallback, useEffect, useRef } from 'react';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

export default function IFrameRenderType({ row, config, type, object, utils }) {
	const FeatureHelper = getAngularModule('FeatureHelper');
	const hasIframeRowPostObjectChange = FeatureHelper.hasSoftDeployAccess('IFRAME_ROW_POST_OBJECT_CHANGE');

	const iframeRef = useRef();
	var iframeProps = {
		className: 'widget-iframe',
		sandbox: 'allow-scripts',
		name:
			hasIframeRowPostObjectChange && row.name
				? getFrameName({ ...config, name: row.name })
				: `ui-element-${config.integrationId}-${row.id || '0'}`,
		style: {},
		ref: iframeRef
	};

	if (row.url) {
		iframeProps.src = row.url;
	} else if (row.html) {
		iframeProps.srcDoc = row.html;
	}

	if (row.height && typeof row.height === 'number') {
		iframeProps.style.height = `${row.height}px`;
	}

	if (row.width && typeof row.width === 'number') {
		iframeProps.style.width = `${row.width}px`;
	}

	if (row.scrolling === false) {
		row.scrolling = 'no';
	}

	const onLoad = () => {
		if (hasIframeRowPostObjectChange) {
			postObjectChangeToFrame(config, object);
		}
	};

	const iframeMessage = useCallback(
		event => {
			handleIntegrationIframeMessage(type, config.integrationId, () => object, utils, event);
		},
		[type, config]
	);

	useEffect(() => {
		window.addEventListener('message', iframeMessage);
		return () => {
			window.removeEventListener('message', iframeMessage);
		};
	}, [iframeMessage]);

	useEffect(() => {
		if (hasIframeRowPostObjectChange) {
			postObjectChangeToFrame(config, object);
		}
	}, [config, JSON.stringify(object)]);

	useEffect(() => {
		if (hasIframeRowPostObjectChange) {
			iframeRef.current?.addEventListener('load', onLoad);
		}

		return () => {
			if (hasIframeRowPostObjectChange) {
				iframeRef.current?.removeEventListener('load');
			}
		};
	}, []);

	iframeProps.scrolling = row.scrolling || 'auto';

	// row 95 to 101 in UiElements.js directive
	// hasnt been ported here yet.

	return <div className="widget-iframe-wrapper">{React.createElement('iframe', iframeProps)}</div>;
}
