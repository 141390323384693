import { ClickableItem, DropDownMenu, EllipsisTooltip, Flex, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { getMainRecipient } from '../Context/Helpers';
import React from 'react';
import { ExternalContact } from 'App/resources/Model/Ticket';
import { useEditTicketContext } from '../Context/Context';
import BemClass from '@upsales/components/Utils/bemClass';
import { dateCalendar } from 'App/helpers/DateHelpers';

type Props = {
	classes: BemClass;
	verticalAlign?: 'top' | 'bottom' | 'center';
	disabled?: boolean;
	tooltipAnchorRef: React.RefObject<HTMLDivElement>;
	onOpen?: () => void;
	onClose?: () => void;
};
const ExternalContactDropdown = ({ classes, verticalAlign, disabled, tooltipAnchorRef, onOpen, onClose }: Props) => {
	const { t } = useTranslation();
	const {
		state: {
			ticket: { externalContacts },
			activeExternalContactId
		},
		setActiveExternalContactId
	} = useEditTicketContext();

	return (
		<DropDownMenu
			renderTrigger={(expanded, setExpanded) => (
				<ClickableItem onClick={setExpanded} icon="chevron-down" borderRadius size="sm" disabled={disabled} />
			)}
			verticalAlign={verticalAlign ?? 'bottom'}
			onOpen={onOpen}
			onClose={onClose}
		>
			{closeDropdown => (
				<Flex direction="column" className={classes.elem('external-contact-dropdown').b()}>
					<Flex className={classes.elem('external-contact-dropdown-header').b()}>
						<Text size="sm" bold>
							{t('ticket.externalContacts')}
						</Text>
					</Flex>
					{(externalContacts ?? []).map((ec: ExternalContact) => (
						<Flex
							key={ec.id}
							onClick={() => {
								setActiveExternalContactId(ec.id);
								closeDropdown();
							}}
							direction="column"
							className={classes
								.elem('external-contact-dropdown-item')
								.mod({ active: ec.id === activeExternalContactId })
								.b()}
							gap="u1"
							justifyContent="center"
						>
							<Flex alignItems="center" gap="u2">
								<EllipsisTooltip
									title={
										getMainRecipient(ec.involved)?.contact?.name ||
										getMainRecipient(ec.involved)?.email
									}
									appendTo={tooltipAnchorRef.current as HTMLDivElement}
								>
									<Text
										size="md"
										bold
										align="left"
										className={classes.elem('external-contact-dropdown-item-title').b()}
									>
										{getMainRecipient(ec.involved)?.contact?.name ||
											getMainRecipient(ec.involved)?.email}
									</Text>
								</EllipsisTooltip>
								{ec.lastUpdated ? (
									<Text size="sm" align="left">
										{dateCalendar(ec.lastUpdated, true)}
									</Text>
								) : null}
							</Flex>
							{ec.client?.name ? (
								<EllipsisTooltip
									title={ec.client.name}
									appendTo={tooltipAnchorRef.current as HTMLDivElement}
								>
									<Text
										align="left"
										size="sm"
										className={classes.elem('external-contact-dropdown-item-subtitle').b()}
									>
										{ec.client.name}
									</Text>
								</EllipsisTooltip>
							) : null}
						</Flex>
					))}
				</Flex>
			)}
		</DropDownMenu>
	);
};

export default ExternalContactDropdown;
