import _ from 'lodash';
import { StandardFieldConfig } from '../AllIWant';

const defaultNameTags = {
	Name: 'default.name',
	Phone: 'default.phone',
	User: 'default.user',
	Notes: 'default.notes',
	About: 'default.about',
	Client: 'default.client',
	Contact: 'default.contact',
	Title: 'default.title',
	Cellphone: 'default.cellPhone',
	Email: 'default.email',
	Date: 'default.date',
	Time: 'default.time',
	Description: 'default.description',
	Project: 'default.project',
	Type: 'default.type',
	LinkedIn: 'leadSource.linkedin'
} as { [key: string]: string };

const defaultIcons = {
	Phone: { class: 'Icon Icon-phone' },
	User: { class: 'Icon Icon-user' },
	Notes: { class: 'Icon Icon-pencil' },
	About: { class: 'Icon Icon-file' },
	Title: { class: 'Icon Icon-user-circle-o' },
	Cellphone: { class: 'Icon Icon-mobile' },
	Email: { class: 'Icon Icon-envelope' },
	Date: { class: 'Icon Icon-calendar' },
	Time: { class: 'Icon Icon-clock-o' },
	Description: { class: 'A' },
	Project: { class: 'Icon Icon-tags' },
	LinkedIn: { class: 'Icon Icon-linkedin' }
} as { [key: string]: { class: string } };

type Config = {
	[key: string]: {
		[key: string]: {
			nameTag?: string;
			descTag?: string;
			icon?: { class: string };
		};
	};
};
const config = {
	Client: {
		Name: {},
		Adress: {
			nameTag: 'tag.client.address.post',
			icon: {
				class: 'Icon Icon-map-marker',
				name: 'map-marker'
			}
		},
		Phone: {},
		Fax: {
			nameTag: 'default.fax',
			icon: {
				class: 'Icon Icon-fax',
				name: 'fax'
			}
		},
		Www: {
			nameTag: 'default.webpage',
			icon: {
				class: 'Icon Icon-globe',
				name: 'globe'
			}
		},
		User: {
			nameTag: 'default.accountManager',
			descTag: 'admin.fields.description.client.user'
		},
		Parent: {
			nameTag: 'default.parentCompany',
			icon: {
				class: 'Icon Icon-sitemap',
				name: 'sitemap'
			}
		},
		Project: {},
		Status: {
			nameTag: 'default.status',
			descTag: 'admin.fields.description.client.status',
			icon: {
				class: 'Icon Icon-university',
				name: 'university'
			}
		},
		Notes: {},
		About: {},
		VisitAddress: {
			nameTag: 'tag.client.address.visit',
			icon: {
				class: 'Icon Icon-map-marker',
				name: 'map-marker'
			}
		},
		DeliveryAddress: {
			nameTag: 'tag.client.address.delivery',
			icon: {
				class: 'Icon Icon-map-marker',
				name: 'map-marker'
			}
		},
		InvoiceAddress: {
			nameTag: 'tag.client.address.invoice',
			icon: {
				class: 'Icon Icon-map-marker',
				name: 'map-marker'
			}
		},
		OtherAddress: {
			nameTag: 'tag.client.address.other',
			icon: {
				class: 'Icon Icon-map-marker',
				name: 'map-marker'
			}
		},
		OrgNo: {
			nameTag: 'default.orgNumber',
			icon: {
				class: 'icon number',
				name: 'integer',
				value: '42'
			}
		},
		Cfar: {
			nameTag: 'default.cfar',
			descTag: 'admin.fields.description.client.cfar',
			icon: {
				class: 'Icon Icon-building',
				name: 'home'
			}
		},
		RegistrationDate: {
			nameTag: 'default.founded',
			icon: {
				class: 'Icon Icon-key',
				name: 'key'
			}
		},
		UKSicCode: {
			nameTag: 'default.companyBranch.uksic',
			descTag: 'admin.fields.description.client.uksicCode',
			icon: {
				class: 'Icon Icon-industry',
				name: 'industry'
			}
		},
		NaceCode: {
			nameTag: 'default.companyBranch.nace',
			descTag: 'admin.fields.description.client.naceCode',
			icon: {
				class: 'Icon Icon-industry',
				name: 'industry'
			}
		},
		SniCode: {
			nameTag: 'default.companyBranch.sni',
			descTag: 'admin.fields.description.client.sniCode',
			icon: {
				class: 'Icon Icon-industry',
				name: 'industry'
			}
		},
		SicCode: {
			nameTag: 'default.companyBranch.sic',
			descTag: 'admin.fields.description.client.sicCode',
			icon: {
				class: 'Icon Icon-industry',
				name: 'industry'
			}
		},
		NaicsCode: {
			nameTag: 'default.companyBranch.naics',
			descTag: 'admin.fields.description.client.naicsCode',
			icon: {
				class: 'Icon Icon-industry',
				name: 'industry'
			}
		},
		CompanyForm: {
			nameTag: 'soliditet.corporateForm',
			descTag: 'admin.fields.description.client.companyForm',
			icon: {
				class: 'Icon Icon-industry',
				name: 'industry'
			}
		},
		CreditRating: {
			nameTag: 'default.creditRating.bisnode',
			descTag: 'admin.fields.description.client.creditRating',
			icon: {
				class: 'Icon Icon-star-half-o',
				name: 'star-half-o'
			}
		},
		ProspectingCreditRating: {
			nameTag: 'admin.fields.prospectingCreditRating',
			descTag: 'admin.fields.description.client.prospectingCreditRating',
			icon: {
				class: 'Icon Icon-star-half-o',
				name: 'star-half-o'
			}
		},
		Turnover: {
			nameTag: 'default.turnover',
			icon: {
				class: 'Icon Icon-money',
				name: 'money'
			}
		},
		Profit: {
			nameTag: 'default.profit',
			icon: {
				class: 'Icon Icon-dollar',
				name: 'dollar'
			}
		},
		NoEmployees: {
			nameTag: 'default.numOfEmployees',
			icon: {
				class: 'Icon Icon-users',
				name: 'users'
			}
		},
		Facebook: {
			nameTag: 'leadSource.facebook',
			icon: {
				class: 'Icon Icon-facebook',
				name: 'facebook'
			}
		},
		Twitter: {
			nameTag: 'leadSource.twitter',
			icon: {
				class: 'Icon Icon-twitter',
				name: 'twitter'
			}
		},
		LinkedIn: {
			nameTag: 'default.linkedin',
			icon: {
				class: 'Icon Icon-linkedin',
				name: 'linkedin'
			}
		}
	},
	Contact: {
		Client: {},
		Name: {},
		FirstName: {
			nameTag: 'default.firstName',
			icon: {
				class: 'Icon Icon-address-card',
				name: 'address-card'
			}
		},
		LastName: {
			nameTag: 'default.lastName',
			icon: {
				class: 'Icon Icon-address-card',
				name: 'address-card'
			}
		},
		Salutation: {
			nameTag: 'default.salutation',
			descTag: 'admin.fields.description.contact.salutation',
			icon: {
				class: 'Icon Icon-list-ul',
				name: 'list-ul'
			}
		},
		TitleCategory: {
			nameTag: 'default.titlecategory',
			descTag: 'default.titlecategory',
			icon: {
				class: 'Icon Icon-user-circle',
				name: 'user-circle'
			}
		},
		Title: {},
		Phone: {},
		Cellphone: {},
		Email: {},
		Notes: {},
		LinkedIn: {}
	},
	Activity: {
		Date: {},
		Time: {},
		Client: {},
		Description: {},
		Contact: {},
		Type: {},
		User: {},
		Project: {},
		Notes: {}
	},
	Appointment: {
		Description: {},
		Type: {},
		StartDate: {},
		StartTime: {},
		Client: {},
		EndDate: {},
		EndTime: {},
		Contact: {},
		Project: {},
		Notes: {}
	},
	Order: {
		Date: {},
		Client: {},
		Description: {},
		Contact: {},
		Stage: {},
		User: {},
		Probability: {
			nameTag: 'default.probability',
			descTag: 'order.standardField.probabilityDesc',
			elevioId: 723,
			icon: {
				class: 'Icon Icon-percent',
				name: 'percent'
			}
		},
		Project: {},
		Notes: {},
		ClientOrderRelation: {
			nameTag: 'order.standardField.relationTitle',
			descTag: 'order.standardField.relationDesc',
			icon: {
				class: 'Icon Icon-sitemap',
				name: 'sitemap'
			}
		},
		OrderLostReason: {
			nameTag: 'default.lostReason',
			descTag: 'default.lostReason',
			icon: {
				class: 'Icon Icon-exclamation-triangle',
				name: 'exclamation-triangle'
			}
		},
		OpportunityWonLost: {
			nameTag: 'order.standardField.opportunityWonLostTitle',
			descTag: 'order.standardField.opportunityWonLostDesc',
			icon: {
				class: 'Icon Icon-exclamation-triangle',
				name: 'exclamation-triangle'
			},
			elevioId: 1239
		},
		Competitor: {
			nameTag: 'order.standardField.competitorTitle',
			descTag: 'order.standardField.competitorDesc',
			drawer: 'CompetitorDrawer',
			icon: {
				class: 'Icon Icon-thumbs-down',
				name: 'thumbs-down'
			}
		},
		ShowCopyButton: {
			nameTag: 'order.standardField.showCopyButtonTitle',
			descTag: 'order.standardField.showCopyButtonDesc',
			icon: {
				class: 'Icon Icon-copy',
				name: 'copy'
			}
		}
	},
	Agreement: {
		Notes: {}
	},
	Product: {
		ArticleNo: {
			nameTag: 'product.articleNo',
			descTag: 'product.articleNo.description',
			icon: {
				class: 'icon',
				value: 'A',
				name: ''
			}
		},
		Description: {
			nameTag: 'default.description',
			descTag: 'product.description.description',
			icon: {
				class: 'icon',
				value: 'A',
				name: ''
			}
		}
	}
} as Config;

// Set default values for fields with same name.
// For example phone, notes and other fields which occur on multiple objects.
_.each(config, function (typeFields) {
	_.each(typeFields, function (fieldConfig, fieldName) {
		if (fieldName && !fieldConfig.icon) {
			fieldConfig.icon = defaultIcons[fieldName];
		}
		if (fieldName && !fieldConfig.nameTag) {
			fieldConfig.nameTag = defaultNameTags[fieldName];
		}
	});
});

type StandardFieldConfigMap = { [key: string]: StandardFieldConfig };

export default function (standardFieldConfigs: { [key: string]: StandardFieldConfigMap }, hasNewFields?: boolean) {
	const mapped: { [key: string]: StandardFieldConfigMap } = {};
	hasNewFields = hasNewFields ?? Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

	Object.keys(standardFieldConfigs).forEach(type => {
		const fields = standardFieldConfigs[type];
		const typeFields = config[type] || {};

		const standardFields: StandardFieldConfigMap = {};
		_.each(fields, function (field: StandardFieldConfig, key) {
			// eslint-disable-next-line
			_.assign(field, typeFields[key ?? '']);
			if (!hasNewFields && type === 'Client' && field.id > 14) {
				return;
			}
			if (!hasNewFields && type === 'Contact' && field.id > 36) {
				return;
			}
			standardFields[field.name] = field;
		});
		mapped[type] = standardFields;
	});

	return mapped;
}
