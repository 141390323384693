import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import Contact from './Model/Contact';
import ResourceTyped from 'Resources/ResourceTyped';

class ContactResource extends ResourceTyped<Contact> {
	eventName: string = 'contact';
	constructor() {
		super('contacts');
	}

	new(): Partial<Contact> & Pick<Contact, 'journeyStep' | 'categories' | 'custom'> {
		return {
			journeyStep: 'lead',
			categories: [],
			custom: []
		};
	}

	findByRelevance(opts: { limit: number; offset: number; accountId: number; subAccountIds?: number[] }) {
		return this.get('', { _url: 'relevance/contacts', params: opts });
	}

	search(
		searchString: string,
		clientIds?: number | null | number[],
		limit?: number,
		requestBuilder = new RequestBuilder(),
		customFieldClientIds?: number[]
	) {
		const LIMIT = limit ?? 10;
		const hasCustomContactSearch = Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_CONTACT_CUSTOM_FIELD_SEARCH');

		requestBuilder.addFilter({ field: 'active' }, comparisonTypes.Equals, true);
		const orBuilder = requestBuilder.orBuilder();
		if (searchString.length > 0) {
			const searchableContactFields = hasCustomContactSearch
				? (Tools.AppService.getCustomFields('contact') || [])
						.filter(cf => cf.searchable)
						.map(function (field) {
							return field.id;
						})
				: [];

			const orFilter = orBuilder.orBuilder();
			orFilter.next();
			orFilter.addFilter({ field: 'name' }, comparisonTypes.Search, searchString);
			orFilter.next();
			orFilter.addFilter({ field: 'email' }, comparisonTypes.Search, searchString);
			orFilter.next();
			orFilter.addFilter({ field: 'client.name' }, comparisonTypes.Search, searchString);
			orFilter.next();
			orFilter.addFilter({ field: 'phone' }, comparisonTypes.Search, searchString.replace(/\s/g, ''));
			orFilter.next();
			orFilter.addFilter({ field: 'cellPhone' }, comparisonTypes.Search, searchString.replace(/\s/g, ''));

			if (searchableContactFields.length) {
				orFilter.next();
				const groupFilter = orFilter.groupBuilder();
				groupFilter.addFilter({ field: 'custom.fieldId' }, comparisonTypes.Equals, searchableContactFields);
				groupFilter.addFilter({ field: 'custom.value' }, comparisonTypes.Search, searchString);
				groupFilter.done();
			}
			orFilter.done();
		}

		if (clientIds) {
			const orFilter = orBuilder.orBuilder();
			orFilter.next();
			orFilter.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientIds);
			orFilter.next();
			orFilter.addFilter({ field: 'connectedClients.relatedToClientId' }, comparisonTypes.Equals, clientIds);
			orFilter.done();
		}
		if (customFieldClientIds?.length) {
			orBuilder.next();
			orBuilder.addFilter({ field: 'client.id' }, comparisonTypes.Equals, customFieldClientIds);
		}
		orBuilder.done();

		requestBuilder.addSort('name', true);
		requestBuilder.limit = LIMIT;

		return this.find(requestBuilder.build());
	}
}

const resource = new ContactResource();

export default resource;
