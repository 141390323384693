import { Block, Card, Button, Icon, Label, Input, OutsideClick, Loader, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useMemo, useState } from 'react';
import T from 'Components/Helpers/translate';
import './RelationSelect.scss';
import { SlideFade } from 'App/components/animations';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import logError from 'App/babel/helpers/logError';
import Relation, { AvailableRelationConfig } from 'App/resources/Model/EntityRelation';
import { fetch, getRelations } from './helpers/fetchHelpers';
import RelationRow from './RelationRow';
import mapRelations, {
	activityRelationMapper,
	opportunityRelationMapper,
	appointmentRelationMapper
} from './helpers/relationMappers';
import RelatedRelations from './RelatedRelations';
import moment from 'moment';
import SelectedRelations from './SelectedRelations';
import Activity from 'App/resources/Model/Activity';
import Appointment from 'App/resources/Model/Appointment';
import Order from 'App/resources/Model/Order';

export type OnChangeValue = {
	[key: string]: Relation['id'] | null | undefined;
};

type Props = Omit<React.ComponentProps<typeof Block>, 'onChange'> & {
	onChange: (value: OnChangeValue) => void;
	onClose?: () => void;
	userId: number;
	client?: Pick<Client, 'id' | 'name'> | null;
	contact?: Pick<Contact, 'id' | 'name'> | null;
	opportunityId?: number | null;
	appointmentId?: number | null;
	activityId?: number | null;
	disabled?: boolean;
	inputRef?: (r: HTMLInputElement) => void;
	onInputBlur?: () => void;
	label?: string;
	autofocus?: boolean;
	autofocusDelay?: number;
	availableEntities?: AvailableRelationConfig;
	multiple?: boolean;
	disableOutsideClick?: boolean;
	openedFromDropdown?: boolean;
	onFetchDone?: () => void;
	hideRelated?: boolean;
	filterOutRelations?: Pick<Relation, 'type' | 'id'>[];
	activity?: Activity;
	appointment?: Appointment;
	opportunity?: Order;
};

const RelationSelect = ({
	client,
	contact,
	userId,
	onChange,
	onClose = () => {},
	opportunityId,
	appointmentId,
	activityId,
	disabled = false,
	inputRef,
	onInputBlur,
	label,
	availableEntities,
	multiple = true,
	autofocus = false,
	autofocusDelay = 0,
	disableOutsideClick = false,
	openedFromDropdown = false,
	onFetchDone,
	hideRelated,
	filterOutRelations = [],
	activity: initalActivity,
	appointment: initalAppointment,
	opportunity: initalOpportunity,
	...props
}: Props) => {
	const classes = new BemClass('RelationSelect');
	const [relations, setRelations] = useState<Relation[]>([]);
	const [open, setOpen] = useState(false);
	const [isFirstFetch, setIsFirstFetch] = useState(true);
	const [searching, setSearching] = useState(false);
	const mainRef = React.useRef<HTMLDivElement>();
	const [search, setSearch] = useState('');
	const hasSelectedValues = [appointmentId, opportunityId, activityId].some(entity => !!entity);

	const [selectedOpportunity, setSelectedOpportunity] = useState<Relation | null>(null);
	const [selectedAppointment, setSelectedAppointment] = useState<Relation | null>(null);
	const [selectedActivity, setSelectedActivity] = useState<Relation | null>(null);

	const existingRelations = useMemo(
		() => ({
			opportunityId,
			appointmentId,
			activityId
		}),
		[]
	);
	useEffect(() => {
		if (!selectedActivity && !selectedAppointment && !selectedOpportunity) return;
		const value: OnChangeValue = { opportunityId: null, appointmentId: null, activityId: null };
		onChange(value);
		setSelectedOpportunity(null);
		setSelectedAppointment(null);
		setSelectedActivity(null);
	}, [userId]);

	useEffect(() => {
		if (initalOpportunity) {
			setSelectedOpportunity(opportunityRelationMapper(initalOpportunity, contact));
		} else {
			setSelectedOpportunity(relations.find(rel => rel.id === opportunityId) ?? null);
		}
	}, [opportunityId]);

	useEffect(() => {
		if (initalAppointment) {
			setSelectedAppointment(appointmentRelationMapper(initalAppointment, userId, contact));
		} else {
			setSelectedAppointment(relations.find(rel => rel.id === appointmentId) ?? null);
		}
	}, [appointmentId]);

	useEffect(() => {
		if (initalActivity) {
			setSelectedActivity(activityRelationMapper(initalActivity, userId, contact));
		} else {
			setSelectedActivity(relations.find(rel => rel.id === activityId) ?? null);
		}
	}, [activityId]);

	useEffect(() => {
		if (!opportunityId && !appointmentId && !activityId) return;
		let fetchSelectedPromise: ReturnType<typeof getRelations> | null = null;
		fetchSelectedPromise = getRelations(
			initalOpportunity ? undefined : opportunityId,
			initalAppointment ? undefined : appointmentId,
			initalActivity ? undefined : activityId
		);

		fetchSelectedPromise?.promise
			.then(([opportunity, appointment, activity]) => {
				const selectedRelations = mapRelations(
					[initalOpportunity ?? opportunity],
					[initalAppointment ?? appointment],
					[initalActivity ?? activity],
					userId,
					contact
				);
				setSelectedOpportunity(selectedRelations.find(rel => rel.id === opportunityId) ?? null);
				setSelectedAppointment(selectedRelations.find(rel => rel.id === appointmentId) ?? null);
				setSelectedActivity(selectedRelations.find(rel => rel.id === activityId) ?? null);
			})
			.catch(err => logError(err, 'Failed to set selected relations'));

		return () => {
			fetchSelectedPromise?.cancel();
		};
	}, []);

	useEffect(() => {
		let fetchPromise: ReturnType<typeof fetch> | null = null,
			debounce: ReturnType<typeof setTimeout> | null = null;

		if (client?.id) {
			setSearching(true);
			debounce = setTimeout(() => {
				fetchPromise = fetch(search, userId, client?.id || null, availableEntities);
				fetchPromise.promise
					.then(([opportunities, appointments, activities]) => {
						const relations = mapRelations(opportunities, appointments, activities, userId, contact).filter(
							item =>
								!filterOutRelations.some(
									filterItem => filterItem.id === item.id && filterItem.type === item.type
								)
						);
						setRelations(relations);
						if (isFirstFetch) {
							setIsFirstFetch(false);
							onFetchDone?.();
						}
					})
					.catch(err => logError(err, 'Failed to fetch appointment & opportunity'))
					.finally(() => {
						setSearching(false);
					});
			}, 300);
		}
		return () => {
			if (debounce) {
				clearTimeout(debounce);
			}
			if (fetchPromise) {
				fetchPromise.cancel();
			}
		};
	}, [client?.id, search, userId]);

	useEffect(() => {
		let autofocusTimeout: NodeJS.Timeout;
		if (autofocus) {
			autofocusTimeout = setTimeout(() => setOpen(true), autofocusDelay);
		}

		return () => {
			if (autofocus && autofocusTimeout) {
				clearTimeout(autofocusTimeout);
			}
		};
	}, [autofocus]);

	useEffect(() => {
		const listeners = [
			Tools.$rootScope.$on('opportunity.updated', (e, opportunity) => {
				setSelectedOpportunity(currentSelectedOpportunity =>
					currentSelectedOpportunity?.id === opportunity.id &&
					currentSelectedOpportunity?.type === 'opportunity'
						? opportunityRelationMapper(opportunity, contact)
						: currentSelectedOpportunity
				);
				setRelations(currentRelations => {
					const relationsCopy = [...currentRelations];
					const index = relationsCopy.findIndex(
						rel => rel.type === 'opportunity' && rel.id === opportunity.id
					);
					if (index !== -1) {
						relationsCopy[index] = opportunityRelationMapper(opportunity, contact) as Relation;
						return relationsCopy;
					}
					return currentRelations;
				});
			})
		];
		return () => {
			listeners.map(listener => listener());
		};
	}, []);

	const toggleRow = (rel: Relation) => {
		if (!multiple && hasSelectedValues) return;
		const value: OnChangeValue = { opportunityId, appointmentId, activityId };
		if (value[`${rel.type}Id`] === rel.id) {
			value[`${rel.type}Id`] = null;
		} else {
			value[`${rel.type}Id`] = rel.id;
		}
		onChange(value);

		// Close dropdown if we only expect one selection
		if (value[`${rel.type}Id`] === rel.id && !multiple) setOpen(false);
	};

	const relationRow = (rel: Relation | undefined) => {
		if (!rel) {
			return null;
		}
		return (
			<RelationRow
				key={`${rel.type}-${rel.id}`}
				relation={rel}
				toggleRow={toggleRow}
				selectedOpportunityId={opportunityId}
				selectedAppointmentId={appointmentId}
				selectedActivityId={activityId}
			/>
		);
	};

	const groupedRelations = useMemo(() => {
		return relations.reduce<{
			withClient: Relation[];
			withContact: Relation[];
			withClientOld: Relation[];
			withContactOld: Relation[];
		}>(
			(res, relation) => {
				if (relation.withContact) {
					if (relation.date && moment(relation.date).diff(moment(), 'days') < -365)
						res.withContactOld.push(relation);
					else {
						res.withContact.push(relation);
					}
				} else {
					if (relation.date && moment(relation.date).diff(moment(), 'days') < -365)
						res.withClientOld.push(relation);
					else {
						res.withClient.push(relation);
					}
				}
				return res;
			},
			{ withContact: [], withClient: [], withContactOld: [], withClientOld: [] }
		);
	}, [relations]);

	const showRelated = !!relations.length || !!search || initalActivity || initalAppointment || initalOpportunity;

	if (!client || !showRelated) {
		if (openedFromDropdown) {
			return (
				<Text className={classes.elem('notFound').b()} italic color="grey-11" align="center">
					{T('relationDropdown.notFound')}
				</Text>
			);
		}
		return null;
	}

	const showInput = multiple || [appointmentId, opportunityId, activityId].every(entity => entity === null);
	const selectedRelations = [selectedOpportunity, selectedAppointment, selectedActivity].filter(r => r) as Relation[];
	return (
		<Block {...props} className={classes.b()}>
			<OutsideClick
				targetRef={() => mainRef.current || null}
				listen={open && !disableOutsideClick}
				outsideClick={() => {
					setOpen(false);
					setSearch('');
					onClose();
					onInputBlur?.();
				}}
			>
				<div
					className={classes.elem('content').mod({ openedFromDropdown }).b()}
					ref={(r: HTMLInputElement) => (mainRef.current = r)}
				>
					{label ? <Label>{label}</Label> : null}
					<SlideFade visible={showInput} direction="top">
						<Input
							className={classes.elem('input').b()}
							inputRef={r => {
								if (r) {
									inputRef?.(r);
								}
							}}
							icon="search"
							placeholder={T('relateTo.searchActivites')}
							value={search}
							onFocus={() => setOpen(true)}
							onChange={e => setSearch(e.target.value)}
							disabled={disabled}
						/>
					</SlideFade>

					{openedFromDropdown ? (
						<RelatedRelations
							opportunityId={existingRelations.opportunityId}
							appointmentId={existingRelations.appointmentId}
							activityId={existingRelations.activityId}
							selectedOpportunityId={opportunityId}
							selectedAppointmentId={appointmentId}
							selectedActivityId={activityId}
							userId={userId}
							contact={contact}
							toggleRow={toggleRow}
						/>
					) : null}

					<SlideFade direction="top" bounce visible={showRelated && open}>
						<Card className={openedFromDropdown ? '' : classes.elem('dropdown').b()}>
							<Block className={classes.elem('card').b()}>
								{searching ? <Loader size="sm" /> : null}
								{groupedRelations.withContact.length ? (
									<Label>{`${T('todo.ongoingWith')} ${contact?.name}`}</Label>
								) : null}
								{groupedRelations.withContact.map(relationRow)}
								{groupedRelations.withClient.length ? (
									<Label>{`${T('todo.ongoingWith')} ${client.name}`}</Label>
								) : null}
								{groupedRelations.withClient.map(relationRow)}
								{groupedRelations.withContactOld.length ? (
									<Label>{`${T('todo.moreThan1YearAgoWith')} ${contact?.name}`}</Label>
								) : null}
								{groupedRelations.withContactOld.map(relationRow)}
								{groupedRelations.withClientOld.length ? (
									<Label>{`${T('todo.moreThan1YearAgoWith')} ${client.name}`}</Label>
								) : null}
								{groupedRelations.withClientOld.map(relationRow)}
							</Block>
							<Button
								block
								color={hasSelectedValues ? 'green' : 'super-light-green'}
								onClick={() => {
									onClose();
									setOpen(false);
									setSearch('');
								}}
							>
								<Icon name={hasSelectedValues ? 'check' : 'times'} space="mrs" />
								{hasSelectedValues ? T('default.save') : T('todo.noNotRelated')}
							</Button>
						</Card>
					</SlideFade>
					{!hideRelated && selectedRelations.length ? (
						<Block space="ptm">
							<SelectedRelations relations={selectedRelations} removeRelation={toggleRow} />
						</Block>
					) : null}
				</div>
			</OutsideClick>
		</Block>
	);
};

export default RelationSelect;
