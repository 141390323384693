import React, { memo, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { TableRow, TableColumn, NumberInput, Icon, Text, Tooltip } from '@upsales/components';
import { OrderRow as OrderRowType, useOrderRowsDispatch } from '../Context/OrderContext';
import T from 'Components/Helpers/translate';
import { currencyFormat } from 'Components/Filters/Currencies';
import CustomFields from 'App/components/CustomFields';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import CMOrderRowSummary from './CMOrderRowSummary';
import { getTierFromOrderRow } from 'Helpers/order';
import FormObserver, { getCustomFieldsSetUpValues, mapCustomValuesToArray } from 'App/components/FormObserver';
import { SubscriptionCustomFormType } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import { getCalculatingFieldTooltip } from 'App/helpers/formulaText';
import { calculateField } from '@upsales/common';
import ProductCategorySelect from './ProductCategorySelect';
import { CalculationCustomField } from 'App/resources/Model/CustomField';
import ErrorMessages from 'App/components/EditSubscription/ErrorEnum';

import './OrderRow.scss';
import MaxDiscountInfo from './MaxDiscountInfo';
import { getCMWithRROption, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { useProductCategories } from 'App/components/hooks/appHooks';
import { useVisibleRowField, useVisibleFields, useDisabledFields } from 'App/components/hooks/editListener';
import { useCustomFieldOverrides, useUpdateHash } from 'App/components/hooks/editListener/editListenerHooks';
import {
	setQuantity,
	setProduct,
	setPrice,
	setDiscountPercent,
	setDiscount,
	removeOrderRow,
	appendOrderRow,
	moveOrderRow,
	setCustomFields
} from '../Context/OrderContextHelpers';
import { NewSubscriptionTracker } from 'Helpers/Tracker';

type Props = {
	orderRow: OrderRowType;
	orderRowsLength: number;
	isCreatedFromOrder?: boolean;
	lastRow: boolean;
	firstRow: boolean;
	currency: string;
	stageId?: number;
	disabled?: boolean;
	isNew?: boolean;
	dontFetch?: boolean;
	editProductBundle: (orderRow: OrderRowType) => void;
	tracker?: typeof NewSubscriptionTracker;
};
const OrderRow = ({
	orderRow,
	orderRowsLength,
	isCreatedFromOrder,
	lastRow,
	firstRow,
	currency,
	stageId,
	disabled,
	isNew,
	dontFetch,
	editProductBundle,
	tracker
}: Props) => {
	const { uuid } = orderRow;
	const disableInputs = !orderRow.product || disabled;
	const classes = new BemClass('OrderRow');
	const {
		metadata,
		self,
		totals: { products: productTotal },
		roleMap
	} = useSelector((state: RootState) => state.App);
	const scrollRef = useRef<HTMLDivElement>(null);
	const productCategories = useProductCategories();
	const locale = self?.userParams.locale;
	const myRole = roleMap.all.find(role => role.id === self?.role?.id);
	const hasDiscount = self?.administrator || !myRole || myRole?.hasDiscount;
	const decimals = metadata?.params.OrderedProductPriceDecimals;
	const salesModel = metadata?.params.SalesModel ?? 'sales';
	const cmWithRROption = getCMWithRROption();
	const salesModelOption = cmWithRROption ?? metadata?.params.SalesModelOption ?? 'sales';
	const useDiscount = metadata?.params.UseDiscount ?? true;

	const shouldDisplayRR = (salesModel === 'rr' && ['arr', 'mrr'].indexOf(salesModelOption) >= 0) || !!cmWithRROption;
	const shouldDisplayCM = metadata?.params.SalesModel === 'cm' || hasRRWithCM();
	const shouldDisplayBoth = shouldDisplayCM && shouldDisplayRR;

	const orderRowTotal = (orderRow.price ?? 0) * (orderRow.quantity ?? 0);
	const orderRowCost = (orderRow.purchaseCost ?? 0) * (orderRow.tierQuantity ?? 0);
	const tierObject = getTierFromOrderRow(orderRow);
	const isBundleProduct = !!orderRow.bundleRows?.length;

	const dispatch = useOrderRowsDispatch();

	const isDisabledField = useDisabledFields('order');
	const isVisibleRowField = useVisibleRowField('order', true);
	const isVisibleRowCustomField = useVisibleRowField('order');
	const isVisibleField = useVisibleFields('order', true);
	const updateHash = useUpdateHash(`${orderRow.uuid}`, orderRow);
	const getCustomFieldOverrides = useCustomFieldOverrides();

	const customFields = useSelector(({ App }: RootState) => App.customFields);
	const customFieldsToShow = (
		productCategories.find(pc => pc.id === orderRow.product?.category?.id)?.orderRowFields || []
	).map(f => f.id);

	const customOrderRowFieldValues = customFields['orderrow'].filter(field => {
		if (customFieldsToShow.length > 0 && !customFieldsToShow.includes(field.id)) {
			return false;
		}

		const fieldIsVisibleFromApp = isVisibleRowCustomField(`custom.${field.id}`, 'orderrow', orderRow);
		// If the field is hidden from app, it should not be validated
		if (fieldIsVisibleFromApp !== undefined && !fieldIsVisibleFromApp) {
			return false;
		}

		// If the field is visible from app, it should be validated
		const fieldIsVisible = fieldIsVisibleFromApp ?? field.visible;

		return field.$hasAccess && (fieldIsVisible || field.editable);
	});

	const customOrderRowFieldValuesHash = customOrderRowFieldValues.reduce((acc, field) => (acc += `${field.id},`), '');

	const { validatonModel, initialValues } = getCustomFieldsSetUpValues(
		customOrderRowFieldValues,
		orderRow.custom,
		stageId,
		ErrorMessages.OrderRowCustom,
		disableInputs
	);

	const displayWithoutCM = () => {
		const rowTotal = isVisibleRowField('price', 'orderrow', orderRow) ? (
			<Text size="sm" bold>
				{currencyFormat(orderRow.orderRowTotal ?? 0, currency)}
			</Text>
		) : null;

		if (!shouldDisplayRR) {
			return rowTotal;
		}
		return (
			<>
				{rowTotal}
				{isVisibleRowField(salesModelOption.toLowerCase(), 'orderrow', orderRow) ? (
					<Text size="sm" bold>
						{currencyFormat(orderRow.recurringValue ?? 0, currency) +
							` (${salesModelOption.toUpperCase()})`}
					</Text>
				) : null}
			</>
		);
	};

	const pushFieldsUp = shouldDisplayBoth && customOrderRowFieldValues.length < 4;
	let calculatingFieldFormula;
	if (orderRow.product?.category) {
		const category = productCategories.find(c => c.id === orderRow.product?.category?.id);
		const calculatingField = Tools.AppService.getCustomFields('orderrow').find(
			field => field.id === category?.calculatingField?.id
		) as CalculationCustomField | undefined;

		if (
			calculatingField?.formula &&
			calculateField.isValidAsValue(calculatingField.formula) &&
			Tools.FeatureHelper.isAvailable('CALCULATING_FIELDS')
		) {
			calculatingFieldFormula = getCalculatingFieldTooltip(calculatingField.formula, calculatingField.name);
		}
	}

	return (
		<>
			<TableRow className={classes.elem('orderTableRow').mod({ pushFieldsUp, isNew }).b()}>
				{isVisibleField('product', 'orderrow') ? (
					<TableColumn>
						{isVisibleRowField('product', 'orderrow', orderRow) ? (
							<ProductCategorySelect
								productTotal={productTotal}
								useDiscount={useDiscount}
								tierObject={tierObject}
								onlyRecurring
								disabled={disabled || isDisabledField('product', 'orderrow', orderRow)}
								orderRow={orderRow}
								anchor={document.querySelector('.OpenSubscription__inner')}
								setProduct={product => dispatch(setProduct(uuid, product, currency, tracker))}
								editProductBundle={editProductBundle}
								usePriceLists
								dontFetch={dontFetch}
							/>
						) : null}
					</TableColumn>
				) : null}

				{isVisibleField('quantity', 'orderrow') || isVisibleField('price', 'orderrow') ? (
					<TableColumn>
						<Tooltip title={calculatingFieldFormula ?? ''} disabled={!calculatingFieldFormula}>
							<div
								ref={scrollRef}
								className={classes
									.elem('quantityPrice')
									.mod({ disabled: disableInputs, useDiscount })
									.b()}
							>
								{isVisibleRowField('quantity', 'orderrow', orderRow) ? (
									<>
										<NumberInput
											disabled={
												disableInputs || isDisabledField('quantity', 'orderrow', orderRow)
											}
											value={orderRow.tierQuantity ?? 0}
											min={1}
											onChange={quantity =>
												dispatch(setQuantity(uuid, quantity, currency, false, tracker))
											}
										/>
										<Text size="sm">{tierObject?.tier.isTotalPrice ? '@' : 'x'}</Text>
									</>
								) : null}

								{isVisibleRowField('price', 'orderrow', orderRow) ? (
									<Tooltip
										distance={25}
										title={T('orderrow.priceLockedTooltip.bundle', {
											productName: orderRow.product?.name
										})}
										disabled={!(isBundleProduct && !orderRow.bundleFixedPrice)}
									>
										<NumberInput
											disabled={
												disableInputs ||
												!!calculatingFieldFormula ||
												(isBundleProduct && !orderRow.bundleFixedPrice) ||
												!hasDiscount ||
												isDisabledField('price', 'orderrow', orderRow)
											}
											value={orderRow.price ?? 0}
											decimals={decimals}
											locale={locale}
											currency={currency}
											onChange={v => dispatch(setPrice(uuid, v, currency, false, tracker))}
										/>
									</Tooltip>
								) : null}
							</div>
						</Tooltip>
					</TableColumn>
				) : null}
				{useDiscount &&
				(isVisibleField('discount', 'orderrow') || isVisibleField('discountpercent', 'orderrow')) ? (
					<TableColumn>
						<Tooltip
							title={T('default.discount.toolTip')}
							disabled={disableInputs || hasDiscount || isDisabledField('discount', 'orderrow', orderRow)}
							position="top"
						>
							<div
								className={classes
									.elem('discount')
									.mod({
										disabled:
											disableInputs ||
											!hasDiscount ||
											isDisabledField('discount', 'orderrow', orderRow),
										useDiscount,
										excessiveDiscount: orderRow.hasExcessiveDiscount && !self?.administrator
									})
									.b()}
							>
								{isVisibleRowField('discountpercent', 'orderrow', orderRow) ? (
									<>
										<NumberInput
											min={0}
											decimals={2} //2 is enough
											locale={locale}
											disabled={
												disableInputs ||
												!hasDiscount ||
												isDisabledField('discountpercent', 'orderrow', orderRow)
											}
											value={
												orderRow.discountPercent === undefined ? 0 : orderRow.discountPercent
											}
											onChange={v => dispatch(setDiscountPercent(uuid, v ?? 0, tracker))}
										/>

										<Text color="grey-10">{'%'}</Text>
									</>
								) : null}

								{isVisibleRowField('discount', 'orderrow', orderRow) ? (
									<NumberInput
										min={0}
										disabled={
											disableInputs ||
											!hasDiscount ||
											isDisabledField('discount', 'orderrow', orderRow)
										}
										value={orderRow.discount ?? 0}
										decimals={decimals}
										locale={locale}
										currency={currency}
										onChange={v => dispatch(setDiscount(uuid, v, tracker))}
									/>
								) : null}
							</div>
						</Tooltip>
						<MaxDiscountInfo
							maxDiscount={orderRow.maxDiscount}
							hasExcessiveDiscount={orderRow.hasExcessiveDiscount}
						/>
					</TableColumn>
				) : null}

				<TableColumn>
					<div className={classes.elem('sum').b()}>
						<div className={classes.elem('sum').elem('row').b()}>
							{shouldDisplayBoth || shouldDisplayCM ? (
								<CMOrderRowSummary
									uuid={uuid}
									total={orderRowTotal}
									disabled={
										disabled ||
										isBundleProduct ||
										isDisabledField('purchasecost', 'orderrow', orderRow)
									}
									currency={currency}
									product={orderRow.product}
									totalPurchaseCost={orderRowCost}
									alsoDisplayRR={shouldDisplayBoth}
									purchaseCost={orderRow.purchaseCost ?? 0}
									rrSalesModelOption={salesModelOption.toUpperCase()}
									recurringValue={orderRow.recurringValue ?? 0}
									orderRow={orderRow}
									tracker={tracker}
								/>
							) : (
								displayWithoutCM()
							)}
						</div>
						{disabled ? null : (
							<div className={classes.elem('sum').elem('row').mod('icons').b()}>
								<Icon
									name="trash"
									onClick={() => dispatch(removeOrderRow(uuid, tracker))}
									color="red"
								/>
								<Icon
									name="copy"
									onClick={() => dispatch(appendOrderRow({ ...orderRow }, undefined, tracker))}
								/>
							</div>
						)}
						{isNew ? (
							<Text bold size="sm" color="medium-green" className={classes.elem('new-text').b()}>
								{T('default.new')}
							</Text>
						) : null}
						{orderRowsLength > 1 && !disabled ? (
							<div className={classes.elem('sum').elem('row').b()}>
								<Icon
									name="chevron-up"
									color={firstRow ? 'grey-10' : undefined}
									onClick={() => !firstRow && dispatch(moveOrderRow(uuid, 'up', tracker))}
								/>
								<Icon
									name="chevron-down"
									color={lastRow ? 'grey-10' : undefined}
									onClick={() => !lastRow && dispatch(moveOrderRow(uuid, 'down', tracker))}
								/>
							</div>
						) : null}
					</div>
				</TableColumn>
			</TableRow>
			<TableRow className={classes.elem('customFields').mod({ pushFieldsUp }).b()}>
				<TableColumn colSpan={4}>
					<FormObserver<SubscriptionCustomFormType>
						key={orderRow.product?.id}
						validateOnMount={isCreatedFromOrder}
						onChange={(values, isValid) => {
							const mappedCustom = mapCustomValuesToArray(values.custom);
							dispatch(setCustomFields(uuid, mappedCustom, isValid));
						}}
						onlyValidateTouched
						model={validatonModel}
						initialValues={initialValues}
						resetStateIfUndefined={orderRow.product?.id}
						skipInitialOnChange={!!updateHash}
						resetStateIfValueChange={`${updateHash}-${customOrderRowFieldValuesHash}`}
					>
						{({ onFormChange, inputProps }) => (
							<CustomFields
								type="orderrow"
								inputProps={inputProps}
								onChange={(id: number, value: string) => onFormChange(`custom.Custom_${id}`, value)}
								disabled={disableInputs}
								stageId={stageId}
								onlyShowIds={customFieldsToShow}
								orderOrOrderRow={orderRow}
								isDisabledField={fieldName => isDisabledField(fieldName, 'orderrow', orderRow)}
								isVisibleField={fieldName => isVisibleRowCustomField(fieldName, 'orderrow', orderRow)}
								getCustomFieldOverrides={fieldId =>
									getCustomFieldOverrides({
										uuid: orderRow.uuid,
										id: orderRow.id,
										sortId: orderRow.sortId,
										entityType: 'orderrow',
										fieldId
									})
								}
							/>
						)}
					</FormObserver>
				</TableColumn>
			</TableRow>
		</>
	);
};

export default memo(OrderRow, (prev, next) => {
	return JSON.stringify(prev) === JSON.stringify(next);
});
