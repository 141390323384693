import T from 'Components/Helpers/translate';
import {
	Button,
	EllipsisTooltip,
	Icon,
	Table,
	TableColumn,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	Text,
	Title,
	Tooltip
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import './ReportcenterTVList.scss';
import openModal from 'App/services/Modal';
import { DefaultButton } from '@upsales/components/Buttons';
type Props = {
	channels: any[];
	dashboards: any[];
	setActiveChannel: (channel: any) => void;
	save: (channel: any) => void;
	deleteChannel: (channel: any) => void;
};

const ReportcenterTVList = ({ channels, dashboards, setActiveChannel, save, deleteChannel }: Props) => {
	const classes = new BemClass('ReportcenterTVList');

	const openSettings = (channel?: any) => {
		openModal('ReportcenterTVSettings', {
			channel,
			dashboards,
			save
		});
	};

	const lang = {
		title: T('reportcenter.tv.myChannels'),
		create: T('reportcenter.tv.createNewChannel'),
		name: T('reportcenter.tv.channelName'),
		dashboards: T('looker.dashboards'),
		play: T('reportcenter.tv.playChannel'),
		noChannels: T('reportcenter.tv.noChannels'),
		createFirst: T('reportcenter.tv.createFirstChannel'),
		unknownDashboard: T('reportcenter.tv.unknownDashboard')
	};
	return (
		<div className={classes.b()}>
			<div className={classes.elem('content').b()}>
				<div className={classes.elem('header').b()}>
					<div className={classes.elem('header-left').b()}>
						<div className={classes.elem('header-title').b()}>
							<Title size="lg">{lang.title}</Title>
						</div>
					</div>
					<div className={classes.elem('header-right').b()}>
						<Button onClick={() => openSettings()}>
							<Icon space="mrm" name="plus" />
							{lang.create}
						</Button>
					</div>
				</div>
				<div className={classes.elem('table').b()}>
					<Table>
						<TableHeader>
							<TableHeaderColumn align="left">{lang.name}</TableHeaderColumn>
							<TableHeaderColumn align="left">{lang.dashboards}</TableHeaderColumn>
							<TableHeaderColumn />
							<TableHeaderColumn />
							<TableHeaderColumn />
						</TableHeader>

						{channels.length
							? channels.map((channel, index) => {
									const [firstDashboard, secondDashboard, ...restDashboards] = channel.dashboards;
									return (
										<TableRow key={index}>
											<TableColumn size="lg" align="left">
												<EllipsisTooltip title={channel.name}>
													<Text>{channel.name}</Text>
												</EllipsisTooltip>
											</TableColumn>
											<TableColumn size="lg" align="left">
												<div className={classes.elem('dashboard-column').b()}>
													{[firstDashboard, secondDashboard].map((dashboard: any) => {
														if (!dashboard) {
															return null;
														}
														const db = dashboards.find(
															(db: any) => db.dashboardId === dashboard.dashboardId
														);

														if (!db) {
															// Note: there is a case where if a dashboard is deleted, it is still in the channel (report tv-dashboards), this is a fix so that it doesn't break the UI, should still be updated to remove the dashboard from the channel
															return null;
														}
														return (
															<div
																className={classes.elem('dashboard-chip').b()}
																key={dashboard.dashboardId}
															>
																<Text size="sm">
																	{db?.title ?? lang.unknownDashboard}
																</Text>
															</div>
														);
													})}
													{restDashboards.length ? (
														<Tooltip
															title={restDashboards
																?.map(
																	(dashboard: any) =>
																		dashboards?.find(
																			(db: any) =>
																				db.dashboardId === dashboard.dashboardId
																		).title ?? lang.unknownDashboard
																)
																.join(', ')}
														>
															<Text>{'+' + restDashboards.length}</Text>
														</Tooltip>
													) : null}
												</div>
											</TableColumn>
											<TableColumn size="lg" align="right">
												<DefaultButton
													onClick={() => {
														setActiveChannel(channel);
														document.documentElement.requestFullscreen();
													}}
												>
													<Icon name="play-circle" space="mrs" />
													{lang.play}
												</DefaultButton>
											</TableColumn>
											<TableColumn size="lg" align="right">
												<Button
													type="link"
													onClick={() => {
														openSettings(channel);
													}}
												>
													<Icon name="edit" />
												</Button>
											</TableColumn>
											<TableColumn size="lg" align="right">
												<Button type="link" onClick={() => deleteChannel(channel)}>
													<Icon name="trash" />
												</Button>
											</TableColumn>
										</TableRow>
									);
							  })
							: null}
					</Table>
					{channels.length ? null : (
						<div className={classes.elem('empty').b()}>
							<img src={'img/empty-briefcase.svg'} />
							<Text size="lg">{lang.noChannels}</Text>
							<Button type="link" size="lg" onClick={() => openSettings()}>
								<Text size="lg" color="blue">
									{lang.createFirst}
								</Text>
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReportcenterTVList;
