import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import TextEditor from 'Components/TextEditor';

import './MailTemplatePreviewSocial.scss';
import AccountProfile from 'App/resources/Model/AccountProfile';

type MailContentPreviewSocialProps = {
	column: any;
	onChange: (obj: any) => void;
	accountProfile: AccountProfile;
	className?: string;
	readOnly: boolean;
	selected: boolean;
};

const MailTemplatePreviewSocial: React.FC<MailContentPreviewSocialProps> = ({
	column,
	accountProfile,
	onChange,
	readOnly,
	selected
}) => {
	const classes = new bemClass('MailTemplatePreviewSocial');
	const editorRef = useRef();

	useEffect(() => {
		if (!readOnly) {
			const ref: any = editorRef.current;
			if (ref) {
				if (selected) {
					ref.element.focus();
				} else {
					ref.element.blur();
				}
			}
		}
	}, [selected]);

	const style = useMemo(
		() => ({
			margin: `0px ${column.iconMargin}px ${column.iconMargin}px ${column.iconMargin}px`
		}),
		[column.iconMargin]
	);

	return (
		<div className={classes.b()}>
			{column.captionEnabled ? (
				<div
					className={classes.elem('align-wrap').b()}
					style={{
						textAlign: column.align
					}}
				>
					{readOnly ? (
						<div
							dangerouslySetInnerHTML={{
								__html: column.caption
							}}
						/>
					) : (
						<TextEditor
							type="inline"
							instanceRef={editorRef}
							value={column.caption}
							accountProfile={accountProfile}
							onChange={(e: any) =>
								onChange({
									...column,
									caption: e.value
								})
							}
							config={{
								extraPlugins: 'tags,signatures',
								toolbar: [
									{
										name: 'basicstyles',
										items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat']
									},
									{ name: 'colors', items: ['TextColor'] },
									{ name: 'styles', items: ['Format', 'FontSize'] },
									{ name: 'fonts', items: ['Font'] },
									{ name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
									{ name: 'links', items: ['Link', 'Unlink'] },
									{ name: 'signatures', items: ['signatures'] }
								],
								format_maintitle: { name: 'Main title', element: 'h2' },
								format_secondarytitle: { name: 'Title', element: 'h3' },
								format_tags: 'paragraph;ingress;maintitle;secondarytitle',
								format_paragraph: {
									name: 'Paragraph',
									element: 'p',
									attributes: {
										class: 'paragraph'
									}
								},
								format_ingress: {
									name: 'Ingress',
									element: 'p',
									attributes: {
										class: 'ingress'
									}
								}
							}}
						/>
					)}
				</div>
			) : null}
			<div className={classes.elem('social-icons-preview').mod(column.align).b()}>
				{['linkedin', 'facebook', 'twitter', 'instagram', 'youtube'].map(medium =>
					column[`${medium}Enabled`] ? (
						<Link href={column[`${medium}URL`]} style={style} key={`${medium}-image-link`}>
							<img
								src={`https://img.upsales.com/icons/${medium}-${column.iconColor}-${column.iconSize}.png`}
								height={column.iconSize}
								width={column.iconSize}
							></img>
						</Link>
					) : null
				)}
			</div>
		</div>
	);
};
export default MailTemplatePreviewSocial;
