import React from 'react';
import PropTypes from 'prop-types';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import InactiveLabel from 'Components/Misc/InactiveLabel';
import openModal, { shouldOpenModal } from 'App/services/Modal';

export default class NameExisting extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			city: t('address.city')
		};
	}

	getAddress() {
		const addressPrio = ['Visit', 'Mail', 'Invoice'];
		const groupedAddresses = _.groupBy(this.props.account.addresses, 'type');

		for (let i = 0; i < addressPrio.length; i++) {
			if (
				groupedAddresses[addressPrio[i]] &&
				groupedAddresses[addressPrio[i]].length &&
				groupedAddresses[addressPrio[i]][0].city
			) {
				return groupedAddresses[addressPrio[i]][0];
			}
		}

		return null;
	}

	getSubtitle() {
		const address = this.getAddress();

		const flag =
			address && address.country ? (
				<b className={'icon flag-icon flag-icon-' + address.country.toLowerCase()} />
			) : null;
		const addressSpan = address ? (
			<span style={{ verticalAlign: 'middle' }}>
				{flag}{' '}
				{ReactTemplates.TOOLS.withTooltip(<span>{address.city}</span>, this.lang.city, {
					key: 'city' + this.props.account.id,
					placement: 'top'
				})}
			</span>
		) : null;

		const history = (
			<ReactTemplates.TOOLS.leadStatus
				style={{ marginRight: '10px' }}
				theKey={this.props.account.id}
				tools={Tools}
				activity={this.props.account.hasActivity}
				activityOld={this.props.account.hadActivity}
				appointment={this.props.account.hasAppointment}
				appointmentOld={this.props.account.hadAppointment}
				opportunity={this.props.account.hasOpportunity}
				opportunityOld={this.props.account.hadOpportunity}
				order={this.props.account.hasOrder}
				orderOld={this.props.account.hadOrder}
			/>
		);
		return (
			<span className="subtitle">
				{history} {addressSpan}
			</span>
		);
	}

	editAccount = event => {
		if (shouldOpenModal('EditClient')) {
			openModal('EditClient', { id: this.props.account.id });
		} else {
			const $upModal = getAngularModule('$upModal');
			$upModal.open('editAccount', { account: this.props.account }, event);
		}
	};

	render() {
		const subtitle = this.getSubtitle();
		const showInactiveLabel = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES);
		const account = this.props.account;

		return (
			<td className={`DataSourceColumn name-existing ${this.props.field.className}`}>
				<div>
					<span className="row-text">{account.name}</span>
					{subtitle}
				</div>
				{showInactiveLabel && !account.active ? (
					<div>
						<InactiveLabel onClick={this.editAccount} />
					</div>
				) : null}
			</td>
		);
	}
}

NameExisting.propTypes = {
	account: PropTypes.object,
	field: PropTypes.object
};
