import PropTypes from 'prop-types';
import React from 'react';
import renderType from './renderTypes';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

const propTypes = {
	config: PropTypes.object.isRequired,
	name: PropTypes.string,
	object: PropTypes.object,
	row: PropTypes.object,
	getUser: PropTypes.func,
	runStandardIntegrations: PropTypes.func,
	runClick: PropTypes.func,
	utils: PropTypes.object,
	type: PropTypes.string
};

class WidgetRowRender extends React.Component {
	constructor(props) {
		super(props);

		this.runClick = this.runClick.bind(this);
		this.runStandardIntegration = this.runStandardIntegration.bind(this);

		const FeatureHelper = getAngularModule('FeatureHelper');
		this.hasAppFrameworkUiElementsTopOfAccountCard = FeatureHelper.hasSoftDeployAccess(
			'APP_FRAMEWORK_UI_ELEMENTS_TOP_OF_ACCOUNT_CARD'
		);
	}

	getIcon(icon, extraClasses = '') {
		if (icon.indexOf('http') !== 0) {
			return <i className={`str-icon fa ${icon} ${extraClasses}`} />;
		}

		return <img className={`str-icon ${extraClasses}`} src={icon} />;
	}

	runStandardIntegration(data) {
		this.props.runStandardIntegrations(data);
	}

	runClick(data, optionIndex) {
		this.props.runClick(data, this.props.config, optionIndex);
	}

	render() {
		const featureAppFrameworkUiElementsRestrictedRenderTypes = ['row', 'emptyRow', 'plainText'];
		const { row, config, object, getUser, type, utils } = this.props;
		const RenderType = renderType[row.type];

		const tryingToRenderRestrictedRenderType =
			!this.hasAppFrameworkUiElementsTopOfAccountCard &&
			featureAppFrameworkUiElementsRestrictedRenderTypes.includes(row.type);

		if (tryingToRenderRestrictedRenderType) {
			return null;
		}

		const renderTypeProps = {
			row,
			config,
			object,
			getUser,
			type,
			utils,
			runStandardIntegration: this.runStandardIntegration,
			getIcon: this.getIcon,
			runClick: this.runClick
		};

		return <RenderType {...renderTypeProps} />;
	}
}

WidgetRowRender.propTypes = propTypes;
export default WidgetRowRender;
