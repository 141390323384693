import React, { useRef, useState, useEffect, MutableRefObject } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import './ReportcenterWidget.scss';
import { Title, Text, Link, Tooltip, Row } from '@upsales/components';
import T from 'Components/Helpers/translate';
import BarchartWidget from './BarchartWidget';
import ColumnChartWidget from './ColumnChartWidget';
import BigNumberWidget from './BigNumberWidget';
import ProgressBarWidget from './ProgressBarWidget';
import PipelineSpecialWidget from './PipelineSpecialWidget';
import FunnelWidget from './FunnelWidget';
import TableWidget from './TableWidget';
import ProductGridWidget from './ProductGridWidget';
import ReportcenterWidgetLegend from './ReportcenterWidgetLegend';
import { displayTypes } from '../reportCenterHelpers';
import { RCDashboardWidget, RCDashboardFilter } from 'Resources/ReportDashboard';
import { RootState } from 'App/babel/store';
import { RCWidgetData, RCWidgetMeta } from 'Resources/ReportWidget';
import ReportcenterWidgetActiveFilters from 'App/pages/Reportcenter/ReportcenterWidgetActiveFilters';
import Project from 'App/resources/Model/Project';
import ClientModel from 'App/resources/Model/Client';
import MarketingContributionColumnWidget from './MarketingContributionColumnWidget';
import DistributionBarWidget from './DistributionBarWidget';
import ReportcenterWidgetInfo from './ReportcenterWidgetInfo';

type ExternalProps = {
	widgetConfig: RCDashboardWidget;
	widgetData?: { data: RCWidgetData; loading: boolean; error: boolean };
	getWidgetData: (config: RCDashboardWidget) => void;
	drilldown: boolean;
	style?: React.CSSProperties;
	dashboardFilters?: RCDashboardFilter;
	campaigns?: Project[];
	disableFilterHover?: boolean;
	filterClients?: ClientModel[];
	onEdit?: () => void;
	loadDrilldown?: () => void;
	error?: string;
	hideProgressLabel?: boolean;
	thinMode?: boolean;
	showExtraTick?: boolean;
	allowZeroHeight?: boolean;
	tooltipDistance?: number;
	renderWithYearInLabel?: boolean;
	disableTooltipIfNoProgress?: boolean;
	widgetMetaObj?: { [k: string]: RCWidgetMeta };
	hideHeader?: boolean;
};

type Props = PropsFromRedux & ExternalProps;

export type GenericWidgetProps = {
	className: string;
	loading: boolean;
	drilldown: boolean;
	data: RCWidgetData;
	height: number;
	width: number;
	groupBy: string[];
	config: RCDashboardWidget;
	showGoal: boolean;
	currency: string;
	displayType: string;
	// Subscription preview props
	hideProgressLabel?: boolean;
	thinMode?: boolean;
	showExtraTick?: boolean;
	allowZeroHeight?: boolean;
	tooltipDistance?: number;
	renderWithYearInLabel?: boolean;
	disableTooltipIfNoProgress?: boolean;
};

type WidgetComponent = React.ComponentType<GenericWidgetProps> | ((p: GenericWidgetProps) => JSX.Element);

const widgetComponents: { [k: string]: WidgetComponent } = {
	bigNumber: BigNumberWidget,
	progressBar: ProgressBarWidget,
	distributionBar: DistributionBarWidget as unknown as WidgetComponent,
	table: TableWidget,
	columnChart: ColumnChartWidget,
	barChart: BarchartWidget
};

const getWidgetComponent = ({ type, displayType }: RCDashboardWidget): React.ComponentType<GenericWidgetProps> => {
	if (type === 'PIPELINE_SPECIAL') {
		return PipelineSpecialWidget;
	}
	if (type === 'SALES_FUNNEL') {
		return FunnelWidget(displayType as 'funnel');
	}
	if (type === 'LEADS_FUNNEL') {
		return FunnelWidget(displayType as 'funnel');
	}
	if (type === 'MARKETING_CONTRIBUTION' && displayType === 'columnChart') {
		return MarketingContributionColumnWidget;
	}
	if (type === 'PRODUCT_GRID') {
		return ProductGridWidget;
	}

	return widgetComponents[displayType];
};

const mapStateToProps = ({ Reportcenter }: RootState, { widgetConfig, widgetData, widgetMetaObj }: ExternalProps) => {
	const uniqueId = widgetConfig.uniqueId;
	const defaultData = {
		data: { rows: [], total: {} } as unknown as RCWidgetData,
		loading: false,
		error: false
	};
	if (!widgetData) {
		if (uniqueId !== undefined) {
			widgetData = Reportcenter.widgetData[uniqueId.toString()] || defaultData;
		} else {
			widgetData = defaultData;
		}
	}
	return {
		widgetMeta: widgetMetaObj?.[widgetConfig.type] ?? Reportcenter.widgetMeta[widgetConfig.type],
		editingDashboard: Reportcenter.editingDashboard,
		widgetData
	};
};

const ReportcenterWidget = ({
	widgetConfig,
	widgetData,
	style,
	drilldown,
	widgetMeta,
	dashboardFilters,
	campaigns,
	disableFilterHover,
	filterClients,
	onEdit,
	editingDashboard,
	hideProgressLabel,
	thinMode,
	showExtraTick,
	allowZeroHeight,
	tooltipDistance,
	getWidgetData = () => {},
	renderWithYearInLabel,
	disableTooltipIfNoProgress,
	hideHeader = false
}: Props) => {
	const titleRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const [smallHeaderFont, setSmallHeaderFont] = useState(false);

	useEffect(() => {
		getWidgetData(widgetConfig);
	}, [
		widgetConfig.filters,
		widgetConfig.groupBy,
		widgetConfig.numberFormat,
		widgetConfig.percent,
		widgetConfig.valueType,
		widgetConfig.weighted,
		widgetConfig.aggregateSubAccounts
	]);

	useEffect(() => {
		const title: HTMLDivElement | null = titleRef?.current;
		setSmallHeaderFont(!!title && title.offsetHeight > 22);
	}, [widgetConfig.width]);

	const classes = new bemClass('ReportcenterWidget').mod(`w${widgetConfig.width}`);
	const WidgetComponent = getWidgetComponent(widgetConfig);

	const showLegend = widgetConfig.legend && displayTypes[widgetConfig.displayType]?.legend;
	const showGoal = !!(widgetMeta?.hasGoal && widgetConfig.goal);

	const showHeader = widgetConfig.title || (editingDashboard && drilldown);

	return (
		<div style={style} className={classes.b()} key={widgetConfig.uniqueId}>
			{!hideHeader && showHeader ? (
				<div className={classes.elem('header').b()}>
					<Tooltip title={widgetConfig.title} disabled={!smallHeaderFont} position="top">
						<div ref={titleRef}>
							<Title size={smallHeaderFont ? 'sm' : 'md'}>{widgetConfig.title}</Title>
						</div>
					</Tooltip>

					<Row>
						{widgetMeta?.infoText ? <ReportcenterWidgetInfo text={widgetMeta?.infoText} /> : null}
						{editingDashboard && drilldown ? (
							<ReportcenterWidgetActiveFilters
								campaigns={campaigns ?? []}
								dashboardFilters={dashboardFilters ?? {}}
								widgetFilters={widgetConfig?.filters}
								disabled={disableFilterHover || false}
								clients={filterClients ?? []}
								onEdit={onEdit}
								widgetMeta={widgetMeta}
							/>
						) : null}
					</Row>
				</div>
			) : null}
			<div className={classes.elem('content').b()}>
				{WidgetComponent && !widgetData.error && !widgetData.data.error ? (
					<WidgetComponent
						className={classes.elem('widget').b()}
						loading={widgetData.loading}
						data={widgetData.data}
						height={widgetConfig.height}
						width={widgetConfig.width}
						groupBy={widgetConfig.groupBy}
						drilldown={drilldown}
						showGoal={showGoal}
						config={widgetConfig}
						currency={widgetData.data.currency}
						displayType={widgetConfig.displayType}
						hideProgressLabel={hideProgressLabel}
						thinMode={thinMode}
						showExtraTick={showExtraTick}
						allowZeroHeight={allowZeroHeight}
						tooltipDistance={tooltipDistance}
						renderWithYearInLabel={renderWithYearInLabel}
						disableTooltipIfNoProgress={disableTooltipIfNoProgress}
					/>
				) : null}
				{widgetData.data.error ? (
					<Text center color="red">
						{widgetData.data.error}
					</Text>
				) : widgetData.error ? (
					<Text center>
						<Link
							color="red"
							align="center"
							onClick={() => {
								getWidgetData(widgetConfig);
							}}
						>
							{T('reportcenter.failedToLoadWidget')}
						</Link>
					</Text>
				) : null}
			</div>
			{showLegend ? (
				<ReportcenterWidgetLegend
					colors={widgetData.data.colors}
					showGoal={showGoal}
					icons={widgetData.data.icons}
				/>
			) : null}
		</div>
	);
};

export const detached = ReportcenterWidget;

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportcenterWidget);
