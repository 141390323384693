import T from 'Components/Helpers/translate';
import {
	DropDownMenu,
	EllipsisTooltip,
	Input,
	DraggableTab,
	Table,
	TableColumn,
	TableRow,
	DraggableTabs,
	Text
} from '@upsales/components';
import React, { useEffect, useRef, useState } from 'react';
import { ProductSearchState } from '../ProductSearch';
import Category from 'App/resources/Model/Category';
import DropDownButton from '@upsales/components/DropDownButton/DropDownButton';
import { DndContext } from 'react-dnd';
type Props = {
	className: string;
	state: ProductSearchState;
	dispatch: React.Dispatch<Partial<ProductSearchState>>;
};

type CategoryTab = {
	id: Category['id'];
	title: Category['name'];
	subtitle: string;
};

const TAB_WIDTH = 100;
const TAB_LEFT_PADDING = 15;
const TAB_RIGHT_PADDING = 15;
const POPUP_WIDTH = 100;

const CategoryBar = ({ className, state, dispatch }: Props) => {
	const [showOverflowPopup, setShowOverflowPopup] = useState(false);
	const [visibleTabAmount, setVisibleTabAmount] = useState(0);
	const [visibleTabs, setVisibleTabs] = useState<CategoryTab[]>([]);
	const [overflowTabs, setOverflowTabs] = useState<CategoryTab[]>([]);
	const [overflowSearchStr, setOverflowSearchStr] = useState('');
	const tabsContainerRef = useRef<HTMLDivElement>(null);
	const isAdmin = Tools.AppService.getSelf().administrator;
	const { categories, products, selectedCategory, settings, settingsFetched, activeFilters } = state;

	const allTabs = categories.reduce((res: CategoryTab[], category) => {
		res.push({
			id: category.id,
			title: category.name,
			subtitle: (
				category.count ?? products.filter(product => product.category?.id === category.id).length
			).toString()
		});
		return res;
	}, []);

	useEffect(() => {
		if (!visibleTabs.length) return;

		// update category quantities based on whether or not filters are active:

		const filters = Object.values(activeFilters).filter(f => !f.inactive).length;

		setVisibleTabs(
			visibleTabs.map((tab: CategoryTab) => ({
				...tab,
				subtitle: (filters && tab.id === selectedCategory
					? products.length
					: categories.find(c => c.id === tab.id)!.count!
				).toString()
			}))
		);
	}, [products]);

	useEffect(() => {
		if (!tabsContainerRef.current) {
			return;
		}

		const handleResize = () => {
			const tabContainerWidth = tabsContainerRef.current?.clientWidth || 0;
			const totalTabWidth = TAB_WIDTH + TAB_LEFT_PADDING + TAB_RIGHT_PADDING;
			let visibleTabAmount = Math.floor(tabContainerWidth / totalTabWidth);

			if (visibleTabAmount < allTabs.length) {
				setShowOverflowPopup(true);
				if (POPUP_WIDTH + visibleTabAmount * totalTabWidth >= tabContainerWidth) {
					visibleTabAmount--;
				}
			} else {
				setShowOverflowPopup(false);
			}
			setVisibleTabAmount(visibleTabAmount);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [tabsContainerRef.current]);

	useEffect(() => {
		const tabs = overflowTabs.length ? [...visibleTabs, ...overflowTabs] : allTabs;

		setVisibleTabs(tabs.slice(0, visibleTabAmount));
		if (settingsFetched) {
			setOverflowTabs(tabs.slice(visibleTabAmount));
		}
	}, [visibleTabAmount, settingsFetched]);

	const updateCategorySettings = (tabs: CategoryTab[]) => {
		const updatedSettings = { ...settings };
		for (const [index, tab] of tabs.entries()) {
			const categorySetting = updatedSettings?.categories?.find(category => category.categoryId === tab.id);
			if (categorySetting) {
				categorySetting.sortId = index;
			} else {
				updatedSettings?.categories?.push({ categoryId: tab.id, sortId: index, columns: [] });
			}
		}
		dispatch({ settings: updatedSettings });
	};

	const showTab = (tabToShow: CategoryTab) => {
		setOverflowSearchStr('');

		// "All" is first tab and we still want that to the far left, we remove the last visible tab
		const newVisibleTabs = [visibleTabs[0], tabToShow, ...visibleTabs.slice(1, visibleTabs.length - 1)];
		setVisibleTabs(newVisibleTabs);

		const newOverflowTabs = [
			visibleTabs[visibleTabs.length - 1]!,
			...overflowTabs.filter((tab: CategoryTab) => tab.id !== tabToShow!.id)
		];
		setOverflowTabs(newOverflowTabs);

		dispatch({ selectedCategory: tabToShow.id });
		updateCategorySettings(newVisibleTabs);
	};

	const onReorder = (item: any, dropIndex: number) => {
		const newTabs = [...visibleTabs];
		const index = newTabs.findIndex(tab => tab.id.toString() === item.id);
		const [removed] = newTabs.splice(index, 1);
		newTabs.splice(dropIndex, 0, removed);

		setVisibleTabs(newTabs);
		updateCategorySettings([...newTabs, ...overflowTabs]);
	};

	return (
		<div className={className} ref={tabsContainerRef}>
			<DndContext.Consumer>
				{({ dragDropManager }) => {
					return (
						<DraggableTabs
							noFlex
							onChange={id => dispatch({ selectedCategory: parseInt(id) })}
							selected={selectedCategory.toString()}
							dragDropManager={dragDropManager}
							draggable={isAdmin}
							showBorderOnHover
						>
							{visibleTabs.map((tab: CategoryTab, index: number) => (
								<DraggableTab
									key={tab.id}
									id={tab.id.toString()}
									subtitle={tab.subtitle}
									style={{
										width: TAB_WIDTH,
										paddingLeft: TAB_LEFT_PADDING,
										paddingRight: TAB_RIGHT_PADDING
									}}
									draggable={tab.id !== 0 && isAdmin}
									index={index}
									onDrop={onReorder}
								>
									<EllipsisTooltip title={tab.title}>
										<Text>{tab.title}</Text>
									</EllipsisTooltip>
								</DraggableTab>
							))}
						</DraggableTabs>
					);
				}}
			</DndContext.Consumer>

			{showOverflowPopup ? (
				<DropDownMenu
					style={{ width: POPUP_WIDTH }}
					align="right"
					renderTrigger={(expanded, setExpanded) => (
						<DropDownButton size="sm" title={T('default.more')} onClick={setExpanded} expanded={expanded} />
					)}
				>
					{setClosed => (
						<>
							{overflowTabs.length > 10 ? (
								<Input
									onChange={e => setOverflowSearchStr(e.target.value)}
									value={overflowSearchStr}
									autofocus
									placeholder={T('default.search')}
								/>
							) : null}
							<Table>
								{overflowTabs
									.filter((tab: CategoryTab) =>
										tab.title.toLowerCase().includes(overflowSearchStr.toLowerCase())
									)
									.map((tab: CategoryTab) => (
										<TableRow
											key={tab.id}
											onClick={() => {
												showTab(tab);
												setClosed();
											}}
										>
											<TableColumn
												size="lg"
												title={<Text>{tab.title}</Text>}
												subtitle={tab.subtitle}
											/>
										</TableRow>
									))}
							</Table>
						</>
					)}
				</DropDownMenu>
			) : null}
		</div>
	);
};

export default CategoryBar;
