import { ActionsGroup, ActionsPeriod } from './SubscriptionGroupContextActions';
import {
	formatCustomValues,
	formatSubscriptionPeriod,
	isSubDirty,
	resetOrderRows
} from './SubscriptionPeriodContextHelpers';
import AgreementGroupResource from 'Resources/AgreementGroup';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { DiffOrder, PeriodSettings, SubscriptionGroupState, SubscriptionPeriodState } from './SubscriptionGroupState';
import { Metadata } from 'App/resources/AllIWant';
import PriceList from 'App/resources/Model/PriceList';
import { getPriceList } from './SubscriptionGroupContext';
import { calculateIntervalValues } from 'App/components/OrderRows/Context/OrderContextHelpers';
import openModal from 'App/services/Modal';
import { pick } from 'App/babel/store/helpers/object';
import type { OrderRow } from 'App/components/OrderRows/Context/OrderContext';
import type { AgreementChange } from 'App/components/AgreementFulfillmentModal';
import logError from 'App/babel/helpers/logError';
import { NewSubscriptionTracker } from 'Helpers/Tracker';

export type Dispatch = (action: any) => void;

const sdf = 'YYYY-MM-DD';

const getSortedPeriods = (subscriptionMap: SubscriptionGroupState['subscriptionMap']) =>
	Object.values(subscriptionMap).sort((period1, period2) =>
		moment(period1.startDate) > moment(period2.startDate) ? 1 : -1
	);

const updateDatesTwoPeriods = (
	subscriptionMap: SubscriptionGroupState['subscriptionMap'],
	firstPeriodUUID: number,
	secondPeriodUUID?: number
) => {
	const updateEndDateAndRenewalDate = (uuid: number) => {
		subscriptionMap[uuid].renewalDate = moment(subscriptionMap[uuid].invoiceStartDate)
			.add(subscriptionMap[uuid].periodLength, 'months')
			.format(sdf);

		if (subscriptionMap[uuid].endDate) {
			subscriptionMap[uuid].endDate = subscriptionMap[uuid].renewalDate;
		}
	};

	const firstPeriod = subscriptionMap[firstPeriodUUID];

	updateEndDateAndRenewalDate(firstPeriod.uuid);

	if (secondPeriodUUID) {
		const secondPeriod = subscriptionMap[secondPeriodUUID];
		subscriptionMap[secondPeriod.uuid].startDate = subscriptionMap[firstPeriod.uuid].endDate as string;
		subscriptionMap[secondPeriod.uuid].invoiceStartDate = subscriptionMap[firstPeriod.uuid].endDate as string;
		updateEndDateAndRenewalDate(secondPeriod.uuid);
	}
};

const updateDatesFuturePeriods = (
	subscriptionMap: SubscriptionGroupState['subscriptionMap'],
	sortedPeriods: SubscriptionPeriodState[],
	currentUUID: number,
	newEndDate: string
) => {
	const updateDates = (periodToUpdate: SubscriptionPeriodState, previousEndDate?: string) => {
		if (previousEndDate) {
			periodToUpdate.startDate = previousEndDate;
			periodToUpdate.invoiceStartDate = previousEndDate;
		}

		periodToUpdate.renewalDate = moment(previousEndDate)
			.add(periodToUpdate.periodLength || 1, 'months')
			.format(sdf);

		if (periodToUpdate.endDate) {
			periodToUpdate.endDate = periodToUpdate.renewalDate;
		}
	};

	const currentIndex = sortedPeriods.findIndex(period => period.uuid === currentUUID);
	const sortedPeriodsToUpdate = sortedPeriods.slice(currentIndex);

	for (let i = 1; i < sortedPeriodsToUpdate.length; i++) {
		const endDate =
			i === 1 ? newEndDate : sortedPeriodsToUpdate[i - 1]?.endDate ?? sortedPeriodsToUpdate[i - 1]?.renewalDate;
		updateDates(sortedPeriodsToUpdate[i], endDate);
		subscriptionMap[sortedPeriodsToUpdate[i].uuid] = sortedPeriodsToUpdate[i];
	}
};

export const updateChildren = (
	children: { startDate: string; endDate: string; id?: number }[],
	chosenPeriod: SubscriptionPeriodState,
	newValues?: {
		orderInterval?: SubscriptionPeriodState['orderInterval'];
		startDate?: SubscriptionPeriodState['startDate'];
		currency?: SubscriptionPeriodState['currency'];
		priceListId?: number;
	}
) => {
	return [...children].map((child, i) => {
		let state: 'default' | 'several' | 'part' = 'default';
		const start = newValues?.startDate ?? chosenPeriod.startDate;
		const interval = newValues?.orderInterval ?? chosenPeriod.orderInterval;

		const startDateMonthDiff = moment(child.startDate).diff(start, 'M', true);
		const endDateMonthDiff = moment(child.endDate).diff(start, 'M', true);

		// If one does not fit the orderinterval, then it's a special state
		if (startDateMonthDiff % interval !== 0 || endDateMonthDiff % interval !== 0) {
			const startDateIntervalDiff = startDateMonthDiff / interval;
			const endDateIntervalDiff = endDateMonthDiff / interval;

			const isPartOfCombined =
				Math.trunc(endDateIntervalDiff) === Math.trunc(startDateIntervalDiff) ||
				(Math.trunc(endDateIntervalDiff) === endDateIntervalDiff &&
					endDateIntervalDiff - startDateIntervalDiff < 1);

			if (isPartOfCombined) {
				state = 'part';
			} else {
				state = 'several';
			}
		}

		const periodLength = moment(child.endDate).diff(child.startDate, 'M', true);

		let childRows;
		if (Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLIT_FIX')) {
			childRows = child.id ? chosenPeriod.children?.find(c => c.id === child.id)?.orderRow : undefined;
		} else {
			childRows = chosenPeriod.children?.[i]?.orderRow;
		}

		const prevChildRows = chosenPeriod.children?.[i - 1]?.orderRow;
		const parentRows = chosenPeriod.orderRows;

		let orderRows = childRows ?? (prevChildRows ?? parentRows).map(row => ({ ...row, id: undefined }));
		if (newValues?.orderInterval) {
			orderRows = orderRows.map(row =>
				calculateIntervalValues(row, chosenPeriod.orderInterval, newValues?.orderInterval)
			);
		} else if (newValues?.currency) {
			orderRows = resetOrderRows(
				orderRows,
				chosenPeriod.orderInterval,
				newValues?.currency,
				newValues?.priceListId
			);
		}

		return {
			...child,
			orderRow: orderRows,
			periodLength,
			state,
			uuid: Date.now() + i
		};
	});
};

export const isMapDirty = (
	current: SubscriptionGroupState['subscriptionMap'],
	updated: SubscriptionGroupState['subscriptionMap']
) => {
	const currentSubs = Object.values(current);
	const updatedSubs = Object.values(updated);
	if (currentSubs.length !== updatedSubs.length) {
		return true;
	}

	for (let idx = 0; idx < currentSubs.length; idx++) {
		const isDirty = isSubDirty(currentSubs[idx], updatedSubs[idx]);
		if (isDirty) {
			return true;
		}
	}

	return false;
};

export const isDetailsDirty = (current: SubscriptionGroupState, updated: SubscriptionGroupState) => {
	const getDetailString = (state: SubscriptionGroupState) =>
		JSON.stringify({
			client: state.client,
			contact: state.contact,
			user: state.user,
			description: state.description,
			notes: state.notes,
			custom: formatCustomValues(state.custom),
			campaign: state.campaign,
			stage: state.stage
		});

	return getDetailString(current) !== getDetailString(updated);
};

export const getDefaultPeriodSettings = (
	priceList: PriceList,
	params?: Metadata['params'],
	startDate?: string
): PeriodSettings => {
	const allowOffset = !!params?.AgreementPrecreate;
	return {
		periodLength: (params?.SubscriptionDefaultPeriod === '' ? 12 : params?.SubscriptionDefaultPeriod) ?? 12,
		orderInterval: params?.SubscriptionDefaultInterval ?? 1,
		startDate: moment(startDate).format(sdf),
		invoiceStartDate: moment(startDate).format(sdf),
		initialInvoiceStartDate: moment(startDate).format(sdf),
		renewalDate: moment(startDate)
			.add(params?.SubscriptionDefaultPeriod ?? 12, 'M')
			.format(sdf),
		endDate: undefined,
		allowOffset,
		offset: allowOffset ? params?.SubscriptionDefaultCreationTime ?? 0 : 0,
		errorMessages: {},
		noticePeriod: params?.SubscriptionDefaultNoticePeriod ?? 0,
		priceList
	};
};

export const updatePeriodDates =
	(dispatch: Dispatch, state: SubscriptionGroupState) =>
	(newPeriodLength?: PeriodSettings['periodLength'], newStartDate?: string, newEndDate?: string) => {
		const subscriptionMap = state.subscriptionMap;

		const period = subscriptionMap[state.currentUUID];
		if (!period) return;

		const sortedPeriods = getSortedPeriods(subscriptionMap);

		const hasSplitFlag = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');
		if (hasSplitFlag) {
			if (newEndDate) {
				updateDatesFuturePeriods(subscriptionMap, sortedPeriods, period.uuid, newEndDate);
			} else {
				let endDate = moment(newStartDate ?? period.invoiceStartDate)
					.add(newPeriodLength || period.periodLength || 1, 'month')
					.format(sdf);

				endDate = newPeriodLength === 0 ? period.endDate ?? endDate : endDate;
				updateDatesFuturePeriods(subscriptionMap, sortedPeriods, period.uuid, endDate);
			}
		} else {
			if (newPeriodLength) {
				subscriptionMap[state.currentUUID].periodLength = newPeriodLength;
			}
			updateDatesTwoPeriods(subscriptionMap, sortedPeriods[0].uuid, sortedPeriods[1]?.uuid);
		}

		dispatch({ type: ActionsGroup.SET_SUBSCRIPTIONS, subscriptionMap });
	};

export const updateCreditDate =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (creditInitialDate?: string, agreementId?: number) => {
		let actualUUID;
		if (agreementId) {
			actualUUID = (
				Object.keys(state.subscriptionMap) as unknown as Array<keyof typeof state.subscriptionMap>
			).find(key => state.subscriptionMap[key].id === agreementId);
		}
		const uuid = actualUUID ?? state.currentUUID;
		dispatch({
			type: ActionsPeriod.SET_CREDIT_INITIAL_DATE,
			periodUUID: uuid,
			creditInitialDate: creditInitialDate
		});
	};

export const updateCurrent = (dispatch: Dispatch, state: SubscriptionGroupState) => (id: number) => {
	dispatch({ type: ActionsGroup.SET_CURRENT, id });
	NewSubscriptionTracker.incrementValueAndTrack('periodChange');
};

export const updateAdvancedMode =
	(dispatch: Dispatch, state: SubscriptionGroupState, params?: Metadata['params']) => (advanced: boolean) => {
		const subscriptionMap = { ...state.subscriptionMap };
		let currentUUID = state.currentUUID;

		// Remove all periods except the first one
		if (!advanced) {
			const subscriptions = getSortedPeriods(state.subscriptionMap);
			currentUUID = subscriptions[0].uuid;
			const defaultPeriodSettings = getDefaultPeriodSettings(
				subscriptionMap[currentUUID].priceList,
				params,
				subscriptionMap[currentUUID].startDate
			);
			const subscriptionsLength = subscriptions.length;
			for (let i = 1; i < subscriptionsLength; i++) {
				delete subscriptionMap[subscriptions[i].uuid];
			}
			// Set the current subscription back to default values
			subscriptionMap[currentUUID] = {
				...subscriptionMap[currentUUID],
				...defaultPeriodSettings
			};
		}

		dispatch({
			type: ActionsGroup.SET_ADVANCED_MODE,
			advanced,
			subscriptionMap,
			currentUUID
		});
		NewSubscriptionTracker.incrementValueAndTrack(advanced ? 'switchToAdvance' : 'switchToDefault');
	};

const updatePeriodPriceLists = (dispatch: Dispatch, state: SubscriptionGroupState) => (priceList: PriceList) => {
	const subscriptionMap = state.subscriptionMap;
	for (const sub of Object.values(subscriptionMap)) {
		subscriptionMap[sub.uuid].priceList = priceList;
	}
	dispatch({ type: ActionsGroup.SET_SUBSCRIPTIONS, subscriptionMap });
};
export const updateGroupFields =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (groupFields: Partial<SubscriptionGroupState>) => {
		groupFields.client = groupFields.client ?? null;
		groupFields.clientConnection = groupFields.clientConnection ?? null;
		groupFields.contact = groupFields.contact ?? null;
		groupFields.user = groupFields.user ?? null;

		const { client: oldClient } = state;
		if (groupFields.client !== oldClient) {
			const priceLists = Tools.AppService.getPriceLists();
			const priceList = getPriceList(priceLists, groupFields?.client?.priceListId, true);
			updatePeriodPriceLists(dispatch, state)(priceList);
		}

		if (!groupFields.clientConnection) {
			groupFields.invoiceRelatedClient = false;
		}

		dispatch({ type: ActionsGroup.SET_GROUP_FIELDS, groupFields });
	};

export const splitSubscription = (dispatch: Dispatch, state: SubscriptionGroupState) => (addFuturePeriod?: boolean) => {
	const { subscriptionMap, currentUUID } = state;
	const newUUID = Date.now();
	let periodToCopy = subscriptionMap[currentUUID];

	if (addFuturePeriod) {
		const sortedSubscription = Object.values(subscriptionMap).sort((period1, period2) =>
			moment(period1.startDate) > moment(period2.startDate) ? 1 : -1
		);
		periodToCopy = sortedSubscription[sortedSubscription.length - 1];
	}

	const periodLength = periodToCopy.periodLength || 12;
	const endOfPeriod = moment(periodToCopy.invoiceStartDate).add(periodLength, 'month').format(sdf);

	const renewalDate = moment(endOfPeriod).add(periodToCopy.periodLength, 'month').format(sdf);

	subscriptionMap[currentUUID].periodLength = periodLength;
	subscriptionMap[currentUUID].endDate = endOfPeriod;
	subscriptionMap[newUUID] = {
		...cloneDeep(periodToCopy),
		uuid: newUUID,
		invoiceStartDate: endOfPeriod,
		startDate: endOfPeriod,
		endDate: undefined,
		renewalDate,
		isParent: false,
		children: []
	};

	dispatch({ type: ActionsGroup.SPLIT_PERIOD, subscriptionMap });
	if (addFuturePeriod) {
		dispatch({ type: ActionsGroup.SET_CURRENT, id: newUUID });
	}
	NewSubscriptionTracker.incrementValueAndTrack('split');
};

export const deletePeriod =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (uuid: SubscriptionGroupState['currentUUID']) => {
		const { subscriptionMap } = state;
		delete subscriptionMap[uuid];

		const currentUUID = parseInt(Object.keys(subscriptionMap)[0]);
		if (!state.isEdit) {
			subscriptionMap[currentUUID].endDate = undefined;
		}

		dispatch({
			type: ActionsGroup.REMOVE_PERIOD,
			subscriptionMap,
			currentUUID
		});
		NewSubscriptionTracker.incrementValueAndTrack('removeSplit');
	};

export type FormatSubscriptionGroupType = ReturnType<typeof formatSubscriptionGroup>;

const formatSubscriptionGroup = (state: SubscriptionGroupState) => {
	const {
		id,
		client,
		clientConnection,
		contact,
		user,
		description,
		notes,
		subscriptionMap,
		stage,
		campaign,
		custom,
		createdFromOrderId,
		diffOrders,
		orderToAdd,
		invoiceRelatedClient
	} = state;

	const agreements = getSortedPeriods(subscriptionMap).map((subscription, idx) => {
		const createDiffOrder =
			!orderToAdd && !!diffOrders.find(diffOrder => diffOrder.agreementId === subscription.id && diffOrder.show);
		const formattedSubscription = formatSubscriptionPeriod(subscription, createDiffOrder);
		const value = subscription.orderRows.reduce((acc, row) => acc + (row.price ?? 0) * (row.quantity ?? 0), 0);

		return {
			...formattedSubscription,
			user,
			client,
			clientConnection,
			description,
			contact,
			stage,
			project: campaign,
			invoiceRelatedClient,
			yearlyValue: value * (12 / formattedSubscription.metadata.agreementIntervalPeriod),
			metadata: {
				...formattedSubscription.metadata,
				agreementDescription: description,
				agreementNotes: notes,
				custom
			},
			createdFromOrderId: idx === 0 ? createdFromOrderId : undefined
		};
	});

	const [firstSub] = agreements;

	return {
		id,
		client,
		opts: {},
		orderToAdd,
		agreements,
		currentAgreement:
			[...agreements]
				.reverse()
				.find(agreement => moment().isSameOrAfter(moment(agreement.metadata.agreementStartdate))) || firstSub
	};
};

export const saveSubscriptionGroup =
	(dispatch: Dispatch, state: SubscriptionGroupState) =>
	(haveToWait: boolean = false) => {
		dispatch({ type: ActionsGroup.SET_SAVING, saving: true });
		const dontWait = state.dontWait && !haveToWait;

		let opts;

		//Only go fast if there are no diff orders
		if (dontWait && !state.diffOrders?.length) {
			opts = {
				dontWait,
				state: {
					...state,
					isEdit: true,
					locked: true,
					dontWait: false,
					lockedReason: 'default.savingChanges'
				}
			};
		}
		const formatted = formatSubscriptionGroup(state);
		const promise = AgreementGroupResource.save(formatted, opts).catch(() => {
			openModal(state.id ? 'EditSubscription' : 'CreateSubscription', { groupState: state });
		});

		if (opts?.dontWait) {
			return Promise.resolve({ data: formatted, error: null });
		}

		return promise;
	};

export const setSaving =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (saving: SubscriptionGroupState['saving']) => {
		dispatch({ type: ActionsGroup.SET_SAVING, saving });
	};

export const setStep =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (step: SubscriptionGroupState['step']) => {
		NewSubscriptionTracker.trackPageSwitch(step, state.step);
		dispatch({ type: ActionsGroup.SET_STEP, step });
	};

export const setFinalStep =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (finalStep: SubscriptionGroupState['finalStep']) => {
		NewSubscriptionTracker.trackPageSwitch(finalStep ?? state.step, state.finalStep ?? state.step);
		dispatch({ type: ActionsGroup.SET_FINAL_STEP, finalStep });
	};

export const setStartDateGroup = (dispatch: Dispatch, state: SubscriptionGroupState) => (date: string) => {
	const { subscriptionMap } = state;

	const [firstPeriod, secondPeriod] = getSortedPeriods(subscriptionMap);
	if (moment(date).isAfter(firstPeriod.invoiceStartDate)) {
		subscriptionMap[firstPeriod.uuid].startDate = moment(date).format(sdf);
		subscriptionMap[firstPeriod.uuid].invoiceStartDate = moment(date).format(sdf);

		updateDatesTwoPeriods(subscriptionMap, firstPeriod.uuid, secondPeriod.uuid);

		dispatch({ type: ActionsGroup.SET_SUBSCRIPTIONS, subscriptionMap });
	} else {
		dispatch({
			type: ActionsPeriod.SET_FIRST_PERIOD_START_DATE,
			firstPeriodUUID: firstPeriod.uuid,
			startDate: date
		});
	}
	NewSubscriptionTracker.incrementValueAndTrack('motherStartDate');
};

export const setInvoiceStartDateGroup = (dispatch: Dispatch, state: SubscriptionGroupState) => (date: string) => {
	const { subscriptionMap } = state;
	const [firstPeriod, secondPeriod] = getSortedPeriods(subscriptionMap);

	subscriptionMap[firstPeriod.uuid].invoiceStartDate = moment(date).format(sdf);
	if (
		moment(subscriptionMap[firstPeriod.uuid].startDate).isAfter(subscriptionMap[firstPeriod.uuid].invoiceStartDate)
	) {
		subscriptionMap[firstPeriod.uuid].startDate = moment(date).format(sdf);
	}
	updateDatesTwoPeriods(subscriptionMap, firstPeriod.uuid, secondPeriod.uuid);

	dispatch({ type: ActionsGroup.SET_SUBSCRIPTIONS, subscriptionMap });
	NewSubscriptionTracker.incrementValueAndTrack('motherOrderStartDate');
};

export const setDiffOrders = (dispatch: Dispatch, state: SubscriptionGroupState) => (diffOrders: DiffOrder[]) => {
	const diffOrdersWithShow = diffOrders.map(diffOrder => ({ ...diffOrder, show: true }));
	dispatch({ type: ActionsGroup.SET_DIFF_ORDERS, diffOrders: diffOrdersWithShow });
};

export const expandPreviewFooter =
	(dispatch: Dispatch, state: SubscriptionGroupState) =>
	(show: boolean, track = true, fromInfoBox = false) => {
		dispatch({ type: ActionsGroup.EXPAND_PREVIEW_FOOTER, showPreviewFooter: show });
		if (track) {
			if (fromInfoBox) {
				NewSubscriptionTracker.incrementValueAndTrack('showGraphFromInfoBox');
			} else {
				NewSubscriptionTracker.trackPreviewGraph(show);
			}
		}
	};

export const showDiffOrder =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (show: boolean, agreementId: number) => {
		const diffOrders = [...state.diffOrders];
		const diffOrder = diffOrders.find(order => order.agreementId === agreementId);
		if (!diffOrder) return;

		diffOrder.show = show;
		dispatch({ type: ActionsGroup.SET_DIFF_ORDERS, diffOrders });
	};

export const setInvoiceRelatedClient =
	(dispatch: Dispatch, state: SubscriptionGroupState) =>
	(invoiceRelatedClient: SubscriptionGroupState['invoiceRelatedClient']) => {
		dispatch({ type: ActionsGroup.SET_INVOICE_RELATED_CLIENT, invoiceRelatedClient });
	};

function getItemMap<T extends { uuid: number; id?: number | undefined }>(items: T[] | undefined) {
	const map = (items ?? []).reduce<{ [uuid: number]: T }>(
		(map, item) => ({ ...map, [item.id ?? item.uuid]: item }),
		{}
	);
	return {
		get: (item: { uuid: number; id?: number | undefined }) => map[item.id ?? item.uuid]
	};
}

const getChangedProjectTemplateOrderRows = (
	result: AgreementChange[],
	startDate: AgreementChange['startDate'],
	endDate: AgreementChange['endDate'],
	oldOrderRowMap: ReturnType<typeof getItemMap<OrderRow>>,
	orderRows: OrderRow[]
) => {
	for (const orderRow of orderRows) {
		if (orderRow.product?.projectPlanTemplate?.active) {
			const oldOrderRow = oldOrderRowMap.get(orderRow);

			if (orderRow.product.id !== oldOrderRow?.product?.id) {
				const newProduct = pick(orderRow.product!, ['id', 'name', 'projectPlanTemplate']);
				const oldProduct = oldOrderRow?.product
					? pick(oldOrderRow.product, ['id', 'name', 'projectPlanTemplate'])
					: null;
				result.push({ newProduct, oldProduct, startDate, endDate });
			}
		}
	}
};

export const getFulfillmentChanges = (dispatch: Dispatch, state: SubscriptionGroupState) => () => {
	const result: AgreementChange[] = [];

	try {
		for (const period of Object.values(state.subscriptionMap)) {
			const hasChildren = period.children?.length ? true : false;

			if (hasChildren) {
				const oldChildMap = getItemMap(period.untouchedValues?.children);

				for (const child of period.children!) {
					const oldChild = oldChildMap.get(child);
					const oldOrderRows = oldChild?.id ? oldChild.orderRow : [];
					const oldOrderRowMap = getItemMap(oldOrderRows);

					getChangedProjectTemplateOrderRows(
						result,
						child.startDate,
						child.endDate,
						oldOrderRowMap,
						child.orderRow
					);
				}
			} else {
				const oldOrderRows = period.untouchedValues?.orderRows ?? [];
				const oldOrderRowMap = getItemMap(oldOrderRows);

				getChangedProjectTemplateOrderRows(
					result,
					period.startDate,
					period.endDate ?? period.renewalDate,
					oldOrderRowMap,
					period.orderRows
				);
			}
		}
	} catch (err) {
		logError(err, 'Failed to get fulfillment changes');
	}

	return result;
};

/***************************/
/* Split in splits actions */
/***************************/
export const setCurrentChild = (dispatch: Dispatch, state: SubscriptionGroupState) => (childIndexNr: number) => {
	dispatch({ type: ActionsGroup.SET_CURRENT_CHILD, childIndexNr });
	NewSubscriptionTracker.incrementValueAndTrack('periodChildChange');
};

export const deleteChildPeriod =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (periodUUID: number, childIndexToDelete: number) => {
		const { subscriptionMap, currentUUID, childIndexNr } = state;
		const chosenPeriod = { ...subscriptionMap[periodUUID] };
		if (!chosenPeriod.children?.length) return;

		let newChildIndex = childIndexNr;
		let splitEnabled = true;
		if (chosenPeriod.children.length > 2 && childIndexToDelete > 0) {
			// Update end date on previous period and remove child
			chosenPeriod.children[childIndexToDelete - 1].endDate = chosenPeriod.children[childIndexToDelete].endDate;
			chosenPeriod.children?.splice(childIndexToDelete, 1);

			if (periodUUID === currentUUID && childIndexNr >= childIndexToDelete) {
				newChildIndex--;
			}
		} else {
			const [firstChild] = chosenPeriod.children;
			chosenPeriod.orderRows = firstChild.orderRow.map(orderRow => ({
				...orderRow,
				id: undefined
			}));

			chosenPeriod.children = [];
			chosenPeriod.isParent = false;
			newChildIndex = 0;
			if (Object.values(subscriptionMap).length === 1) {
				splitEnabled = false;
			}
		}

		subscriptionMap[periodUUID] = chosenPeriod;
		const updatedSubscriptionMap = { ...subscriptionMap };

		dispatch({
			type: ActionsGroup.SET_PERIOD_CHILDREN,
			subscriptionMap: updatedSubscriptionMap,
			childIndexNr: newChildIndex,
			splitEnabled
		});
		NewSubscriptionTracker.incrementValueAndTrack('removeChild');
	};

export const setPeriodChildren =
	(dispatch: Dispatch, state: SubscriptionGroupState) =>
	(children: { startDate: string; endDate: string }[], chosenUUID: number, childIndexNr = 0) => {
		const { subscriptionMap } = state;
		const chosenPeriod = { ...subscriptionMap[chosenUUID] };
		const currentChildrenLength = chosenPeriod.children?.length ?? 0;

		const newChildren = updateChildren(children, chosenPeriod);
		chosenPeriod.children = newChildren;
		chosenPeriod.isParent = true;
		subscriptionMap[chosenUUID] = chosenPeriod;

		dispatch({
			type: ActionsGroup.SET_PERIOD_CHILDREN,
			subscriptionMap,
			childIndexNr,
			splitEnabled: true,
			currentUUID: chosenUUID
		});

		const addedChildrenLength = children.length - currentChildrenLength;
		NewSubscriptionTracker.incrementValueAndTrack('setPeriodChildren', {
			nrOfChildren: addedChildrenLength,
			addMore: !!currentChildrenLength
		});
	};

export const deleteSplitPeriod =
	(dispatch: Dispatch, state: SubscriptionGroupState) => (uuid: SubscriptionGroupState['currentUUID']) => {
		const { subscriptionMap, currentUUID } = state;

		let newUUID = currentUUID;
		if (uuid === currentUUID) {
			newUUID = parseInt(Object.keys(subscriptionMap)[0]);
		}

		const period = subscriptionMap[uuid];
		const hasEndDate = !!period.endDate;

		delete subscriptionMap[uuid];

		if (!hasEndDate) {
			const sortedSubscription = Object.values(subscriptionMap).sort((period1, period2) =>
				moment(period1.startDate) > moment(period2.startDate) ? 1 : -1
			);
			const { uuid: lastPeriodUUID } = sortedSubscription[sortedSubscription.length - 1];
			subscriptionMap[lastPeriodUUID].endDate = undefined;
		}

		let splitEnabled = true;
		if (Object.values(subscriptionMap).length <= 1 && !subscriptionMap[newUUID].isParent) {
			splitEnabled = false;
		}
		dispatch({
			type: ActionsGroup.REMOVE_PERIOD,
			subscriptionMap,
			currentUUID: newUUID,
			splitEnabled
		});
		NewSubscriptionTracker.incrementValueAndTrack('removeSplit');
	};

export const addFuturePeriod = (dispatch: Dispatch, state: SubscriptionGroupState) => () => {
	const { subscriptionMap } = state;
	const newUUID = Date.now();

	const sortedSubscription = Object.values(subscriptionMap).sort((period1, period2) =>
		moment(period1.startDate) > moment(period2.startDate) ? 1 : -1
	);
	const periodToCopy = sortedSubscription[sortedSubscription.length - 1];
	const periodLength = periodToCopy.periodLength || 12;
	const children = periodToCopy.children || [];
	const orderRows = children.length ? children[children.length - 1].orderRow : periodToCopy.orderRows;

	const endOfPeriod = periodToCopy.endDate ?? (periodToCopy.renewalDate as string);
	const renewalDate = moment(endOfPeriod).add(periodLength, 'month').format(sdf);

	subscriptionMap[periodToCopy.uuid].endDate = endOfPeriod;

	const renewalRows = orderRows.map(row => ({ ...row, initExcessiveDiscount: true }));

	subscriptionMap[newUUID] = {
		...cloneDeep(periodToCopy),
		orderSequenceNr: 0,
		id: undefined,
		uuid: newUUID,
		locked: false,
		invoiceStartDate: endOfPeriod,
		initialInvoiceStartDate: endOfPeriod,
		startDate: endOfPeriod,
		endDate: undefined,
		periodLength,
		renewalDate,
		orderRows: renewalRows,
		isParent: false,
		children: []
	};

	dispatch({ type: ActionsGroup.SPLIT_PERIOD, subscriptionMap });
	dispatch({ type: ActionsGroup.SET_CURRENT, id: newUUID });

	NewSubscriptionTracker.incrementValueAndTrack('split');
};
