import { comparisonTypes } from 'Resources/RequestBuilder';

const deleteMulti = (params, selected, skipTriggers, closeAfterSave, onError) => {
	const notificationService = Tools.NotificationService;
	var filters = params.filters;
	if (selected && selected.length) {
		filters.addFilter({ field: 'id' }, comparisonTypes.Equals, selected);
	}

	return Tools.MultiActions.customer(params.customerId)
		.delete(
			params.entity,
			selected.length,
			filters.build(),
			params.name,
			skipTriggers,
			params.notificationEntityNameExtension
		)
		.then(function (res) {
			// If we selected less than 10 we delete them now
			if (selected.length <= 10) {
				var body = 'deleted.' + params.notifyEntity;
				var allNotChanged = res.metadata ? res.metadata.allNotChanged : false;
				var totalChanged = res.metadata ? res.metadata.totalChanged : selected.length;
				if (res.metadata && allNotChanged) {
					if (res.metadata.totalChanged) {
						var ofStr = Tools.$translate('default.of').toLowerCase();
						var only = Tools.$translate('default.only');
						body =
							only +
							' ' +
							totalChanged +
							' ' +
							ofStr +
							' ' +
							res.metadata.nrOfIds +
							' ' +
							Tools.$translate('default.wasDeletedRightsProblem').toLowerCase();
					} else {
						body = 'default.nothingDeletedRightsProblem';
					}
				}
				// Wait for index then..
				setTimeout(function () {
					notificationService.addNotification({
						style: allNotChanged ? notificationService.style.WARN : notificationService.style.SUCCESS,
						icon: allNotChanged ? 'warning' : 'check',
						title: !totalChanged ? 'default.nothingDeleted' : 'default.deleted',
						body: body
					});

					if (closeAfterSave) {
						closeAfterSave(selected);
					}
				}, 2000);
			} else {
				// Else a job will be created
				notificationService.addNotification({
					style: notificationService.style.SUCCESS,
					icon: 'check',
					title: 'default.deleted',
					body: 'deleteQued.' + params.notifyEntity
				});

				if (closeAfterSave) {
					closeAfterSave();
				}
			}
		})
		.catch(() => {
			if (onError) {
				onError();
			}
			notificationService.addNotification({
				style: notificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'deleteError.' + params.notifyEntity
			});
		});
};

window.Tools.deleteMulti = deleteMulti;

export default deleteMulti;
