import React, { useState } from 'react';
import {
	useOrderRowsDispatch,
	OrderRow as _OrderRow,
	useOrderRowsSelector
} from 'App/components/OrderRows/Context/OrderContext';
import { TableRow, TableColumn, NumberInput, Text, Icon, ClickableItem, EllipsisTooltip } from '@upsales/components';
import { DisplaySettings, getOrderRowSummary } from './helpers';
import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';
import { useSelector, useSoftDeployAccess } from '../hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	setPrice,
	setPurchaseCost,
	setQuantity,
	setProduct,
	removeOrderRow
} from '../OrderRows/Context/OrderContextHelpers';
import ProductCategorySelect from '../OrderRows/OrderRow/ProductCategorySelect';
import { getTierFromOrderRow } from 'Helpers/order';
import { useMetadata } from '../hooks/appHooks';
import { RootState } from 'Store/index';
import { ThirdButton } from '@upsales/components/Buttons';

type OrderRow = Required<_OrderRow>;

type BundleRowProps = BundleRowsProps & { orderRow: OrderRow };
const ComponentRow = (props: BundleRowProps) => {
	const {
		displaySettings,
		priceAdjustment,
		productHasPriceAdjustment,
		orderRow,
		currency,
		showCost,
		editable,
		fixedPrice,
		onlyRecurringProducts
	} = props;
	const classes = new BemClass('BundleRow');
	const dispatch = useOrderRowsDispatch();

	const numberFormat = new NumberFormat(undefined, 0);
	const currencyFormat = new CurrencyFormat(currency, undefined, displaySettings.priceDecimalPlaces);
	const hasBundleAdjustable = useSoftDeployAccess('BUNDLE_ADJUSTABLE');
	const summary = getOrderRowSummary(displaySettings, currency, orderRow);
	const listPrice = orderRow.listPrice;
	const price = orderRow.price;

	const regularDiscount = parseFloat(((listPrice - price) / listPrice).toFixed(2));

	// If the bundle has an initial added cost. The discount is negative.
	const discountPercent = priceAdjustment > 1 && regularDiscount !== 0 ? regularDiscount * -1 : regularDiscount;

	// ProductCategorySelect stuff
	const tierObject = getTierFromOrderRow(orderRow);
	const { params } = useMetadata() ?? {};
	const useDiscount = params?.UseDiscount ?? true;
	const {
		totals: { products: productTotal }
	} = useSelector((state: RootState) => state.App);
	const [editing, setEditing] = useState(orderRow.justAdded);

	return (
		<TableRow className={!orderRow.tierQuantity ? classes.elem('unused').b() : ''}>
			{editable ? (
				editing || !orderRow.product ? (
					<TableColumn>
						<ProductCategorySelect
							anchor={'.EditProductBundleOrder'}
							orderRow={orderRow}
							useDiscount={useDiscount}
							tierObject={tierObject}
							setProduct={product => {
								dispatch(
									setProduct(orderRow.uuid, product, currency, undefined, true, priceAdjustment)
								);
							}}
							productTotal={productTotal}
							virtualized={productTotal <= 4000}
							usePriceLists
							excludeBundles
							onlyRecurring={onlyRecurringProducts}
							onBlur={() => setEditing(false)}
							autoFocus
						/>
					</TableColumn>
				) : (
					<TableColumn size="lg">
						<ClickableItem
							onClick={() => setEditing(true)}
							title={orderRow.product.name}
							subtitle={
								displaySettings.showArticleNo && orderRow.product.articleNo
									? orderRow.product.articleNo
									: undefined
							}
							icon="pencil"
							borderRadius
							className={classes.elem('product').b()}
						/>
					</TableColumn>
				)
			) : (
				<TableColumn
					className={classes.elem('product').b()}
					size="lg"
					title={
						<EllipsisTooltip title={orderRow.product.name}>
							<Text>{orderRow.product.name}</Text>
						</EllipsisTooltip>
					}
					subtitle={
						displaySettings.showArticleNo && orderRow.product.articleNo
							? orderRow.product.articleNo
							: undefined
					}
				/>
			)}
			<TableColumn size="lg">
				<NumberInput
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					decimals={10}
					value={orderRow.tierQuantity}
					onChange={tierQuantity => dispatch(setQuantity(orderRow.uuid, tierQuantity, currency, true))}
				/>
			</TableColumn>
			{productHasPriceAdjustment ? (
				<TableColumn size="lg" title={listPrice > 0 ? numberFormat.percent(discountPercent) : ''} />
			) : null}

			{fixedPrice || !hasBundleAdjustable || !orderRow.adjustable ? (
				<TableColumn
					size="lg"
					title={
						<Text color={fixedPrice ? 'grey-10' : undefined}>{currencyFormat.default(orderRow.price)}</Text>
					}
				/>
			) : null}
			{showCost && (fixedPrice || !hasBundleAdjustable || !orderRow.adjustable) ? (
				<TableColumn
					size="lg"
					title={
						<Text color={fixedPrice ? 'grey-10' : undefined}>
							{currencyFormat.default(orderRow.purchaseCost)}
						</Text>
					}
				/>
			) : null}
			{!fixedPrice && hasBundleAdjustable && orderRow.adjustable ? (
				<>
					<TableColumn size="lg">
						<NumberInput
							max={Number.MAX_SAFE_INTEGER}
							decimals={10}
							value={orderRow.bundleRowPrice ?? orderRow.price}
							onChange={price => dispatch(setPrice(orderRow.uuid, price, currency, true))}
							currency={currency}
						/>
					</TableColumn>
					{showCost ? (
						<TableColumn size="lg">
							<NumberInput
								min={0}
								max={Number.MAX_SAFE_INTEGER}
								decimals={10}
								value={orderRow.purchaseCost}
								onChange={purchaseCost => dispatch(setPurchaseCost(orderRow.uuid, purchaseCost, true))}
								currency={currency}
							/>
						</TableColumn>
					) : null}
				</>
			) : null}
			<TableColumn
				className={classes.elem('sum-column').b()}
				align="right"
				size="lg"
				title={
					<Text bold color={fixedPrice ? 'grey-10' : undefined}>
						{summary.title}
					</Text>
				}
				subtitle={summary.subtitle}
			/>
			{editable ? (
				<TableColumn>
					<ThirdButton onClick={() => dispatch(removeOrderRow(orderRow.uuid))}>
						<Icon name="times" />
					</ThirdButton>
				</TableColumn>
			) : null}
		</TableRow>
	);
};

type BundleRowsProps = {
	priceAdjustment: number;
	productHasPriceAdjustment: boolean;
	displaySettings: DisplaySettings;
	currency: string;
	showCost: boolean;
	editable: boolean;
	fixedPrice: boolean;
	onlyRecurringProducts: boolean;
};

const BundleRows = ({ ...props }: BundleRowsProps) => {
	const orderRows = useOrderRowsSelector(s => s.orderRows);

	return (
		<>
			{orderRows.map(orderRow => (
				<ComponentRow key={orderRow.uuid} orderRow={orderRow as Required<OrderRow>} {...props} />
			))}
		</>
	);
};

export default BundleRows;
