import {
	Row,
	Table,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	TableColumn,
	NumberInput,
	Button,
	Icon,
	Text,
	Help,
	Checkbox,
	SelectAsync,
	Flex
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useMemo } from 'react';
import T from 'Components/Helpers/translate';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import { BundleState, ProductRow, ProductTypes } from '../ProductBundleDrawer';
import { useSoftDeployAccess } from 'App/components/hooks';
import Products from 'Resources/Product';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import Product from 'App/resources/Model/Product';
import logError from 'Helpers/logError';
import { DefaultButton } from '@upsales/components/Buttons';

const fetchProducts = (productType: ProductTypes | null) => async (searchTerm: string) => {
	if (productType === null) {
		return [];
	}
	const rb = new RequestBuilder();
	if (searchTerm) {
		rb.addFilter({ field: 'name' }, comparisonTypes.Search, searchTerm);
	}
	rb.addFilter({ field: 'active' }, comparisonTypes.Equals, true);
	rb.addFilter({ field: 'isRecurring' }, comparisonTypes.Equals, !!productType);
	rb.addFilter({ field: 'bundlePriceAdjustment' }, comparisonTypes.Equals, null);

	return Products.find({ ...rb.build(), usePriceLists: true })
		.then(({ data }) => data.map((d: Product) => ({ ...d, title: d.name })))
		.catch(e => logError(e, 'Failed to fetch products'));
};

type Props = {
	classes: BemClass;
	bundle: BundleState;
	selectAnchor: Element | null;
	addProductRow: () => void;
	deleteProductRow: (index: number) => void;
	updateProductRow: (index: number, newRow: ProductRow) => void;
};
const BundleProducts = ({
	classes,
	bundle,
	addProductRow,
	deleteProductRow,
	updateProductRow,
	selectAnchor
}: Props) => {
	const hasSelectAsyncFeature = useSoftDeployAccess('BUNDLE_PRODUCTS_SELECTASYNC');
	const cf = new CurrencyFormat();

	const {
		productRows,
		isMRR,
		useContributionMargin,
		summary,
		priceAdjustment,
		priceAdjustmentPercent,
		productType,
		fixedPrice
	} = bundle;
	const lang = {
		recurringType: isMRR ? T('default.mrr') : T('default.arr'),
		activate: bundle.active ? T('admin.products.deactivateBundle') : T('admin.products.activateBundle')
	};

	const hasBundleAdjustable = useSoftDeployAccess('BUNDLE_ADJUSTABLE');

	const renderedProductRows = useMemo(() => {
		return productRows.map((row, index) => (
			<TableRow key={index}>
				<TableColumn size="lg">
					{/* Using select2 as a placeholder until we fix performance issues with the new select */}
					{hasSelectAsyncFeature ? (
						<SelectAsync
							className={classes.elem('products').elem('product-select').b()}
							anchor={selectAnchor}
							fetcher={fetchProducts(productType)}
							fetchOnOpen
							placeholder={T('admin.products.selectProduct')}
							value={row.product}
							onChange={value => {
								updateProductRow(index, { ...row, product: value });
							}}
						/>
					) : (
						/* @ts-ignore */
						<ReactTemplates.INPUTS.upProducts
							value={row.product}
							onChange={(value: any) => {
								updateProductRow(index, { ...row, product: value });
							}}
							placeholder={T('admin.products.selectProduct')}
							excludeBundles
							excludeRecurring={productType === ProductTypes.ONEOFF}
							excludeOneOffs={productType === ProductTypes.RECURRING}
							usePriceLists={true}
						/>
					)}
				</TableColumn>
				<TableColumn size="lg">
					<NumberInput
						value={row.quantity}
						decimals={10}
						min={0}
						align="center"
						onChange={value => {
							updateProductRow(index, {
								...row,
								quantity: value !== undefined ? Math.max(value, 0) : 0
							});
						}}
					/>
				</TableColumn>
				<TableColumn
					size="lg"
					title={<Text color={fixedPrice ? 'grey-10' : undefined}>{cf.short(row.unitPrice)}</Text>}
				/>
				<TableColumn
					size="lg"
					title={
						<Text bold color={fixedPrice ? 'grey-10' : undefined}>
							{cf.short(row.value * priceAdjustment)}
						</Text>
					}
					subtitle={
						row.product?.isRecurring
							? `${cf.short((isMRR ? row.monthlyValue : row.annualValue) * priceAdjustment)} (${
									lang.recurringType
							  })`
							: useContributionMargin
							? `${cf.short(row.value * priceAdjustment - row.totalPurchaseCost)} (${T(
									'default.contributionMarginShort'
							  )})`
							: undefined
					}
				/>
				{hasBundleAdjustable && !fixedPrice ? (
					<TableColumn size="lg">
						<Checkbox
							checked={row.adjustable}
							onChange={value => updateProductRow(index, { ...row, adjustable: value })}
						/>
					</TableColumn>
				) : null}
				<TableColumn size="lg">
					<Button
						type="link"
						onClick={() => {
							deleteProductRow(index);
						}}
					>
						<Icon name="times" />
					</Button>
				</TableColumn>
			</TableRow>
		));
	}, [productRows, fixedPrice, selectAnchor]);
	return (
		<>
			<div className={classes.elem('products').b()}>
				<Text bold className={classes.elem('product-table-label').b()} space="mbm">
					{productRows.length
						? `${productRows.length} ${
								productRows.length === 1
									? T('admin.products.productInBundle').toLowerCase()
									: T('admin.products.productsInBundle').toLowerCase()
						  }`
						: T('admin.products.productsInBundle')}
					<Help articleId={1443} />
				</Text>
				{!productRows.length ? (
					<Text color="grey-11" italic>
						{T('admin.products.noProductsInBundle')}
					</Text>
				) : (
					<Table>
						<TableHeader>
							<TableHeaderColumn column={{ title: T('default.product') }} />
							<TableHeaderColumn column={{ title: T('default.quantity') }} />
							<TableHeaderColumn column={{ title: T('default.price') }} />
							<TableHeaderColumn column={{ title: T('default.total') }} />
							{hasBundleAdjustable && !fixedPrice ? (
								<TableHeaderColumn column={{ title: T('admin.products.bundleAdjustable') }} />
							) : null}
							<TableHeaderColumn column={{ title: '' }} />
						</TableHeader>
						{renderedProductRows}
					</Table>
				)}
			</div>
			<Flex justifyContent="space-between" space="mtl">
				<DefaultButton onClick={() => addProductRow()}>
					<Icon name="plus" space="mrm" />
					{T('admin.products.addProduct')}
				</DefaultButton>
				{productRows.length ? (
					<div className={classes.elem('summary').b()}>
						<div className={classes.elem('summary-details').b()}>
							<Row>
								<Text>{T('admin.products.gross')}</Text>
								<Text>{cf.short(summary.gross)}</Text>
							</Row>
							{summary.recurringValue ? (
								<Row>
									<Text>{lang.recurringType}</Text>
									<Text>{cf.short(summary.recurringValue)}</Text>
								</Row>
							) : null}
							{useContributionMargin ? (
								<Row>
									<Text>{T('default.contributionMargin')}</Text>
									<Text>{cf.short(summary.contributionMargin)}</Text>
								</Row>
							) : null}
							{priceAdjustment !== 1 && !fixedPrice ? (
								<Row>
									<Text>
										{priceAdjustment < 1
											? T('admin.products.bundleDiscount')
											: T('admin.products.bundleCost')}{' '}
										{priceAdjustmentPercent}%
									</Text>
									<Text>{cf.short(summary.net - summary.gross)}</Text>
								</Row>
							) : null}
						</div>
						<Row>
							<Text bold>{T('admin.products.net')}</Text>
							<Text bold>{cf.short(summary.net)}</Text>
						</Row>
					</div>
				) : null}
			</Flex>
		</>
	);
};

export default BundleProducts;
