import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Row, Label, Toggle, Headline, Input } from '@upsales/components';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

class MailContentEditorBrowserLink extends React.PureComponent {
	constructor(p) {
		super(p);

		const t = getAngularModule('$translate');

		this.lang = {
			openEmailInBrowser: t('editor.openEmailInBrowser'),
			linkText: t('mailTemplate.linkText'),
			willNotWorkInTest: t('mailTemplate.willNotWorkInTest'),
			linkColor: t('form.linkColor')
		};
	}

	render() {
		const classes = new bemClass('MailContentEditor');
		return (
			<div className={classes.elem('sidebar-section').b()}>
				<Row className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<Headline size="xs">{this.lang.openEmailInBrowser}</Headline>
					</Column>
					<Column align="right" size={2}>
						<Toggle
							color="medium-green"
							checked={this.props.config.hasBrowserLink}
							onChange={val => this.props.configChanged('hasBrowserLink', val)}
						/>
					</Column>
				</Row>
				{this.props.config.hasBrowserLink ? (
					<div>
						<Row className={classes.elem('sidebar-section-input-row').b()}>
							<Column>
								<Label>{`${this.lang.linkText} (${this.lang.willNotWorkInTest})`}</Label>
								<Input
									value={this.props.config.browserLink}
									onChange={e => this.props.configChanged('browserLink', e.target.value)}
								/>
							</Column>
						</Row>
						<Row className={classes.elem('sidebar-section-input-row').b()}>
							<Column>
								<Label>{this.lang.linkColor}</Label>
							</Column>
							<Column align="right">
								<ReactTemplates.bannerEditor.colorPicker
									value={this.props.config.browserLinkColor}
									presets={this.props.accountProfile.colorVariables}
									onChange={color => this.props.configChanged('browserLinkColor', color)}
								/>
							</Column>
						</Row>
					</div>
				) : null}
			</div>
		);
	}
}

MailContentEditorBrowserLink.propTypes = {
	configChanged: PropTypes.func.isRequired,
	config: PropTypes.object,
	accountProfile: PropTypes.object
};

MailContentEditorBrowserLink.defaultProps = {
	configChanged: () => {}
};

export default MailContentEditorBrowserLink;
