import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './MailTemplatePreviewButton.scss';

const MailTemplatePreviewButton = ({ column }) => {
	const classes = new bemClass('MailTemplatePreviewButton');
	return (
		<div className={classes.mod({ [column.align]: true, expand: column.expand }).b()}>
			<div
				className={classes.elem('btn').b()}
				style={{ textAlign: column.align, backgroundColor: column.btnBg || null, color: column.color }}
			>
				{column.content}
			</div>
			{column.showCaption ? (
				<div style={{ textAlign: column.captionAlign }} dangerouslySetInnerHTML={{ __html: column.caption }} />
			) : null}
		</div>
	);
};
export default MailTemplatePreviewButton;
