import Product from 'App/resources/Model/Product';
import ProductCategory from 'App/resources/Model/ProductCategory';
import { AnyAction } from 'redux';

export const RESET = '[AdminProductList] RESET';
export const SET_LOADING = '[AdminProductList] SET_LOADING';
export const SET_SELECTED_TAB = '[AdminProductList] SET_SELECTED_TAB';
export const SET_PRODUCTS_LOADING = '[AdminProductList] SET_PRODUCTS_LOADING';
export const SET_CATEGORIES_LOADING = '[AdminProductList] SET_CATEGORIES_LOADING';
export const SET_PRODUCTS = '[AdminProductList] SET_PRODUCTS';
export const SET_UPDATED_PRODUCT = '[AdminProductList] SET_UPDATED_PRODUCT';
export const SET_CATEGORIES = '[AdminProductList] SET_CATEGORIES';
export const SET_PRODUCTS_ERROR = '[AdminProductList] SET_PRODUCTS_ERROR';
export const SET_CATEGORIES_ERROR = '[AdminProductList] SET_CATEGORIES_ERROR';
export const SET_OFFSET = '[AdminProductList] SET_OFFSET';
export const SET_SEARCH_STR = '[AdminProductList] SET_SEARCH_STR';
export const SET_INCLUDE_INACTIVE = '[AdminProductList] SET_INCLUDE_INACTIVE';
export const SET_INCLUDE_SUBSCRIPTION_PRODUCTS = '[AdminProductList] SET_INCLUDE_SUBSCRIPTION_PRODUCTS';
export const SET_INCLUDE_ONEOFF_PRODUCTS = '[AdminProductList] SET_INCLUDE_ONEOFF_PRODUCTS';
export const SET_INCLUDE_BUNDLES = '[AdminProductList] SET_INCLUDE_BUNDLES';
export const SET_TOTAL = '[AdminProductList] SET_TOTAL';
export const SET_MASTER_CURRENCY = '[AdminProductList] SET_MASTER_CURRENCY';
export const SET_SELECTED_CATEGORY = '[AdminProductList] SET_SELECTED_CATEGORY';
export const SET_ALL_PRODUCTS_COUNT = '[AdminProductList] SET_ALL_PRODUCTS_COUNT';

export type AdminProductListState = {
	products: Product[];
	updatedProduct: Product | null;
	categories: ProductCategory[];
	selectedTab: 'products' | 'categories';
	productsLoading: boolean;
	categoriesLoading: boolean;
	loading: boolean;
	offset: number;
	productsError: boolean;
	categoriesError: boolean;
	searchStr: string;
	includeInactive: boolean;
	includeSubscriptionProducts: boolean;
	includeOneOffProducts: boolean;
	includeBundles: boolean;
	total: {
		products: number;
		categories: number;
	};
	allProductsCount: { loading: boolean; count: number };
	masterCurrency: string;
	selectedCategory: null | ProductCategory;
};

export const initialState: AdminProductListState = {
	products: [],
	updatedProduct: null,
	categories: [],
	selectedTab: 'products',
	productsLoading: false,
	categoriesLoading: false,
	loading: true,
	offset: 0,
	productsError: false,
	categoriesError: false,
	searchStr: '',
	includeInactive: false,
	includeSubscriptionProducts: true,
	includeOneOffProducts: true,
	includeBundles: true,
	total: {
		products: 0,
		categories: 0
	},
	allProductsCount: { loading: true, count: 0 },
	masterCurrency: 'SEK',
	selectedCategory: null
};

const ACTION_HANDLERS: { [key: string]: (state: AdminProductListState, action: AnyAction) => AdminProductListState } = {
	[RESET]: () => ({ ...initialState }),
	[SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[SET_SELECTED_TAB]: (state, { selectedTab }) => ({ ...state, selectedTab }),
	[SET_PRODUCTS_LOADING]: (state, { productsLoading }) => ({ ...state, productsLoading }),
	[SET_CATEGORIES_LOADING]: (state, { categoriesLoading }) => ({ ...state, categoriesLoading }),
	[SET_PRODUCTS]: (state, { products }) => ({ ...state, products }),
	[SET_UPDATED_PRODUCT]: (state, { updatedProduct }) => ({ ...state, updatedProduct }),
	[SET_CATEGORIES]: (state, { categories }) => ({ ...state, categories }),
	[SET_PRODUCTS_ERROR]: (state, { productsError }) => ({ ...state, productsError }),
	[SET_CATEGORIES_ERROR]: (state, { categoriesError }) => ({ ...state, categoriesError }),
	[SET_OFFSET]: (state, { offset }) => ({ ...state, offset }),
	[SET_SEARCH_STR]: (state, { searchStr }) => ({ ...state, searchStr }),
	[SET_INCLUDE_INACTIVE]: (state, { includeInactive }) => ({ ...state, includeInactive }),
	[SET_INCLUDE_SUBSCRIPTION_PRODUCTS]: (state, { includeSubscriptionProducts }) => ({
		...state,
		includeSubscriptionProducts
	}),
	[SET_INCLUDE_ONEOFF_PRODUCTS]: (state, { includeOneOffProducts }) => ({
		...state,
		includeOneOffProducts
	}),
	[SET_INCLUDE_BUNDLES]: (state, { includeBundles }) => ({
		...state,
		includeBundles
	}),
	[SET_TOTAL]: (state, { total }) => ({ ...state, total }),
	[SET_MASTER_CURRENCY]: (state, { masterCurrency }) => ({ ...state, masterCurrency }),
	[SET_SELECTED_CATEGORY]: (state, { selectedCategory }) => ({ ...state, selectedCategory }),
	[SET_ALL_PRODUCTS_COUNT]: (state, { allProductsCount }) => ({ ...state, allProductsCount })
};

export default (state = initialState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
