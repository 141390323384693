import React, { useEffect, useState } from 'react';
import {
	Block,
	ButtonSelect,
	Flex,
	Icon,
	Label,
	NumberInput,
	RadioItem,
	Select,
	Text,
	Tooltip
} from '@upsales/components';
import { BundleState, PriceAdjustmentTypes, ProductTypes } from '../ProductBundleDrawer';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { useMetadata, usePriceLists, useStaticValues } from 'App/components/hooks/appHooks';
import { useFeatureAvailable } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

const clamp = (value: number): number => {
	return Math.max(0, Math.min(value, 100));
};

type Props = {
	classes: BemClass;
	bundle: BundleState;
	updateBundle: React.Dispatch<Partial<BundleState>>;
	selectAnchor?: Element | null;
};

const RadioItemInput = ({
	classes,
	bundle,
	updateBundle,
	type,
	label
}: Props & { type: PriceAdjustmentTypes; label: string }) => {
	const { priceAdjustmentPercent, priceAdjustmentType } = bundle;
	const [priceModifierInput, setPriceModifierInput] = useState<number | undefined>(priceAdjustmentPercent || 0);

	useEffect(() => {
		setPriceModifierInput(priceAdjustmentPercent);
	}, [priceAdjustmentType]);

	return (
		<>
			<RadioItem
				checked={bundle.priceAdjustmentType === type}
				value={type}
				label={label}
				onChange={value =>
					updateBundle({
						priceAdjustmentType:
							(value as unknown as PriceAdjustmentTypes) ?? PriceAdjustmentTypes.UNCHANGED
					})
				}
			/>
			{bundle.priceAdjustmentType === type ? (
				<div className={classes.elem('price-adjustment-input').b()}>
					<NumberInput
						decimals={2}
						value={priceModifierInput}
						onChange={value => {
							setPriceModifierInput(value);
							if (value !== undefined && clamp(value) !== priceAdjustmentPercent) {
								updateBundle({ priceAdjustmentPercent: clamp(value || 0) });
							}
						}}
						onBlur={() => {
							setPriceModifierInput(clamp(priceModifierInput || 0));
							if (priceModifierInput !== priceAdjustmentPercent) {
								updateBundle({
									priceAdjustmentPercent: clamp(priceModifierInput || 0)
								});
							}
						}}
						allowEmpty
					/>
					<Text italic color="grey-10" className={classes.elem('percent').b()}>
						%
					</Text>
				</div>
			) : null}
		</>
	);
};

const BundlePrice = ({ classes, bundle, updateBundle, selectAnchor }: Props) => {
	const { currencies, selectedPriceList, fixedPrice, recurringInterval, productType } = bundle;
	const { t } = useTranslation();
	const intervals = useStaticValues('recurringInterval');
	const hasPriceLists = useFeatureAvailable(Feature.PRICE_LISTS);
	const priceLists = usePriceLists();
	const [currenciesExpanded, setCurrenciesExpanded] = useState(false);
	const toggleCurrenciesExpanded = () => setCurrenciesExpanded(!currenciesExpanded);

	const customerCurrencies = useMetadata()?.customerCurrencies?.filter(c => c.active);
	const masterCurrency = customerCurrencies?.find(c => c.masterCurrency);

	if (!customerCurrencies || !masterCurrency) {
		// If this happens then app metadata is not loaded
		return null;
	}

	const onPriceChange = (value: number, currency: string) => {
		const updatedCurrencies = currencies.map(c => {
			if (c.currency === currency && c.priceListId === selectedPriceList?.id) {
				return { ...c, price: value };
			}
			return c;
		});

		if (!updatedCurrencies.find(c => c.currency === currency && c.priceListId === selectedPriceList?.id)) {
			updatedCurrencies.push({
				currency,
				price: value,
				priceListId: selectedPriceList?.id,
				purchaseCost: 0
			});
		}

		updateBundle({ currencies: updatedCurrencies });
	};

	return (
		<Flex direction="column" gap="u3" className={classes.elem('price-settings').b()}>
			<ButtonSelect
				className={classes.elem('price-type').b()}
				value={fixedPrice}
				options={[
					{
						value: false,
						// @ts-ignore - typed as string but this works
						title: (
							<Text>
								{t('admin.products.dynamicPrice')}
								<Tooltip title={t('admin.products.dynamicPriceTooltip')}>
									<Icon name="question-circle" />
								</Tooltip>
							</Text>
						)
					},
					{
						value: true,
						// @ts-ignore - typed as string but this works
						title: (
							<Text>
								{t('admin.products.fixedPrice')}
								<Tooltip title={t('admin.products.fixedPriceTooltip')}>
									<Icon name="question-circle" />
								</Tooltip>
							</Text>
						)
					}
				]}
				onChange={value => updateBundle({ fixedPrice: value })}
			/>
			{fixedPrice ? (
				<Flex direction="column" gap="u4">
					{hasPriceLists ? (
						<Block>
							<Label>{t('admin.pricelist')}</Label>
							<Select
								options={priceLists
									.filter(priceList => priceList.active)
									.map(priceList => ({ id: priceList.id, title: priceList.name }))}
								value={{ id: selectedPriceList.id, title: selectedPriceList.name }}
								onChange={value => {
									const priceList = priceLists.find(priceList => priceList.id === value.id);
									if (priceList) {
										updateBundle({ selectedPriceList: priceList });
									}
								}}
								anchor={selectAnchor}
							/>
						</Block>
					) : null}
					<Flex gap="u3">
						<Block>
							<Label required>{`${t('admin.products.bundlePrice')} ${masterCurrency.iso}`}</Label>
							<NumberInput
								value={
									currencies.find(
										c =>
											c.priceListId === selectedPriceList?.id && c.currency === masterCurrency.iso
									)?.price ?? 0
								}
								onChange={value => {
									if (value !== undefined) {
										onPriceChange(value, masterCurrency.iso);
									}
								}}
								decimals={10}
							/>
						</Block>
						{productType === ProductTypes.RECURRING ? (
							<Block>
								<Label>{t('admin.products.recurringInterval')}</Label>
								<Select
									options={intervals.map(i => ({ id: i.id, title: i.name }))}
									value={{
										id: recurringInterval?.toString() ?? '12',
										title:
											intervals.find(i => i.id === (recurringInterval?.toString() ?? '12'))
												?.name ?? ''
									}}
									onChange={value => {
										updateBundle({ recurringInterval: parseInt(value.id) });
									}}
									anchor={selectAnchor}
								/>
							</Block>
						) : null}
					</Flex>
					{customerCurrencies.length > 1 ? (
						<Block>
							<Text
								bold
								onClick={toggleCurrenciesExpanded}
								className={classes.elem('currencies-toggle').b()}
							>
								{`${t('admin.products.otherCurrencies')} (${customerCurrencies.length - 1})`}
								<Icon space="mlm" name={currenciesExpanded ? 'chevron-up' : 'chevron-down'} />
							</Text>
							<Flex
								gap="u4"
								wrap="wrap"
								className={classes.elem('currencies').mod({ show: currenciesExpanded }).b()}
							>
								{customerCurrencies
									.filter(c => !c.masterCurrency)
									.map(c => {
										return (
											<Block key={c.iso} space="mtl">
												<Label>{`${t('admin.products.bundlePrice')} ${c.iso}`}</Label>
												<NumberInput
													value={
														currencies.find(
															bc =>
																bc.priceListId === selectedPriceList?.id &&
																bc.currency === c.iso
														)?.price ?? 0
													}
													decimals={10}
													onChange={value => {
														if (value !== undefined) {
															onPriceChange(value, c.iso);
														}
													}}
												/>
											</Block>
										);
									})}
							</Flex>
						</Block>
					) : null}
				</Flex>
			) : (
				<Flex direction="column" gap="u2">
					<RadioItemInput
						classes={classes}
						bundle={bundle}
						updateBundle={updateBundle}
						type={PriceAdjustmentTypes.DECREASE}
						label={t('admin.products.addDiscount')}
					/>
					<RadioItemInput
						classes={classes}
						bundle={bundle}
						updateBundle={updateBundle}
						type={PriceAdjustmentTypes.INCREASE}
						label={t('admin.products.addExtraCost')}
					/>
				</Flex>
			)}
		</Flex>
	);
};

export default BundlePrice;
