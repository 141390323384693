import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './MailTemplatePreview.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { parseVariable, getColWidthFromSize } from 'Services/MailTemplate';
import bemClass from '@upsales/components/Utils/bemClass';
import { Row, Column, Text, Icon, Button, ButtonGroup, Tooltip } from '@upsales/components';
import MailTemplatePreviewText from './MailTemplatePreviewText';
import MailTemplatePreviewHtml from './MailTemplatePreviewHtml';
import MailTemplatePreviewHr from './MailTemplatePreviewHr';
import MailTemplatePreviewImage from './MailTemplatePreviewImage';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import MailTemplatePreviewAddButton from './MailTemplatePreviewAddButton';
import MailTemplatePreviewButton from './MailTemplatePreviewButton';
import MailTemplatePreviewAddMenu from './MailTemplatePreviewAddMenu';
import MailTemplatePreviewHeader from './MailTemplatePreviewHeader';
import MailTemplatePreviewFooter from './MailTemplatePreviewFooter';
import InlineConfirm from '../Dialogs/InlineConfirm';
import MailTemplatePreviewSocial from './MailTemplatePreviewSocial';
import MailTemplatePreviewPadding from './MailTemplatePreviewPadding';
import MailTemplatePreviewColumnTools from './MailTemplatePreviewColumnTools';

const MAX_COLS = 6;

// Apply the same css to multiple selectors
const buildSelectors = function (base, selectors = ['']) {
	if (!Array.isArray(selectors)) {
		selectors = [selectors];
	}
	let css = '';
	const selector = [];
	selectors.forEach(function (sel) {
		selector.push(base + ' ' + sel);
	});
	css = selector.join(',');

	return css;
};

const getFontWeight = bool => {
	return 'font-weight: ' + (bool ? 'bold' : 'normal') + ';';
};

const getUnderline = bool => {
	return 'text-decoration: ' + (bool ? 'underline' : 'none') + ';';
};

const getItalic = bool => {
	return 'font-style: ' + (bool ? 'italic' : 'normal') + ';';
};

const styleBlock = accountProfile => {
	// color variables
	const contentColor = accountProfile.colors.contentText;
	const contentIngressColor = accountProfile.colors.contentTextIngress;
	const mainColor = accountProfile.colors.mainText;
	const secondaryColor = accountProfile.colors.secondaryText;
	const linkColor = accountProfile.colors.linkText;
	const btnBgColor = accountProfile.colors.buttonBg;

	// font variables
	// Family
	const h2Family = accountProfile.typography.h2.fontFamily;
	const h3Family = accountProfile.typography.h3.fontFamily;
	const ingressFamily = accountProfile.typography.ingress.fontFamily;
	const pFamily = accountProfile.typography.p.fontFamily;
	const linkFamily = accountProfile.typography.link.fontFamily;
	// size
	const h2Size = accountProfile.typography.h2.size;
	const h3Size = accountProfile.typography.h3.size;
	const ingressSize = accountProfile.typography.ingress.size;
	const pSize = accountProfile.typography.p.size;
	// bold
	const h2Weight = getFontWeight(accountProfile.typography.h2.bold);
	const h3Weight = getFontWeight(accountProfile.typography.h3.bold);
	const ingressWeight = getFontWeight(accountProfile.typography.ingress.bold);
	const pWeight = getFontWeight(accountProfile.typography.p.bold);
	const linkWeight = getFontWeight(accountProfile.typography.link.bold);
	// // underline
	const h2Underline = getUnderline(accountProfile.typography.h2.underline);
	const h3Underline = getUnderline(accountProfile.typography.h3.underline);
	const ingressUnderline = getUnderline(accountProfile.typography.ingress.underline);
	const pUnderline = getUnderline(accountProfile.typography.p.underline);
	const linkUnderline = getUnderline(accountProfile.typography.link.underline);
	// // italic
	const h2Italic = getItalic(accountProfile.typography.h2.italic);
	const h3Italic = getItalic(accountProfile.typography.h3.italic);
	const ingressItalic = getItalic(accountProfile.typography.ingress.italic);
	const pItalic = getItalic(accountProfile.typography.p.italic);
	const linkItalic = getItalic(accountProfile.typography.link.italic);

	const btnBorderRadius = accountProfile.btnBorderRadius;

	return (
		<style>
			{`
			${buildSelectors('.MailTemplatePreview', ['p', 'p > *:not(a)', 'ul li', 'ol li'])} {
				color: ${contentColor};
				font-size: ${pSize}px;
				${pWeight}
				${pUnderline}
				${pItalic}
				white-space: initial;
				font-family: ${pFamily};
			}

			${buildSelectors('.MailTemplatePreview', ['> :not(.MailTemplatePreviewFooter):not(.MailTemplatePreviewHeader) a'])} {
				color: ${linkColor};
				${linkWeight}
				${linkUnderline}
				${linkItalic}
				font-family: ${linkFamily};
			}

			${buildSelectors('.MailTemplatePreview', ['p strong', 'p strong *', 'p.ingress strong', 'p.ingress strong *'])} {
				font-weight: bold !important;
			}

			${buildSelectors('.MailTemplatePreview', ['p em', 'p em *', 'p.ingress em', 'p.ingress em *'])} {
				font-style: italic;
			}

			${buildSelectors('.MailTemplatePreview', ['p u', 'p u *', 'p.ingress u', 'p.ingress u *'])} {
				text-decoration: underline;
			}

			${buildSelectors('.MailTemplatePreview', ['.ingress'])} {
				color: ${contentIngressColor};
				font-size: ${ingressSize}px;
				${ingressWeight}
				${ingressUnderline}
				${ingressItalic}
				${ingressFamily}
			}

			${buildSelectors('.MailTemplatePreview', ['h2'])} {
				color: ${mainColor};
				font-size: ${h2Size}px;
				${h2Weight}
				${h2Underline}
				${h2Italic}
				width: 100%;
				font-family: ${h2Family};
			}

			${buildSelectors('.MailTemplatePreview', ['h3'])} {
				color: ${secondaryColor};
				font-size: ${h3Size}px;
				${h3Weight}
				${h3Underline}
				${h3Italic}
				font-family: ${h3Family};
			}

			${buildSelectors('.MailTemplatePreviewButton', ['.MailTemplatePreviewButton__btn'])} {
				background-color: ${btnBgColor};
				border-radius: ${btnBorderRadius}px;
				font-family: ${linkFamily};
			}
		`}
		</style>
	);
};

const stringifyStyleContent = (style, accountProfile) => {
	return Object.keys(style)
		.map(key => {
			return `${key}: ${parseVariable(style[key], accountProfile)};`;
		})
		.join('');
};

const getInlineStyleBlock = (prefix, id, style, accountProfile) => {
	if (!style.self) {
		return null;
	}

	return (
		<style>
			{`
			${buildSelectors(`#${prefix}-${id}`)} {
				${stringifyStyleContent(style.self, accountProfile)}
			}
			`}
		</style>
	);
};

const handleClick = e => {
	if (e.target.tagName.toLowerCase() === 'a') {
		e.preventDefault();
	}
};

const isDraggingColumn = draggableId => draggableId.startsWith('col-');

class MailTemplatePreview extends React.PureComponent {
	constructor(p) {
		super(p);

		const t = getAngularModule('$translate');

		this.lang = {
			empty: t('default.empty'),
			deleteRow: t('mailTemplate.deleteRow'),
			duplicateRow: t('mailTemplate.duplicateRow'),
			deleteConfirm: t('mail.deleteConfirm'),
			row: t('mail.deleteBtnRow'),
			addFirstCol: t('mailTemplate.addFirstCol')
		};

		this.state = {
			dragging: false, // if anything, col or row is dragging
			dragType: 'row', // indicates if we are draging a row or column
			hoveredRowColSize: 12, // size of columns in hovered row, we use this to change the width of the dragged col (to fit in the wow)
			draggableId: null, // holds the id of the dragged element
			colSourceDroppableId: null // holds the droppableId of the dragged column home
		};

		this.onBeforeCapture = ({ draggableId }) => {
			const isColDrag = isDraggingColumn(draggableId);
			if (isColDrag) {
				const draggableElem = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`);
				if (draggableElem) {
					draggableElem.style.maxHeight = '100px';
				}
			}
		};

		this.onDragUpdate = update => {
			if (update?.destination?.droppableId && this.state.dragType === 'column') {
				const rowId = update.destination.droppableId.replace('col-droppable-', '');
				const row = this.props.config.rows.find(row => row.id + '' === rowId);
				if (row) {
					if (update.destination.droppableId === update.source.droppableId) {
						this.setState({ hoveredRowColSize: 12 / row.columns.length });
					} else {
						this.setState({ hoveredRowColSize: 12 / (row.columns.length + 1) });
					}
				}
			}
		};

		this.onDragStart = ({ draggableId, source }) => {
			const isColDrag = isDraggingColumn(draggableId);
			this.setState({
				dragging: true,
				dragType: isColDrag ? 'column' : 'row',
				draggableId,
				colSourceDroppableId: isColDrag ? source.droppableId : null
			});
		};

		this.onRowDragEnd = result => {
			this.setState({ dragging: false, draggableId: null, colSourceDroppableId: null });
			const { destination, source, draggableId } = result;

			const isColDrag = isDraggingColumn(draggableId);
			if (isColDrag) {
				const draggableElem = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`);
				if (draggableElem) {
					draggableElem.style.maxHeight = '';
				}
			}

			// dropped outside the list
			if (!destination) {
				return;
			}

			// Dropped in same location
			if (destination.droppableId === source.droppableId && destination.index === source.index) {
				return;
			}

			if (isColDrag) {
				this.props.onColMove(source.droppableId, destination.droppableId, source.index, destination.index);
			} else {
				this.props.onRowMove(source.index, destination.index);
			}
		};
		const FeatureHelper = getAngularModule('FeatureHelper');
		this.hasRowDeleteAccess = FeatureHelper.hasSoftDeployAccess('QUICK_TEMPLATE_ROW_REMOVE');
	}

	componentDidMount() {
		this._wrapper.addEventListener('click', handleClick);
	}

	componentWillUnmount() {
		this._wrapper.removeEventListener('click', handleClick);
	}

	renderRows(classes) {
		const accountProfile = this.props.accountProfile;
		const { readOnly, onColumnChange, isSocialEvent, isWebinar, config, isAutoReplyTemplate } = this.props;
		return this.props.config.rows.map((row, i) => {
			const rowId = `row-${row.id}`;
			const rowIsFull = !(row.columns.length < MAX_COLS);
			const droppableId = `col-droppable-${row.id}`;
			return (
				<Draggable key={row.id} draggableId={'row-' + row.id} index={i} isDragDisabled={readOnly}>
					{(rowDragProvided, rowDragSnapshot) => (
						<div ref={rowDragProvided.innerRef} {...rowDragProvided.draggableProps}>
							<Droppable
								droppableId={droppableId}
								direction="horizontal"
								ignoreContainerClipping
								isDropDisabled={
									this.state.dragType === 'row' ||
									(rowIsFull && this.state.colSourceDroppableId !== droppableId)
								}
							>
								{(rowDropProvided, rowDropSnapshot) => (
									<Fragment>
										<style>
											{`
								#row-${row.id}:after {
									background-color: ${parseVariable(row.backgroundColor, accountProfile)};
								}
								`}
										</style>
										<div
											id={rowId}
											className={classes
												.elem('row')
												.mod({
													'full-width': row.fullWidth,
													selected: this.props.selectedRow === row.id,
													hovered: this.props.hoveredRow === row.id,
													dragging: rowDragSnapshot.isDragging,
													'row-is-full':
														this.state.dragging &&
														this.state.dragType === 'column' &&
														this.state.colSourceDroppableId !== droppableId &&
														rowIsFull,
													'read-only': readOnly
												})
												.b()}
											style={this.state.dragging ? { pointerEvents: 'none' } : {}}
										>
											{!readOnly && this.hasRowDeleteAccess ? (
												<div className={classes.elem('edit-options-row').b()}>
													<ButtonGroup>
														<Tooltip title={this.lang.duplicateRow}>
															<Button
																color="super-light-green"
																className={classes
																	.elem('edit-options-row-btn')
																	.mod('first')
																	.b()}
																onClick={() =>
																	Promise.resolve(this.props.onCopy(row, i + 1))
																}
															>
																<Icon name="copy" />
															</Button>
														</Tooltip>

														<Tooltip title={this.lang.deleteRow}>
															<InlineConfirm
																show={true}
																tooltip={null}
																onConfirm={() =>
																	Promise.resolve(this.props.onDelete(row.id))
																}
																entity={'mail.row'}
																btnText={this.lang.deleteConfirm}
																showBody={false}
															>
																<Button
																	color="super-light-green"
																	className={classes.elem('edit-options-row-btn').b()}
																>
																	<Icon name="trash" />
																</Button>
															</InlineConfirm>
														</Tooltip>
													</ButtonGroup>

													<div
														className={classes.elem('row-drag-handle').b()}
														{...rowDragProvided.dragHandleProps}
													>
														<Icon name="arrows" />
													</div>
												</div>
											) : null}

											<div id={rowId + '-ck'} className="ck-inline-toolbar"></div>
											{i === 0 && !readOnly ? (
												<MailTemplatePreviewAddButton
													onSelect={c => this.props.onAddRow(c, 0)}
													first={!config.hasHeader}
												/>
											) : null}
											<Row
												className={classes.elem('row-inner').b()}
												ref={rowDropProvided.innerRef}
												{...rowDropProvided.droppableProps}
											>
												{row.columns.map((col, ci) => {
													let content;
													const draggableId = `col-${row.id}-${col.id}`;
													const thisColIsDragged = this.state.draggableId === draggableId;
													const selected = this.props.selectedColumn === col.id;
													// set column size if this row is being dragged over and was not the source droppable
													const size =
														rowDropSnapshot.isDraggingOver &&
														!rowDropSnapshot.draggingFromThisWith
															? this.state.hoveredRowColSize
															: col.size;

													const colProps = {
														id: 'column-' + col.id,
														onClick: readOnly
															? null
															: () => this.props.onSelectColumn(col, row),
														style: {
															backgroundColor:
																(col.enableCellBackground && col.backgroundColor) ||
																(thisColIsDragged ? 'white' : 'transparent')
														}
													};
													switch (col.type) {
														case 'HTML':
															content = <MailTemplatePreviewHtml column={col} />;
															break;
														case 'TEXT':
															content = (
																<MailTemplatePreviewText
																	column={col}
																	columnIndex={ci}
																	readOnly={readOnly}
																	onChange={onColumnChange}
																	selected={selected}
																	accountProfile={this.props.accountProfile}
																	isSocialEvent={isSocialEvent}
																	isWebinar={isWebinar}
																	rowId={rowId}
																	isFirstRow={!config.hasHeader && i === 0}
																	isAutoReplyTemplate={isAutoReplyTemplate}
																/>
															);
															break;
														case 'HR':
															content = <MailTemplatePreviewHr column={col} />;
															break;
														case 'VIDEO':
														case 'IMAGE':
															content = (
																<MailTemplatePreviewImage
																	column={col}
																	readOnly={readOnly}
																	onChange={onColumnChange}
																	selected={selected}
																	accountProfile={this.props.accountProfile}
																	maxWidth={
																		rowDropSnapshot.isDraggingOver ||
																		thisColIsDragged
																			? getColWidthFromSize(
																					this.state.hoveredRowColSize
																			  )
																			: undefined
																	}
																/>
															);
															break;
														case 'BUTTON':
															content = <MailTemplatePreviewButton column={col} />;
															break;

														case 'SOCIAL':
															content = (
																<MailTemplatePreviewSocial
																	column={col}
																	readOnly={readOnly}
																	onChange={onColumnChange}
																	selected={selected}
																	accountProfile={this.props.accountProfile}
																/>
															);
															break;
														case 'EMPTY':
															content = readOnly ? null : (
																<div className={classes.elem('empty-content').b()}>
																	<Text center={true} color="green">
																		{this.lang.empty}
																	</Text>
																</div>
															);
															break;
														default:
															content = col.type;
													}

													const canAddCols = !readOnly && !rowIsFull;
													return (
														<Draggable
															key={'col-' + col.id}
															draggableId={draggableId}
															index={ci}
															isDragDisabled={readOnly}
														>
															{(colDrag, colDragSnapshot) => (
																<Fragment>
																	{getInlineStyleBlock(
																		'column',
																		col.id,
																		col.style,
																		accountProfile
																	)}
																	<Column
																		{...colProps}
																		size={
																			colDragSnapshot.isDragging
																				? this.state.hoveredRowColSize
																				: size
																		}
																		className={classes
																			.elem('column')
																			.mod({
																				selected,
																				hovered:
																					this.props.hoveredColumn === col.id,
																				empty: col.type === 'EMPTY',
																				[col.type?.toLowerCase()]: !!col.type,
																				dragging: colDragSnapshot.isDragging,
																				'read-only': readOnly
																			})
																			.b()}
																		ref={colDrag.innerRef}
																		{...colDrag.draggableProps}
																		style={{
																			...colProps.style,
																			...colDrag.draggableProps.style,
																			width: colDragSnapshot.isDragging
																				? getColWidthFromSize(
																						this.state.hoveredRowColSize
																				  )
																				: undefined,
																			overflow:
																				rowDropSnapshot.isDraggingOver ||
																				colDragSnapshot.isDragging
																					? 'hidden'
																					: undefined,
																			flex: colDragSnapshot.isDragging
																				? `0 0 ${getColWidthFromSize(
																						this.state.hoveredRowColSize
																				  )}px`
																				: null,
																			transform:
																				!colDragSnapshot.isDragging &&
																				colDrag.draggableProps.style.transform
																					? `translate(${getColWidthFromSize(
																							this.state.hoveredRowColSize
																					  )}px, 0px)`
																					: colDrag.draggableProps.style
																							.transform,
																			minHeight:
																				this.state.dragging &&
																				this.state.dragType === 'column'
																					? '50px'
																					: undefined
																		}}
																	>
																		{!readOnly ? (
																			<MailTemplatePreviewColumnTools
																				onRemove={() =>
																					this.props.onRemoveCol(i, ci)
																				}
																				dragHandleProps={
																					colDrag.dragHandleProps
																				}
																			/>
																		) : null}
																		{canAddCols ? (
																			<MailTemplatePreviewAddButton
																				vertical
																				onSelect={() =>
																					this.props.onAddColumn(i, ci)
																				}
																			/>
																		) : null}
																		<MailTemplatePreviewPadding column={col} />
																		{content}
																		{canAddCols && ci === row.columns.length - 1 ? (
																			<MailTemplatePreviewAddButton
																				vertical
																				last
																				onSelect={() =>
																					this.props.onAddColumn(i, ci + 1)
																				}
																			/>
																		) : null}
																	</Column>
																</Fragment>
															)}
														</Draggable>
													);
												})}
												{rowDropProvided.placeholder}
											</Row>
											{!readOnly ? (
												<MailTemplatePreviewAddButton
													onSelect={c => this.props.onAddRow(c, i + 1)}
												/>
											) : null}
										</div>
									</Fragment>
								)}
							</Droppable>
						</div>
					)}
				</Draggable>
			);
		});
	}

	render() {
		const classes = new bemClass('MailTemplatePreview', this.props.className);
		const { config, readOnly, accountProfile, selectedColumn } = this.props;
		const rootStyle = {
			backgroundColor: config.bodyBg
		};
		const browserLinkStyle = {
			color: config.browserLinkColor
		};
		let headerMargin = config.headerMargin;
		if (!headerMargin && headerMargin !== 0 && headerMargin !== '0') {
			headerMargin = 15;
		}

		const showAddFirstRow = !config.hasFooter && !config.hasHeader && !readOnly && !config.rows.length;

		return (
			<div className={classes.b()} style={rootStyle} ref={r => (this._wrapper = r)}>
				{styleBlock(accountProfile)}

				{config.hasBrowserLink && config.browserLink ? (
					<div
						className={classes
							.elem('browser-link')
							.mod({ selected: selectedColumn === 'browser-link' })
							.b()}
						onClick={() => this.props.onSelectColumn({ id: 'browser-link' })}
					>
						<a href="#" style={browserLinkStyle}>
							{config.browserLink}
						</a>
					</div>
				) : null}

				{config.hasHeader ? (
					<MailTemplatePreviewHeader
						selected={selectedColumn === 'header'}
						readOnly={readOnly}
						config={config}
						accountProfile={accountProfile}
						onClick={readOnly ? null : () => this.props.onSelectColumn({ id: 'header' })}
					/>
				) : null}

				{showAddFirstRow ? (
					<MailTemplatePreviewAddMenu
						className={classes.elem('add-first-row').b()}
						header={this.lang.addFirstCol}
						onSelect={c => this.props.onAddRow(c, 0)}
					/>
				) : null}

				<DragDropContext
					onBeforeCapture={this.onBeforeCapture}
					onDragStart={this.onDragStart}
					onDragEnd={this.onRowDragEnd}
					onDragUpdate={this.onDragUpdate}
				>
					<Droppable
						droppableId="row-droppable"
						direction="vertical"
						isDropDisabled={!this.state.dragging || this.state.dragType !== 'row'}
					>
						{provided => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								{...provided.droppablePlaceholder}
							>
								{this.renderRows(classes)}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>

				{config.hasFooter ? (
					<MailTemplatePreviewFooter
						selected={selectedColumn === 'footer'}
						readOnly={readOnly}
						accountProfile={accountProfile}
						config={config}
						onClick={readOnly ? null : () => this.props.onSelectColumn({ id: 'footer' })}
					/>
				) : null}
			</div>
		);
	}
}

MailTemplatePreview.propTypes = {
	config: PropTypes.object.isRequired,
	accountProfile: PropTypes.object.isRequired,
	onSelectColumn: PropTypes.func.isRequired,
	onColumnChange: PropTypes.func.isRequired,
	selectedColumn: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['browser-link', 'header', 'footer'])]),
	selectedRow: PropTypes.number,
	hoveredColumn: PropTypes.number,
	hoveredRow: PropTypes.number,
	onAddRow: PropTypes.func,
	onAddColumn: PropTypes.func,
	isSocialEvent: PropTypes.bool,
	isWebinar: PropTypes.bool,
	readOnly: PropTypes.bool,
	isAutoReplyTemplate: PropTypes.bool
};
export default MailTemplatePreview;
