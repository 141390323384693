import { getDefaultCustomValues } from 'App/components/CustomFields/CustomFieldsUtils';
import { EntityCustomField } from 'App/resources/Model/CustomField';
import Product from 'App/resources/Model/Product';
import React from 'react';
import { useSelector as useReduxSelector } from 'react-redux';
import { RootState } from 'Store/index';

import Actions from './OrderContextActions';

import {
	fixInitialOrderRows,
	totalAmount,
	totalDiscount,
	totalRecurringValue,
	totalContributionMargin,
	totalPurchaseCost
} from './OrderContextHelpers';
import { Action, createLocalContextWithActionHandlers } from 'App/helpers/stateHelper';
export { type Dispatch } from 'App/helpers/stateHelper';
import { replaceItem } from 'Store/helpers/array';

export type BundleRow = {
	id?: number;
	readonly uuid: number;
	listPrice: number;
	price: number;
	quantity: number;
	tierQuantity: number;
	adjustable?: boolean;
	bundleRowPrice?: number;
	bundleRowCost?: number;
	purchaseCost: number;
	productId: number;
	product?: Product;
	discount?: number;
	discountPercent?: number;
	latestDiscountChange?: 'percent' | 'absolute';
	orderRowTotal?: number;
	recurringValue?: number;
	priceListShowDiscount?: boolean;
	resetOrderDiscount?: boolean;
};

export type OrderRow = {
	productId?: number;
	id?: number;
	readonly uuid: number;
	justAdded?: boolean;
	product?: Product;
	listPrice?: number; // The base product price based on tiers and recurring interval
	price?: number; // The actual price that is displayed on the row based on discounts and what the user types in
	quantity?: number; // The quantity number used to multiply with when calculating (Will always be 1 for Tiered Total)
	tierQuantity?: number; // The actual quantity that is displayed on the order row
	discount?: number;
	discountPercent?: number;
	orderRowTotal?: number;
	recurringValue?: number;
	purchaseCost?: number;
	orderRowCost?: number;
	currencies?: [{ currency: string; price: number }];
	tiers?: [{ currencies: [{ currency: string; value: number }]; start: number; end: number; isTotalPrice: boolean }];
	custom?: EntityCustomField[];
	latestDiscountChange?: 'percent' | 'absolute';
	isValid?: boolean;
	bundleRows?: BundleRow[];
	isNew?: boolean;
	priceListId?: number;
	priceListShowDiscount?: boolean;
	defaultPriceListPrice?: number;
	$percentPlaceholder?: string;
	maxDiscount?: number;
	hasExcessiveDiscount?: boolean;
	initExcessiveDiscount?: boolean;
	bundleRowPrice?: number;
	bundleRowCost?: number;
	adjustable?: boolean;
	sortId?: number;
	resetOrderDiscount?: boolean;
	$discount?: number;
	$discountRaw?: number;
	bundleFixedPrice?: boolean;
};

export type OrderRowsState = {
	orderRows: OrderRow[];
	totalDiscount: number;
	totalAmount: number;
	totalRecurringValue: number;
	totalContributionMargin: number;
	totalPurchaseCost: number;
	orderInterval: number;
};
// added + 1 to index due to a mismatch from backend - frontend creating an alert bug when cancelling editSub view
const addSortIdToOrderRows = (orderRows: OrderRow[]) => orderRows.map((row, index) => ({ ...row, sortId: index + 1 }));
export const getReCalculatedNumbers = (orderRows: OrderRow[]) => ({
	totalAmount: totalAmount(orderRows),
	totalDiscount: totalDiscount(orderRows),
	totalRecurringValue: totalRecurringValue(orderRows),
	totalContributionMargin: totalContributionMargin(orderRows),
	totalPurchaseCost: totalPurchaseCost(orderRows)
});

const ACTION_HANDLERS: { [key: string]: (s: OrderRowsState, a: Action) => OrderRowsState } = {
	[Actions.SET_ORDER_ROW]: (state, { orderRows }) => ({
		...state,
		orderRows: addSortIdToOrderRows(orderRows),
		...getReCalculatedNumbers(orderRows)
	}),
	[Actions.UPDATE_ORDER_ROW]: (state, { orderRow: updated }) => {
		const index = state.orderRows.findIndex(row => row.uuid === updated.uuid);
		const orderRow = { ...state.orderRows[index], ...updated };
		const orderRows = replaceItem(state.orderRows, index, orderRow);

		return {
			...state,
			orderRows: addSortIdToOrderRows(orderRows),
			...getReCalculatedNumbers(orderRows)
		};
	},
	[Actions.ORDER_INTERVAL_CHANGED]: (state, { orderRows, orderInterval }) => ({
		...state,
		orderRows: addSortIdToOrderRows(orderRows),
		orderInterval,
		...getReCalculatedNumbers(orderRows)
	})
};

type ProviderProps = {
	children: React.ReactNode;
	initialRows: OrderRow[];
	orderInterval: number;
	priceListId?: number;
};

const { Provider, useDispatch, useSelector } = createLocalContextWithActionHandlers(ACTION_HANDLERS);
export const useOrderRowsDispatch = useDispatch;
export const useOrderRowsSelector = useSelector;

export function OrderRowsProvider({ children, initialRows, orderInterval, priceListId }: ProviderProps) {
	const { customFields } = useReduxSelector((state: RootState) => ({
		customFields: state.App.customFields?.['orderrow']
	}));
	const custom = getDefaultCustomValues(customFields);
	const orderRows = initialRows?.length
		? fixInitialOrderRows(initialRows, orderInterval, priceListId)
		: [{ uuid: Date.now(), custom, priceListId }];

	const initialState: OrderRowsState = {
		orderRows,
		totalDiscount: totalDiscount(orderRows),
		totalAmount: totalAmount(orderRows),
		orderInterval: orderInterval ?? 1,
		totalRecurringValue: totalRecurringValue(orderRows),
		totalContributionMargin: totalContributionMargin(orderRows),
		totalPurchaseCost: totalPurchaseCost(orderRows)
	};

	return <Provider value={initialState}>{children}</Provider>;
}
