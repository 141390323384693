import logError from 'Helpers/logError';
import Resource from './Resource';

class Provisioning extends Resource {
	constructor() {
		super('provisioning', null);
		this.provisioningNoAuth = new Resource('provisioningNoAuth');

		this.notifications = {
			buyAddonError: () => ({
				title: 'default.error',
				body: 'admin.billing.addon.failedToBuyAddon'
			})
		};
	}

	doSomething(method, parameters = {}, reqMethod = 'get') {
		if (Tools.ENV === 'ALPHA' || window.location.origin.includes('sandbox')) {
			switch (method) {
				case 'getAvailableAddons':
				case 'getSubscriptionAddons':
				case 'getTrialAddons':
					return Promise.resolve({ data: [] });
				default:
					return Promise.resolve({ data: {} });
			}
		}
		var promise;
		if (reqMethod === 'post') {
			promise = super._postRequest(`?method=${method}`, { method, ...parameters });
		} else {
			let params = '';
			Object.keys(parameters).forEach(key => {
				if (parameters[key]) {
					params += '&' + key + '=' + parameters[key];
				}
			});
			promise = super._getRequest(`?method=${method}${params}`, { methodName: method });
		}

		return promise
			.then(res => ({
				error: null,
				data: res.data
			}))
			.catch(error =>
				Promise.reject({
					error,
					data: null
				})
			);
	}

	/* PROVISIONING */

	hasApp(alias) {
		return this.doSomething('hasProduct', {
			type: 'integration',
			productAlias: alias
		});
	}

	/* BILLING */

	getSubscription() {
		return this.doSomething('getSubscription');
	}

	getFutureSubscription() {
		return this.doSomething('getFutureSubscription');
	}
	getActiveLicense() {
		return this.doSomething('getActiveLicense');
	}

	getFutureLicense() {
		return this.doSomething('getFutureLicense');
	}

	getSubscriptionAddons() {
		return this.doSomething('getSubscriptionAddons');
	}

	getTrialAddons(clientId) {
		// We need to pass clientId to this function since we try to get trial addons right after we've logged in, but before AppService is initialized
		// I can't use the functions in LocalStorage.ts here since it uses AppService

		let trialCache = JSON.parse(localStorage.getItem('trialCache')) || {};
		// Once we're done with the login process we can use AppService to get the customerId
		const customerId = Tools.AppService.getCustomerId() || clientId || 0;
		if (customerId && Tools.FeatureHelper.hasSoftDeployAccess('CACHE_GETTRIALADDONS')) {
			try {
				if (trialCache[customerId] && trialCache[customerId]?.expiry > new Date().getTime()) {
					return Promise.resolve(trialCache[customerId]);
				} else {
					// We fully reset the cache if the expiry date has passed/the customerId has changed in order to clear out potentially other customerIds from the cache
					trialCache = {};
					localStorage.removeItem('trialCache');

					const res = this.doSomething('getTrialAddons');
					return res
						.then(data => {
							if (!data.error) {
								trialCache[customerId] = data;
								trialCache[customerId].expiry = new Date().getTime() + 1000 * 60 * 60 * 1; // 1 hour, feel free to change if you want :)
								localStorage.setItem('trialCache', JSON.stringify(trialCache));
								return data;
							} else {
								return data;
							}
						})
						.catch(e => {
							trialCache = {};
							localStorage.removeItem('trialCache');
							logError('error in getTrialAddons:', e);
						});
				}
			} catch (e) {
				trialCache = {};
				localStorage.removeItem('trialCache');
				logError('error in getTrialAddons:', e);
			}
		}
		return this.doSomething('getTrialAddons');
	}

	getCustomerDetails() {
		return this.doSomething('getCustomerDetails');
	}

	getLicenceInfo() {
		return this.doSomething('getLicenceInfo');
	}

	getProduct(productAlias, type) {
		const options = { productAlias };
		if (type) {
			options.type = type;
		}
		return this.doSomething('getProduct', options);
	}

	purchase(productAlias) {
		return this.doSomething('purchase', { productAlias });
	}

	cancel(productAlias) {
		return this.doSomething('cancel', { productAlias });
	}

	getSeatChangeInfo(seatDiff) {
		return this.doSomething('getSeatChangeInfo', { seatDiff });
	}

	addSeats(params) {
		return this.doSomething('addSeats', params);
	}
	removeSeats(params) {
		return this.doSomething('removeSeats', params);
	}

	addBisnodeCredits(params) {
		return this.doSomething('addBisnodeCredits', params);
	}

	saveBillingAddress(address) {
		return this.doSomething('saveBillingAddress', address);
	}

	getAvailableAddons() {
		return this.doSomething('getAvailableAddons');
	}

	buyAddon(alias) {
		return this.doSomething('buyAddon', { alias });
	}
	addTrialAddon(alias, productId, trialPeriod) {
		return this.doSomething('addTrialAddon', { alias, productId, trialPeriod });
	}

	cancelAddon(productId, agreementGroup) {
		return this.doSomething('cancelAddon', { productId, agreementGroup });
	}

	cancelSubscription({ agreementGroup, reasons }) {
		return this.doSomething('cancelSubscription', { agreementGroup, reasons }, 'post');
	}

	reactivateAddon(productId, agreementGroup) {
		return this.doSomething('reactivateAddon', { productId, agreementGroup });
	}

	reactivateSubscription() {
		return this.doSomething('reactivateSubscription');
	}
	makeGroup(agreementGroup) {
		return this.doSomething('makeGroup', { agreementGroup });
	}
}

const resource = new Provisioning();
export default resource;
