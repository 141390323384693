import { useSubscriptionGroupContext } from 'App/components/EditSubscription/Context/SubscriptionGroupContext';
import SubscriptionHotspot from 'App/components/EditSubscription/SubscriptionHotspot';
import BemClass from '@upsales/components/Utils/bemClass';
import { PrimaryButton } from '@upsales/components/Buttons';
import T from 'Components/Helpers/translate';
import PeriodTree from './PeriodTree';
import moment from 'moment';
import React from 'react';

import './SubscriptionPeriodsTree.scss';

type Props = {
	activateSplitPage: () => void;
	disabled?: boolean;
};

export type Child = {
	startDate: Date | string;
	endDate: Date | string;
	periodLength?: number;
};

export type Tree = {
	uuid: number;
	periodLength: number;
	state: 'passed' | 'current' | 'renewed';
	children?: Child[];
	startDate: string;
	endDate?: string;
};

const SubscriptionPeriodsTree = ({ activateSplitPage, disabled }: Props) => {
	const classes = new BemClass('SubscriptionPeriodsTree');

	const {
		state: { locked, subscriptionMap, currentUUID },
		firstPeriod,
		updateCurrent,
		setCurrentChild
	} = useSubscriptionGroupContext();

	const periods: Tree[] = Object.values(subscriptionMap).map((p, i) => {
		const startDate = i === 0 ? p.invoiceStartDate : p.startDate;
		const endDateHasPassed = p.endDate && moment(p.endDate).isBefore();
		const startDateHasPassed = moment(startDate).isBefore(moment(), 'day');
		let state: 'renewed' | 'passed' | 'current' = 'renewed';
		if (endDateHasPassed) {
			state = 'passed';
		} else if (startDateHasPassed || firstPeriod.uuid === p.uuid) {
			state = 'current';
		}
		return {
			uuid: p.uuid,
			state,
			periodLength: p.periodLength,
			children: p.children,
			startDate: startDate,
			endDate: p.periodLength ? p.endDate ?? p.renewalDate : p.endDate
		};
	});

	return (
		<div className={classes.b()}>
			{periods.map(tree => (
				<PeriodTree
					key={tree.uuid}
					onClick={
						!disabled
							? (resetChildIndex = false) => {
									updateCurrent(tree.uuid);
									if (resetChildIndex) {
										setCurrentChild(0);
									}
							  }
							: undefined
					}
					tree={tree}
					isActive={tree.uuid === currentUUID}
					hideLine={disabled}
				/>
			))}
			<SubscriptionHotspot type="renewalCreated" />
			<PrimaryButton
				disabled={disabled || locked}
				onClick={activateSplitPage}
				className={classes.elem('plan-button').b()}
			>
				{T('subscription.modal.summary.splitSidebar.title')}
			</PrimaryButton>
		</div>
	);
};

export default SubscriptionPeriodsTree;
