import React from 'react';
import { Icon, Headline, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';

const MailContentEditorEmpty = ({ onSelect }) => {
	const classes = new bemClass('MailContentEditor');
	return (
		<div className={classes.elem('empty').b()}>
			<Headline size="xs">{translate('mail.selectContent')}</Headline>

			<div className={classes.elem('empty-selector').b()}>
				<div>
					<button onClick={() => onSelect('TEXT')}>
						<Icon name="align-center" />
						<Text>{translate('mail.text')}</Text>
					</button>
				</div>
				<div>
					<button onClick={() => onSelect('IMAGE')}>
						<Icon name="image" />
						<Text>{translate('default.image')}</Text>
					</button>
				</div>
				<div>
					<button
						onClick={() =>
							onSelect('IMAGE', {
								showCaption: true,
								caption: `<p>${translate('mail.imageCaption')}</p>`
							})
						}
					>
						<Icon name="image-text" />
						<Text>{translate('mail.imageWithText')}</Text>
					</button>
				</div>
				<div>
					<button onClick={() => onSelect('BUTTON')}>
						<Icon name="button" />
						<Text>{translate('bannerEditor.button')}</Text>
					</button>
				</div>
				<div>
					<button onClick={() => onSelect('HR')}>
						<Icon name="divider-line" />
						<Text>{translate('mail.line')}</Text>
					</button>
				</div>
				<div>
					<button onClick={() => onSelect('VIDEO')}>
						<Icon name="play" />
						<Text>{translate('landingpage.video')}</Text>
					</button>
				</div>
				<div>
					<button onClick={() => onSelect('HTML')}>
						<Icon name="code" />
						<Text>{'HTML'}</Text>
					</button>
				</div>
				<div>
					<button onClick={() => onSelect('SOCIAL')}>
						<Icon name="share" />
						<Text>{translate('mail.social.title')}</Text>
					</button>
				</div>
			</div>
		</div>
	);
};

export default MailContentEditorEmpty;
