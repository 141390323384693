import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import ContactResource from 'Resources/Contact';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import { Block, Link, Select, SelectAsync, Text, Icon, Flex, Tooltip } from '@upsales/components';
import './HiddenContactSelect.scss';
import Contact from 'App/resources/Model/Contact';
import { SelectCoreProps } from '@upsales/components/Select/Select';
import Client from 'App/resources/Model/Client';
import { getJourneyStep } from 'Components/Helpers/journeyStep';

type MappedContact = Contact & { otitle: string };

type HiddenContactSelectProps = Pick<SelectCoreProps<Contact>, 'anchor' | 'scrollContainer'> & {
	contacts: Contact[];
	client: Client;
	onChange: (contact: Contact | null) => void;
	asAjax?: boolean;
	canCreateContacts: boolean;
	onCreateContact: () => void;
};

const mapper = (c: Contact): MappedContact => ({ ...c, otitle: c.title, title: c.name });

const fetchContacts =
	(clientId: number) =>
	(searchTerm?: string): Promise<MappedContact[]> => {
		const rb = new RequestBuilder();
		rb.addFilter(ContactResource.attr.client.attr.id, comparisonTypes.Equals, clientId);
		rb.addFilter(ContactResource.attr.name, comparisonTypes.WildcardEnd, searchTerm);
		rb.addFilter(ContactResource.attr.active, comparisonTypes.Equals, true);
		if (!searchTerm) {
			rb.addSort(ContactResource.attr.name, true);
		}
		rb.limit = 20;
		return ContactResource.find(rb.build()).then(({ data }) => data.map(mapper));
	};

const createContactLink = (
	close: () => void,
	onCreateContact: HiddenContactSelectProps['onCreateContact'] | null,
	noResult: boolean
) => {
	return (
		<Block space="ptl prl pbl pll">
			<Text color="grey-10" italic={noResult}>
				{noResult ? `${T('default.noContacts')}. ` : null}
				{onCreateContact ? (
					<Link
						onMouseDown={() => {
							close();
							onCreateContact();
						}}
					>
						{T('default.createAContact')}
					</Link>
				) : null}
			</Text>
		</Block>
	);
};

const HiddenContactSelect: React.FC<HiddenContactSelectProps> = ({
	children,
	contacts,
	client,
	onChange,
	asAjax,
	canCreateContacts,
	onCreateContact,
	...props
}) => {
	const classes = new BemClass('HiddenContactSelect');
	const sharedSelectProps: SelectCoreProps<MappedContact, false> = {
		value: null,
		onChange: ({ otitle, ...c }) => onChange({ ...c, title: otitle }),
		className: classes.b(),
		inline: true,
		renderSelectedItem: () => <div>{children}</div>,
		renderCustomExtraRow: canCreateContacts ? close => createContactLink(close, onCreateContact, false) : undefined,
		renderCustomNoResults: close => createContactLink(close, canCreateContacts ? onCreateContact : null, true),
		renderItem: contact => (
			<Flex
				key={contact.id}
				className={classes.elem('row').b()}
				data-id={contact.id}
				direction="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Flex className={classes.elem('userInfo').b()} direction="column" space="mll">
					<Text ellipsis className={classes.elem('row').elem('title').b()}>
						{contact.client.id !== client.id ? <Icon name="sitemap" space="mrm" /> : null}

						{contact.name}
					</Text>
					<Text ellipsis size="sm" color="grey-11">
						{contact.otitle}
					</Text>
					{contact.client ? (
						<Flex space="prs" className={classes.elem('row').elem('subtitle').b()}>
							<Text size="sm" color="grey-11" ellipsis>
								{contact.client.name} • {getJourneyStep(contact.journeyStep)?.name}
							</Text>
						</Flex>
					) : null}
				</Flex>
				<Flex space="prm" alignItems="center" justifyContent="space-between" gap="u6">
					<Tooltip disabled={!contact.email} title={contact.email ?? 'null'}>
						<Icon color={contact.email ? 'black' : 'grey-7'} name="envelope" />
					</Tooltip>
					<Tooltip
						position="left"
						disabled={!contact.cellPhone && !contact.phone}
						title={`${contact.phone ? T('contact.row.phone') + contact.phone + '\n' : ''}${
							contact.cellPhone ? T('contact.row.mobile') + contact.cellPhone : ''
						}`}
					>
						<Icon color={contact.cellPhone || contact.phone ? 'black' : 'grey-7'} name="phone" />
					</Tooltip>
				</Flex>
			</Flex>
		)
	};

	return asAjax ? (
		<SelectAsync<MappedContact>
			key="HiddenContactSelect-Select"
			{...sharedSelectProps}
			{...props}
			fetcher={fetchContacts(client.id)}
			fetchOnOpen
		/>
	) : (
		<Select<MappedContact>
			key="HiddenContactSelect-Select"
			{...sharedSelectProps}
			{...props}
			options={contacts.map(mapper) ?? []}
		/>
	);
};

HiddenContactSelect.propTypes = {
	contacts: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	onCreateContact: PropTypes.func.isRequired,
	canCreateContacts: PropTypes.bool.isRequired,
	asAjax: PropTypes.bool
};

export default HiddenContactSelect;
