import { reducer, getInitialState } from './SubscriptionIndexingState/SubscriptionIndexingReducer';
import SubscriptionIndexingState, { State } from './SubscriptionIndexingState/SubscriptionIndexingState';
import { PersistedIndexIncreaseSettings } from 'App/resources/Model/IndexIncreaseSettings';
import { Tabs, Tab, Text, Button, FullscreenModal, Row, Icon, ColorSwitcher } from '@upsales/components';
import SubscriptionIndexingSettings from './SubscriptionIndexingSettings';
import SubscriptionIndexingPreview from './SubscriptionIndexingPreview';
import SubscriptionIndexingSummary from './SubscriptionIndexingSummary';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from 'App/components/Modals/Modals';
import React, { useEffect, useReducer } from 'react';
import EditorHeader from 'Components/EditorHeader';
import moment from 'moment';

import './SubscriptionIndexingModal.scss';
import { useDebouncePromise } from 'Components/Helpers/Debounce';
import { circle } from 'Components/Helpers/styleHelper';
import { useSelf } from 'App/components/hooks/appHooks';

const STEP = {
	SETTINGS: 'SETTINGS',
	SELECTION: 'SELECTION',
	FINAL: 'FINAL'
} as const;

const DATE_FORMAT = 'YYYY-MM-DD';

type StepType = (typeof STEP)[keyof typeof STEP];

type Props = ModalProps & {
	indexIncreaseSetting?: PersistedIndexIncreaseSettings;
	isCopy?: boolean;
};

const SubscriptionIndexingModal = ({ className, close, indexIncreaseSetting, isCopy }: Props) => {
	const classNames = new BemClass('SubscriptionIndexingModal', className);

	const { t } = useTranslation();
	const self = useSelf();

	const [state, dispatch] = useReducer(reducer, indexIncreaseSetting, settings => getInitialState(settings, isCopy));
	const [currentStep, setCurrentStep] = React.useState<StepType>(state.isPassed ? STEP.FINAL : STEP.SETTINGS);

	const {
		id,
		name,
		filter,
		isDirty,
		isLocked,
		isSaving,
		isPassed,
		specificDate,
		indexInterval,
		indexPercentage,
		indexConflictDecisionsMap,
		subscriptionCount
	} = state;

	const { doSave, getSubscriptionCount, lockAtSave } = SubscriptionIndexingState(dispatch);

	const fetchSubscriptionCount = useDebouncePromise(
		(filter: State['filter'], indexConflictDecisionsMap: State['indexConflictDecisionsMap']) =>
			getSubscriptionCount(filter, indexConflictDecisionsMap, id),
		[],
		200
	);

	useEffect(() => {
		const refreshCount = () => {
			if (!isPassed) {
				fetchSubscriptionCount(filter, indexConflictDecisionsMap);
			}
		};
		refreshCount();
		const listeners = [
			Tools.$rootScope.$on('agreement.updated', refreshCount),
			Tools.$rootScope.$on('agreementGroup.updated', refreshCount),
			Tools.$rootScope.$on('agreementGroup.created', refreshCount)
		];
		return () => {
			listeners.forEach(l => l());
		};
	}, [JSON.stringify(filter), JSON.stringify(indexConflictDecisionsMap), isLocked]);

	const getTabName = (tab: StepType) => {
		return tab === STEP.SETTINGS
			? t('subscription.indexing.tabs.settings')
			: t('subscription.indexing.tabs.preview');
	};

	const getContent = (step: StepType) => {
		switch (step) {
			case STEP.SETTINGS:
				return <SubscriptionIndexingSettings state={state} dispatch={dispatch} />;
			case STEP.SELECTION:
				return <SubscriptionIndexingPreview state={state} dispatch={dispatch} />;
			case STEP.FINAL:
				return <SubscriptionIndexingSummary state={state} dispatch={dispatch} />;
			default:
				return <div></div>;
		}
	};

	const save = async () => {
		await doSave(state);
		close();
	};

	const isEdit = !!id;
	const getConfirmTitle = () => {
		if (currentStep === STEP.SETTINGS) {
			return t('subscription.indexing.tabs.preview');
		} else if (currentStep === STEP.SELECTION) {
			return t('subscription.indexing.header.confirm');
		} else if (isPassed) {
			return t('close');
		} else {
			return indexInterval === 'once'
				? t('subscription.indexing.header.confirm.finalStep.specificDate.save', {
						date: moment(specificDate).format(DATE_FORMAT)
				  })
				: t('subscription.indexing.header.confirm.finalStep.renewal.save');
		}
	};

	const disableSave = isPassed
		? false
		: isSaving ||
		  (currentStep === STEP.SETTINGS && !name?.length) ||
		  (currentStep === STEP.FINAL && (!name?.length || !subscriptionCount || !indexPercentage));

	const getConfirmTooltip = () => {
		if (isPassed) {
			return '';
		}

		if (currentStep === STEP.FINAL) {
			if (!name?.length) {
				return t('default.nameIsRequired');
			}
			if (!subscriptionCount) {
				return t('subscription.indexing.header.confirm.finalStep.noSubscription');
			}
			if (!indexPercentage) {
				return t('subscription.indexing.header.confirm.finalStep.noPercentage');
			}
		} else if (currentStep === STEP.SETTINGS) {
			if (!name?.length) {
				return t('default.nameIsRequired');
			}
		}
		return '';
	};

	const onBack =
		currentStep === STEP.SETTINGS || isPassed
			? null
			: () => setCurrentStep(currentStep === STEP.FINAL ? STEP.SELECTION : STEP.SETTINGS);

	const onConfirm = () =>
		currentStep === STEP.FINAL
			? isPassed
				? close()
				: save()
			: setCurrentStep(currentStep === STEP.SETTINGS ? STEP.SELECTION : STEP.FINAL);

	const showLockBanner = isEdit && isLocked && !isPassed;

	return (
		<FullscreenModal className={classNames.b()}>
			<EditorHeader
				onConfirmTitle={getConfirmTitle()}
				onConfirmSupertitle={currentStep === STEP.FINAL ? undefined : t('form.nextStep')}
				disableSave={disableSave}
				onConfirmTooltip={getConfirmTooltip()}
				onBackTitle={t('back')}
				// @ts-ignore because it always expects null
				onBack={onBack}
				onAbortConfirm={isDirty}
				onConfirm={onConfirm}
				onConfirmIcon={currentStep === STEP.FINAL ? 'check' : 'chevron-right'}
				className={className}
				subtitle={
					indexInterval === 'once'
						? moment(specificDate)
								.locale(self?.language ?? 'sv-SE') // the first time it loads may be undefined, Adding a default just in case
								.format('Do MMMM YYYY')
						: t('subscription.indexing.when.renewal.subtitle')
				}
				onAbort={isPassed ? null : close}
				loading={isSaving}
				title={t(`subscription.indexing.header.title${isPassed ? '.passed' : ''}`)}
			>
				{currentStep === STEP.FINAL ? null : (
					<Tabs
						noFlex
						color="white"
						onChange={tab => {
							setCurrentStep(tab as StepType);
						}}
						selected={currentStep.toString()}
					>
						{[STEP.SETTINGS, STEP.SELECTION].map((tab, i) => (
							<Tab
								key={tab}
								id={tab.toString()}
								disabled={tab === STEP.SELECTION && currentStep === STEP.SETTINGS && !name?.length}
							>
								<ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>
								{getTabName(tab)}
							</Tab>
						))}
					</Tabs>
				)}
			</EditorHeader>

			{showLockBanner ? (
				<Row align="space-between" className={classNames.elem('banner-lock').b()}>
					<Row align="center">
						<Icon space="prm" name="info-circle" />
						<Text space="prm" bold>
							{t('subscription.indexing.bannerLock.title')}
						</Text>
						<Text>{t('subscription.indexing.bannerLock.subTitle')}</Text>
					</Row>
					<Button size="sm" onClick={lockAtSave}>
						<Row align="center">
							<Icon space="prm" name="lock" />
							<Text color="white">{t('subscription.indexing.bannerLock.button')}</Text>
						</Row>
					</Button>
				</Row>
			) : null}

			<div className={classNames.elem('wrapper').b()}>{getContent(currentStep)}</div>
		</FullscreenModal>
	);
};
export default SubscriptionIndexingModal;
