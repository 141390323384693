import { Title, Text, Tooltip, ButtonBox, Flex, Block } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import FlexItem from 'App/components/FlexItem';
import React from 'react';

import './CheckboxToggle.scss';

type Option = {
	title: string;
	subtitle?: string;
	checked: boolean;
	onChange: () => void;
	disabled?: boolean;
};

type CheckboxToggleProps = {
	title: string;
	subtitle?: string;
	tooltipTitle?: string;
	options: [Option, Option];
};

const CheckboxToggle = ({ title, subtitle, tooltipTitle, options }: CheckboxToggleProps) => {
	const classNames = new BemClass('CheckboxToggle');

	return (
		<div className={classNames.b()}>
			<Block space="pbm">
				<Title>{title}</Title>
				{subtitle ? (
					<Text size="sm" color="grey-11">
						{subtitle}
					</Text>
				) : null}
			</Block>
			<Flex gap="u3" space="mtm">
				{options.map((option, index) => (
					<FlexItem
						flex={1}
						component={Tooltip}
						key={index}
						title={tooltipTitle ?? ''}
						disabled={!option.disabled}
					>
						<ButtonBox
							className={classNames.elem('buttonBox').b()}
							title={option.title}
							subtitle={option.subtitle}
							disabled={option.disabled}
							selected={option.checked}
							onClick={() => option.onChange()}
							size="sm"
						/>
					</FlexItem>
				))}
			</Flex>
		</div>
	);
};

export default CheckboxToggle;
