import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';
import { Metadata } from 'App/resources/AllIWant';
import T from 'Components/Helpers/translate';
import { OrderRowsState, OrderRow as _OrderRow } from 'App/components/OrderRows/Context/OrderContext';
import { getBundleRow } from 'App/components/OrderRows/Context/OrderContextHelpers';

type OrderRow = Required<_OrderRow>;

export type DisplaySettings = {
	showDiscount: boolean;
	showArticleNo: boolean;
	showRecurringValue: boolean;
	showContributionMargin: boolean;
	priceDecimalPlaces: number;
	recurringType: null | string;
};

export const getDisplaySettings = (metadata: Metadata) => {
	const {
		SalesModel,
		SalesModelOption,
		SalesModelOption3,
		UseDiscount: showDiscount,
		OrderedProductPriceDecimals: priceDecimalPlaces
	} = metadata.params;

	const showArticleNo = metadata.standardFields.Product.ArticleNo.active;
	const showRecurringValue =
		(SalesModel === 'rr' && ['arr', 'mrr'].indexOf(SalesModelOption) >= 0) ||
		(SalesModel === 'cm' &&
			(SalesModelOption3 === 'cmCombinedWithARR' || SalesModelOption3 === 'cmCombinedWithMRR'));
	const recurringType = showRecurringValue
		? SalesModel === 'rr'
			? SalesModelOption.toUpperCase()
			: SalesModelOption3?.substring(SalesModelOption3.length - 3)?.toUpperCase() ?? null
		: null;
	const showContributionMargin = SalesModel === 'cm';

	return {
		showDiscount,
		showArticleNo,
		recurringType,
		showRecurringValue,
		priceDecimalPlaces,
		showContributionMargin
	};
};

export const getOrderSummary = (
	displaySettings: DisplaySettings,
	currency: string,
	order: Pick<OrderRowsState, 'totalAmount' | 'totalDiscount' | 'totalRecurringValue' | 'totalContributionMargin'>,
	isBundle?: boolean,
	isFixedPriceBundle?: boolean
) => {
	const currencyFormat = new CurrencyFormat(currency, undefined, displaySettings.priceDecimalPlaces);
	const numberFormat = new NumberFormat(undefined, 0);

	const { totalAmount: totalGrossAmount, totalDiscount, totalRecurringValue, totalContributionMargin } = order;

	const totalNetAmount = totalGrossAmount - totalDiscount;

	const rows = [
		{
			title: `${T('order.gross')}${isFixedPriceBundle ? ` (${T('admin.products.fixedPrice')})` : ''}`,
			value: currencyFormat.default(totalGrossAmount)
		}
	];

	// Some special logic as I use this both for the bundle and order summary
	if (isBundle) {
		if (totalDiscount > 0) {
			rows.push({
				title: T('bundle.discount'),
				value: `(${numberFormat.percent(totalDiscount / totalGrossAmount)}) ${currencyFormat.default(
					totalDiscount
				)}`
			});
		}
	} else if (displaySettings.showDiscount) {
		rows.push({ title: T('default.discount'), value: currencyFormat.default(totalDiscount) });
	}

	if (displaySettings.showContributionMargin) {
		rows.push({
			title: T('default.contributionMarginShort'),
			value: currencyFormat.default(totalContributionMargin)
		});
	}

	if (displaySettings.showRecurringValue) {
		rows.push({ title: displaySettings.recurringType!, value: currencyFormat.default(totalRecurringValue) });
	}

	rows.push({ title: T('default.netAmount'), value: currencyFormat.default(totalNetAmount) });

	return rows;
};

export const getOrderRowSummary = (displaySettings: DisplaySettings, currency: string, orderRow: OrderRow) => {
	const currencyFormat = new CurrencyFormat(currency, undefined, displaySettings.priceDecimalPlaces);

	if (displaySettings.showContributionMargin) {
		const orderRowTotalPurchaseCost = orderRow.purchaseCost * orderRow.tierQuantity;
		const contributionMargin = orderRow.orderRowTotal - orderRowTotalPurchaseCost;

		return {
			title: currencyFormat.default(orderRow.orderRowTotal),
			subtitle: `${T('default.contributionMarginShort')}: ${currencyFormat.default(contributionMargin)}`
		};
	} else if (displaySettings.showRecurringValue) {
		const isRecurring = orderRow.product?.isRecurring;

		return {
			title: currencyFormat.default(orderRow.orderRowTotal),
			subtitle: isRecurring
				? `${currencyFormat.default(orderRow.recurringValue)} (${displaySettings.recurringType})`
				: `(${T('default.oneOffValue')})`
		};
	} else {
		return {
			title: currencyFormat.default(orderRow.orderRowTotal),
			subtitle: undefined
		};
	}
};

export const mapBundleRowsAsOrderRows = (
	bundleOrderRow: OrderRow,
	currency: string,
	orderInterval: number,
	hasBundleAdjustable: boolean
) => {
	// Convert type to OrderRow, and remove broken rows. (you should not be able to remove products used in a bundle but this is just and extra precaution)
	const existingBundleRows = bundleOrderRow.bundleRows.filter(bundleRow => !!bundleRow.product) as _OrderRow[];

	// This is to add bundleRows with 0 quantity for products added to the bundle after the opportunity was created
	const productMap = existingBundleRows.reduce<{ [productId: number]: number }>((map, { product }) => {
		if (map[product!.id]) {
			map[product!.id]++;
		} else {
			map[product!.id] = 1;
		}
		return map;
	}, {});

	const newBundleRows = bundleOrderRow.product.bundle
		.filter(({ product }) => {
			if (productMap[product.id] === undefined) {
				return true;
			} else if (productMap[product.id] > 0) {
				productMap[product.id]--;
				return false;
			} else {
				return true;
			}
		})
		.map(({ product, adjustable }, index) =>
			getBundleRow(
				{ uuid: existingBundleRows.length + index, product },
				currency,
				orderInterval,
				0,
				bundleOrderRow.product.bundlePriceAdjustment ?? 1,
				hasBundleAdjustable && adjustable
			)
		);

	return existingBundleRows.concat(newBundleRows);
};
