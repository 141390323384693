import React from 'react';
import PropTypes from 'prop-types';
import TextEditor from 'Components/TextEditor';

const MailContentEditorTextEditor = ({ onChange, value }) => {
	return (
		<TextEditor
			className="MailContentEditorTextEditor"
			config={{
				extraPlugins: 'tags',
				format_maintitle: { name: 'Main title', element: 'h2' },
				format_secondarytitle: { name: 'Title', element: 'h3' },
				format_tags: 'paragraph;ingress;maintitle;secondarytitle',
				format_paragraph: {
					name: 'Paragraph',
					element: 'p',
					attributes: { class: 'paragraph' }
				},
				format_ingress: {
					name: 'Ingress',
					element: 'p',
					attributes: { class: 'ingress' }
				},
				removePlugins: 'image,elementspath'
			}}
			onChange={onChange}
			value={value}
		/>
	);
};

MailContentEditorTextEditor.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
};

MailContentEditorTextEditor.defaultProps = {
	onChange: () => {},
	value: ''
};

export default MailContentEditorTextEditor;
