import React, { useState, useEffect, useRef } from 'react';
import RequestBuilder, { comparisonTypes, Filter } from 'App/babel/resources/RequestBuilder';
import { CallList } from 'App/resources/Model/Project';
import logError from 'App/babel/helpers/logError';
import CallListResource from 'Resources/CallList';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import DynamicSelect, { DynamicSelectConsumerProps } from 'App/components/Inputs/Selects/DynamicSelect';

const LIMIT = 100;

type MappedCallList = CallList & { title: string };

const mapper = (c: CallList): MappedCallList => ({ ...c, title: c.name });

const getMappedCallLists = async (term?: Filter['v']): Promise<MappedCallList[]> => {
	const projectRb = new RequestBuilder();
	projectRb.addSort(CallListResource.attr.name, true);
	projectRb.addFilter(CallListResource.attr.active, comparisonTypes.Equals, 1);
	if (term) {
		projectRb.addFilter(CallListResource.attr.name, comparisonTypes.Wildcard, term);
	}
	projectRb.limit = LIMIT;
	const { data } = await CallListResource.find(projectRb.build());
	return data?.map(mapper) ?? [];
};

const getMappedCallList = async (id: number): Promise<MappedCallList | null> => {
	const { data } = await CallListResource.get(id);
	if (data) {
		return mapper(data);
	} else {
		return null;
	}
};

type Props = Omit<DynamicSelectConsumerProps<MappedCallList>, 'fetcher' | 'value' | 'onChange'> & {
	value: number | null;
	onChange: (value: number | null) => void;
};

const CallListSelect = ({ value, onChange, ...props }: Props) => {
	const [selectedItem, setSelectedItem] = useState<MappedCallList | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const promise = useRef<CancelablePromise<any> | null>(null);

	const fetchSelectedItem = async (id: number) => {
		if (promise.current) {
			promise.current.cancel();
		}
		setIsLoading(true);
		promise.current = makeCancelable(getMappedCallList(id));
		promise.current.promise
			.then(res => {
				setSelectedItem(res);
				setIsLoading(false);
			})
			.catch(err => {
				logError(err, 'failed getting currently selected CallList');
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (value) {
			fetchSelectedItem(value);
		} else {
			setSelectedItem(null);
		}
		return () => {
			promise.current?.cancel();
		};
	}, [value]);

	return (
		<DynamicSelect<MappedCallList>
			value={selectedItem}
			fetcher={getMappedCallLists}
			isLoading={isLoading}
			fetchOnOpen
			onChange={e => {
				onChange(e ? (typeof e.id === 'string' ? parseInt(e.id) : e.id) : null);
			}}
			{...props}
		/>
	);
};

export default CallListSelect;
