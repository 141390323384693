import React from 'react';
import './MailTemplatePreviewHr.scss';

const MailTemplatePreviewHr = ({ column }) => {
	const divStyle = {
		borderBottomStyle: column.hrStyle,
		borderBottomColor: column.hrColor
	};
	return <div className="MailTemplatePreviewHr" style={divStyle} />;
};
export default MailTemplatePreviewHr;
