import React, { PureComponent, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Loader } from '@upsales/components';
import sanitizeHtml from 'sanitize-html';

const allowedTags = [
	'img',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'blockquote',
	'p',
	'a',
	'ul',
	'ol',
	'nl',
	'li',
	'b',
	'i',
	'strong',
	'em',
	'strike',
	'code',
	'hr',
	'br',
	'div',
	'table',
	'thead',
	'caption',
	'tbody',
	'tr',
	'th',
	'td',
	'pre'
];

const allowedAttributes = {
	'*': ['style'],
	a: ['href', 'name', 'target'],
	img: ['src', 'width', 'height', 'alt', 'title']
};

class MailContentEditorCodeEditor extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		initialValue: PropTypes.string
	};

	constructor(props) {
		super(props);

		this.editorRef = createRef();

		this.state = {
			isSaving: false
		};
	}

	hasErrors() {
		const annotations = this.editor.session?.$annotations;
		return !annotations || annotations.some(annotation => annotation.type === 'error');
	}

	getSanitizedValue() {
		return sanitizeHtml(this.editor.session.getValue(), {
			allowedTags,
			allowedAttributes
		});
	}

	addOnChangeListener() {
		let i, j;
		this.editor.session.on('change', () => {
			clearTimeout(i);
			clearTimeout(j);

			i = setTimeout(() => {
				this.setState({ isSaving: true });

				j = setTimeout(() => {
					if (this.hasErrors()) {
						return this.setState({ isSaving: false });
					}

					this.props.onChange(this.getSanitizedValue());
					this.setState({ isSaving: false });
				}, 300);
			}, 200);
		});
	}

	componentDidMount() {
		if (!window.ace) {
			return;
		}

		this.editor = window.ace.edit(this.editorRef.current);
		this.editor.setTheme('ace/theme/monokai');
		this.editor.session.setMode('ace/mode/html');
		this.editor.setValue(this.props.initialValue || '');
		this.addOnChangeListener();
	}

	componentWillUnmount() {
		if (this.editor) {
			this.editor.destroy();
		}
	}

	render() {
		const classes = new bemClass('MailContentEditor');
		return (
			<Fragment>
				<div className={classes.elem('code-editor').b()} ref={this.editorRef} />
				{this.state.isSaving ? <Loader noU dark className={classes.elem('code-editor-loader').b()} /> : null}
			</Fragment>
		);
	}
}

export default MailContentEditorCodeEditor;
