import React from 'react';
import PropTypes from 'prop-types';
import { Headline, Button, Row, Column, Label, Toggle, ButtonBox, Flex } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

class MailContentEditorRowSettings extends React.PureComponent {
	constructor(p) {
		super(p);

		const t = getAngularModule('$translate');

		this.lang = {
			padding: {
				wPaddings: t('mail.wPaddings'),
				woPaddings: t('mail.woPaddings')
			},
			clearRow: t('mail.clearRow'),
			rowTools: t('mail.rowTools'),
			backgroundColor: t('mailTemplate.rowBackgroundColor'),
			fullWidth: t('default.fullWidth'),
			rowMargins: t('mail.rowMargins'),
			deleteRow: t('editor.deleteRow'),
			noCollapse: t('editor.noCollapse'),
			collapseToRows: t('editor.collapseToRows'),
			keepAsColumns: t('editor.keepAsColumns')
		};

		this.paddingChange = () => {};
	}
	render() {
		const { row, onChange, accountProfile } = this.props;
		const classes = new bemClass('MailContentEditor');
		return (
			<div>
				<div className={classes.elem('sidebar-section').b()}>
					<Row className={classes.elem('sidebar-section-input-row').b()}>
						<Column>
							<Headline size="xs">{this.lang.rowTools}</Headline>
						</Column>
						<Column align="right">
							<Button type="link" color="grey" onClick={this.props.onClear}>
								{this.lang.clearRow}
							</Button>
						</Column>
					</Row>

					<Row className={classes.elem('sidebar-section-input-row').b()}>
						<Column>
							<Label>{this.lang.backgroundColor}</Label>
						</Column>
						<Column align="right">
							<ReactTemplates.bannerEditor.colorPicker
								value={row.backgroundColor}
								presets={accountProfile.colorVariables}
								onChange={color => onChange({ ...row, backgroundColor: color })}
							/>
						</Column>
					</Row>

					<Row className={classes.elem('sidebar-section-input-row').b()}>
						<Column>
							<Label>{this.lang.fullWidth}</Label>
						</Column>
						<Column align="right">
							<Toggle
								color="medium-green"
								checked={row.fullWidth}
								onChange={val => onChange({ ...row, fullWidth: val })}
							/>
						</Column>
					</Row>

					<Row className={classes.elem('sidebar-section-input-row').b()}>
						<Column>
							<Label>{this.lang.noCollapse}</Label>
						</Column>
					</Row>
					<Flex gap="u2" className={classes.elem('sidebar-section-no-collapse').b()} space="mtl mbl">
						<ButtonBox
							selected={row.noCollapse}
							onClick={() => onChange({ ...row, noCollapse: true })}
							title={this.lang.keepAsColumns}
							size="sm"
						>
							<img src="img/keep-as-columns.svg"></img>
						</ButtonBox>
						<ButtonBox
							selected={!row.noCollapse}
							onClick={() => onChange({ ...row, noCollapse: false })}
							title={this.lang.collapseToRows}
							size="sm"
						>
							<img src="img/collapse-to-rows.svg"></img>
						</ButtonBox>
					</Flex>

					<Button block={true} color="red" onClick={this.props.onDelete}>
						{this.lang.deleteRow}
					</Button>
				</div>
			</div>
		);
	}
}

MailContentEditorRowSettings.defaultProps = {
	onChange: () => {},
	onClear: () => {},
	onDelete: () => {},
	onCopy: () => {}
};

MailContentEditorRowSettings.propTypes = {
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
	row: PropTypes.object.isRequired
};

export default MailContentEditorRowSettings;
