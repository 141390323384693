import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { useTranslation } from 'Components/Helpers/translate';
import MailSignature from 'App/resources/Model/MailSignature';
import React, { useEffect, useState, useRef, useMemo, ComponentProps } from 'react';
import { useEditTicketContext } from '../Context/Context';
import TextEditor from 'Components/TextEditor';
import logError from 'Helpers/logError';
import { Block, Flex, Icon, Link, Select, StateFrame, Text } from '@upsales/components';
import { getSelf } from 'Store/selectors/AppSelectors';
import { ThirdButton } from '@upsales/components/Buttons';
import history from 'App/pages/routes/history';
import { FormObserverOnFieldChange } from 'App/components/FormObserver';
import FileUploadButton from 'App/components/Buttons/FileUploadButton';
import { useSoftDeployAccess } from 'App/components/hooks';
import TicketResponseTemplate from 'App/resources/Model/TicketResponseTemplate';
import TicketResponseTemplateResource from 'App/resources/TicketResponseTemplate';
import './MailEditor.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import TemplateDropdown from './TemplateDropdown/TemplateDropdown';
import { useAppDispatch } from 'App/components/hooks';
import { addNotification, STYLE as notificationStyle } from 'Store/reducers/SystemNotificationReducer';

type Props = ComponentProps<typeof Block> & {
	extra?: object;
	disableEditing?: boolean;
	closed?: boolean;
	contactName?: string;
	onLeave?: () => void;
	canChangeEmail?: boolean;
	onFormChange?: FormObserverOnFieldChange;
	fileNames?: {
		[key: string]: number;
	};
	setFileNames?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: number;
		}>
	>;
	ticketResponseTemplate?: TicketResponseTemplate;
	setTicketResponseTemplate?: (template: TicketResponseTemplate | undefined) => void;
	onEmailChange?: (value: string) => void;
	type: 'edit' | 'create';
};

const MAX_NUMBER_OF_ATTACHMENTS = 5;
const MAX_TOTAL_FILE_SIZE = 15_000_000;
const MAX_INDIVIDUAL_FILE_SIZE = 10_000_000;

const FileUploadButtonClass = (signatures: MailSignature[] | null): string => {
	const classes = new BemClass('FileUploadButtonWrapper');

	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAIL_SIGNATURE) && signatures) {
		classes.mod('with-signature');
	}

	if (Tools.AppService.getMetadata().activatedFeatures.easyBooking.active) {
		classes.mod('easy-booking');
	}

	return classes.b();
};

const MailEditor = ({
	extra = {},
	disableEditing = false,
	closed = false,
	contactName = '',
	onLeave,
	canChangeEmail,
	onFormChange,
	fileNames: fileNamesProp,
	setFileNames: setFileNamesProp,
	ticketResponseTemplate,
	setTicketResponseTemplate,
	onEmailChange,
	type,
	...props
}: Props) => {
	const {
		state: { publicMessage, canSendPublicMessage, supportEmails, ticket, files, totalFileSize },
		setPublicMessage,
		setEmailUsed,
		addFile,
		setFiles
	} = useEditTicketContext();
	const { t } = useTranslation();
	const self = getSelf();
	const dispatch = useAppDispatch();

	const hasTicketResponseTemplates = useSoftDeployAccess('TICKET_RESPONSE_TEMPLATES');
	const isSupportUser = self?.support;
	const isAdminUser = self?.administrator;

	const [mailSignatures, setMailSignatures] = useState<MailSignature[] | null>(null);
	const mailSignaturesnPromise = useRef<CancelablePromise<{
		data: MailSignature[];
	}> | null>(null);
	const [ref, setRef] = useState<HTMLDivElement | null>(null);
	const [fileNameInternal, setFileNameInternal] = useState<{ [key: string]: number }>({});
	const [editorHeaderHeight, setEditorHeaderHeight] = useState(0);
	const ticketResponseTemplatesPromise = useRef<CancelablePromise<{
		data: TicketResponseTemplate[];
	}> | null>(null);
	const updateResponseTemplatePromise = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof TicketResponseTemplateResource.save>>
	>>(null);

	const fileNames = fileNamesProp ?? fileNameInternal;
	const setFileNames = setFileNamesProp ?? setFileNameInternal;

	const classes = new BemClass('MailEditor');

	useEffect(() => {
		if (disableEditing) {
			return;
		}
		if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAIL_SIGNATURE)) {
			mailSignaturesnPromise.current = makeCancelable(Tools.MailSignatures.getActiveSignatures());
			mailSignaturesnPromise.current?.promise
				.then(({ data }) => setMailSignatures(data))
				.catch(err => {
					setMailSignatures([]);
					logError(err, 'Failed to fetch active mail signatures');
				});
		} else {
			setMailSignatures([]);
		}

		return () => {
			mailSignaturesnPromise.current?.cancel();
			ticketResponseTemplatesPromise.current?.cancel();
			updateResponseTemplatePromise.current?.cancel();
		};
	}, []);

	const changeSelectedTemplate = (id: number | string | null, templates: TicketResponseTemplate[] = []) => {
		if (setTicketResponseTemplate) {
			if (id === null) {
				setTicketResponseTemplate(undefined);
				setPublicMessage('');
				setFiles([]);
				return;
			} else {
				const ticket = templates?.find(t => t.id === id);
				setTicketResponseTemplate(ticket);
				setPublicMessage(ticket?.body ? ticket.body : '');
				setFiles(ticket?.attachments ? ticket.attachments : []);
			}
		}
	};

	const config = useMemo(() => {
		if (!mailSignatures) {
			return null;
		}

		const EditorCk = getAngularModule('EditorCk');
		const ckConfig = {
			...EditorCk.getOptions({
				height: 'auto',
				fileEntity: 'mail',
				mailSignatures,
				hideImage: disableEditing,
				hideTags: !hasTicketResponseTemplates,
				tagEntity: hasTicketResponseTemplates ? 'ticket' : undefined
			}),
			editorplaceholder: closed
				? t('ticket.typeYourMessageHereClosedTicket', { contactName: contactName })
				: t('ticket.typeYourMessageHere'),
			enterMode: window.CKEDITOR.ENTER_BR,
			forceSimpleAmpersand: true,
			shiftEnterMode: window.CKEDITOR.ENTER_BR,
			resize_enabled: true,
			resize_dir: 'vertical',
			removePlugins: 'liststyle,tableselection,tabletools,tableresize,contextmenu,image,elementspath',
			...extra
		} as React.ComponentProps<typeof TextEditor>['config'];

		// remove source if ticket is closed
		if (disableEditing) {
			ckConfig.toolbar = ckConfig.toolbar.filter((item: { name: string }) => item.name !== 'document');
		}

		return ckConfig;
	}, [mailSignatures, contactName]);

	if (!isSupportUser && ticket.id) {
		return (
			<StateFrame
				icon="info-circle"
				state="info"
				title={t('ticket.noPermissionHeader')}
				subtitle={t('ticket.noPermissionBody')}
			/>
		);
	}

	const selectedEmail = supportEmails.find(e => e.email === ticket.emailUsed);

	if (!canSendPublicMessage) {
		return (
			<StateFrame
				icon="exclamation-triangle"
				state="warning"
				title={t(
					!selectedEmail && ticket.id ? 'ticket.supportEmailHasBeenRemoved' : 'ticket.noSupportEmailTitle'
				)}
				subtitle={t(isAdminUser ? 'ticket.noSupportEmailSubtitle' : 'ticket.noSupportEmailSubtitleNonAdmin')}
			>
				{isAdminUser ? (
					<ThirdButton
						onClick={() => {
							onLeave?.();
							history.push('/admin/customer-support/verify');
						}}
					>
						{t('ticket.noSupportEmailAction')}
					</ThirdButton>
				) : null}
			</StateFrame>
		);
	}

	if (!config) {
		return null;
	}

	const selectedEmailIsVerified = !!selectedEmail?.domain.verified;

	return (
		<Block className={classes.mod({ hasTicketResponseTemplates }).b()} {...props} ref={r => setRef(r)}>
			<TextEditor
				readOnly={disableEditing}
				config={config}
				onInstanceReady={(e: unknown) => {
					config.init(e);
					const topElemHeight = document.querySelector('.cke_top')?.clientHeight ?? 0;
					setEditorHeaderHeight(topElemHeight + 1);
				}}
				onChange={({ value }: { value: string }) => {
					if (value !== undefined) {
						onFormChange?.('publicMessage', value);
						setPublicMessage(value);
					}
				}}
				value={publicMessage ?? ''}
			/>
			{hasTicketResponseTemplates ? (
				<TemplateDropdown
					newTicket={type === 'create'}
					publicMessage={publicMessage}
					ticketResponseTemplate={ticketResponseTemplate}
					setTicketResponseTemplate={setTicketResponseTemplate}
					editorHeaderHeight={editorHeaderHeight}
					files={files}
					onChange={changeSelectedTemplate}
					brandId={ticket.brandId}
				/>
			) : null}

			{files.length < MAX_NUMBER_OF_ATTACHMENTS ? (
				<div className={FileUploadButtonClass(mailSignatures)}>
					<FileUploadButton
						onChangeHandler={e => {
							if (e.target.files && e.target.files.length) {
								const file = e.target.files[e.target.files.length - 1];
								let newFile: File | undefined;
								const increment = fileNames[file.name] ?? 0;

								if (fileNames[file.name]) {
									const name = file.name.substring(0, file.name.lastIndexOf('.'));
									const extension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);
									newFile = new File([file], name + increment + extension);
								}

								if (file.size > MAX_INDIVIDUAL_FILE_SIZE) {
									dispatch(
										addNotification({
											style: notificationStyle.WARN,
											icon: 'warning',
											title: t('default.fileTooLarge'),
											body: t('default.maxSizeIs', { size: '10MB' })
										})
									);
									return;
								}

								if (totalFileSize + file.size > MAX_TOTAL_FILE_SIZE) {
									dispatch(
										addNotification({
											style: notificationStyle.WARN,
											icon: 'warning',
											title: t('default.fileSizeTooLarge'),
											body: t('groupMail.fileLimit')
										})
									);
									return;
								}

								setFileNames(prev => ({ ...prev, ...{ [file.name]: increment + 1 } }));

								addFile(newFile ?? e.target.files[e.target.files.length - 1]);
							}
						}}
					/>
				</div>
			) : null}

			{/* This will not render if we had no supportEmails, so we can asume that we have one or more addresses at this point */}
			<Block space="mtm">
				<Flex>
					<Text size="md">
						{/* If only one then we just display it */}
						<span className={classes.elem('bold').b()}>
							{t(type === 'create' ? 'ticket.confirmationSentFrom' : 'ticket.repliesSentFrom')}
						</span>
						<span>{supportEmails.length === 1 || !canChangeEmail ? ticket.emailUsed : ''}</span>
						{/* If more than one we display a select */}
						{canChangeEmail && supportEmails.length > 1 ? (
							<Select
								value={ticket.emailUsed ? { id: ticket.emailUsed, title: ticket.emailUsed } : null}
								onChange={v =>
									onEmailChange
										? onEmailChange(v.id)
										: onFormChange?.('emailUsed', v.id) ?? setEmailUsed(v.id)
								}
								options={supportEmails.map(e => ({ id: e.email, title: e.email }))}
								inline
								showSearch={false}
								anchor={ref}
							/>
						) : null}
					</Text>
				</Flex>
			</Block>
			{!selectedEmailIsVerified ? (
				<Block space="mtm">
					<Text color="yellow-6" size="sm">
						<Icon name="exclamation-triangle" space="mrs" />
						{t('ticket.domainNotVerified')}
						{isAdminUser ? (
							<>
								{'. '}
								<Link href="/#/admin/customer-support/verify" onClick={() => onLeave?.()}>
									{t('ticket.noSupportEmailAction')}
								</Link>
							</>
						) : null}
					</Text>
				</Block>
			) : null}
		</Block>
	);
};

export default MailEditor;
