import React, { useState, useMemo } from 'react';
import T from 'Components/Helpers/translate';
import { AccountManagers, ClientColumnSubtitle, ClientColumnTitle } from './columnParts';
import { DefaultButton } from '@upsales/components/Buttons';
import { Flex, TableRow, TableColumn, Tooltip, ClickableItem } from '@upsales/components';
import { removeAsSubAccount } from './methods';

import type Client from 'App/resources/Model/Client';

type Props = {
	client: Client;
	goToAccount: (client: Pick<Client, 'id'>) => void;
};

enum Action {
	RemoveAsSubAccount
}

const ListRow = ({ client, goToAccount }: Props) => {
	const lang = useMemo(
		() => ({
			dropdownActionsRemoveAsSubaccount: T('SubaccountDrawer.List.dropdownActions.removeAsSubaccount'),
			showOptions: T('default.showOptions'),
			viewAccount: T('SubaccountDrawer.List.viewAccount')
		}),
		[]
	);
	const [disabled, setDisabled] = useState(false);

	const runAction = (action: Action) => {
		setDisabled(true);

		switch (action) {
			case Action.RemoveAsSubAccount:
				removeAsSubAccount(client);
				break;
		}
	};

	return (
		<TableRow inactive={!client.active} disabled={disabled}>
			<TableColumn
				size="xl"
				title={<ClientColumnTitle client={client} />}
				subtitle={<ClientColumnSubtitle client={client} />}
			/>
			<TableColumn>
				<AccountManagers users={client.users} />
			</TableColumn>
			<TableColumn align="right">
				<Flex alignItems="center" justifyContent="end">
					<DefaultButton onClick={() => goToAccount(client)} size="sm" disabled={disabled}>
						{lang.viewAccount}
					</DefaultButton>
					<Tooltip title={lang.dropdownActionsRemoveAsSubaccount}>
						<ClickableItem
							block={true}
							borderRadius
							icon="unlink"
							onClick={() => runAction(Action.RemoveAsSubAccount)}
						/>
					</Tooltip>
				</Flex>
			</TableColumn>
		</TableRow>
	);
};

export default ListRow;
