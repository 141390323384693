import { FilterConfig, FilterConfigRaw, MinifiedFilter } from 'App/babel/filterConfigs/FilterConfig';
import AccessType from 'App/enum/AccessType';
import { renderContent as renderJourneyStepFilterRow } from 'Components/Filters/JourneyStepFilterRow';
import T from 'Components/Helpers/translate';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import FieldTranslation from 'App/babel/resources/FieldTranslations';
import Project from 'App/resources/Project';
import Client from 'App/resources/Client';
import Import from 'App/resources/Import';
import Segment from 'App/resources/Segment';
import GroupMailCategory from 'App/resources/GroupMailCategory';
import ClientAttributes from 'App/babel/attributes/Client';
import { getCategories } from 'Store/actions/AppGetterActions';
import store from 'Store/index';
import { AggregationTypes } from 'Resources/Aggregation';
import _ from 'lodash';
import sortAlphabetically from 'App/babel/utils/sortAlphabetically';
import AddressFilter from 'App/upsales/common/attributes/filter/addressFilter';
import DatePreset from 'App/upsales/common/services/datePresetService';
import SocialEventFilter from 'App/babel/filters/SocialEvent';
import OrderFilter from 'App/babel/angular/attributes/filter/orderFilter';
import TaskFilter from 'App/babel/angular/attributes/filter/taskFilter';
import OpportunityFilter from 'App/babel/angular/attributes/filter/opportunityFilter';
import ActivityFilter from 'App/babel/angular/attributes/filter/activityFilter';
import { default as generateActivityFilter } from 'App/babel/filters/Activity';
import Category from 'App/resources/Model/Category';
import { AnyAction } from 'redux';
import { FilterPreset, generateFilter } from '../filterGenerators';
// import AgreementFilter from 'App/babel/angular/attributes/filter/agreementFilter';
import { default as generateAppointmentFilter } from 'App/babel/filters/Appointment';
import filterParse from 'App/upsales/common/components/listFilters/filterParse';
import TicketFilter from 'App/babel/angular/attributes/filter/ticketFilter';

// TODO: This file is not converted to new filter format yet -- feels a bit daunting and not fully
// sure what to do with the commented out code.

const dateFilterPresets: string[] = [
	'whenever',
	'last7days',
	'last14days',
	'last30days',
	'last45days',
	'last90days',
	'lastXdays',
	'currentMonth',
	'currentQuarter',
	'currentWeek',
	'currentYear',
	'lastMonth',
	'lastWeek',
	'lastYear',
	'custom'
];

// const agreementDateFilterPresets: string[] = [
// 	'whenever',
// 	'lastDay',
// 	'lastWeek',
// 	'lastMonth',
// 	'lastQuarter',
// 	'lastYear',
// 	'untilToday',
// 	'currentDay',
// 	'fromToday',
// 	'currentWeek',
// 	'currentMonth',
// 	'currentQuarter',
// 	'currentYear',
// 	'nextDay',
// 	'nextWeek',
// 	'nextMonth',
// 	'nextQuarter',
// 	'nextYear',
// 	'nextXdays',
// 	'nextXweeks',
// 	'nextXmonths',
// 	'nextXyears',
// 	'last7days',
// 	'last14days',
// 	'last30days',
// 	'custom'
// ];

// You may be thinking "why is there so much commented out code?"
// It's because these are only used in list-view right now so filters that have showOnSegment + hide
// will not be used from this file. But in a near future they should
export default (): { [key: string]: FilterConfig & { showOnSegment?: boolean } } => ({
	Name: {
		field: 'name',
		columnPath: 'name',
		inputType: 'text',
		type: 'text',
		title: 'default.name'
	},
	// NameList: {
	// 	field: 'name',
	// 	inputType: 'list',
	// 	type: 'list',
	// 	displayType: 'text',
	// 	comparisonType: 'Search',
	// 	title: 'default.name',
	// 	hide: true,
	// 	showOnSegment: true
	// },
	Title: {
		field: 'title',
		columnPath: 'title',
		inputType: 'text',
		type: 'text',
		title: 'default.title'
	},
	TitleCategory: {
		field: 'titleCategory.tagId',
		columnPath: 'titleCategory',
		key: 'tagId', // TODO: Check what this does
		resource: () => (_, values) => {
			const filter = new RequestBuilder();

			filter.addFilter({ field: 'type' }, ComparisonTypes.Equals, 'titlecategory');
			filter.addFilter({ field: 'tagId' }, ComparisonTypes.Equals, values);

			return FieldTranslation.find(filter.build());
		},
		displayType: 'listLazy',
		type: 'list',
		search: true,
		idAttr: {
			field: 'tagId'
		},
		searchFn: () => (term, fields, offset, limit) => {
			const filter = new RequestBuilder();
			filter.fields = fields;
			filter.offset = offset;
			filter.limit = limit;

			filter.addFilter({ field: 'type' }, ComparisonTypes.Equals, 'titlecategory');
			filter.addSort({ field: 'value' }, true);

			if (term) {
				filter.addFilter({ field: 'value' }, ComparisonTypes.Search, term);
			}

			return FieldTranslation.find(filter.build());
		},
		searchField: 'value',
		displayText: 'value',
		title: 'default.titlecategory',
		multiComparitors: true,
		unreleasedFeature: 'NEW_FIELDS'
	},
	Salutation: {
		field: 'salutation.tagId',
		key: 'tagId',
		resource: () => (_, values) => {
			const filter = new RequestBuilder();

			filter.addFilter({ field: 'type' }, ComparisonTypes.Equals, 'salutation');
			filter.addFilter({ field: 'tagId' }, ComparisonTypes.Equals, values);

			return FieldTranslation.find(filter.build());
		},
		displayType: 'listLazy',
		type: 'list',
		search: true,
		searchFn: () => (term, fields, offset, limit) => {
			const filter = new RequestBuilder();
			filter.fields = fields;
			filter.offset = offset;
			filter.limit = limit;

			filter.addFilter({ field: 'type' }, ComparisonTypes.Equals, 'salutation');
			filter.addSort({ field: 'value' }, true);

			if (term) {
				filter.addFilter({ field: 'value' }, ComparisonTypes.Search, term);
			}

			return FieldTranslation.find(filter.build());
		},
		searchField: 'value',
		displayText: 'value',
		title: 'default.salutation',
		multiComparitors: true,
		unreleasedFeature: 'NEW_FIELDS'
	},
	// TitleList: {
	// 	field: 'title',
	// 	columnPath: 'title',
	// 	type: 'list',
	// 	displayType: 'lookUp',
	// 	comparisonType: 'MatchExact',
	// 	title: 'default.title',
	// 	hide: true,
	// 	showOnSegment: true
	// },
	Campaign: {
		field: 'project.id',
		columnPath: 'campaigns',
		resource: 'Campaign',
		inputType: 'select',
		type: 'list',
		search: true,
		includeFields: ['name'],
		searchFn: () => (term, fields, offset, limit) => {
			const filter = new RequestBuilder();
			filter.fields = fields;
			filter.offset = offset;
			filter.limit = limit;

			filter.addSort(Project.attr.name, true);

			if (term) {
				filter.addFilter(Project.attr.name, ComparisonTypes.Search, term);
			}

			return Project.find(filter.build());
		},
		searchField: 'name',
		displayText: 'name',
		title: 'default.campaigns',
		multiComparitors: true,
		showOnSegment: true
	},
	// /*
	// 	I dont think this is used anymore, as hide = true and showOnSegment = undefined,
	// 	however I dont dare to remove it if some customer happens to have it in their saved views.
	// 	maby we can check that in the database now that we have migrated from saving saved views in redis.
	// */
	Category: {
		field: 'category.id',
		inputType: 'select',
		dataPromise: () => {
			const categories = store.dispatch(
				getCategories('contact', false) as unknown as AnyAction
			) as unknown as Category[];
			return Promise.resolve({ data: categories });
		},
		searchField: 'name',
		displayText: 'name',
		title: 'default.categories',
		multiComparitors: true,
		multiple: true,
		type: 'list',
		columnPath: 'categories',
		hide: true
	},
	// One could call this a hack, but by doing this the category filter ends up at the correct place in the list ;)
	StandardCategory: {
		parent: 'default.categories',
		entity: 'contact'
	} as unknown as FilterConfig,
	HasVisit: {
		title: 'default.siteVisits',
		parent: 'default.market',
		type: 'raw',
		inputType: 'radio',
		displayType: 'radio',
		options: [
			{ text: 'default.all', value: null, inactive: true },
			{ text: 'default.with', value: true },
			{ text: 'default.without', value: false }
		],
		generate: () => {
			return {
				value: null
			};
		},
		build: ({ value }, rb) => {
			if (value === null) {
				return;
			} else if (value === true) {
				rb.addFilter({ field: 'visit.id' }, ComparisonTypes.NotEquals, null);
			} else {
				rb.addFilter({ field: 'visit.id' }, ComparisonTypes.Equals, null);
			}
		}
	},
	HasMail: {
		title: 'default.mailing',
		parent: 'default.market',
		type: 'raw',
		inputType: 'radio',
		displayType: 'radio',
		options: [
			{ text: 'default.all', value: null, inactive: true },
			{ text: 'default.with', value: true },
			{ text: 'default.without', value: false }
		],
		generate: () => {
			return {
				value: null
			};
		},
		build: ({ value }, rb) => {
			if (value === null) {
				return;
			}
			const group = rb.groupBuilder();
			group.addFilter({ field: 'mail.mailCampaign.id' }, ComparisonTypes.GreaterThan, 0);
			if (value !== true) {
				group.isNotFilter();
			}
			group.done();
		}
	},
	HasFormSubmit: {
		title: 'event.types.Form',
		parent: 'default.market',
		type: 'raw',
		inputType: 'radio',
		displayType: 'radio',
		options: [
			{ text: 'default.all', value: null, inactive: true },
			{ text: 'default.with', value: true },
			{ text: 'default.without', value: false }
		],
		generate: () => {
			return {
				value: null
			};
		},
		build: ({ value }, rb) => {
			if (value === null) {
				return;
			} else if (value === true) {
				rb.addFilter({ field: 'formSubmit.id' }, ComparisonTypes.NotEquals, null);
			} else {
				rb.addFilter({ field: 'formSubmit.id' }, ComparisonTypes.Equals, null);
			}
		}
	},
	NameClient: {
		field: 'client.name',
		entity: 'account',
		columnPath: 'name',
		inputType: 'text',
		type: 'text',
		title: 'default.nameText'
	},
	// NameClientList: {
	// 	field: 'client.name',
	// 	inputType: 'list',
	// 	type: 'list',
	// 	displayType: 'text',
	// 	comparisonType: 'Search',
	// 	title: 'default.name',
	// 	showOnSegment: true,
	// 	entity: 'account',
	// 	hide: true
	// },
	Account: {
		field: 'client.id',
		columnPath: 'account',
		inputType: 'select',
		multiple: true,
		resource: 'Account',
		type: 'list',
		displayType: 'listLazy',
		includeFields: ['id', 'name'],
		search: true,
		searchFn: () => (term, fields, offset, limit) => {
			const filter = new RequestBuilder();
			filter.fields = fields;
			filter.offset = offset;
			filter.limit = limit;

			filter.addSort(ClientAttributes.name, true);

			if (term) {
				filter.addFilter(ClientAttributes.name, ComparisonTypes.Search, term);
			}

			return Client.find(filter.build());
		},
		searchField: 'name',
		displayText: 'name',
		title: 'default.name',
		multiComparitors: true,
		entity: 'account'
	},

	AccountManager: generateFilter(
		FilterPreset.User,
		{
			field: 'client.users.id',
			title: 'default.accountManagers',
			entity: 'account'
		},
		AccessType.CONTACT
	),
	// AccountManagerSegment: {
	// 	title: 'segment.accountManager',
	// 	type: 'raw',
	// 	displayType: 'list',
	// 	hide: true,
	// 	showOnSegment: true,
	// 	entity: 'account',
	// 	multiComparitors: true,
	// 	generate: UserRoleFilter.generate,
	// 	isInactive: UserRoleFilter.isInactive,
	// 	toUrl: UserRoleFilter.toUrl,
	// 	fromUrl: UserRoleFilter.fromUrl,
	// 	build: UserRoleFilter.build({ fieldPrefix: 'client' }),
	// 	dataPromise: function ($q) {
	// 		var options = {
	// 			accessType: Tools.AppService.AccessType.CONTACT,
	// 			roleIdPrefix: 'role-'
	// 		};

	// 		return $q.when({ data: window.UserRoleTree(options) });
	// 	}
	// },
	// One could call this a hack, but by doing this the category filter ends up at the correct place in the list ;)
	CategoryClient: {
		parent: 'default.categories',
		entity: 'account'
	} as unknown as FilterConfig,
	CampaignClient: {
		field: 'client.project.id',
		entity: 'account',
		inputType: 'select',
		resource: 'Campaign',
		multiple: true,
		search: true,
		includeFields: ['name'],
		searchFn: () => (term, fields, offset, limit) => {
			const filter = new RequestBuilder();
			filter.fields = fields;
			filter.offset = offset;
			filter.limit = limit;

			filter.addSort(Project.attr.name, true);

			if (term) {
				filter.addFilter(Project.attr.name, ComparisonTypes.Search, term);
			}

			return Project.find(filter.build());
		},
		searchField: 'name',
		displayText: 'name',
		type: 'list',
		displayType: 'listLazy',
		multiComparitors: true,
		title: 'default.campaigns',
		columnPath: 'campaigns',
		showOnSegment: true
	},
	ScoreClient: {
		title: 'default.score',
		field: 'client.score',
		entity: 'account',
		columnPath: 'score',
		type: 'range',
		inputType: 'range',
		displayType: 'range',
		parent: 'default.market',
		showOnSegment: true
	},
	ScoreUpdateDateClient: {
		title: 'default.scoreUpdate',
		field: 'client.scoreUpdateDate',
		entity: 'account',
		columnPath: 'scoreUpdateDate',
		inputType: 'date',
		type: 'dateRange',
		parent: 'default.market',
		showOnSegment: true,
		presets: dateFilterPresets
	},
	PhoneClient: {
		title: 'filters.columns.phoneAccount',
		field: 'client.phone',
		entity: 'account',
		columnPath: 'phone',
		inputType: 'text',
		type: 'text'
	},
	ParentClient: {
		field: 'client.parent.id',
		entity: 'account',
		columnPath: 'parent',
		inputType: 'select',
		multiple: true,
		resource: 'Account',
		type: 'list',
		displayType: 'listLazy',
		searchFn: () => async term => {
			const { customerId } = store.getState().App;

			const rb = new RequestBuilder();

			rb.addFilter(ClientAttributes.parent.attr.name, ComparisonTypes.Search, term);
			const idBuilder = rb.aggregationBuilder();
			idBuilder.aggregationSize(1000);
			idBuilder.addAggregation(AggregationTypes.Terms, ClientAttributes.parent.attr.id);
			const nameBuilder = idBuilder.aggregationBuilder();
			nameBuilder.addAggregation(AggregationTypes.Terms, ClientAttributes.parent.attr.name);
			nameBuilder.done();
			idBuilder.done();

			const res = await Tools.Report.customer(customerId).setType(Tools.Report.type.CLIENT).find(rb.build());
			// eslint-disable-next-line camelcase
			const data = res.data.group_by_parent_id.buckets.map(
				// eslint-disable-next-line camelcase
				(item: { key: string; group_by_parent_name?: { buckets?: [{ key?: string }] } }) => {
					return {
						id: item.key,
						name: _.get(item, 'group_by_parent_name.buckets[0].key') || ''
					};
				}
			);
			return { data: data.sort(sortAlphabetically('name')) };
		},
		searchField: 'name',
		displayText: 'name',
		search: true,
		title: 'default.parentCompany',
		multiComparitors: true,
		showOnSegment: true
	},
	WebpageClient: {
		field: 'client.webpage',
		entity: 'account',
		inputType: 'text',
		type: 'text',
		title: 'default.webpage',
		parent: 'filters.columns.contactinformation'
	},
	IsAssignedClient: {
		title: 'scoreboard.assigned',
		entity: 'account',
		field: 'client.assigned.user.id',
		type: 'radio',
		inputType: 'radio',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'scoreboard.assigned', value: null, comparisonType: 'NotEquals' },
			{ text: 'scoreboard.unassigned', value: null, comparisonType: 'Equals' }
		],
		parent: 'filters.columns.accountother'
	},
	AddressFiltersClient: {
		title: 'default.addresses',
		entity: 'account',
		type: 'raw',
		inputType: 'address',
		displayType: 'address',
		availableAddresses: ['Visit', 'Mail', 'Delivery', 'Invoice', 'Other'],
		getText: AddressFilter.getText,
		generate: AddressFilter.generate,
		isInactive: AddressFilter.isInactive,
		toUrl: AddressFilter.toUrl,
		fromUrl: AddressFilter.fromUrl,
		build: AddressFilter.build({ fieldPrefix: 'client' }),
		parent: 'filters.columns.contactinformation'
	},
	// Address: {
	// 	field: 'client.address.address',
	// 	inputType: 'list',
	// 	type: 'list',
	// 	displayType: 'text',
	// 	comparisonType: 'Search',
	// 	title: 'address.address',
	// 	hide: true,
	// 	showOnSegment: true,
	// 	entity: 'account',
	// 	parent: 'filters.columns.contactinformation'
	// },
	// ZipCode: {
	// 	field: 'client.address.zipcode',
	// 	type: 'raw',
	// 	displayType: 'range',

	// 	title: 'address.zip',
	// 	hide: true,
	// 	showOnSegment: true,
	// 	parent: 'filters.columns.contactinformation',
	// 	entity: 'account',
	// 	generate: function () {
	// 		return {
	// 			inactive: true,
	// 			value: { start: null, end: null }
	// 		};
	// 	},
	// 	toUrl: function (filter) {
	// 		var f = { v: {} };

	// 		f.i = filter.inactive;
	// 		f.s = filter.value.start;
	// 		f.e = filter.value.end;

	// 		return f;
	// 	},
	// 	fromUrl: function (rawFilter) {
	// 		var filter = this.generate();
	// 		filter.inactive = rawFilter.i;
	// 		filter.value = {
	// 			start: rawFilter.s,
	// 			end: rawFilter.e
	// 		};
	// 		return filter;
	// 	},
	// 	build: function (filter, rb) {
	// 		if (filter.inactive) {
	// 			return;
	// 		}

	// 		if (filter.value.start || filter.value.end) {
	// 			var group = rb.groupBuilder();

	// 			if (filter.value.start) {
	// 				group.addFilter(
	// 					{ field: 'client.address.zipcode' },
	// 					rb.comparisonTypes.GreaterThanEquals,
	// 					filter.value.start
	// 				);
	// 			}
	// 			if (filter.value.end) {
	// 				group.addFilter(
	// 					{ field: 'client.address.zipcode' },
	// 					rb.comparisonTypes.LessThanEquals,
	// 					filter.value.end
	// 				);
	// 			}

	// 			group.done();
	// 		}
	// 	}
	// },
	// Country: {
	// 	field: 'client.address.country',
	// 	type: 'list',
	// 	dataPromise: function (CountryCodes) {
	// 		var customerId = AppService.getCustomerId();
	// 		var language = AppService.getSelf().language;

	// 		return CountryCodes.customer(customerId)
	// 			.find({})
	// 			.then(function (res) {
	// 				var mapped = _.cloneDeep(res.data).map(function (cc) {
	// 					cc.name = T(cc.lang);
	// 					return cc;
	// 				});

	// 				if (language) {
	// 					mapped.sort(sortAlphabetically('name', false, language));
	// 				}

	// 				return { data: mapped, metadata: { total: mapped.length } };
	// 			});
	// 	},
	// 	title: 'address.country',
	// 	hide: true,
	// 	showOnSegment: true,
	// 	parent: 'filters.columns.contactinformation',
	// 	entity: 'account'
	// },
	// State: {
	// 	field: 'client.address.state',
	// 	searchField: 'addresses.attr.state',
	// 	type: 'list',
	// 	displayType: 'lookUp',
	// 	comparisonType: 'MatchExact',
	// 	title: 'address.state',
	// 	hide: true,
	// 	showOnSegment: true,
	// 	parent: 'filters.columns.contactinformation',
	// 	entity: 'account'
	// },
	// City: {
	// 	field: 'client.address.city',
	// 	searchField: 'addresses.attr.city',
	// 	type: 'list',
	// 	displayType: 'lookUp',
	// 	comparisonType: 'MatchExact',
	// 	title: 'address.city',
	// 	hide: true,
	// 	showOnSegment: true,
	// 	parent: 'filters.columns.contactinformation',
	// 	entity: 'account'
	// },
	Score: {
		field: 'score',
		columnPath: 'score',
		title: 'default.score',
		type: 'range',
		inputType: 'range',
		displayType: 'range',
		parent: 'default.market',
		showOnSegment: true
	},
	ScoreUpdateDate: {
		columnPath: 'scoreUpdateDate',
		title: 'default.scoreUpdate',
		inputType: 'date',
		displayType: 'dateRange',
		type: 'raw',
		parent: 'default.market',
		showOnSegment: true,
		presets: dateFilterPresets,
		generate: () => ({ value: { start: null, end: null, preset: 'whenever' } }),
		build: (filter, rb) => {
			const val = DatePreset.getDatesFromValue(filter.value, true);

			if (val.start) {
				rb.addFilter({ field: 'scoreUpdateDate' }, ComparisonTypes.GreaterThanEquals, val.start);
			}
			if (val.end) {
				rb.addFilter({ field: 'scoreUpdateDate' }, ComparisonTypes.LessThanEquals, val.end);
			}
			rb.addFilter({ field: 'score' }, ComparisonTypes.GreaterThan, 0);
		}
	},
	MailBounced: {
		title: 'event.bounce',
		type: 'raw',
		displayType: 'listShortSingle',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'mailbounce.filter.didnt', value: false },
			{ text: 'mailbounce.filter.all', value: true },
			{ text: 'mailbounce.filter.hard', value: 'hard' },
			{ text: 'mailbounce.filter.soft', value: 'soft' }
		],
		generate: () => ({
			inactive: true,
			value: false
		}),
		toUrl: filter => ({ i: filter.inactive, v: filter.value }),
		fromUrl: function (rawFilter) {
			const filter = this.generate!();
			filter.inactive = rawFilter.i;
			filter.value = rawFilter.v;

			return filter;
		},
		build: (filter, rb, getField) => {
			if (filter.inactive) {
				return;
			}

			let value = ['hard_bounce', 'soft_bounce'];
			let ct: (typeof ComparisonTypes)[keyof typeof ComparisonTypes] = ComparisonTypes.NotTerm;
			const field = getField('mailBounces');

			if (filter.value === true) {
				ct = ComparisonTypes.Term;
			} else if (filter.value === 'soft') {
				value = ['soft_bounce'];
				ct = ComparisonTypes.Term;
				rb.addFilter(field, ComparisonTypes.NotTerm, ['hard_bounce']);
			} else if (filter.value === 'hard') {
				value = ['hard_bounce'];
				ct = ComparisonTypes.Term;
			} // else = false = no bounce

			rb.addFilter(field, ct, value);
		},
		parent: 'default.market',
		showOnSegment: true
	},
	InboundEmail: {
		title: 'default.respondedToEmail',
		type: 'raw',
		hide: true,
		generate: () => ({
			inactive: true,
			value: {
				hasInbound: true,
				Date: {
					value: {
						start: null,
						end: null,
						preset: 'last7days'
					}
				}
			}
		}),
		toUrl: filter => ({ i: filter.inactive, hi: filter.value.hasInbound, d: filter.value.Date.value }),
		fromUrl: function (rawFilter) {
			const filter = this.generate!();
			filter.inactive = rawFilter.i;
			filter.value.hasInbound = rawFilter.hi;
			filter.value.Date.value = rawFilter.d;

			return filter;
		},
		build: (filter, rb, getField) => {
			if (filter.inactive) {
				return;
			}

			if (filter.value) {
				rb.addFilter(getField('mail.id'), ComparisonTypes.NotEquals, null);
				rb.addFilter(getField('mail.type'), ComparisonTypes.Equals, 'in');
			}

			if (filter.value.Date?.value) {
				filterParse.dateRange(rb, getField('mail.date').field, filter.value.Date.value, null, true);
			}
		},
		parent: 'default.market',
		showOnSegment: false
	},
	Phone: {
		field: 'phone',
		columnPath: 'phone',
		inputType: 'text',
		type: 'text',
		title: 'default.phone',
		parent: 'filters.columns.contact'
	},
	CellPhone: {
		field: 'cellPhone',
		columnPath: 'cellPhone',
		inputType: 'text',
		type: 'text',
		title: 'default.cellPhone',
		parent: 'filters.columns.contact'
	},
	Email: {
		parent: 'filters.columns.contact',
		type: 'raw',
		displayType: 'radioSearch',
		title: 'default.email',
		options: [
			{ text: 'default.any', value: null, inactive: true },
			{ text: 'default.has', value: true },
			{ text: 'default.hasNot', value: false }
		],
		generate: () => {
			return {
				value: null,
				inactive: true
			};
		},
		toUrl: filter => {
			return { v: filter.value };
		},
		fromUrl: function (rawFilter) {
			const filter = this.generate!();
			filter.value = rawFilter.v;
			return filter;
		},
		build: (filter, rb) => {
			if (typeof filter.value === 'string') {
				rb.addFilter({ field: 'email' }, ComparisonTypes.Search, filter.value);
			} else {
				if (filter.value === true) {
					rb.addFilter({ field: 'email' }, ComparisonTypes.NotEquals, null);
				} else if (filter.value === false) {
					const or = rb.orBuilder();
					or.next();
					or.addFilter({ field: 'email' }, ComparisonTypes.Equals, null);
					or.next();
					or.addFilter({ field: 'email' }, ComparisonTypes.Equals, '');
					or.done();
				}
			}
		}
	},
	// EmailList: {
	// 	field: 'email',
	// 	inputType: 'list',
	// 	type: 'list',
	// 	displayType: 'text',
	// 	comparisonType: 'Search',
	// 	title: 'default.email',
	// 	showOnSegment: true,
	// 	hide: true
	// },
	Active: {
		field: 'active',
		listTitle: 'default.activeInactive',
		title: 'filters.contactStatus',
		type: 'radio',
		inputType: 'radio',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'default.activeContacts', value: true, comparisonType: 'Equals' },
			{ text: 'default.inactiveContacts', value: false, comparisonType: 'Equals' }
		],
		parent: 'filters.columns.contact',
		showOnSegment: true
	},
	Notes: {
		inputType: 'text',
		type: 'raw',
		generate: () => {
			return {
				value: null,
				inactive: true
			};
		},
		build: (filter, rb, getField) => {
			if (filter.value) {
				const orBuilder = rb.orBuilder();

				orBuilder.next();
				orBuilder.addFilter(getField('comment.description'), ComparisonTypes.Search, filter.value);

				orBuilder.next();
				orBuilder.addFilter(getField('notes'), ComparisonTypes.Wildcard, filter.value);

				orBuilder.done();
			}
		},
		title: 'default.notes',
		displayType: 'text'
	},
	FromImport: {
		field: 'importId',
		multiple: true,
		inputType: 'select',
		type: 'list',
		dataPromise: () => Import.find({ status: Import.status.FINISHED, limit: 500, f: ['id', 'file'] }),
		searchField: 'file.filename',
		displayText: imprt => (imprt.file ? imprt.file.filename : ''),
		title: 'import.import',
		multiComparitors: true,
		parent: 'filters.columns.contact'
	},
	RegDate: {
		field: 'regDate',
		title: 'default.created',
		inputType: 'date',
		type: 'dateRange',
		parent: 'filters.columns.contact',
		presets: [
			'whenever',
			'currentDay',
			'lastDay',
			'currentWeek',
			'lastWeek',
			'currentMonth',
			'lastMonth',
			'currentQuarter',
			'lastQuarter',
			'currentYear',
			'lastYear',
			'last7days',
			'last14days',
			'last30days',
			'custom'
		]
	},
	// CreatedAt: {
	// 	field: 'regDate',
	// 	title: 'default.created',
	// 	inputType: 'date',
	// 	type: 'dateRange',
	// 	presets: dateFilterPresets,
	// 	hide: true,
	// 	showOnSegment: true,
	// 	generate: function () {
	// 		return { value: { start: null, end: null, preset: 'whenever' }, comparisonType: null };
	// 	},
	// 	build: function (filter, rb) {
	// 		var val = filter.value;
	// 		val = DatePreset.getDatesFromValue(val, true);

	// 		if (val.start) {
	// 			rb.addFilter({ field: 'regDate' }, rb.comparisonTypes.GreaterThanEquals, val.start);
	// 		}
	// 		if (val.end) {
	// 			rb.addFilter({ field: 'regDate' }, rb.comparisonTypes.LessThanEquals, val.end);
	// 		}
	// 	}
	// },
	// CreatedAtClient: {
	// 	title: 'default.created',
	// 	field: 'client.regDate',
	// 	entity: 'account',
	// 	columnPath: 'regDate',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	inputType: 'date',
	// 	type: 'dateRange',
	// 	presets: dateFilterPresets,
	// 	generate: function () {
	// 		return { value: { start: null, end: null, preset: 'whenever' }, comparisonType: null };
	// 	},
	// 	build: function (filter, rb) {
	// 		var val = filter.value;
	// 		val = DatePreset.getDatesFromValue(val, true);

	// 		if (val.start) {
	// 			rb.addFilter({ field: 'regDate' }, rb.comparisonTypes.GreaterThanEquals, val.start);
	// 		}
	// 		if (val.end) {
	// 			rb.addFilter({ field: 'regDate' }, rb.comparisonTypes.LessThanEquals, val.end);
	// 		}
	// 	}
	// },
	OptIn: {
		field: 'optIn.id',
		multiple: true,
		inputType: 'select',
		type: 'list',
		dataPromise: OptIn => OptIn.find({}),
		searchField: 'title',
		displayText: optIn => optIn.title,
		title: 'default.optIns',
		multiComparitors: true,
		parent: 'default.market',
		showOnSegment: true
	},
	Unsubscribed: {
		field: 'unsubscribed',
		columnPath: 'unsubscribed',
		title: 'mail.unsubscribedGroupMail',
		type: 'radio',
		inputType: 'radio',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'default.yes', value: null, comparisonType: 'NotEquals' },
			{ text: 'default.no', value: null, comparisonType: 'Equals' }
		],
		parent: 'default.market',
		showOnSegment: true
	},
	// EmailMissingAt: {
	// 	title: 'default.email',
	// 	type: 'raw',
	// 	inputType: 'text',
	// 	hide: true,
	// 	generate: function () {
	// 		return {
	// 			value: {
	// 				Email: { value: '' },
	// 				MissingAt: false
	// 			}
	// 		};
	// 	},
	// 	build: function (filter, rb, getField) {
	// 		var val = filter.value;

	// 		if (val.MissingAt) {
	// 			// var group = rb.groupBuilder();
	// 			rb.addFilter(getField('email'), rb.comparisonTypes.NotWildcard, '@');
	// 			// group.isNotFilter();
	// 			// group.done();
	// 		} else if (val.Email.value && val.Email.value.length) {
	// 			rb.addFilter(getField('email'), rb.comparisonTypes.Wildcard, val.Email.value);
	// 		}
	// 	}
	// },
	// MailCampaignFilters: {
	// 	title: 'default.mailing',
	// 	type: 'raw',
	// 	hide: true,
	// 	displayType: 'mailCampaign',
	// 	generate: MailCampaignFilter.generate,
	// 	isInactive: MailCampaignFilter.isInactive,
	// 	toUrl: MailCampaignFilter.toUrl,
	// 	fromUrl: MailCampaignFilter.fromUrl,
	// 	build: MailCampaignFilter.build(),
	// 	datePresets: [
	// 		'whenever',
	// 		'last7days',
	// 		'last14days',
	// 		'last30days',
	// 		'last45days',
	// 		'last90days',
	// 		'lastXdays',
	// 		'prev6Month',
	// 		'prev12Month',
	// 		'prev18Month',
	// 		'prev24Month'
	// 	],
	// 	controller: '',
	// 	view: '',
	// 	showOnSegment: true,
	// 	parent: 'default.market'
	// },
	// VisitorFilters: {
	// 	title: 'default.siteVisits',
	// 	type: 'raw',
	// 	hide: true,
	// 	displayType: 'visit',
	// 	generate: VisitorFilter.generate,
	// 	isInactive: VisitorFilter.isInactive,
	// 	toUrl: VisitorFilter.toUrl,
	// 	fromUrl: VisitorFilter.fromUrl,
	// 	build: VisitorFilter.build(),
	// 	datePresets: [
	// 		'whenever',
	// 		'last7days',
	// 		'last14days',
	// 		'last30days',
	// 		'last45days',
	// 		'last90days',
	// 		'lastXdays',
	// 		'prev6Month',
	// 		'prev12Month',
	// 		'prev18Month',
	// 		'prev24Month'
	// 	],
	// 	controller: '',
	// 	view: '',
	// 	showOnSegment: true,
	// 	parent: 'default.market'
	// },
	// FormSubmitFilters: {
	// 	title: 'default.formFormSubmit',
	// 	type: 'raw',
	// 	hide: true,
	// 	displayType: 'formSubmit',
	// 	generate: FormSubmitFilter.generate,
	// 	isInactive: FormSubmitFilter.isInactive,
	// 	toUrl: FormSubmitFilter.toUrl,
	// 	fromUrl: FormSubmitFilter.fromUrl,
	// 	build: FormSubmitFilter.build(),
	// 	datePresets: [
	// 		'whenever',
	// 		'last7days',
	// 		'last14days',
	// 		'last30days',
	// 		'last45days',
	// 		'last90days',
	// 		'lastXdays',
	// 		'prev6Month',
	// 		'prev12Month',
	// 		'prev18Month',
	// 		'prev24Month'
	// 	],
	// 	controller: '',
	// 	view: '',
	// 	showOnSegment: true,
	// 	parent: 'default.market'
	// },
	// AccountManagerFilters: {
	// 	title: 'filter.accountManagerFilter',
	// 	type: 'raw',
	// 	hide: true,
	// 	controller: '',
	// 	view: '',
	// 	generate: AccountManagerFilter.generate,
	// 	isInactive: AccountManagerFilter.isInactive,
	// 	toUrl: AccountManagerFilter.toUrl,
	// 	fromUrl: AccountManagerFilter.fromUrl,
	// 	build: AccountManagerFilter.build()
	// },
	ListSearch: {
		filterName: 'ListSearch',
		type: 'raw',
		generate: () => {
			return {
				value: null,
				inactive: true
			};
		},
		toUrl: filter => {
			return { v: filter.value };
		},
		fromUrl: rawFilter => {
			return {
				value: rawFilter.v,
				inactive: false
			};
		},
		build: (filter, rb) => {
			if (filter.value && filter.value.length) {
				const orBuilder = rb.orBuilder();

				orBuilder.next();
				orBuilder.addFilter({ field: 'client.name' }, ComparisonTypes.Wildcard, filter.value);

				orBuilder.next();
				orBuilder.addFilter({ field: 'name' }, ComparisonTypes.Wildcard, filter.value);

				orBuilder.next();
				orBuilder.addFilter({ field: 'email' }, ComparisonTypes.Wildcard, filter.value);

				orBuilder.done();
			}
		}
	},
	// // What is the difference between all of these? Id, ExcludeId, IncludeId
	Id: generateFilter(FilterPreset.Number, {
		title: 'default.id',
		field: 'id',
		columnPath: 'id',
		hide: true,
		type: 'idList'
	}),
	ExcludeId: {
		title: 'default.excludedContacts',
		field: 'id',
		columnPath: 'id',
		inputType: 'number',
		type: 'idList',
		isExcludeFilter: true,
		hide: true,
		noExpand: true
	},
	// IncludeId: {
	// 	title: 'default.id',
	// 	field: 'id',
	// 	columnPath: 'id',
	// 	inputType: 'number',
	// 	type: 'idList',
	// 	isExcludeFilter: true,
	// 	excludeType: 'Equals',
	// 	hide: true
	// },
	// HasEmail: {
	// 	title: 'filters.hasEmail',
	// 	type: 'raw',
	// 	inputType: 'radio',
	// 	displayType: 'radio',
	// 	options: [
	// 		{ text: 'default.all', value: null, inactive: true },
	// 		{ text: 'default.yes', value: true },
	// 		{ text: 'default.no', value: false }
	// 	],
	// 	generate: function () {
	// 		return {
	// 			value: null
	// 		};
	// 	},
	// 	toUrl: function (filter) {
	// 		return { v: filter.value };
	// 	},
	// 	fromUrl: function (rawFilter) {
	// 		var filter = this.generate();
	// 		filter.value = rawFilter.v;
	// 		return filter;
	// 	},
	// 	build: function (filter, rb) {
	// 		if (filter.value === true) {
	// 			rb.addFilter({ field: 'email' }, rb.comparisonTypes.NotEquals, null);
	// 		} else if (filter.value === false) {
	// 			const or = rb.orBuilder();
	// 			or.next();
	// 			or.addFilter({ field: 'email' }, rb.comparisonTypes.Equals, null);
	// 			or.next();
	// 			or.addFilter({ field: 'email' }, rb.comparisonTypes.Equals, '');
	// 			or.done();
	// 		}
	// 	},
	// 	hide: true,
	// 	showOnSegment: true
	// },
	MissingEmail: {
		title: 'mail.missingEmail',
		filterName: 'MissingEmail',
		type: 'raw',
		inputType: 'radio',
		displayType: 'radio',
		options: [
			{ text: 'default.all', value: null, inactive: true },
			{ text: 'default.yes', value: true },
			{ text: 'default.no', value: false }
		],
		generate: function () {
			return {
				value: null
			};
		},
		toUrl: function (filter) {
			return { v: filter.value };
		},
		fromUrl: function (rawFilter) {
			var filter = this.generate!();
			filter.value = rawFilter.v;
			return filter;
		},
		build: function (filter, rb) {
			if (filter.value === true) {
				rb.addFilter({ field: 'email' }, ComparisonTypes.Equals, null);
			} else if (filter.value === false) {
				rb.addFilter({ field: 'email' }, ComparisonTypes.NotEquals, null);
			}
		},
		hide: true
	},
	Segment: {
		columnPath: 'segments',
		displayType: 'listLazy',
		type: 'raw',
		search: true,
		resource: 'Segment',
		searchFn: () => term => {
			const filter = new RequestBuilder();
			filter.addFilter(Segment.attr.active, ComparisonTypes.Equals, true);
			if (term) {
				filter.addFilter(Segment.attr.name, ComparisonTypes.Search, term);
			}
			return Segment.find(filter.build());
		},
		searchField: 'name',
		generate: () => ({
			value: [],
			comparisonType: 'Equals',
			inactive: true
		}),
		isInactive: filter => !filter || filter.inactive || !filter.value || !filter.value.length,
		toUrl: filter => ({ v: filter.value, c: filter.comparisonType } as MinifiedFilter),
		fromUrl: function (rawFilter) {
			const filter = this.generate!();
			filter.value = rawFilter.v;

			if (rawFilter.c) {
				filter.comparisonType = rawFilter.c as keyof typeof ComparisonTypes;
			}

			return filter;
		},
		build: (filter, rb, getFieldWithPrefix) => {
			if (
				!filter.inactive &&
				filter.value &&
				filter.value.length &&
				typeof filter.comparisonType !== 'undefined'
			) {
				rb.addFilter(
					getFieldWithPrefix('segment.id'),
					ComparisonTypes[filter.comparisonType! as keyof typeof ComparisonTypes],
					filter.value
				);
				// We want to be able to remove this. And I think it is safe, but put it behind a feature flag so we can test it.
				if (!Tools.FeatureHelper.hasSoftDeployAccess('SEGMENT_FILTER_NO_EXTRA_PARAMS')) {
					const { self, customerId } = store.getState().App;
					rb.addExtraParam('customerId', customerId);
					rb.addExtraParam('userId', self?.id);
				}
			}
		},
		displayText: 'name',
		title: 'segment.segments',
		multiComparitors: true,
		multiple: true,
		showOnSegment: true
	},
	SocialEvent: new SocialEventFilter({
		parent: 'default.market'
	}) as unknown as FilterConfig<any>,
	ContactHistoryFilter: {
		type: 'raw',
		title: 'default.history',
		entity: 'contact',
		displayType: 'history',
		hide: true,
		combinationFilter: {
			ContactOrderFilters: {
				icon: 'fa fa-usd'
			},
			ContactOnlyActivityFilters: {
				icon: 'up-icon-activity'
			},
			ContactOpportunityFilters: {
				icon: 'up-icon-pipeline'
			}
		}
	},
	ContactOrderFilters: {
		title: 'default.sales',
		type: 'raw',
		displayType: 'order',
		hide: true,
		entity: 'contact',
		generate: OrderFilter.generate,
		isInactive: OrderFilter.isInactive,
		toUrl: OrderFilter.toUrl,
		fromUrl: OrderFilter.fromUrl,
		build: OrderFilter.build({ mixedProductsAndCategories: true }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last45days',
			'last90days',
			'lastXdays',
			'prev6Month',
			'prev12Month',
			'prev18Month',
			'prev24Month',
			'custom'
		],
		showOnSegment: false,
		parent: 'advancedSearch.contactHistory'
	},
	ClientOrderFilters: {
		title: 'default.sales',
		type: 'raw',
		displayType: 'HistoryFilter',
		parent: 'filter.saleHistory',
		entity: 'account',
		getText: OrderFilter.getText,
		generate: OrderFilter.generate,
		isInactive: OrderFilter.isInactive,
		toUrl: OrderFilter.toUrl,
		fromUrl: OrderFilter.fromUrl,
		build: OrderFilter.build({ fieldPrefix: 'client', mixedProductsAndCategories: true }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last45days',
			'last90days',
			'lastXdays',
			'prev6Month',
			'prev12Month',
			'prev18Month',
			'prev24Month',
			'custom'
		],
		showOnSegment: true
	},
	// ContactAgreementFilters: {
	// 	title: 'default.agreement',
	// 	type: 'raw',
	// 	displayType: 'agreement',
	// 	hide: true,
	// 	entity: 'contact',
	// 	generate: AgreementFilter.generate,
	// 	isInactive: AgreementFilter.isInactive,
	// 	toUrl: AgreementFilter.toUrl,
	// 	fromUrl: AgreementFilter.fromUrl,
	// 	build: AgreementFilter.build({ mixedProductsAndCategories: true }),
	// 	presets: agreementDateFilterPresets,
	// 	showOnSegment: false,
	// 	parent: 'advancedSearch.contactHistory'
	// },
	// ClientAgreementFilters: {
	// 	title: 'default.agreement',
	// 	type: 'raw',
	// 	displayType: 'agreement',
	// 	hide: true,
	// 	entity: 'account',
	// 	generate: AgreementFilter.generate,
	// 	isInactive: AgreementFilter.isInactive,
	// 	toUrl: AgreementFilter.toUrl,
	// 	fromUrl: AgreementFilter.fromUrl,
	// 	build: AgreementFilter.build({ fieldPrefix: 'client', mixedProductsAndCategories: true }),
	// 	presets: agreementDateFilterPresets,
	// 	// showOnSegment: true,
	// 	parent: 'filter.saleHistory'
	// },
	HasTodo: {
		title: 'default.todo',
		parent: 'filter.contactHistory',
		type: 'raw',
		displayType: 'HistoryFilter',
		entity: 'contact',
		getText: TaskFilter.getText,
		generate: TaskFilter.generate,
		isInactive: TaskFilter.isInactive,
		toUrl: TaskFilter.toUrl,
		fromUrl: TaskFilter.fromUrl,
		build: TaskFilter.build({ phonecall: false }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last90days',
			'next7days',
			'next30days',
			'next45days',
			'next90days',
			'custom'
		]
	},
	HasPhonecall: {
		title: 'default.phonecall',
		parent: 'filter.contactHistory',
		type: 'raw',
		displayType: 'HistoryFilter',
		entity: 'contact',
		getText: TaskFilter.getText,
		generate: TaskFilter.generate,
		isInactive: TaskFilter.isInactive,
		toUrl: TaskFilter.toUrl,
		fromUrl: TaskFilter.fromUrl,
		build: TaskFilter.build({ phonecall: true }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last90days',
			'next7days',
			'next30days',
			'next45days',
			'next90days',
			'custom'
		]
	},
	ClientTodoFilters: {
		title: 'default.todo',
		parent: 'filter.sale',
		type: 'raw',
		displayType: 'HistoryFilter',
		entity: 'account',
		getText: TaskFilter.getText,
		generate: TaskFilter.generate,
		isInactive: TaskFilter.isInactive,
		toUrl: TaskFilter.toUrl,
		fromUrl: TaskFilter.fromUrl,
		build: TaskFilter.build({ phonecall: false, fieldPrefix: 'client' }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last90days',
			'next7days',
			'next30days',
			'next45days',
			'next90days',
			'custom'
		]
	},
	ClientPhonecallFilters: {
		title: 'default.phonecall',
		parent: 'filter.sale',
		type: 'raw',
		displayType: 'HistoryFilter',
		entity: 'account',
		getText: TaskFilter.getText,
		generate: TaskFilter.generate,
		isInactive: TaskFilter.isInactive,
		toUrl: TaskFilter.toUrl,
		fromUrl: TaskFilter.fromUrl,
		build: TaskFilter.build({ phonecall: true, fieldPrefix: 'client' }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last90days',
			'next7days',
			'next30days',
			'next45days',
			'next90days',
			'custom'
		]
	},
	OpportunityFilters: {
		title: 'default.opportunity',
		type: 'raw',
		displayType: 'HistoryFilter',
		parent: 'filter.saleHistory',
		entity: 'account',
		getText: OpportunityFilter.getText,
		generate: OpportunityFilter.generate,
		isInactive: OpportunityFilter.isInactive,
		toUrl: OpportunityFilter.toUrl,
		fromUrl: OpportunityFilter.fromUrl,
		build: OpportunityFilter.build({ fieldPrefix: 'client', mixedProductsAndCategories: true }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last45days',
			'last90days',
			'lastXdays',
			'prev6Month',
			'prev12Month',
			'prev18Month',
			'prev24Month',
			'custom'
		],
		showOnSegment: true
	},
	ContactOpportunityFilters: {
		title: 'default.opportunity',
		type: 'raw',
		displayType: 'opportunity',
		hide: true,
		entity: 'contact',
		generate: OpportunityFilter.generate,
		isInactive: OpportunityFilter.isInactive,
		toUrl: OpportunityFilter.toUrl,
		fromUrl: OpportunityFilter.fromUrl,
		build: OpportunityFilter.build({ mixedProductsAndCategories: true }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last45days',
			'last90days',
			'lastXdays',
			'prev6Month',
			'prev12Month',
			'prev18Month',
			'prev24Month',
			'custom'
		],
		showOnSegment: false,
		parent: 'advancedSearch.contactHistory'
	},
	Appointment: generateAppointmentFilter({
		hide: true
	}) as FilterConfigRaw,
	// ClientAppointment: generateAppointmentFilter(
	// 	{ fieldPrefix: 'client' },
	// 	{ filterName: 'ClientAppointment', entity: 'account', parent: 'filter.saleHistory' }
	// ),
	ClientActivityFilters: {
		title: 'default.activitiesAndAppointments.short',
		type: 'raw',
		parent: 'filter.saleHistory',
		displayType: 'HistoryFilter',
		entity: 'account',
		getText: ActivityFilter.getText,
		generate: ActivityFilter.generate,
		isInactive: ActivityFilter.isInactive,
		toUrl: ActivityFilter.toUrl,
		fromUrl: ActivityFilter.fromUrl,
		build: ActivityFilter.build({ fieldPrefix: 'client' }),
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last90days',
			'lastXdays',
			'next7days',
			'next30days',
			'next45days',
			'next90days',
			'custom'
		],
		showOnSegment: true
	},
	ContactOnlyActivityFilters: generateActivityFilter(
		{},
		{ filterName: 'ContactOnlyActivityFilters' }
	) as FilterConfig,
	// OrgNo: {
	// 	field: 'client.orgNo',
	// 	inputType: 'list',
	// 	type: 'list',
	// 	displayType: 'text',
	// 	comparisonType: 'Search',
	// 	title: 'default.orgNumber',
	// 	entity: 'account',
	// 	// showOnSegment: true,
	// 	hide: true,
	// 	// unreleasedFeature: 'NEW_FIELDS'
	// },
	// Status: {
	// 	title: 'default.status',
	// 	field: 'client.status',
	// 	type: 'list',
	// 	inputType: 'select',
	// 	dataPromise: function () {
	// 		return Tools.AppService.getStaticValuesPromise('status').then(function (options) {
	// 			return { data: options };
	// 		});
	// 	},
	// 	searchField: 'text',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	comparisonType: 'MatchExact',
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// CompanyForm: {
	// 	title: 'soliditet.corporateForm',
	// 	field: 'client.companyForm',
	// 	type: 'list',
	// 	inputType: 'select',
	// 	dataPromise: function () {
	// 		return Tools.AppService.getStaticValuesPromise('companyForm').then(function (options) {
	// 			return { data: options };
	// 		});
	// 	},
	// 	searchField: 'text',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	comparisonType: 'MatchExact',
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// CreditRating: {
	// 	title: 'default.creditRating',
	// 	field: 'client.creditRating',
	// 	type: 'list',
	// 	inputType: 'select',
	// 	dataPromise: function () {
	// 		return Tools.AppService.getStaticValuesPromise('creditRating').then(function (options) {
	// 			return { data: options };
	// 		});
	// 	},
	// 	searchField: 'text',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	comparisonType: 'MatchExact',
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// Turnover: {
	// 	title: 'default.turnover',
	// 	field: 'client.turnover',
	// 	type: 'range',
	// 	inputType: 'range',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// Profit: {
	// 	title: 'default.profit',
	// 	field: 'client.profit',
	// 	type: 'range',
	// 	inputType: 'range',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// NoEmployees: {
	// 	title: 'default.numOfEmployees',
	// 	field: 'client.noEmployees',
	// 	type: 'range',
	// 	inputType: 'range',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// RegistrationDate: {
	// 	title: 'default.founded',
	// 	field: 'client.registrationDate',
	// 	inputType: 'date',
	// 	type: 'dateRange',
	// 	presets: dateFilterPresets,
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// SniCode: {
	// 	field: 'client.sniCode',
	// 	type: 'list',
	// 	title: 'default.companyBranch.sni',
	// 	displayType: 'listLazy',
	// 	dataPromise: function () {
	// 		return Tools.AppService.getStaticValuesPromise('sniCode').then(function (options) {
	// 			return { data: options };
	// 		});
	// 	},
	// 	searchField: 'name',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	comparisonType: 'MatchExact',
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// SicCode: {
	// 	field: 'client.sicCode',
	// 	type: 'list',
	// 	title: 'default.companyBranch.sic',
	// 	displayType: 'listLazy',
	// 	dataPromise: function () {
	// 		return Tools.AppService.getStaticValuesPromise('sicCode').then(function (options) {
	// 			return { data: options };
	// 		});
	// 	},
	// 	searchField: 'name',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	comparisonType: 'MatchExact',
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// NaicsCode: {
	// 	field: 'client.naicsCode',
	// 	type: 'list',
	// 	title: 'default.companyBranch.naics',
	// 	displayType: 'listLazy',
	// 	dataPromise: function () {
	// 		return Tools.AppService.getStaticValuesPromise('naicsCode').then(function (options) {
	// 			return { data: options };
	// 		});
	// 	},
	// 	searchField: 'name',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	comparisonType: 'MatchExact',
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	// UKSicCode: {
	// 	field: 'client.ukSicCode',
	// 	type: 'list',
	// 	title: 'default.companyBranch.uksic',
	// 	displayType: 'listLazy',
	// 	dataPromise: function () {
	// 		return Tools.AppService.getStaticValuesPromise('ukSicCode').then(function (options) {
	// 			return { data: options };
	// 		});
	// 	},
	// 	searchField: 'name',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true,
	// 	comparisonType: 'MatchExact',
	// 	unreleasedFeature: 'NEW_FIELDS'
	// },
	UnsubscribedList: {
		field: 'optOut.id',
		columnPath: 'optOut.id',
		title: 'mail.unsubscribedFromList',
		multiple: true,
		multiComparitors: true,
		inputType: 'select',
		type: 'list',
		dataPromise: function () {
			return GroupMailCategory.find({});
		},
		searchField: 'title',
		displayText: 'title',
		parent: 'default.market',
		showOnSegment: true
	},
	// MailCampaignStatus: {
	// 	// SPECIAL
	// 	hide: true,
	// 	title: 'default.mailing',
	// 	parent: 'default.market',
	// 	type: 'raw',
	// 	generate: function () {
	// 		return {
	// 			value: null
	// 		};
	// 	},
	// 	build: function (filter, rb, getField) {
	// 		var val = filter.value;
	// 		var group = rb.groupBuilder();

	// 		group.addFilter(
	// 			getField('mail.mailCampaign.id'),
	// 			rb.comparisonTypes.Equals,
	// 			val.mailCampaignId
	// 		);

	// 		if (val.deliveredGroup) {
	// 			if (val.open) {
	// 				group.addFilter(getField('mail.lastReadDate'), rb.comparisonTypes.NotEquals, null);
	// 			}
	// 			if (val.click) {
	// 				group.addFilter(getField('mail.lastClickDate'), rb.comparisonTypes.NotEquals, null);
	// 			}
	// 			if (val.submit) {
	// 				group.addFilter(
	// 					getField('contact.formSubmit.leadSource.numvalue'),
	// 					rb.comparisonTypes.Equals,
	// 					val.mailCampaignId
	// 				);
	// 			}
	// 			if (val.unsub) {
	// 				group.addFilter(getField('mail.event.type'), rb.comparisonTypes.Equals, 'unsub');
	// 			}

	// 			group.addFilter(getField('mail.errorCause'), rb.comparisonTypes.Equals, null);
	// 		} else {
	// 			var errors = [];
	// 			if (val.bounce) {
	// 				errors.push('hard_bounce');
	// 				errors.push('soft_bounce');
	// 			}
	// 			if (val.prevbounce) {
	// 				errors.push('previous_bounce');
	// 			}

	// 			if (val.prevunsub) {
	// 				errors.push('previous_unsubscribed');
	// 			}
	// 			if (val.duplicates) {
	// 				errors.push('duplicate_email');
	// 			}
	// 			if (val.invalidemail) {
	// 				errors.push('invalid_email');
	// 			}

	// 			if (errors.length) {
	// 				group.addFilter(getField('mail.errorCause'), rb.comparisonTypes.MatchExact, errors);
	// 			} else {
	// 				group.addFilter(getField('mail.errorCause'), rb.comparisonTypes.NotEquals, null);
	// 			}
	// 		}

	// 		if (val.mailId && val.mailId.length) {
	// 			group.addFilter(getField('mail.id'), rb.comparisonTypes.Equals, val.mailId);
	// 		}

	// 		group.done();
	// 	}
	// },
	JourneyStep: {
		type: 'list',
		filterName: 'JourneyStep',
		field: 'journeyStep',
		title: 'default.journeyStep',
		hide: true,
		dataPromise: function () {
			const journeySteps = store.getState().App.journeySteps;

			return Promise.resolve({
				data: journeySteps.map(step => {
					return { id: step.value, ...step };
				})
			});
		},
		displayText: function (item) {
			return T(item.name);
		},
		displayComponent: function (item) {
			return renderJourneyStepFilterRow(item);
		},
		showOnSegment: true
	},
	ClientJourneyStep: {
		type: 'list',
		filterName: 'JourneyStep',
		field: 'client.journeyStep',
		title: 'default.journeyStep',
		entity: 'account',
		dataPromise: function () {
			const journeySteps = store.getState().App.journeySteps;
			return Promise.resolve({
				data: journeySteps.map(step => {
					return { id: step.value, ...step };
				})
			});
		},
		displayText: function (item) {
			return T(item.name);
		},
		displayComponent: function (item) {
			return renderJourneyStepFilterRow(item);
		},
		showOnSegment: true
	},
	HasSupportTickets: {
		title: 'ticket.tickets',
		parent: 'default.support',
		type: 'raw',
		displayType: 'HistoryFilter',
		entity: 'contact',
		getText: TicketFilter.getText,
		generate: TicketFilter.generate,
		isInactive: TicketFilter.isInactive,
		toUrl: TicketFilter.toUrl,
		fromUrl: TicketFilter.fromUrl,
		build: TicketFilter.build(),
		unreleasedFeature: 'CLIENT_CONTACT_SUPPORT_FILTERS'
	},
	ClientSupportTicketFilters: {
		title: 'ticket.tickets',
		parent: 'default.support',
		type: 'raw',
		displayType: 'HistoryFilter',
		entity: 'account',
		getText: TicketFilter.getText,
		generate: TicketFilter.generate,
		isInactive: TicketFilter.isInactive,
		toUrl: TicketFilter.toUrl,
		fromUrl: TicketFilter.fromUrl,
		build: TicketFilter.build({ fieldPrefix: 'client' }),
		unreleasedFeature: 'CLIENT_CONTACT_SUPPORT_FILTERS'
	}
	// JourneyStepChangedDate: {
	// 	inputType: 'date',
	// 	type: 'dateRange',
	// 	presets: dateFilterPresets,
	// 	filterName: 'JourneyStepdChangeDate',
	// 	field: 'journeyStepChangedDate',
	// 	title: 'default.journeyStepChangedDate',
	// 	entity: 'contact',
	// 	showOnSegment: true,
	// 	hide: true
	// },
	// ClientJourneyStepChangedDate: {
	// 	inputType: 'date',
	// 	type: 'dateRange',
	// 	presets: dateFilterPresets,
	// 	filterName: 'ClientJourneyStepdChangeDate',
	// 	field: 'client.journeyStepChangedDate',
	// 	title: 'default.journeyStepChangedDate',
	// 	entity: 'account',
	// 	showOnSegment: true,
	// 	hide: true
	// }
});
