import React from 'react';

const MailTemplatePreviewHtml = ({ column }) => {
	return (
		<div
			className="MailTemplatePreviewHtml"
			style={{ textAlign: column.align }}
			dangerouslySetInnerHTML={{ __html: column.content }}
		/>
	);
};
export default MailTemplatePreviewHtml;
