import React from 'react';
import { Drawer, DrawerHeader, Block, Paginator, Icon, Button, Tooltip } from '@upsales/components';
import { connect, ConnectedProps } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import ReportcenterDrilldown from './ReportcenterDrilldown';
import PotentialOpportunityValue from './PotentialOpportunityValue';
import './ReportcenterDrilldownDrawer.scss';
import { loadDrilldown, getQueriableFilters } from 'Store/actions/ReportcenterActions';
import T from 'Components/Helpers/translate';
import type { RootState } from 'Store/index';
import { RCDashboardWidget } from 'Resources/ReportDashboard';
import { RCWidgetMeta } from 'Resources/ReportWidget';
import ClientResource from 'App/resources/Client';
import Client from 'App/resources/Model/Client';
import ProductResource from 'Resources/Product';
import Product from 'App/resources/Model/Product';

const getExportText = (entity: string) => {
	switch (entity.toLowerCase()) {
		case 'activity':
			return 'report.exportActivities';
		case 'appointment':
			return 'report.exportAppointments';
		case 'opportunity':
			return 'report.exportOpportunities';
		case 'order':
			return 'report.exportOrders';
		case 'lead':
			return 'report.exportJourney';
		case 'activities':
			return 'report.exportActivities';
		case 'appointments':
			return 'report.exportAppointments';
		case 'pipeline':
			return 'report.exportOpportunities';
		case 'sales':
			return 'report.exportOrders';
		case 'journey':
			return 'report.exportJourney';
		case 'agreement':
			return 'report.exportAgreements';
		case 'formsubmit':
			return 'report.exportFormSubmits';
		case 'visit':
			return 'report.exportWebsitevisits';
		default:
			return 'default.export';
	}
};

const mapStateToProps = (state: RootState) => ({
	activeDrilldown: state.Reportcenter.activeDrilldown,
	widgetMeta: state.Reportcenter.widgetMeta,
	loadingDrilldown: state.Reportcenter.loadingDrilldown
});

const mapDispatchToProps = { loadDrilldown };

const SpecialDrilldownOptions = {
	potentialOpportunityValue: 'potentialOpportunityValue'
};

const getSpecialDrilldownType = (
	widgetMeta: { [k: string]: RCWidgetMeta },
	widgetConfig: RCDashboardWidget,
	drilldownType: number
) => {
	const meta = widgetMeta[widgetConfig.type];
	const opts = meta?.specialDrilldownOptions;
	if (!opts) {
		return;
	}
	const [firstGrouping, secondGrouping] = widgetConfig.groupBy;
	if (
		opts.potentialOpportunityValue &&
		opts.potentialOpportunityValue.drilldownTypes.includes(drilldownType) &&
		opts.potentialOpportunityValue.firstGroupings.includes(firstGrouping) &&
		opts.potentialOpportunityValue.secondGroupings.includes(secondGrouping)
	) {
		return SpecialDrilldownOptions.potentialOpportunityValue;
	}
};
type ExternalProps = {
	widgetConfig: RCDashboardWidget;
	drilldownType: number;
	keys: string | string[];
	className: string;
	close: () => void;
};

type State = {
	offset: number;
	limit: number;
	sorting?: string | null;
	sortingAsc: boolean;
	client: Client | null;
	product: Product | null;
};

class ReportcenterDrilldownDrawer extends React.Component<PropsFromRedux & ExternalProps, State> {
	showPotentialOpportunityValue = false;
	specialDrilldownType?: string;
	state = {
		offset: 0,
		limit: 10,
		sorting: null,
		sortingAsc: true,
		client: null,
		product: null
	};

	async loadClient(id: number) {
		const res = await ClientResource.find({ id });
		if (res.data?.length) {
			this.setState({ client: res.data[0] });
		}
	}

	async loadProduct(id: number) {
		const res = await ProductResource.find({ id });
		if (res.data?.length) {
			this.setState({ product: res.data[0] });
		}
	}

	componentDidMount() {
		const { widgetConfig, widgetMeta, drilldownType, keys } = this.props;
		widgetConfig.sorting = undefined;

		this.specialDrilldownType = getSpecialDrilldownType(widgetMeta, widgetConfig, drilldownType);
		this.showPotentialOpportunityValue =
			getSpecialDrilldownType(widgetMeta, widgetConfig, drilldownType) ===
			SpecialDrilldownOptions.potentialOpportunityValue;

		let limit = Math.floor((window.innerHeight - 119) / 40);
		if (this.showPotentialOpportunityValue) {
			limit = Math.floor(limit / 2);
			const clientId = parseInt(keys[0]);
			const productId = parseInt(keys[1]);
			this.loadClient(clientId);
			this.loadProduct(productId);
		}

		this.props.loadDrilldown(widgetConfig, this.props.keys, 0, limit, this.props.drilldownType);
		this.setState({ limit });
	}

	handlePageChange = (offset: number) => {
		const { activeDrilldown, drilldownType, loadDrilldown, widgetConfig } = this.props;
		this.setState({ offset });
		loadDrilldown(widgetConfig, activeDrilldown?.keys, offset, this.state.limit, drilldownType);
	};

	onSortChange = (newSort: { field: string; asc: boolean }) => {
		const { activeDrilldown, drilldownType, loadDrilldown, widgetConfig } = this.props;
		const offset = 0;
		widgetConfig.sorting = `${newSort.field} ${newSort.asc ? 'asc' : 'desc'}`;
		loadDrilldown(widgetConfig, activeDrilldown?.keys, offset, this.state.limit, drilldownType);
		this.setState({ sorting: newSort.field, sortingAsc: newSort.asc, offset });
	};

	exportData = () => {
		const { activeDrilldown, drilldownType, widgetMeta, widgetConfig } = this.props;
		const exportEntity = activeDrilldown.exportEntity ?? widgetMeta[widgetConfig.type].exportEntity;
		const entity = exportEntity === 'Opportunity' ? 'Order' : exportEntity;

		Tools.$upModal.open('ExportData', {
			optionsByType: true,
			filters: {},
			widget: {
				type: widgetConfig.type,
				groupBy: widgetConfig.groupBy,
				keys: activeDrilldown?.keys,
				dashboardFilters: activeDrilldown?.dashboardFilters,
				filters: getQueriableFilters(widgetConfig.filters),
				drilldownType,
				customOrderSumField: widgetConfig.customOrderSumField,
				valueType: widgetConfig.valueType
			},
			exportType: exportEntity?.toLowerCase(),
			entity,
			justExport: true
		});
	};

	render() {
		const { activeDrilldown, loadingDrilldown, widgetMeta, widgetConfig, className } = this.props;

		const classes = new bemClass('ReportcenterDrilldownDrawer', className);
		const showPaginator = this.state.limit < (activeDrilldown?.count || 0);
		// Disable export on standard db/widget with no id for now
		const canExport = widgetMeta[widgetConfig.type]?.export || widgetConfig.exportOutsideRC;
		const widgetFamily = widgetMeta[widgetConfig.type]?.family || '';
		const hasExportPermission = Tools.AppService.getSelf().export;

		return (
			<Drawer className={classes.b()}>
				<DrawerHeader
					title={activeDrilldown?.title ?? ''}
					subtitle={activeDrilldown?.subtitle ?? ''}
					onHide={this.props.close}
				>
					{!hasExportPermission ? (
						<Tooltip
							title={T('report.exportDenied.toolTip')}
							position="left"
							className={classes.elem('export-tooltip').b()}
						>
							<Button
								type="link"
								size="sm"
								disabled={true}
								className={classes.elem('export-btn-disabled').b()}
							>
								<Icon name="file-excel" space="mlm mrs" />
								{T(getExportText(widgetFamily))}
							</Button>
						</Tooltip>
					) : canExport ? (
						<Button
							type="link"
							size="sm"
							onClick={() => this.exportData()}
							className={classes.elem('export-btn').b()}
						>
							<Icon name="file-excel" space="mlm mrs" />
							{T(
								getExportText(
									activeDrilldown.exportEntity ?? widgetMeta[widgetConfig.type]?.exportEntity ?? ''
								)
							)}
						</Button>
					) : null}
				</DrawerHeader>
				<Block className={classes.elem('content-wrapper').b()}>
					<ReportcenterDrilldown
						loading={loadingDrilldown}
						columns={activeDrilldown?.columns ?? []}
						maxRowCount={this.state.limit}
						rows={activeDrilldown?.rows ?? []}
						link={activeDrilldown?.link ?? {}}
						closeDrawer={this.props.close}
						onSortChange={this.onSortChange}
						sorting={this.state.sorting}
						sortingAsc={this.state.sortingAsc}
						specialDrilldownType={this.specialDrilldownType}
						client={this.state.client}
					/>
					{showPaginator ? (
						<Paginator
							limit={this.state.limit}
							offset={this.state.offset}
							total={activeDrilldown ? activeDrilldown.rowCount ?? activeDrilldown?.count ?? 0 : 0}
							onChange={this.handlePageChange}
						/>
					) : null}
					{this.showPotentialOpportunityValue ? (
						<PotentialOpportunityValue client={this.state.client} product={this.state.product} />
					) : null}
				</Block>
			</Drawer>
		);
	}
}

export const detached = ReportcenterDrilldownDrawer;

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportcenterDrilldownDrawer);
