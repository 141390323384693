import React from 'react';
import RequestBuilder from 'Resources/RequestBuilder';
import entityName from 'Components/Helpers/EntityName';
import T from 'Components/Helpers/translate';
import AlertConfirm from 'Components/Dialogs/AlertConfirm';
import AlertBody from 'Components/Dialogs/Body/AlertBody';
import deleteMulti from 'Components/Helpers/DeleteMulti';
import openModal from 'App/services/Modal';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';

export default function remove(
	rb: RequestBuilder | null,
	entity: string,
	notifyEntity: string,
	name: string | null,
	selected: number[],
	numSelected: number,
	removedCallback: (res?: any) => void,
	onErrorCallback: (err?: Error) => void,
	notificationEntityNameExtension?: string /* Hax for CallLists to use the correct entity name
	for notifications and confirmation text since they are Projects/Campaigns but are called call lists. 
	notifyEntity fixed some of the notifications but not all so had to add this
	*/
) {
	// TODO: Refactor and use new modalService
	const customerId = Tools.AppService.getCustomerId();
	const confirmButtonText = T('admin.modal.deleteEntity', {
		entity: T(entityName(notificationEntityNameExtension || entity, numSelected)).toLowerCase()
	});
	const alertConfirmOptions = {
		type: 'confirm',
		reactive: true,
		fullscreen: true,
		hideAbort: false,
		dialog: AlertConfirm,
		id: 'confirm-delete-fields',
		body: React.createElement(AlertBody, {
			account: {
				id: selected
			},
			allSelected: !selected?.length,
			customerId,
			numSelected,
			entity,
			notificationEntityNameExtension
		}),
		confirmButtonText: confirmButtonText,
		confirmClass: 'btn-red',
		confirmationMode: 'text',
		confirmText: Tools.AppService.getSelf().email,
		confirmFn: function () {
			const modalParams = {
				customerId,
				entity,
				filters: rb,
				notifyEntity,
				name,
				notificationEntityNameExtension
			};
			return deleteMulti(modalParams, selected, false, removedCallback, onErrorCallback);
		}
	};

	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
		openModal('Alert', {
			...alertConfirmOptions,
			title: getConfirmationTitle(entity, numSelected, '', !selected?.length, notificationEntityNameExtension),
			onClose: async confirm => {
				if (confirm) {
					try {
						await alertConfirmOptions.confirmFn();
					} catch (err: any) {
						onErrorCallback(err);
					}
				}
			}
		});
	} else {
		Tools.$upModal.open('alert', alertConfirmOptions).catch(onErrorCallback);
	}
}
