import React from 'react';
import { Input, Row, Column, Label, Toggle } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';
import MailContentEditorAlign from './MailContentEditorAlign';
import MailContentEditorTextEditor from './MailContentEditorTextEditor';

const MailContentEditorButton = ({ column, onChange = () => {}, accountProfile }) => {
	const classes = new bemClass('MailContentEditor');
	return (
		<div>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('mail.text')}</Label>
					<Input value={column.content} onChange={e => onChange({ ...column, content: e.target.value })} />
				</Column>
			</Row>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('default.link')}</Label>
					<Input
						placeholder="https://upsales.com"
						value={column.href}
						onChange={e => onChange({ ...column, href: e.target.value })}
					/>
				</Column>
			</Row>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('form.buttonTextColor')}</Label>
				</Column>
				<Column align="right">
					<ReactTemplates.bannerEditor.colorPicker
						value={column.color}
						presets={accountProfile.colorVariables}
						onChange={color => onChange({ ...column, color: color })}
					/>
				</Column>
			</Row>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('form.buttonColor')}</Label>
				</Column>
				<Column align="right">
					<ReactTemplates.bannerEditor.colorPicker
						value={column.btnBg}
						presets={accountProfile.colorVariables}
						onChange={color => onChange({ ...column, btnBg: color })}
					/>
				</Column>
			</Row>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('mailTemplate.buttonFullWidth')}</Label>
				</Column>
				<Column align="right">
					<Toggle
						color="medium-green"
						checked={column.expand}
						onChange={val => onChange({ ...column, expand: val })}
					/>
				</Column>
			</Row>
			{!column.expand ? (
				<Row className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<MailContentEditorAlign
							value={column.align}
							onChange={align => onChange({ ...column, align })}
						/>
					</Column>
				</Row>
			) : null}
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('mail.buttonText')}</Label>
				</Column>
				<Column align="right" size={4}>
					<Toggle
						color="medium-green"
						checked={column.showCaption}
						onChange={val => onChange({ ...column, showCaption: val })}
					/>
				</Column>
			</Row>
			{column.showCaption ? (
				<div>
					<MailContentEditorTextEditor
						value={column.caption}
						onChange={e => onChange({ ...column, caption: e.value })}
					/>
					<div className={classes.elem('sidebar-section-input-row').b()}>
						<MailContentEditorAlign
							value={column.captionAlign}
							onChange={captionAlign => onChange({ ...column, captionAlign })}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default MailContentEditorButton;
