import { SelectTypeWithChildren } from 'App/components/GenericSelectEntityModal/GenericSelectEntityModal';
import Client from 'App/resources/Model/Client';
import CustomField from 'App/resources/Model/CustomField';
import logError from 'Helpers/logError';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

export const formatWithSubAccounts = (
	accs: Client[] = [],
	translationFn: (langKey: string) => string,
	recent = false
): SelectTypeWithChildren<Client>[] | Client[] => {
	const mainAccounts = [];
	const subAccounts = [];
	const lang = {
		accounts: translationFn(recent ? 'default.latestAccounts' : 'default.accounts'),
		subaccounts: translationFn(recent ? 'default.latestSubaccounts' : 'account.subaccounts')
	};

	for (const acc of accs) {
		if (acc.operationalAccount) {
			subAccounts.push(acc);
		} else {
			mainAccounts.push(acc);
		}
	}
	const formattedResults: SelectTypeWithChildren<Client>[] = [];
	if (mainAccounts.length) {
		formattedResults.push({
			name: lang.accounts,
			title: lang.accounts,
			icon: 'home',
			children: mainAccounts
		});
	}
	if (subAccounts.length) {
		formattedResults.push({
			name: lang.subaccounts,
			title: lang.subaccounts,
			icon: 'subaccounts',
			children: subAccounts
		});
	}
	return formattedResults;
};

export const getRelatedClientIds = (
	object: {
		clientConnection: null | {
			id: number;
		};
		client: null | {
			operationalAccount: null | {
				id: number;
			};
		};
	},
	hasSubAccounts: boolean = true
) => {
	const relatedIds = [];

	if (object?.clientConnection?.id) {
		relatedIds.push(object.clientConnection.id);
	}
	if (hasSubAccounts && object?.client?.operationalAccount?.id) {
		relatedIds.push(object.client.operationalAccount.id);
	}

	return relatedIds;
};

export const binaryGroup = <T extends any>(objects: T[], predicate: (object: T) => boolean) =>
	objects.reduce<[T[], T[]]>(
		(res, object) => {
			const [truthy, falsey] = res;
			if (predicate(object)) {
				truthy.push(object);
			} else {
				falsey.push(object);
			}
			return [truthy, falsey];
		},
		[[], []]
	);

export const setCustomClientFieldsFilters = (reqBuilder: RequestBuilder, searchString: string) => {
	const hasCustomContactSearch = Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_CONTACT_CUSTOM_FIELD_SEARCH');
	if (!hasCustomContactSearch) {
		return false;
	}
	const searchableClientFields = ((Tools.AppService.getCustomFields('account') || []) as CustomField[])
		.filter(cf => cf.searchable)
		.map(field => field.id);

	if (searchableClientFields.length) {
		reqBuilder.next();
		const groupFilter = reqBuilder.groupBuilder();
		groupFilter.addFilter({ field: 'custom.fieldId' }, comparisonTypes.Equals, searchableClientFields);
		groupFilter.addFilter({ field: 'custom.value' }, comparisonTypes.Search, searchString);
		groupFilter.done();
		return true;
	}
	return false;
};

export const fetchClientsFromCustomFields = async (searchString: string) => {
	const { Account, AppService } = Tools;
	let clientIds: number[] = [];

	const accountFilter = new RequestBuilder();
	accountFilter.addFilter(Account.attr.active, comparisonTypes.Equals, 1);
	if (!setCustomClientFieldsFilters(accountFilter, searchString)) {
		return [];
	}

	try {
		const response = await Account.customer(AppService.getCustomerId()).find(accountFilter.build());
		clientIds = (response?.data || []).map((client: Client) => client.id);
	} catch (e) {
		logError(e, 'Failed to fetch clients');
	}

	return clientIds;
};
