import { removeItem, replaceItem } from 'Store/helpers/array';
import { batch } from 'react-redux';
import { SET_SCHEDULED_DASHBOARD_FILTERS } from 'Store/reducers/ReportcenterReducer';
import ReportDashboard from 'Resources/ReportDashboard';
import ReportWidget from 'Resources/ReportWidget';
import ReportWidgetDrilldown from 'Resources/ReportWidgetDrilldown';
import {
	filterPresets,
	getDefaultDashboard,
	getOptimalDimensionsByType
} from '../../../pages/Reportcenter/reportCenterHelpers';
import history from 'App/pages/routes/history';
import ReportScheduleResource from 'App/resources/ReportSchedule';
import * as RCActionsHelpers from './helpers/reportcenterActionsHelpers';
import { generateGroups } from '../../../pages/Reportcenter/ReportcenterWidgetLibrary/widgetExamples';
import T from 'Components/Helpers/translate';
import { globalTracker } from 'Helpers/Tracker';
import logError from 'App/babel/helpers/logError';

const {
	reset,
	setActiveDrilldown,
	setCreatingDashboard,
	setDashboardError,
	setDashboardFilters,
	setDashboards,
	setDeletingDashboard,
	setEditingDashboard,
	setEditWidgetData,
	setFetchingDashboards,
	setInitialDashboardHash,
	setInitialWidgetHash,
	setLoading,
	setLoadingDashboard,
	setLoadingDrilldown,
	setPageError,
	setPendingDashboardSave,
	setScheduledDashboards,
	setScheduledSelectedDashboard,
	setSelectedDashboard,
	setSelectedView,
	setWidgetDataObject,
	setWidgetMeta,
	setWidgetCategories
} = RCActionsHelpers;

export const {
	resetEditWidgetData,
	setEditWidget,
	setEditWidgetCategory,
	setScheduledDashboardFilters,
	setScheduling
} = RCActionsHelpers;

const placeholderDataObj = { data: { rows: [], total: {} }, loading: false };

const getDashboards = () => async dispatch => {
	dispatch(setFetchingDashboards(true));
	// Fetch all db's
	const { data } = await ReportDashboard.find();

	dispatch(setDashboards(data));
	dispatch(setFetchingDashboards(false));

	return data;
};

const initWidgetData = widgets => dispatch => {
	const widgetData = widgets.reduce((res, w) => {
		res[w.uniqueId] = { ...placeholderDataObj, loading: true };
		return res;
	}, {});
	dispatch(setWidgetDataObject(widgetData));
};

const updateWidgetDataObjectById = (uniqueId, dataObject) => (dispatch, getState) => {
	const { widgetData } = getState().Reportcenter;

	dispatch(
		setWidgetDataObject({
			...widgetData,
			[uniqueId]: { ...placeholderDataObj, ...widgetData[uniqueId], ...dataObject }
		})
	);
};

export const getSaveableFilters = (dashboardFilters, skipEncode = false) => {
	const filters = [];
	if (dashboardFilters.User?.value?.length) {
		filters.push({
			attribute: 'user',
			comparison: dashboardFilters.User.comparison,
			value: dashboardFilters.User.value.join(',')
		});
	}
	if (dashboardFilters.Role?.value?.length) {
		filters.push({
			attribute: 'role',
			comparison: dashboardFilters.Role.comparison,
			value: dashboardFilters.Role.value.join(',')
		});
	}
	if (dashboardFilters.Date?.value && dashboardFilters.Date?.value !== 'Custom') {
		filters.push({
			attribute: 'date',
			comparison: 'eq',
			value: dashboardFilters.Date.value
		});
	}
	if (dashboardFilters.Stage?.value?.length) {
		filters.push({
			attribute: 'stage',
			comparison: dashboardFilters.Stage.comparison,
			value: dashboardFilters.Stage.value.join(',')
		});
	}
	if (dashboardFilters.Campaign?.value?.length) {
		filters.push({
			attribute: 'campaign',
			comparison: dashboardFilters.Campaign.comparison,
			value: dashboardFilters.Campaign.value.join(',')
		});
	}
	if (dashboardFilters.ClientCampaign?.value?.length) {
		filters.push({
			attribute: 'clientCampaign',
			comparison: dashboardFilters.ClientCampaign.comparison,
			value: dashboardFilters.ClientCampaign.value.join(',')
		});
	}
	if (dashboardFilters.CallList?.value?.length) {
		filters.push({
			attribute: 'callList',
			comparison: dashboardFilters.CallList.comparison,
			value: dashboardFilters.CallList.value.join(',')
		});
	}
	if (dashboardFilters.Product?.value?.length) {
		filters.push({
			attribute: 'product',
			comparison: dashboardFilters.Product.comparison,
			value: dashboardFilters.Product.value.join(',')
		});
	}
	if (dashboardFilters.ProductCategory?.value?.length) {
		filters.push({
			attribute: 'productCategory',
			comparison: dashboardFilters.ProductCategory.comparison,
			value: dashboardFilters.ProductCategory.value.join(',')
		});
	}
	if (dashboardFilters.Currency?.value?.length) {
		filters.push({
			attribute: 'currency',
			comparison: dashboardFilters.Currency.comparison,
			value: dashboardFilters.Currency.value.join(',')
		});
	}
	if (dashboardFilters.Event?.value?.length) {
		filters.push({
			attribute: 'event',
			comparison: dashboardFilters.Event.comparison,
			value: dashboardFilters.Event.value.join(',')
		});
	}
	if (dashboardFilters.Flow?.value?.length) {
		filters.push({
			attribute: 'flow',
			comparison: dashboardFilters.Flow.comparison,
			value: dashboardFilters.Flow.value.join(',')
		});
	}
	if (dashboardFilters.Form?.value?.length) {
		filters.push({
			attribute: 'form',
			comparison: dashboardFilters.Form.comparison,
			value: dashboardFilters.Form.value.join(',')
		});
	}
	if (dashboardFilters.EasyBooking?.value?.length) {
		filters.push({
			attribute: 'easyBooking',
			comparison: dashboardFilters.EasyBooking.comparison,
			value: dashboardFilters.EasyBooking.value.join(',')
		});
	}
	if (dashboardFilters.ClientCategory?.value?.length) {
		filters.push({
			attribute: 'clientCategory',
			comparison: dashboardFilters.ClientCategory.comparison,
			value: dashboardFilters.ClientCategory.value.join(',')
		});
	}
	if (dashboardFilters.AccountManager?.value?.length) {
		filters.push({
			attribute: 'accountManager',
			comparison: dashboardFilters.AccountManager.comparison,
			value: dashboardFilters.AccountManager.value.join(',')
		});
	}
	if (dashboardFilters.CustomDate?.value) {
		if (dashboardFilters.CustomDate.value.start) {
			filters.push({ attribute: 'date', comparison: 'gte', value: dashboardFilters.CustomDate.value.start });
		}
		if (dashboardFilters.CustomDate.value.end) {
			filters.push({ attribute: 'date', comparison: 'lte', value: dashboardFilters.CustomDate.value.end });
		}
	}
	if (dashboardFilters.AppointmentType?.value?.length) {
		filters.push({
			attribute: 'appointmentType',
			comparison: dashboardFilters.AppointmentType.comparison,
			value: dashboardFilters.AppointmentType.value.join(',')
		});
	}
	if (dashboardFilters.Type?.value?.length) {
		filters.push({
			attribute: 'type',
			comparison: dashboardFilters.Type.comparison,
			value: dashboardFilters.Type.value.join(',')
		});
	}
	if (dashboardFilters.ActivityType?.value?.length) {
		filters.push({
			attribute: 'activityType',
			comparison: dashboardFilters.ActivityType.comparison,
			value: dashboardFilters.ActivityType.value.join(',')
		});
	}
	if (dashboardFilters.Client?.value?.length) {
		filters.push({
			attribute: 'client',
			comparison: dashboardFilters.Client.comparison,
			value: dashboardFilters.Client.value.join(',')
		});
	}
	if (dashboardFilters.LabelForm?.value?.length) {
		filters.push({
			attribute: 'labelForm',
			comparison: dashboardFilters.LabelForm.comparison,
			value: dashboardFilters.LabelForm.value.join(',')
		});
	}
	if (dashboardFilters.LabelMailCampaign?.value?.length) {
		filters.push({
			attribute: 'labelMailCampaign',
			comparison: dashboardFilters.LabelMailCampaign.comparison,
			value: dashboardFilters.LabelMailCampaign.value.join(',')
		});
	}
	if (dashboardFilters.ClientNewSale?.value?.length) {
		filters.push({
			attribute: 'clientNewSale',
			comparison: dashboardFilters.ClientNewSale.comparison,
			value: dashboardFilters.ClientNewSale.value.join(',')
		});
	}
	if (dashboardFilters.ClientAddressesCountry?.value?.length) {
		filters.push({
			attribute: 'clientAddressesCountry',
			comparison: dashboardFilters.ClientAddressesCountry.comparison,
			value: dashboardFilters.ClientAddressesCountry.value.join(',')
		});
	}
	if (dashboardFilters.ClientAddressesState?.value?.length) {
		filters.push({
			attribute: 'clientAddressesState',
			comparison: dashboardFilters.ClientAddressesState.comparison,
			value: dashboardFilters.ClientAddressesState.value.join(',')
		});
	}
	if (dashboardFilters.ClientAddressesCity?.value?.length) {
		filters.push({
			attribute: 'clientAddressesCity',
			comparison: dashboardFilters.ClientAddressesCity.comparison,
			value: dashboardFilters.ClientAddressesCity.value.join(',')
		});
	}
	if (dashboardFilters.JourneyStepClient?.value?.length) {
		filters.push({
			attribute: 'journeyStepClient',
			comparison: dashboardFilters.JourneyStepClient.comparison,
			value: dashboardFilters.JourneyStepClient.value.join(',')
		});
	}
	if (dashboardFilters.TicketType?.value?.length) {
		filters.push({
			attribute: 'ticketType',
			comparison: dashboardFilters.TicketType.comparison,
			value: dashboardFilters.TicketType.value.join(',')
		});
	}
	if (Object.keys(dashboardFilters.Custom?.value ?? {}).length) {
		for (const [entity, customFilters] of Object.entries(dashboardFilters.Custom.value)) {
			for (const [id, value] of Object.entries(customFilters)) {
				const encodedValues = value.value.map(v => (skipEncode ? v : encodeURIComponent(v)));
				const attribute = `${entity}_custom_${id}`;
				filters.push({
					attribute,
					comparison: value.comparison,
					value: skipEncode ? encodedValues : encodedValues.join(',')
				});
			}
		}
	}
	return filters;
};

export const getQueriableFilters = (filters = []) => {
	const orGroups = {
		user: {
			fields: ['role', 'user'],
			query: null
		},
		product: {
			fields: ['product', 'productCategory'],
			query: null
		}
	};
	const queryFilters = filters.reduce((parsedFilters, f) => {
		const shouldDecodeValue = ['webPage'].includes(f.attribute);
		const value =
			typeof f.value === 'string'
				? f.value.split(',').map(a => (a === 'null' ? null : shouldDecodeValue ? decodeURIComponent(a) : a))
				: f.value;
		const parsedFilter = {
			a: f.attribute,
			c: f.comparison,
			v: value
		};

		const orGroupKey = Object.keys(orGroups).find(group => orGroups[group].fields.includes(f.attribute));

		if (parsedFilter.c === 'ne' && !parsedFilter.v.includes(null)) {
			parsedFilters.push({ or: [{ ...parsedFilter }, { a: parsedFilter.a, c: 'eq', v: null }] });
		} else if (orGroupKey && parsedFilter.c !== 'ne') {
			let query = orGroups[orGroupKey].query;
			if (!query) {
				query = orGroups[orGroupKey].query = { or: [parsedFilter] };
				parsedFilters.push(query);
			} else {
				query.or.push(parsedFilter);
			}
		} else {
			parsedFilters.push(parsedFilter);
		}
		return parsedFilters;
	}, []);

	return queryFilters;
};

export const getWidgetData = widgetConfig => async (dispatch, getState) => {
	dispatch(updateWidgetDataObjectById(widgetConfig.uniqueId, { loading: true, error: false }));
	try {
		const { dashboardFilters } = getState().Reportcenter;
		const { data } = await ReportWidget.find(widgetConfig.type, {
			grouping: widgetConfig.groupBy,
			q: getQueriableFilters(widgetConfig.filters),
			dashboardFilters: getQueriableFilters(getSaveableFilters(dashboardFilters)),
			weighted: widgetConfig.weighted,
			valueType: widgetConfig.valueType,
			numberFormat: widgetConfig.numberFormat,
			customOrderSumField: widgetConfig.customOrderSumField,
			aggregateSubAccounts: widgetConfig.aggregateSubAccounts
		});
		dispatch(updateWidgetDataObjectById(widgetConfig.uniqueId, { data: data, loading: false }));
	} catch (e) {
		logError(e);
		dispatch(updateWidgetDataObjectById(widgetConfig.uniqueId, { loading: false, error: true }));
	}
};

export const saveSelectedDashboard =
	(saveData = {}) =>
	async (dispatch, getState) => {
		const { selectedDashboard, dashboardFilters, dashboards } = getState().Reportcenter;
		// Map filters
		const filters = getSaveableFilters(dashboardFilters);

		const isHidden = dashboards.find(db => db.id === selectedDashboard.id)?.hidden;
		dispatch(setInitialDashboardHash({ ...selectedDashboard, ...saveData }, dashboardFilters));
		dispatch(setInitialWidgetHash({ ...selectedDashboard, ...saveData }));
		dispatch(setPendingDashboardSave(true));

		const { data } = await ReportDashboard.save({ ...selectedDashboard, ...saveData, filters });
		const savedSelectedDashboard = {
			...selectedDashboard,
			...data,
			widgets: selectedDashboard.widgets,
			hidden: !!isHidden
		};
		const nonUpdatedDashboards = dashboards.filter(dashboard => dashboard.id !== selectedDashboard.id);
		dispatch(setDashboards([...nonUpdatedDashboards, savedSelectedDashboard]));
		dispatch(setSelectedDashboard(savedSelectedDashboard));
		dispatch(setPendingDashboardSave(false));
		return data;
	};

const updateExistingWidget = widgetData => async (dispatch, getState) => {
	const { selectedDashboard } = getState().Reportcenter;
	const i = selectedDashboard.widgets.findIndex(w => w.uniqueId === widgetData.uniqueId);
	if (i !== -1) {
		const newData = {
			...selectedDashboard.widgets[i],
			...widgetData
		};
		dispatch(
			setSelectedDashboard({
				...selectedDashboard,
				widgets: replaceItem(selectedDashboard.widgets, i, newData)
			})
		);
		dispatch(getWidgetData(newData));
	}
};

export const getEditWidgetData = () => async (dispatch, getState) => {
	const { editWidget, editWidgetData } = getState().Reportcenter;
	dispatch(setEditWidgetData({ ...editWidgetData, loading: true })); // set loading
	const { data } = await ReportWidget.find(editWidget.type, {
		grouping: editWidget.groupBy,
		q: getQueriableFilters(editWidget.filters ?? []),
		weighted: editWidget.weighted,
		aggregateSubAccounts: editWidget.aggregateSubAccounts,
		valueType: editWidget.valueType,
		numberFormat: editWidget.numberFormat,
		customOrderSumField: editWidget.customOrderSumField
	});
	dispatch(setEditWidgetData({ data: data, loading: false }));
};

export const addWidget = () => dispatch => {
	dispatch(
		setEditWidget({
			title: '',
			subtitle: '',
			type: null, // type is set to null so that the library will open first
			displayType: 'bigNumber',
			width: 1,
			height: 1,
			groupBy: ['user'],
			legend: false,
			goal: true,
			x: -1,
			y: -1
		})
	);
};

export const deleteWidget = uniqueId => (dispatch, getState) => {
	const { selectedDashboard } = getState().Reportcenter;
	const i = selectedDashboard.widgets.findIndex(w => w.uniqueId === uniqueId);
	if (i !== -1) {
		dispatch(
			setSelectedDashboard({
				...selectedDashboard,
				widgets: removeItem(selectedDashboard.widgets, i)
			})
		);
	}
};

export const saveEditedWidget = () => async (dispatch, getState) => {
	const { editWidget, selectedDashboard = { widgets: [] } } = getState().Reportcenter;
	if (editWidget.uniqueId) {
		// Update
		dispatch(updateExistingWidget(editWidget));
	} else {
		editWidget.uniqueId = Date.now();

		if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_WIDGET_GALLERY')) {
			editWidget.x = -1;
			editWidget.y = -1;
			const { width, height } = getOptimalDimensionsByType(editWidget.displayType);
			editWidget.height = height;
			editWidget.width = width;
		}

		dispatch(
			setSelectedDashboard({
				...(selectedDashboard ?? {}),
				widgets: [...(selectedDashboard?.widgets ?? []), editWidget]
			})
		);
	}
};

export const duplicateWidget = widget => (dispatch, getState) => {
	const { selectedDashboard } = getState().Reportcenter;
	const newWidget = { ...widget, id: undefined };
	newWidget.x = 0;
	newWidget.y = -1;
	newWidget.uniqueId = Date.now();
	newWidget.title = `${T('reportcenter.copyOf')} ${widget.title}`;
	dispatch(setSelectedDashboard({ ...selectedDashboard, widgets: [...selectedDashboard.widgets, newWidget] }));
};

export const saveDashboardSettings = reorderedDashboards => async (dispatch, getState) => {
	const { dashboards } = getState().Reportcenter;
	ReportDashboard.saveSettings(reorderedDashboards);
	const unchangedDashboards =
		dashboards.filter(dashboard => !reorderedDashboards.some(db => db.id.toString() === dashboard.id.toString())) ??
		[];
	dispatch(setDashboards([...unchangedDashboards, ...reorderedDashboards]));
};

export const createDashboard = data => async (dispatch, getState) => {
	dispatch(setCreatingDashboard(true));
	try {
		const { data: dashboard } = await ReportDashboard.save(data);
		const { dashboards } = getState().Reportcenter;
		dispatch(saveDashboardSettings([...dashboards, dashboard]));
		history.push(`/rc/${dashboard.id}`);
	} catch (e) {
		logError(e);
	}
	dispatch(setCreatingDashboard(false));
};

export const updateDashboardLayout = layout => (dispatch, getState) => {
	const { selectedDashboard } = getState().Reportcenter;
	dispatch(
		setSelectedDashboard({
			...selectedDashboard,
			widgets: selectedDashboard.widgets.map(w => {
				const layoutData = layout.find(l => l.i === w.uniqueId + '');
				return { ...w, x: layoutData.x, y: layoutData.y, width: layoutData.w, height: layoutData.h };
			})
		})
	);
};

export const shareDashboard = usersAndRoles => async (dispatch, getState) => {
	const { selectedDashboard } = getState().Reportcenter;
	const newUsers = [];
	const newRoles = [];
	for (const obj of usersAndRoles) {
		if (obj.isRole) {
			newRoles.push(obj.$id ?? obj.id);
		} else {
			newUsers.push(obj.id);
		}
	}
	selectedDashboard.users = newUsers;
	selectedDashboard.roles = newRoles;
	await dispatch(setSelectedDashboard({ ...selectedDashboard }));
	dispatch(saveSelectedDashboard());
};
export const updateExistingWidgetData = (uniqueId, widgetData) => dispatch => {
	dispatch(updateExistingWidget({ uniqueId, ...widgetData }));
};

const parseId = (r, s) => {
	if (!isNaN(Number(s))) {
		r.push(Number(s));
	} else {
		r.push(s);
	}
	return r;
};

export const mapDashboardFilters = dashboardFilters => {
	const parsedFilters = dashboardFilters.reduce((res, filter) => {
		if (filter.attribute.includes('_custom_')) {
			if (!res.custom) {
				res.custom = {};
			}
			const [entity, fieldId] = filter.attribute.split('_custom_');
			if (!res.custom[entity]) {
				res.custom[entity] = {};
			}
			res.custom[entity][fieldId] = {};
			res.custom[entity][fieldId].value = filter.value.split(',').map(v => decodeURIComponent(v));
			res.custom[entity][fieldId].comparison = filter.comparison;
		} else {
			res[filter.attribute] = {};
			res[filter.attribute].value = filter.value;
			res[filter.attribute].comparison = filter.comparison;
		}
		return res;
	}, {});
	const isCustomDate =
		!!parsedFilters.date && !filterPresets.date.presets.some(p => p.value === parsedFilters.date.value);

	const getCustomDateObj = () => {
		const dates = dashboardFilters.filter(f => f.attribute === 'date');
		const start = dates.find(d => d.comparison === 'gte');
		const end = dates.find(d => d.comparison === 'lte');
		return { start: start ? new Date(start.value) : null, end: end ? new Date(end.value) : null };
	};
	const res = {
		CustomDate: {
			value: isCustomDate ? getCustomDateObj() : null
		},
		Date: {
			value: !isCustomDate ? parsedFilters.date?.value ?? null : 'Custom'
		},
		User: {
			value: parsedFilters.user?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.user?.comparison ?? 'eq'
		},
		Role: {
			value: parsedFilters.role?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.role?.comparison ?? 'eq'
		},
		Stage: {
			value: parsedFilters.stage?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.stage?.comparison ?? 'eq'
		},
		Campaign: {
			value: parsedFilters.campaign?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.campaign?.comparison ?? 'eq'
		},
		ClientCampaign: {
			value: parsedFilters.clientCampaign?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.clientCampaign?.comparison ?? 'eq'
		},
		JourneyStepClient: {
			value: parsedFilters.JourneyStepClient?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.JourneyStepClient?.comparison ?? 'eq'
		},
		CallList: {
			value: parsedFilters.callList?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.callList?.comparison ?? 'eq'
		},
		Product: {
			value: parsedFilters.product?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.product?.comparison ?? 'eq'
		},
		ProductCategory: {
			value: parsedFilters.productCategory?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.productCategory?.comparison ?? 'eq'
		},
		Currency: {
			value: parsedFilters.currency?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.currency?.comparison ?? 'eq'
		},
		ClientCategory: {
			value: parsedFilters.clientCategory?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.clientCategory?.comparison ?? 'eq'
		},
		AccountManager: {
			value: parsedFilters.accountManager?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.accountManager?.comparison ?? 'eq'
		},
		AppointmentType: {
			value: parsedFilters.appointmentType?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.appointmentType?.comparison ?? 'eq'
		},
		Type: {
			value: parsedFilters.type?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.type?.comparison ?? 'eq'
		},
		ActivityType: {
			value: parsedFilters.activityType?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.activityType?.comparison ?? 'eq'
		},
		Client: {
			value: parsedFilters.client?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.client?.comparison ?? 'eq'
		},
		Event: {
			value: parsedFilters.event?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.event?.comparison ?? 'eq'
		},
		Flow: {
			value: parsedFilters.flow?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.flow?.comparison ?? 'eq'
		},
		Custom: {
			value: parsedFilters.custom || {}
		},
		Form: {
			value: parsedFilters.form?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.form?.comparison ?? 'eq'
		},
		EasyBooking: {
			value: parsedFilters.easyBooking?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.easyBooking?.comparison ?? 'eq'
		},
		ClientAddressesCountry: {
			value: parsedFilters.clientAddressesCountry?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.clientAddressesCountry?.comparison ?? 'eq'
		},
		ClientAddressesState: {
			value: parsedFilters.clientAddressesState?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.clientAddressesState?.comparison ?? 'eq'
		},
		ClientAddressesCity: {
			value: parsedFilters.clientAddressesCity?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.clientAddressesCity?.comparison ?? 'eq'
		},
		ClientNewSale: {
			value: parsedFilters.clientNewSale?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.clientNewSale?.comparison ?? 'eq'
		},
		LabelForm: {
			value: parsedFilters.labelForm?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.labelForm?.comparison ?? 'eq'
		},
		LabelMailCampaign: {
			value: parsedFilters.labelMailCampaign?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.labelMailCampaign?.comparison ?? 'eq'
		},
		TicketType: {
			value: parsedFilters.ticketType?.value?.split(',')?.reduce(parseId, []) || [],
			comparison: parsedFilters.ticketType?.comparison ?? 'eq'
		}
	};
	return res;
};

const initDashboardFilters = dashboardFilters => dispatch => {
	const res = mapDashboardFilters(dashboardFilters);
	dispatch(setDashboardFilters(res));
	return res;
};

export const updateDashboardFilter = (name, value, comparison, skipFetch) => async (dispatch, getState) => {
	const { dashboardFilters, selectedDashboard } = getState().Reportcenter;
	dispatch(setDashboardFilters({ ...dashboardFilters, [name]: { value, comparison } }));

	globalTracker.track('Reportcenter - Filter', { filter: name });

	if (skipFetch) {
		return;
	}

	// Get new data for all widgets
	selectedDashboard.widgets.forEach(widgetConfig => {
		dispatch(getWidgetData(widgetConfig));
	});
};

export const updateScheduledDashboardFilters = (name, value, comparison, remove) => async (dispatch, getState) => {
	const { scheduledDashboardFilters } = getState().Reportcenter;
	if (remove) {
		delete scheduledDashboardFilters[name];
		dispatch({
			type: SET_SCHEDULED_DASHBOARD_FILTERS,
			scheduledDashboardFilters: { ...scheduledDashboardFilters }
		});
	} else {
		dispatch({
			type: SET_SCHEDULED_DASHBOARD_FILTERS,
			scheduledDashboardFilters: { ...scheduledDashboardFilters, [name]: { value, comparison } }
		});
	}
};

export const loadDashboard =
	(dashboardId, viewId, extraFilters, editing = true) =>
	async (dispatch, getState) => {
		if (!dashboardId) {
			dispatch(setSelectedDashboard(null));
			dispatch(setSelectedView(null));
			return;
		}

		batch(() => {
			dispatch(setLoadingDashboard(true));
			dispatch(setDashboardError(false));
		});
		try {
			let resetFilters = false;
			const dbRes = await ReportDashboard.get(dashboardId);

			const dashboard = dbRes.data;
			if (!viewId) {
				if (dashboard.views?.length) {
					viewId = dashboard.views[0].id;
					resetFilters = true;
					dispatch(setSelectedView(viewId));
				} else {
					dispatch(setSelectedView(null));
				}
			}
			if (viewId) {
				const getActiveDashboardFilters = dashboardFilters => {
					// return only the filters that have values
					const activeFilters = {};
					Object.entries(dashboardFilters).forEach(([key, value]) => {
						if (!value?.value) {
							return;
						}
						const filterValue = value;
						if (Array.isArray(filterValue.value) && filterValue.value.length) {
							activeFilters[key] = filterValue;
						} else if (!Array.isArray(filterValue.value) && filterValue.value) {
							activeFilters[key] = filterValue;
						}
					});
					return activeFilters;
				};

				const view = dashboard.views?.find(view => view.id === viewId);
				dashboard.widgets = view.widgets;

				const dashboardFilters = mapDashboardFilters(dashboard.filters);
				const activeDashboardFilters = resetFilters
					? {}
					: getActiveDashboardFilters(getState().Reportcenter.dashboardFilters);
				const viewFilters = getActiveDashboardFilters(mapDashboardFilters(view.filters ?? dashboard.filters));
				dashboard.filters = { ...dashboardFilters, ...activeDashboardFilters, ...viewFilters };
				if (extraFilters) {
					const mappedExtraFilters = Object.entries(mapDashboardFilters(extraFilters)).reduce(
						(memo, [key, val]) => {
							const { value, comparison } = val;
							if (
								(Array.isArray(value) && value.length) ||
								(value && typeof value === 'object' && Object.keys(value).length) ||
								value
							) {
								memo[key] = {
									value
								};
								if (comparison) {
									memo[key].comparison = comparison;
								}
							}
							return memo;
						},
						{}
					);

					dashboard.filters = { ...dashboard.filters, ...mappedExtraFilters };
				}
				if (dashboard.filters.Date?.value !== 'Custom' && dashboard.filters.CustomDate) {
					dashboard.filters.CustomDate = { value: null };
				}
				dashboard.initialDashboardFilters = { ...dashboardFilters, ...viewFilters };
			} else if (extraFilters) {
				dashboard.filters = [...dashboard.filters, ...extraFilters];
			}

			const { users: _users, roles: _roles } = dashboard;
			const fixedDashboard = Object.assign({}, dashboard, { usersAndRoles: [] });
			// Give each widget a unique id
			fixedDashboard.widgets = fixedDashboard.widgets?.map(w => ({ ...w, uniqueId: w.id })) ?? [];

			if (!Tools.FeatureHelper.isAvailable('REPORTCENTER_PRO')) {
				// Reset grouping for any widgets grouped by custom fields without report center pro
				fixedDashboard.widgets =
					fixedDashboard.widgets?.map(w => {
						if (_.some(w.groupBy, g => g.includes('_custom_'))) {
							const availableGroupings = getState().Reportcenter.widgetMeta[w.type].groupings;
							const defaultGrouping = Object.values(availableGroupings)[0];
							return { ...w, groupBy: [defaultGrouping] };
						} else {
							return w;
						}
					}) ?? [];
			}

			for (const mainUser of _users) {
				const user = Tools.AppService.getUsers().find(u => u.id === mainUser);
				if (user) {
					fixedDashboard.usersAndRoles.push(user);
				}
			}
			for (const mainRole of _roles) {
				const role = Tools.AppService.getRoles().find(r => r.id === mainRole);
				if (role) {
					role.isRole = true;
					fixedDashboard.usersAndRoles.push(role);
				}
			}

			let id = dashboardId;
			if (viewId) {
				id = dashboardId + ':' + viewId;
			}
			const filter = {
				q: [
					{ a: 'reportDashboardId', c: 'eq', v: id },
					{ a: 'sendImmediately', c: 'eq', v: 0 }
				]
			};
			const scheduledSelectedDashboard = await ReportScheduleResource.find(filter);

			batch(() => {
				dispatch(initWidgetData(fixedDashboard.widgets));
				const dashboardFilters = viewId
					? fixedDashboard.initialDashboardFilters
					: dispatch(initDashboardFilters(fixedDashboard.filters)); // Only reset filters when switching dashboard
				if (viewId) {
					// Adding view specific filters while keeping ones changed by user
					dispatch(setDashboardFilters(fixedDashboard.filters));
				}
				if (scheduledSelectedDashboard.data) {
					dispatch(setScheduledSelectedDashboard(scheduledSelectedDashboard.data));
				}

				dispatch(setEditingDashboard(editing));
				dispatch(setSelectedDashboard(fixedDashboard));
				dispatch(setInitialDashboardHash(fixedDashboard, dashboardFilters));
				dispatch(setInitialWidgetHash(fixedDashboard));
			});
		} catch (e) {
			logError(e);
			dispatch(setDashboardError(true));
		} finally {
			dispatch(setLoadingDashboard(false));
		}
	};

export const deleteDashboard = id => async (dispatch, getState) => {
	dispatch(setDeletingDashboard(true));
	try {
		await ReportDashboard.delete(id);
		const filter = {
			q: [
				{ a: 'sendImmediately', c: 'eq', v: 0 },
				{ a: 'reportDashboardId', c: 'eq', v: id }
			]
		};

		const dashboardSchedules = await ReportScheduleResource.find(filter);
		dashboardSchedules.data.forEach(async schedule => await ReportScheduleResource.delete(schedule.id));
		const { dashboards } = getState().Reportcenter;
		const i = dashboards.findIndex(d => d.id === id);
		const newBoards = removeItem(dashboards, i);
		if (i !== -1) {
			dispatch(setDashboards(newBoards));
		}
		// Select first board or nothing
		const firstDashboard = getDefaultDashboard(newBoards);
		history.push(`/rc/${firstDashboard?.id ?? ''}`);
		dispatch(setDeletingDashboard(false));
	} catch (e) {
		logError(e);
		dispatch(setDeletingDashboard(false));
	}
};

export const loadDrilldown =
	(widgetConfig, keys, offset = 0, limit = 10, drilldownType = 1) =>
	async (dispatch, getState) => {
		dispatch(setLoadingDrilldown(true));
		try {
			const { dashboardFilters } = getState().Reportcenter;
			const dbFilters = getQueriableFilters(getSaveableFilters(dashboardFilters));

			const { data } = await ReportWidgetDrilldown.getDrilldownByType(widgetConfig.type, keys, {
				grouping: widgetConfig.groupBy,
				dashboardFilters: dbFilters,
				q: getQueriableFilters(widgetConfig.filters),
				offset,
				limit,
				weighted: widgetConfig.weighted,
				aggregateSubAccounts: widgetConfig.aggregateSubAccounts,
				valueType: widgetConfig.valueType,
				displayType: widgetConfig.displayType,
				sorting: widgetConfig.sorting,
				drilldownType,
				customOrderSumField: widgetConfig.customOrderSumField
			});
			data.keys = keys;
			data.dashboardFilters = dbFilters;
			dispatch(setActiveDrilldown(data));
		} catch (e) {
			logError(e);
			dispatch(setLoadingDrilldown(false));
		} finally {
			dispatch(setLoadingDrilldown(false));
		}
	};

const unsetSharing = dashboard => {
	dashboard.users = [];
	dashboard.roles = [];
	dashboard.usersAndRoles = [];
};

export const cloneDashboard = data => (dispatch, getState) => {
	const { selectedDashboard, dashboardFilters } = getState().Reportcenter;
	const filters = getSaveableFilters(dashboardFilters);

	unsetSharing(selectedDashboard);
	if (!data) {
		data = { name: selectedDashboard.name + ` (${T('default.copied')})` };
	}
	dispatch(createDashboard({ ...selectedDashboard, ...data, filters, id: undefined }));
};

export const init =
	(selectedDashboardId, viewId, extraFilters, editing = true) =>
	async dispatch => {
		try {
			dispatch(reset());
			const [dashboards, widgetMeta] = await Promise.all([
				dispatch(getDashboards()),
				ReportWidget.getMeta() // this will change when new resource is in place from other ticket
			]);

			dispatch(setWidgetMeta(widgetMeta.data));

			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_WIDGET_GALLERY')) {
				const widgetCategories = generateGroups(widgetMeta.data);
				dispatch(setWidgetCategories(widgetCategories));
			}

			// Make sure we have an initially selected db
			let dashboard;
			// Find by id
			if (selectedDashboardId) {
				dashboard = dashboards.find(db => db.id === selectedDashboardId);
			}

			if (dashboard) {
				dispatch(loadDashboard(dashboard.id, viewId, extraFilters, editing));
			}

			if (viewId) {
				dispatch(setSelectedView(viewId));
			}
		} catch (e) {
			logError(e);
			dispatch(setPageError(true));
		} finally {
			dispatch(setLoading(false));
		}
	};

export const initScheduledDashboards = (selectedDashboardId, viewId) => async dispatch => {
	const filter = {
		q: [{ a: 'sendImmediately', c: 'eq', v: 0 }]
	};
	if (selectedDashboardId) {
		let id = selectedDashboardId;
		if (viewId) {
			id = selectedDashboardId + ':' + viewId;
		}
		filter.q.push({ a: 'reportDashboardId', c: 'eq', v: id });

		const scheduledSelectedDashboard = await ReportScheduleResource.find(filter);
		if (scheduledSelectedDashboard) {
			dispatch(setScheduledSelectedDashboard(scheduledSelectedDashboard.data));
		}
	} else {
		const scheduledDashboards = await ReportScheduleResource.find(filter);
		if (scheduledDashboards) {
			dispatch(setScheduledDashboards(scheduledDashboards.data));
		}
	}
};

export const revertDashboardChanges = () => (dispatch, getState) => {
	const { selectedDashboard } = getState().Reportcenter;
	dispatch(setLoadingDashboard(true));
	setTimeout(() => {
		dispatch(loadDashboard(selectedDashboard.id));
	});
};

export const loadView = viewId => (dispatch, getState) => {
	const { selectedDashboard } = getState().Reportcenter;
	dispatch(setSelectedView(viewId));
	setTimeout(() => {
		dispatch(loadDashboard(selectedDashboard.id, viewId));
		globalTracker.track('Reportcenter - group', {
			dashboard: selectedDashboard.name,
			view: selectedDashboard.views.find(v => v.id === viewId).label
		});
	});
};
