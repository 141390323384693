import React from 'react';
import MailContentEditorCodeEditor from './MailContentEditorCodeEditor';

const MailContentEditorHtml = ({ column, onChange = () => {} }) => {
	return (
		<div>
			<MailContentEditorCodeEditor
				onChange={content => onChange({ ...column, content })}
				initialValue={column.content}
			/>
		</div>
	);
};

export default MailContentEditorHtml;
