const TemplateSubjectByMailType = template => {
	const TEMPLATE_FILTER_TYPES = {
		my: 'my',
		recent: 'recent',
		upsales: 'upsales',
		scratch: 'scratch',
		plain: 'plain'
	};
	let subject = template.subject;
	switch (template.templateType) {
		case TEMPLATE_FILTER_TYPES.upsales:
		case TEMPLATE_FILTER_TYPES.scratch:
		case TEMPLATE_FILTER_TYPES.plain:
			subject = '';
			break;
	}
	return subject || '';
};

export default TemplateSubjectByMailType;
