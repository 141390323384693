import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './CreateExternalContact.scss';
import ClientContactFollowupSelect from 'App/components/Selectors/ClientContactFollowupSelect';
import { Block, DrawerHeader, Flex, Input, Text, Tooltip } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { FormComponent, FormInput } from 'App/components/FormComponent';
import { PrimaryButton } from '@upsales/components/Buttons';
import { ModalProps } from 'App/components/Modals/Modals';
import { ExternalContactSave } from 'App/resources/Model/Ticket';
import { ClientValue, ContactValue } from 'Components/CreateCall/CreateCallClientContactSelect';
import emailRegex from 'Helpers/emailRegex';

const InfoBox = ({ className }: { className: string }) => {
	const { t } = useTranslation();
	return (
		<Block className={className} space="ptl pbl pll prl">
			<Text size="md" bold color="black">
				{t('ticket.whatIsExternalContact')}
			</Text>
			<Text size="md" space="mts" color="grey-11">
				{t('ticket.externalContactDesc1')}
			</Text>
			<Text size="md" space="mtl" color="grey-11">
				{t('ticket.externalContactDesc2')}
			</Text>
		</Block>
	);
};

type Props = ModalProps & {
	title?: string;
	save: (obj: ExternalContactSave) => Promise<void>;
};

type EditExternalContact = {
	contactInfo: {
		client: ClientValue | null;
		contact: ContactValue | null;
		email: string | null;
	};
	title: string;
};
const CreateExternalContact = ({ className, title = '', close, save }: Props) => {
	const { t } = useTranslation();
	const classes = new bemClass('CreateExternalContact', className);
	const [externalContact, setExternalContact] = React.useState<EditExternalContact>({
		contactInfo: {
			client: null,
			contact: null,
			email: ''
		},
		title
	});
	const showEmailField = !externalContact?.contactInfo.contact?.email;

	const emailIsValid = emailRegex.test(externalContact.contactInfo.email ?? '');
	const isValid = !!(externalContact.contactInfo.email?.length && emailIsValid && externalContact.title?.length);

	const doSave = async () => {
		if (!isValid) {
			return;
		}

		await save({
			title: externalContact.title,
			client: externalContact.contactInfo.client,
			involved: [
				{
					type: 'TO',
					email: externalContact.contactInfo.email as string,
					contact: externalContact.contactInfo.contact
				}
			]
		});
		close();
	};
	return (
		<div className={classes.b()}>
			<DrawerHeader title={t('ticket.addAnExternalContact')} icon="user-plus" onHide={close} />
			<Flex className={classes.elem('content-wrapper').b()} direction="column" justifyContent="space-between">
				<Flex className={classes.elem('content').b()} space="pll prl ptxl" direction="column" gap="u4">
					<InfoBox className={classes.elem('description').b()} />
					<FormComponent label={t('ticket.whoToContact')} required={false} hideMessage>
						<ClientContactFollowupSelect
							onChange={({ client, contact }) => {
								setExternalContact(old => ({
									...old,
									contactInfo: {
										client: client?.id ? client : null,
										contact: contact?.id ? contact : null,
										email:
											contact?.id && client?.id
												? contact.email ?? ''
												: client?.id
												? externalContact.contactInfo.email
												: null
									}
								}));
							}}
							contact={externalContact.contactInfo.contact}
							client={externalContact.contactInfo.client}
							saving={false}
							autofocus
						/>
					</FormComponent>
					{showEmailField ? (
						<FormComponent label={t('ticket.email')} required hideMessage>
							<Input
								type="email"
								value={externalContact.contactInfo.email ?? ''}
								data-1p-ignore
								placeholder={t('default.enterEmail')}
								onChange={e => {
									setExternalContact(old => ({
										...old,
										contactInfo: {
											email: e.target.value,
											contact: externalContact.contactInfo.contact ?? null,
											client: externalContact.contactInfo.client ?? null
										}
									}));
								}}
							/>
						</FormComponent>
					) : null}

					<FormInput
						label={t('support.subject')}
						inputProps={{
							placeholder: t('ticket.enterSubject'),
							required: true,
							value: externalContact.title ?? '',
							maxLength: 150,
							state: undefined,
							onChange: e => setExternalContact(old => ({ ...old, title: e.target.value as string })),
							label: t('support.subject'),
							name: 'title'
						}}
						hideMessage
					/>
				</Flex>
				<Block space="pll pbl ptl prl" backgroundColor="white" className={classes.elem('save').b()}>
					<Tooltip
						disabled={isValid}
						title={!emailIsValid ? t('error.invalid.email') : t('validation.missingRequiredFieldsTitle')}
					>
						<PrimaryButton
							block
							ellipsis
							onClick={() => {
								doSave();
							}}
							size="lg"
							disabled={!isValid}
						>
							{externalContact.contactInfo.contact?.name
								? t('default.add') + ' ' + externalContact.contactInfo.contact.name
								: t('ticket.addExternalContact')}
						</PrimaryButton>
					</Tooltip>
				</Block>
			</Flex>
		</div>
	);
};

export default CreateExternalContact;
