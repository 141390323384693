import { Row, Column, Label, Text, Toggle, ButtonGroup, Button, Input } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import AccountProfile from 'App/resources/Model/AccountProfile';
import StepIntInput from 'Components/Inputs/StepIntInput';
import React from 'react';
import MailContentEditorAlign from './MailContentEditorAlign';

type MailContentEditorSocialProps = {
	column: any;
	onChange: (obj: any) => void;
	accountProfile: AccountProfile;
};

const MailContentEditorSocial: React.FC<MailContentEditorSocialProps> = ({
	column,
	onChange = () => {},
	accountProfile
}) => {
	const t = Tools.$translate;

	const classes = new BemClass('MailContentEditor');

	const socialToggleRow = (medium: string) => {
		return (
			<React.Fragment>
				<Row className={classes.elem('sidebar-section-input-row').b()} key={`toggle-container-${medium}`}>
					<Column>
						<div className={classes.elem('sidebar-section-flex').b()}>
							<Toggle
								key={`toggle-${medium}`}
								checked={column[`${medium}Enabled`]}
								onChange={enabled => onChange({ ...column, [`${medium}Enabled`]: enabled })}
							></Toggle>
							<Text>{t(`default.${medium}`)}</Text>
						</div>
					</Column>
					{column[`${medium}Enabled`] ? (
						<Column align="right">
							<Input
								key={`url-${medium}`}
								value={column[`${medium}URL`]}
								onChange={(e: any) => onChange({ ...column, [`${medium}URL`]: e.target.value })}
								placeholder={`https://${medium}.com/`}
							/>
						</Column>
					) : null}
				</Row>
			</React.Fragment>
		);
	};

	return (
		<div className={classes.b()}>
			<Row key="align" className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<MailContentEditorAlign value={column.align} onChange={align => onChange({ ...column, align })} />
				</Column>
			</Row>
			{socialToggleRow('linkedin')}
			{socialToggleRow('facebook')}
			{socialToggleRow('twitter')}
			{socialToggleRow('instagram')}
			{socialToggleRow('youtube')}
			<Row key="icon-size" className={classes.elem('sidebar-section-input-row', 'icon-size-group').b()}>
				<Column>
					<ButtonGroup block={true}>
						{['24px', '48px'].map(size => (
							<Button
								key={size}
								shadow="none"
								size="sm"
								onClick={() => onChange({ ...column, iconSize: size })}
								color={column.iconSize === size ? 'green' : 'light-grey'}
							>
								<Text color={column.iconSize === size ? 'light-grey' : 'green'}>{size}</Text>
							</Button>
						))}
					</ButtonGroup>
				</Column>
			</Row>
			<Row key="icon-color" className={classes.elem('sidebar-section-input-row', 'icon-color-group').b()}>
				<Column>
					<ButtonGroup block={true}>
						{['light', 'dark'].map(color => (
							<Button
								key={color}
								shadow="none"
								size="sm"
								onClick={() => onChange({ ...column, iconColor: color })}
								color={column.iconColor === color ? 'green' : 'light-grey'}
							>
								<Text color={column.iconColor === color ? 'light-grey' : 'green'}>
									{t(`mail.social.color.${color}`)}
								</Text>
							</Button>
						))}
					</ButtonGroup>
				</Column>
			</Row>

			<Row key="icon-margin" className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{t('mail.social.iconMargin')}</Label>
				</Column>
				<Column align="right">
					<StepIntInput
						size="sm"
						value={column.iconMargin}
						onChange={(e: any) => onChange({ ...column, iconMargin: e.target.value })}
					/>
				</Column>
			</Row>
			<Row key="enable-caption" className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Text>{t('mail.social.captionEnableText')}</Text>
				</Column>
				<Column align="right">
					<Toggle
						checked={column.captionEnabled}
						onChange={enabled => onChange({ ...column, captionEnabled: enabled })}
					></Toggle>
				</Column>
			</Row>
		</div>
	);
};

export default MailContentEditorSocial;
