import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Row, Label, Loader, Toggle, Headline, Input, Tabs, Tab, Button, Help } from '@upsales/components';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import './MailContentEditor.scss';
import { types, getColumnInnerMaxWidth } from 'Services/MailTemplate';
import MailTemplatePreview from 'Components/MailTemplatePreview';
import MailContentEditorHeader from './MailContentEditorHeader';
import MailContentEditorHr from './MailContentEditorHr';
import MailContentEditorRowSettings from './MailContentEditorRowSettings';
import MailContentEditorButton from './MailContentEditorButton';
import MailContentEditorImage from './MailContentEditorImage';
import MailContentEditorText from './MailContentEditorText';
import MailContentEditorHtml from './MailContentEditorHtml';
import MailContentEditorEmpty from './MailContentEditorEmpty';
import MailContentEditorPadding from './MailContentEditorPadding';
import MailContentEditorBrowserLink from './MailContentEditorBrowserLink';
import MailContentEditorRows from './MailContentEditorRows';
import MailContentEditorHoc, {
	getValueHash,
	SIDEBAR_MIN_WIDTH,
	SIDEBAR_MAX_FROM_LEFT,
	findColIndex,
	findRowIndex
} from './MailContentEditorHoc';
import { EditorWrap } from 'Components/EditorUtils';
import MailContentEditorCellSettings from './MailContentEditorCellSettings';
import MailContentEditorSocial from './MailContentEditorSocial';

class MailContentEditor extends MailContentEditorHoc {
	constructor(p) {
		super(p);

		const t = getAngularModule('$translate');

		this.lang = {
			general: t('mail.generalSettings'),
			backgroundColor: t('mailTemplate.mailBackgroundColor'),
			useOwnImage: t('mail.useOwnImage'),
			footer: t('mail.footer'),
			unsubscribeLink: t('mail.unsubscribeLink'),
			subscriptionsLink: t('mail.subscriptionsLink'),
			column: t('mail.column'),
			row: t('mail.row'),
			line: t('mail.line'),
			image: t('default.image'),
			button: t('bannerEditor.button'),
			text: t('mail.text'),
			clearColumn: t('mail.clearColumn'),
			html: t('form.html'),
			allRowsAndCols: t('mailTemplate.allRowsAndCols'),
			settings: t('form.settings'),
			video: t('landingpage.video'),
			social: t('mail.social.title'),
			footerLinkTextColor: t('admin.footerLinkTextColor'),
			footerTextColor: t('admin.footerTextColor')
		};
	}

	componentDidUpdate() {
		if (getValueHash(this.props.config) !== this.state.hash) {
			this.setState({ config: this.props.config });
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// If config changed from the outside we generate new html
		const newHash = getValueHash(nextProps.config);
		if (newHash !== prevState.hash) {
			let newState = {};
			if (nextProps.updateSidebarTrigger !== prevState.updateSidebarTrigger) {
				newState = {
					updateSidebarTrigger: nextProps.updateSidebarTrigger,
					...getUpdatedSidebarState(nextProps.config)
				};
			}

			return { ...newState, hash: newHash, config: nextProps.config };
		}

		return null;
	}
	renderColumnRowSettings(classes) {
		const selectedColumn = this.state.selectedColumn;
		if (!selectedColumn) {
			return null;
		}
		let content = null;

		if (this.state.selectedToolsTab === 'row') {
			content = (
				<MailContentEditorRowSettings
					row={this.state.selectedRow}
					onChange={this.onRowChange}
					onDelete={this.onRowDelete}
					onClear={this.onRowClear}
					onCopy={this.onRowCopy}
					accountProfile={this.props.accountProfile}
				/>
			);
		} else if (selectedColumn.type === types.EMPTY) {
			content = <MailContentEditorEmpty onSelect={this.changeColumnType} />;
		} else {
			const baseProps = {
				column: selectedColumn,
				onChange: this.onColumnChange,
				accountProfile: this.props.accountProfile
			};
			let tools = null;
			let title = null;
			let articleId = null;
			switch (selectedColumn.type) {
				case types.HR:
					title = this.lang.line;
					tools = <MailContentEditorHr {...baseProps} />;
					break;
				case types.IMAGE:
					title = this.lang.image;
					tools = (
						<MailContentEditorImage
							{...baseProps}
							maxWidth={getColumnInnerMaxWidth(selectedColumn)}
							resetWidth={selectedColumn.realImgWidth}
						/>
					);
					break;
				case types.VIDEO:
					title = this.lang.video;
					tools = (
						<MailContentEditorImage
							{...baseProps}
							maxWidth={getColumnInnerMaxWidth(selectedColumn)}
							resetWidth={selectedColumn.realImgWidth}
							isVideo
						/>
					);
					break;
				case types.BUTTON:
					title = this.lang.button;
					tools = <MailContentEditorButton {...baseProps} />;
					break;
				case types.TEXT:
					title = this.lang.text;
					tools = <MailContentEditorText {...baseProps} />;
					break;
				case types.HTML:
					title = this.lang.html;
					articleId = 1124;
					tools = <MailContentEditorHtml {...baseProps} />;
					break;
				case types.SOCIAL:
					title = this.lang.social;
					tools = <MailContentEditorSocial {...baseProps} />;
					break;
			}
			content = (
				<div>
					<div className={classes.elem('sidebar-section').b()}>
						<Row className={classes.elem('sidebar-header').b()}>
							<Column>
								<Headline size="xs">
									{title}
									{articleId ? <Help articleId={articleId} sidebar={true} /> : null}
								</Headline>
							</Column>
							<Column align="right">
								<Button type="link" color="grey" onClick={this.clearColumn}>
									{this.lang.clearColumn}
								</Button>
							</Column>
						</Row>
						{tools}
					</div>
					<MailContentEditorPadding column={selectedColumn} onChange={this.onColumnSettingsChanged} />
					<MailContentEditorCellSettings
						column={selectedColumn}
						onChange={this.onColumnSettingsChanged}
						accountProfile={this.props.accountProfile}
					/>
				</div>
			);
		}

		return (
			<div>
				<Tabs
					selected={this.state.selectedToolsTab}
					onChange={selectedToolsTab => this.setState({ selectedToolsTab })}
				>
					<Tab id="column">{this.lang.column}</Tab>
					<Tab id="row">{this.lang.row}</Tab>
				</Tabs>
				<div className={classes.elem('sidebar-inner').b()}>{content}</div>
			</div>
		);
	}

	selectFromRowsPanel = (selectedColumn, selectedRow) => {
		const state = {
			selectedSettingsTab: 'settings',
			selectedRow
		};
		if (selectedColumn) {
			state.selectedToolsTab = 'column';
			state.selectedColumn = selectedColumn;
		} else {
			state.selectedColumn = selectedRow.columns[0];
			state.selectedToolsTab = 'row';
		}
		this.setState(state);
	};

	setHovered = (hoveredColumn, hoveredRow) => {
		this.setState({ hoveredColumn, hoveredRow });
	};

	render() {
		const classes = new bemClass('MailContentEditor');
		const { readOnly, accountProfile, config, isSocialEvent, isWebinar } = this.props;
		const columnSelected =
			this.state.selectedColumn &&
			['browser-link', 'header', 'footer'].indexOf(this.state.selectedColumn.id) !== -1;
		const hasOptionalUnsubLink = Tools.FeatureHelper.hasSoftDeployAccess('OPTIONAL_UNSUB');

		return (
			<EditorWrap
				className={classes.b()}
				sidebarVisible={this.state.sidebarVisible}
				sidebarDisabled={readOnly}
				sidebarMinWidth={SIDEBAR_MIN_WIDTH}
				sidebarMaxWidth={SIDEBAR_MAX_FROM_LEFT}
				onOpen={this.openSidebar}
				onClose={this.closeSidebar}
				renderMain={() => (
					<div>
						{this.state.loadingPreview ? (
							<Loader className={classes.elem('preview-loader').b()} size="sm" />
						) : (
							<MailTemplatePreview
								className={classes.elem('preview').b()}
								isSocialEvent={isSocialEvent}
								isWebinar={isWebinar}
								config={this.state.config}
								accountProfile={accountProfile}
								onSelectColumn={this.selectColumn}
								selectedColumn={this.state.selectedColumn && this.state.selectedColumn.id}
								selectedRow={this.state.selectedRow && this.state.selectedRow.id}
								hoveredColumn={this.state.hoveredColumn}
								hoveredRow={this.state.hoveredRow}
								onAddRow={this.onAddRow}
								onAddColumn={this.onAddColumn}
								onRemoveCol={this.onRemoveCol}
								onRowMove={this.onRowMove}
								onColMove={this.onColMove}
								onColumnChange={this.onColumnChange}
								readOnly={readOnly}
								onDelete={this.onRowDelete}
								onCopy={this.onRowCopy}
								isAutoReplyTemplate={this.props.isAutoReplyTemplate}
							/>
						)}
					</div>
				)}
				renderSidebar={() => (
					<div className={classes.elem('sidebar').b()}>
						{columnSelected ? (
							<div>
								<Tabs
									selected={this.state.selectedSettingsTab}
									onChange={selectedSettingsTab => this.setState({ selectedSettingsTab })}
								>
									<Tab id="settings">{this.lang.settings}</Tab>
									<Tab id="rows">{this.lang.allRowsAndCols}</Tab>
								</Tabs>
								{this.state.selectedSettingsTab === 'settings' ? (
									<div>
										<div className={classes.elem('sidebar-section').b()}>
											<Headline size="xs">{this.lang.general}</Headline>
											<Row className={classes.elem('sidebar-section-input-row').b()}>
												<Column>
													<Label>{this.lang.backgroundColor}</Label>
												</Column>
												<Column align="right">
													<ReactTemplates.bannerEditor.colorPicker
														value={this.state.config.bodyBg}
														presets={accountProfile.colorVariables}
														onChange={color => this.configChanged('bodyBg', color)}
													/>
												</Column>
											</Row>
										</div>

										<MailContentEditorHeader
											config={this.state.config}
											configChanged={this.configChanged}
											changeLogoType={this.changeLogoType}
											saveProfileLogo={this.saveProfileLogo}
											accountProfile={accountProfile}
										/>

										<MailContentEditorBrowserLink
											config={this.state.config}
											configChanged={this.configChanged}
											accountProfile={this.props.accountProfile}
										/>

										<div className={classes.elem('sidebar-section').b()}>
											<Row className={classes.elem('sidebar-section-input-row').b()}>
												<Column>
													<Headline size="xs">{this.lang.footer}</Headline>
												</Column>
												<Column align="right">
													<Toggle
														color="medium-green"
														checked={this.state.config.hasFooter}
														onChange={val => this.configChanged('hasFooter', val)}
													/>
												</Column>
											</Row>
											{this.state.config.hasFooter ? (
												<>
													<Row className={classes.elem('sidebar-section-input-row').b()}>
														<Column>
															<Label>
																{hasOptionalUnsubLink
																	? this.lang.subscriptionsLink
																	: this.lang.unsubscribeLink}
															</Label>
															<Input
																value={this.state.config.unsubText}
																onChange={e =>
																	this.configChanged('unsubText', e.target.value)
																}
															/>
														</Column>
													</Row>
													<Row className={classes.elem('sidebar-section-input-row').b()}>
														<Column>
															<Label>{this.lang.footerTextColor}</Label>
														</Column>
														<Column align="right">
															<ReactTemplates.bannerEditor.colorPicker
																align="right"
																selfContained={true}
																direction="up"
																value={this.state.config.footerTextColor}
																presets={accountProfile.colorVariables}
																onChange={color =>
																	this.configChanged('footerTextColor', color)
																}
															/>
														</Column>
													</Row>
													<Row className={classes.elem('sidebar-section-input-row').b()}>
														<Column>
															<Label>{this.lang.footerLinkTextColor}</Label>
														</Column>
														<Column align="right">
															<ReactTemplates.bannerEditor.colorPicker
																align="right"
																selfContained={true}
																direction="up"
																value={this.state.config.footerLinkTextColor}
																presets={accountProfile.colorVariables}
																onChange={color =>
																	this.configChanged('footerLinkTextColor', color)
																}
															/>
														</Column>
													</Row>
												</>
											) : null}
										</div>
									</div>
								) : (
									<MailContentEditorRows
										rows={config.rows}
										onSelect={this.selectFromRowsPanel}
										setHovered={this.setHovered}
									/>
								)}
							</div>
						) : (
							this.renderColumnRowSettings(classes)
						)}
					</div>
				)}
			/>
		);
	}
}

function getUpdatedSidebarState(config) {
	const updatedState = {};
	if (config.selectedRowId && config.selectedColumnId) {
		const rowIndex = findRowIndex(config, config.selectedRowId);
		const colIndex = findColIndex(config, rowIndex, config.selectedColumnId);
		if (rowIndex !== -1 && colIndex != null && colIndex !== -1) {
			updatedState.selectedRow = config.rows[rowIndex];
			updatedState.selectedColumn = config.rows[rowIndex].columns[colIndex];
			updatedState.sidebarVisible = true;
		}
	} else {
		updatedState.selectedRow = updatedState.selectedColumn = null;
		updatedState.sidebarVisible = false;
	}
	return updatedState;
}

MailContentEditor.propTypes = {
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	accountProfile: PropTypes.object.isRequired,
	css: PropTypes.string.isRequired,
	isSocialEvent: PropTypes.bool,
	isWebinar: PropTypes.bool,
	readOnly: PropTypes.bool,
	updateSidebarTrigger: PropTypes.number,
	isAutoReplyTemplate: PropTypes.bool
};

MailContentEditor.defaultProps = {
	onChange: () => {},
	readOnly: false
};

export default MailContentEditor;
