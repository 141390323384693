import React from 'react';
import { ModalHeader } from '@upsales/components';
import Order from 'App/resources/Model/Order';
import T from 'Components/Helpers/translate';
import CopyableId from '../CopyableId';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	order: Partial<Order>;
	close: () => void;
	type: 'order' | 'opportunity';
};

const EditOrderHeader = ({ order, close, type }: Props) => {
	const classes = new BemClass('EditOrderHeader');
	const lang = {
		editOrder: T('order.editOrder'),
		createOrder: T('order.createOrder'),
		editOpportunity: T('order.editOpportunity'),
		createOpportunity: T('order.createOpportunity')
	};
	const showTitle = () => {
		if (type === 'order') {
			if (order.id) {
				return `${lang.editOrder}: ${order.description}`;
			} else {
				return lang.createOrder;
			}
		} else {
			if (order.id) {
				return `${lang.editOpportunity}: ${order.description}`;
			} else {
				return lang.createOpportunity;
			}
		}
	};
	return (
		<ModalHeader
			icon={type === 'order' ? 'dollar' : 'opportunity'}
			className={classes.b()}
			title={
				<div className={classes.elem('title').b()}>
					<div>{showTitle()}</div>
					{order.id ? <CopyableId objectId={order.id} type="order"></CopyableId> : null}
				</div>
			}
			onClose={close}
			useHeadline={false}
		></ModalHeader>
	);
};

export default EditOrderHeader;
