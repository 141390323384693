import React from 'react';
import { Button, Row, Column, ButtonGroup, Label } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';

const MailContentEditorHr = ({ column, onChange = () => {}, accountProfile }) => {
	const classes = new bemClass('MailContentEditor');
	return (
		<div>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('mail.style')}</Label>
					<ButtonGroup block={true} className={classes.elem('border-style').b()}>
						{['solid', 'dashed', 'dotted'].map(borderStyle => (
							<Button
								key={borderStyle}
								shadow="none"
								size="sm"
								onClick={() => onChange({ ...column, hrStyle: borderStyle })}
								color={column.hrStyle === borderStyle ? 'bright-blue' : 'light-grey'}
							>
								<div style={{ borderStyle }} />
							</Button>
						))}
					</ButtonGroup>
				</Column>
			</Row>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('mailTemplate.lineColor')}</Label>
				</Column>
				<Column align="right">
					<ReactTemplates.bannerEditor.colorPicker
						value={column.hrColor}
						presets={accountProfile.colorVariables}
						onChange={color => onChange({ ...column, hrColor: color })}
					/>
				</Column>
			</Row>
		</div>
	);
};

export default MailContentEditorHr;
