import { AccountSelf } from 'App/resources/AllIWant';
import { AppThunk, RootState } from '..';

export enum Addon {
	MARKETING_AUTOMATION = 'MARKETING_AUTOMATION',
	ADS = 'ADS',
	GROUP_MAIL = 'GROUP_MAIL',
	SOCIAL_EVENTS = 'SOCIAL_EVENTS',
	BRANDS = 'BRANDS',
	MARKETING_AUTOMATION_ENTERPRISE = 'MARKETING_AUTOMATION_ENTERPRISE'
}

export const Product = {
	CRM: 'CRM',
	MA: 'MA'
} as const;
export type ProductName = (typeof Product)[keyof typeof Product];

export const Feature = {
	UNRELEASED_FEATURE: 'UNRELEASED_FEATURE',
	ACTIVITIES_AND_APPOINTMENTS: 'ACTIVITIES_AND_APPOINTMENTS',
	PROJECTS: 'PROJECTS',
	ADS: 'ADS',
	ADVANCED_SEARCH: 'ADVANCED_SEARCH',
	AUTOMATIONS: 'AUTOMATIONS',
	CALCULATING_FIELDS: 'CALCULATING_FIELDS',
	COMPANIES_AND_CONTACTS: 'COMPANIES_AND_CONTACTS',
	INACTIVE_COMPANIES: 'INACTIVE_COMPANIES',
	COMPANY_PROFILE: 'COMPANY_PROFILE',
	COMPANY_RELATIONS: 'COMPANY_RELATIONS',
	DOCUMENTS: 'DOCUMENTS',
	DYNAMIC_LINKS: 'DYNAMIC_LINKS',
	EMAIL: 'EMAIL',
	ESIGN: 'ESIGN',
	FORMS: 'FORMS',
	GROUP_MAIL: 'GROUP_MAIL',
	CALCULATING_ROW_VALUE: 'CALCULATING_ROW_VALUE',
	CONTRIBUTION_MARGIN: 'CONTRIBUTION_MARGIN',
	IP_RESTRICTIONS: 'IP_RESTRICTIONS',
	LEADS: 'LEADS',
	LOOKER: 'LOOKER',
	ORDER_ROW_CUSTOM: 'ORDER_ROW_CUSTOM',
	ORDER_CUSTOM_STAGES: 'ORDER_CUSTOM_STAGES',
	ROW_CUSTOM_VISIBILITY: 'ROW_CUSTOM_VISIBILITY',
	ORDERS: 'ORDERS',
	CREATE_ENTITY_FROM_TICKET: 'CREATE_ENTITY_FROM_TICKET',
	PIPELINE: 'PIPELINE',
	PERIODIZATION: 'PERIODIZATION',
	PRODUCT_CUSTOM_FIELDS: 'PRODUCT_CUSTOM_FIELDS',
	RECURRING_ORDER: 'RECURRING_ORDER',
	REPORTS: 'REPORTS',
	SALESBOARD: 'SALESBOARD',
	SALESBOARD_CUSTOM_CARDS: 'SALESBOARD_CUSTOM_CARDS',
	SALES_PROCESS: 'SALES_PROCESS',
	SALES_PROCESS_PRO: 'SALES_PROCESS_PRO',
	SALES_PROCESS_MULTI: 'SALES_PROCESS_MULTI',
	SOLIDITET: 'SOLIDITET',
	SOLIDITET_MULTI_MARKET: 'SOLIDITET_MULTI_MARKET',
	SOLIDITET_AUTOMATION: 'SOLIDITET_AUTOMATION',
	SOLIDITET_MATCHER: 'SOLIDITET_MATCHER',
	TRIGGERS: 'TRIGGERS',
	UDO: 'UDO',
	UI_SCRIPTS: 'UI_SCRIPTS',
	USER_PERMISSIONS_ADVANCED: 'USER_PERMISSIONS_ADVANCED',
	VISITS: 'VISITS',
	VISITS_BIG_VOLUME: 'VISITS_BIG_VOLUME',
	INTEGRATIONS: 'INTEGRATIONS',
	INTEGRATION_PUBLISHER: 'INTEGRATION_PUBLISHER',
	MULTI_CURRENCY: 'MULTI_CURRENCY',
	REQUIRE_BUSINESS_EMAIL: 'REQUIRE_BUSINESS_EMAIL',
	SMSAUTH: 'SMSAUTH',
	FLASH: 'FLASH',
	STAKEHOLDERS: 'STAKEHOLDERS',
	CREATE_DELETE_STAKEHOLDERS: 'CREATE_DELETE_STAKEHOLDERS',
	API_KEYS: 'API_KEYS',
	STAGES_ADMIN: 'STAGES_ADMIN',
	ADVANCED_ROLE_SETTINGS: 'ADVANCED_ROLE_SETTINGS',
	BISNODE_AUTOCOMPLETE: 'BISNODE_AUTOCOMPLETE',
	MA_DASHBOARD: 'MA_DASHBOARD',
	CREATE_CUSTOM_FIELDS_CLIENT: 'CREATE_CUSTOM_FIELDS_CLIENT',
	CREATE_CUSTOM_FIELDS_CONTACT: 'CREATE_CUSTOM_FIELDS_CONTACT',
	CREATE_CUSTOM_FIELDS_ACTIVITY: 'CREATE_CUSTOM_FIELDS_ACTIVITY',
	CREATE_CUSTOM_FIELDS_APPOINTMENT: 'CREATE_CUSTOM_FIELDS_APPOINTMENT',
	CREATE_CUSTOM_FIELDS_PROJECT: 'CREATE_CUSTOM_FIELDS_PROJECT',
	CREATE_CUSTOM_FIELDS_USER: 'CREATE_CUSTOM_FIELDS_USER',
	CREATE_CUSTOM_FIELDS_RECCURING_ORDER: 'CREATE_CUSTOM_FIELDS_RECCURING_ORDER',
	CREATE_CUSTOM_FIELDS_ORDER: 'CREATE_CUSTOM_FIELDS_ORDER',
	CREATE_CUSTOM_FIELDS_ORDER_ROW: 'CREATE_CUSTOM_FIELDS_ORDER_ROW',
	CREATE_CUSTOM_FIELDS_PRODUCT: 'CREATE_CUSTOM_FIELDS_PRODUCT',
	CREATE_CUSTOM_FIELDS_PROJECT_PLAN: 'CREATE_CUSTOM_FIELDS_PROJECT_PLAN',
	CREATE_CUSTOM_FIELDS_TICKET: 'CREATE_CUSTOM_FIELDS_TICKET',
	GROUP_TREE: 'GROUP_TREE',
	OPT_IN: 'OPT_IN',
	CLIENT_CUSTOM: 'CLIENT_CUSTOM',
	CONTACT_CUSTOM: 'CONTACT_CUSTOM',
	ACTIVITY_CUSTOM: 'ACTIVITY_CUSTOM',
	APPOINTMENT_CUSTOM: 'APPOINTMENT_CUSTOM',
	ORDER_CUSTOM: 'ORDER_CUSTOM',
	PROJECT_CUSTOM: 'PROJECT_CUSTOM',
	USER_CUSTOM: 'USER_CUSTOM',
	RECURRING_ORDER_CUSTOM: 'RECURRING_ORDER_CUSTOM',
	PROJECT_PLAN_CUSTOM: 'PROJECT_PLAN_CUSTOM',
	TICKET_CUSTOM: 'TICKET_CUSTOM',
	ADMIN_PRODUCTS: 'ADMIN_PRODUCTS',
	ADMIN_CURRENCIES: 'ADMIN_CURRENCIES',
	ADMIN_STANDARD_FIELD_TOOLTIP: 'ADMIN_STANDARD_FIELD_TOOLTIP',
	AB_TESTING: 'AB_TESTING',
	EXPORT_DATA: 'EXPORT_DATA',
	SEGMENT: 'SEGMENT',
	MAIL_SIGNATURE: 'MAIL_SIGNATURE',
	NEW_FIELDS: 'NEW_FIELDS',
	SOCIAL_EVENTS: 'SOCIAL_EVENTS',
	SINGLE_SIGN_ON: 'SINGLE_SIGN_ON',
	ONBOARDING: 'ONBOARDING',
	GROUP_SIZE: 'GROUP_SIZE',
	JOURNEY_STATUS: 'JOURNEY_STATUS',
	MULTIPLE_JOURNEY_STATUS: 'MULTIPLE_JOURNEY_STATUS',
	BRANDS: 'BRANDS',
	LANDINGPAGE_CUSTOM_DOMAIN: 'LANDINGPAGE_CUSTOM_DOMAIN',
	BROKEN_FISCAL_YEAR: 'BROKEN_FISCAL_YEAR',
	UTM_GENERATOR: 'UTM_GENERATOR',
	SHARED_VIEWS: 'SHARED_VIEWS',
	FIND_HIDDEN_PROSPECTS: 'FIND_HIDDEN_PROSPECTS',
	MARKETING_EVENTS: 'MARKETING_EVENTS',
	FLOW: 'FLOW',
	FORMS_BACKEND: 'FORMS_BACKEND',
	PRODUCT_TIERS: 'PRODUCT_TIERS',
	ACTIVITY_OUTCOMES: 'ACTIVITY_OUTCOMES',
	GROUP_MAIL_BACKEND: 'GROUP_MAIL_BACKEND',
	EASY_BOOKING: 'EASY_BOOKING',
	EASY_BOOKING_PRO: 'EASY_BOOKING_PRO',
	PROSPECTING_BASIC: 'PROSPECTING_BASIC',
	PROSPECTING_PRO: 'PROSPECTING_PRO',
	PROSPECTING_SIGNALS: 'PROSPECTING_SIGNALS',
	APPOINTMENT_OUTCOME: 'APPOINTMENT_OUTCOME',
	PROSPECTING_SE: 'PROSPECTING_SE',
	PROSPECTING_GB: 'PROSPECTING_GB',
	PROSPECTING_NO: 'PROSPECTING_NO',
	SALES_PROCESS_CUSTOM_CHECKLIST: 'SALES_PROCESS_CUSTOM_CHECKLIST',
	REPORTCENTER_PRO: 'REPORTCENTER_PRO',
	PRODUCT_BUNDLES: 'PRODUCT_BUNDLES',
	PRICE_LISTS: 'PRICE_LISTS',
	PROJECT_PLAN: 'PROJECT_PLAN',
	FORM_GROUPS: 'FORM_GROUPS',
	PRODUCT_DESCRIPTION: 'PRODUCT_DESCRIPTION',
	PRODUCT_CUSTOM_FIELD_CATEGORY: 'PRODUCT_CUSTOM_FIELD_CATEGORY',
	PRODUCT_SEARCH: 'PRODUCT_SEARCH',
	REPORTCENTER_TV: 'REPORTCENTER_TV',
	MAX_DISCOUNT: 'MAX_DISCOUNT',
	CUSTOMER_PORTFOLIO: 'CUSTOMER_PORTFOLIO',
	ACCOUNT_PLAN: 'ACCOUNT_PLAN',
	CLIENT_TARGET: 'CLIENT_TARGET',
	NOTIFICATIONS: 'NOTIFICATIONS',
	REMINDERS: 'REMINDERS',
	CLIENT_ORDERS: 'CLIENT_ORDERS',
	CLIENT_OPPORTUNITIES: 'CLIENT_OPPORTUNITIES',
	CLIENT_ACTIVITIES: 'CLIENT_ACTIVITIES',
	CLIENT_MARKETING: 'CLIENT_MARKETING',
	CLIENT_DOCUMENTS: 'CLIENT_DOCUMENTS',
	CLIENT_ESIGNS: 'CLIENT_ESIGNS',
	CLIENT_CARD_RELATIONS: 'CLIENT_CARD_RELATIONS',
	CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
	PROSPECTING_BULK: 'PROSPECTING_BULK',
	SET_DISCOUNT: 'SET_DISCOUNT'
} as const;
export type FeatureName = (typeof Feature)[keyof typeof Feature];

export const FeatureMap: {
	[key in FeatureName]?: { key: string; section?: string; fn?: () => AppThunk<boolean>; betaKey?: string };
} = {
	[Feature.UNRELEASED_FEATURE]: { key: 'unreleasedFeature', section: 'sale' },
	[Feature.ACTIVITIES_AND_APPOINTMENTS]: { key: 'activitiesAndAppointments', section: 'sale' },
	[Feature.PROJECTS]: { key: 'projects', section: 'sale' },
	[Feature.ADS]: { key: 'ads', section: 'market' },
	[Feature.ADVANCED_SEARCH]: { key: 'advancedSearch', section: 'sale' },
	[Feature.AUTOMATIONS]: { key: 'automations', section: 'sale' },
	[Feature.COMPANIES_AND_CONTACTS]: { key: 'companiesAndContacts', section: 'sale' },
	[Feature.INACTIVE_COMPANIES]: { key: 'inactiveCompanies', section: 'sale' },
	[Feature.COMPANY_PROFILE]: { key: 'companyProfile', section: 'sale' },
	[Feature.COMPANY_RELATIONS]: { key: 'companyRelations', section: 'sale' },
	[Feature.DOCUMENTS]: { key: 'documents', section: 'sale' },
	[Feature.DYNAMIC_LINKS]: { key: 'dynamicLinks', section: 'sale' },
	[Feature.EMAIL]: { key: 'email', section: 'market' },
	[Feature.ESIGN]: {
		key: 'esign',
		fn: () => (dispatch, getStore) => {
			const { accountSelf, esignIntegrations } = getStore().App;
			var haveEsign = accountSelf?.features.esign ? true : false;
			return haveEsign && esignIntegrations.length > 0;
		},
		section: 'sale'
	},
	[Feature.FORMS]: { key: 'forms', section: 'market' },
	[Feature.GROUP_MAIL]: { key: 'groupMail', section: 'market' },
	[Feature.IP_RESTRICTIONS]: { key: 'ipRestrictions', section: 'sale' },
	[Feature.LEADS]: { key: 'leads', section: 'market' },
	[Feature.LOOKER]: { key: 'looker', section: 'followup' },
	[Feature.ORDER_ROW_CUSTOM]: { key: 'orderRowCustom', section: 'sale' },
	[Feature.CALCULATING_FIELDS]: { key: 'calculatingFields', section: 'sale' },
	[Feature.ROW_CUSTOM_VISIBILITY]: { key: 'rowCustomVisibility', section: 'sale' },
	[Feature.ORDERS]: { key: 'orders', section: 'sale' },
	[Feature.PIPELINE]: { key: 'pipeline', section: 'sale' },
	[Feature.PRODUCT_CUSTOM_FIELDS]: { key: 'productCustomFields', section: 'sale' },
	[Feature.RECURRING_ORDER]: { key: 'recurringOrder', section: 'sale' },
	[Feature.REPORTS]: { key: 'reports', section: 'followup' },
	[Feature.SALESBOARD]: { key: 'salesboard', section: 'sale' },
	[Feature.SALESBOARD_CUSTOM_CARDS]: { key: 'salesboardCustomCards', section: 'sale' },
	[Feature.SALES_PROCESS]: { key: 'salesProcess', section: 'sale' },
	[Feature.SALES_PROCESS_PRO]: { key: 'salesProcessPro', section: 'sale' },
	[Feature.SALES_PROCESS_MULTI]: { key: 'salesProcessMulti', section: 'sale' },
	[Feature.SOLIDITET]: { key: 'soliditet', section: 'sale' },
	[Feature.SOLIDITET_MULTI_MARKET]: { key: 'soliditetMultiMarkets', section: 'sale' },
	[Feature.SOLIDITET_AUTOMATION]: { key: 'soliditetAutomation', section: 'sale' },
	[Feature.SOLIDITET_MATCHER]: { key: 'soliditetMatcher', section: 'sale' },
	[Feature.STAKEHOLDERS]: { key: 'stakeholders', section: 'sale' },
	[Feature.CREATE_DELETE_STAKEHOLDERS]: { key: 'createDeleteStakeholders', section: 'sale' },
	[Feature.TRIGGERS]: { key: 'triggers', section: 'sale' },
	[Feature.UDO]: { key: 'userDefinedObjects', section: 'sale' },
	[Feature.UI_SCRIPTS]: { key: 'uiScripts', section: 'sale' },
	[Feature.USER_PERMISSIONS_ADVANCED]: { key: 'userPermissionsAdvanced', section: 'sale' },
	[Feature.VISITS]: { key: 'visits', section: 'market' },
	[Feature.VISITS_BIG_VOLUME]: { key: 'visitsBigVolume', section: 'market' },
	[Feature.INTEGRATIONS]: { key: 'integrations', section: 'admin' },
	[Feature.INTEGRATION_PUBLISHER]: { key: 'integrationPublisher', section: 'admin' },
	[Feature.MULTI_CURRENCY]: { key: 'multiCurrency', section: 'admin' },
	[Feature.REQUIRE_BUSINESS_EMAIL]: { key: 'requireBusinessEmail', section: 'market' },
	[Feature.SMSAUTH]: { key: 'SMSAuth', section: 'sale' },
	[Feature.FLASH]: { key: 'flash', section: 'sale' },
	[Feature.API_KEYS]: { key: 'apiKeys', section: 'admin' },
	[Feature.STAGES_ADMIN]: { key: 'stagesAdmin', section: 'admin' },
	[Feature.ADVANCED_ROLE_SETTINGS]: { key: 'advancedRoleSettings', section: 'admin' },
	[Feature.BISNODE_AUTOCOMPLETE]: { key: 'bisnodeAutocomplete', section: 'market' },
	[Feature.MA_DASHBOARD]: { key: 'maDashboard', section: 'market' },
	[Feature.CALCULATING_ROW_VALUE]: { key: 'calculatingRowValue', section: 'sale' },
	[Feature.CONTRIBUTION_MARGIN]: { key: 'contributionMargin', section: 'sale' },
	[Feature.CREATE_CUSTOM_FIELDS_CLIENT]: { key: 'createCustomFieldsClient', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_CONTACT]: { key: 'createCustomFieldsContact', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_ACTIVITY]: { key: 'createCustomFieldsActivity', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_APPOINTMENT]: { key: 'createCustomFieldsAppointment', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_PROJECT]: { key: 'createCustomFieldsProject', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_PROJECT_PLAN]: {
		key: 'createCustomFieldsProjectPlan',
		betaKey: Feature.PROJECT_PLAN, // The beta flag is named the same as the feature, so I use it here, but it should probably have been named PROJECT_PLAN_BETA
		section: 'admin'
	},
	[Feature.CREATE_CUSTOM_FIELDS_USER]: { key: 'createCustomFieldsUser', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_RECCURING_ORDER]: { key: 'createCustomFieldsReccuringOrder', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_ORDER]: { key: 'createCustomFieldsOrder', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_ORDER_ROW]: { key: 'createCustomFieldsOrderRow', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_PRODUCT]: { key: 'createCustomFieldsProduct', section: 'admin' },
	[Feature.CREATE_CUSTOM_FIELDS_TICKET]: { key: 'createCustomFieldsTicket', section: 'admin' },
	[Feature.GROUP_TREE]: { key: 'groupTree', section: 'sale' },
	[Feature.OPT_IN]: { key: 'optIn', section: 'market' },
	[Feature.CLIENT_CUSTOM]: { key: 'clientCustom', section: 'admin' },
	[Feature.CONTACT_CUSTOM]: { key: 'contactCustom', section: 'admin' },
	[Feature.ACTIVITY_CUSTOM]: { key: 'activityCustom', section: 'admin' },
	[Feature.APPOINTMENT_CUSTOM]: { key: 'appointmentCustom', section: 'admin' },
	[Feature.ORDER_CUSTOM]: { key: 'orderCustom', section: 'admin' },
	[Feature.PROJECT_CUSTOM]: { key: 'projectCustom', section: 'admin' },
	[Feature.PROJECT_PLAN_CUSTOM]: {
		key: 'projectPlanCustom',
		betaKey: Feature.PROJECT_PLAN, // The beta flag is named the same as the feature, so I use it here, but it should probably have been named PROJECT_PLAN_BETA
		section: 'admin'
	},
	[Feature.USER_CUSTOM]: { key: 'userCustom', section: 'admin' },
	[Feature.RECURRING_ORDER_CUSTOM]: { key: 'recurringOrderCustom', section: 'admin' },
	[Feature.TICKET_CUSTOM]: { key: 'ticketCustom', section: 'admin' },
	[Feature.ADMIN_PRODUCTS]: { key: 'adminProducts', section: 'admin' },
	[Feature.ADMIN_CURRENCIES]: { key: 'adminCurrencies', section: 'admin' },
	[Feature.ADMIN_STANDARD_FIELD_TOOLTIP]: { key: 'adminStandardFieldTooltip', section: 'admin' },
	[Feature.FORM_GROUPS]: { key: 'formGroups', section: 'admin' },
	[Feature.EXPORT_DATA]: { key: 'exportData', section: 'sale' },
	[Feature.SEGMENT]: { key: 'segment', section: 'market' },
	[Feature.SOCIAL_EVENTS]: { key: 'socialEvents', section: 'market' },
	[Feature.JOURNEY_STATUS]: { key: 'journeyStatus', section: 'admin' },
	[Feature.MULTIPLE_JOURNEY_STATUS]: { key: 'multipleJourneyStatus', section: 'admin' },
	[Feature.SINGLE_SIGN_ON]: { key: 'singleSignOn', section: 'admin' },
	[Feature.MAIL_SIGNATURE]: { key: 'mailSignature', section: 'admin' },
	[Feature.GROUP_SIZE]: { key: 'groupSize', section: 'sale' },
	[Feature.ORDER_CUSTOM_STAGES]: { key: 'orderCustomStages', section: 'admin' },
	[Feature.BRANDS]: { key: 'brands', section: 'admin' },
	[Feature.LANDINGPAGE_CUSTOM_DOMAIN]: { key: 'landingpageCustomDomain', section: 'market' },
	[Feature.PERIODIZATION]: { key: 'periodization', section: 'sale' },
	[Feature.SHARED_VIEWS]: { key: 'sharedViews', section: 'sale' },
	[Feature.UTM_GENERATOR]: { key: 'utmGenerator', section: 'market' },
	[Feature.BROKEN_FISCAL_YEAR]: { key: 'brokenFiscalYear', section: 'sale' },
	[Feature.MARKETING_EVENTS]: {
		key: 'marketingEvents',
		fn: () => dispatch => {
			// Not exactly sure what should be here
			return [
				Feature.ADS,
				Feature.EMAIL,
				Feature.FORMS,
				Feature.GROUP_MAIL,
				Feature.LEADS,
				Feature.VISITS,
				Feature.SOCIAL_EVENTS
			].some(f => dispatch(isAvailable(f)));
		},
		section: 'market'
	},
	[Feature.FLOW]: { key: 'flow', section: 'market' },
	[Feature.FORMS_BACKEND]: { key: 'formsBackend', section: 'market' },
	[Feature.PRODUCT_TIERS]: { key: 'productTiers', section: 'admin' },
	[Feature.ACTIVITY_OUTCOMES]: { key: 'activityOutcomes', section: 'sale' },
	[Feature.GROUP_MAIL_BACKEND]: { key: 'groupMailBackend', section: 'market' },
	[Feature.EASY_BOOKING]: { key: 'easyBooking', section: 'admin' },
	[Feature.EASY_BOOKING_PRO]: { key: 'easyBookingPro', section: 'admin' },
	[Feature.PROSPECTING_SE]: { key: 'prospectingSE', section: 'sale' },
	[Feature.PROSPECTING_GB]: { key: 'prospectingGB', section: 'sale' },
	[Feature.PROSPECTING_NO]: { key: 'prospectingNO', section: 'sale' },
	[Feature.PROSPECTING_BASIC]: { key: 'prospectingBasic', section: 'sale' },
	[Feature.PROSPECTING_PRO]: { key: 'prospectingPro', section: 'sale' },
	[Feature.PROSPECTING_SIGNALS]: { key: 'prospectingSignals', section: 'sale' },
	[Feature.APPOINTMENT_OUTCOME]: { key: 'appointmentOutcome', section: 'sale' },
	[Feature.SALES_PROCESS_CUSTOM_CHECKLIST]: { key: 'salesProcessCustomChecklist', section: 'sale' },
	[Feature.REPORTCENTER_PRO]: { key: 'reportcenterPro', section: 'sale' },
	[Feature.PRODUCT_BUNDLES]: { key: 'productBundles', section: 'sale' },
	[Feature.PRICE_LISTS]: { key: 'priceLists', section: 'sale' },
	[Feature.PROJECT_PLAN]: {
		key: 'projectPlan',
		betaKey: Feature.PROJECT_PLAN, // The beta flag is named the same as the feature, so I use it here, but it should probably have been named PROJECT_PLAN_BETA
		section: 'sale'
	},
	[Feature.PRODUCT_DESCRIPTION]: { key: 'productDescription', section: 'sale' },
	[Feature.PRODUCT_CUSTOM_FIELD_CATEGORY]: { key: 'productCustomFieldCategory', section: 'sale' },
	[Feature.PRODUCT_SEARCH]: { key: 'productSearch', section: 'sale' },
	[Feature.REPORTCENTER_TV]: { key: 'reportcenterTV', section: 'sale' },
	[Feature.MAX_DISCOUNT]: { key: 'maxDiscount', section: 'sale' },
	[Feature.CUSTOMER_PORTFOLIO]: { key: 'customerPortfolio', section: 'sale' },
	[Feature.ACCOUNT_PLAN]: { key: 'accountPlan', section: 'sale' },
	[Feature.CLIENT_TARGET]: { key: 'clientTarget', section: 'sale' },
	[Feature.NOTIFICATIONS]: { key: 'notifications', section: 'admin' },
	[Feature.CREATE_ENTITY_FROM_TICKET]: { key: 'createEntityFromTicket', section: 'admin' },
	[Feature.REMINDERS]: { key: 'reminders', section: 'admin' },
	[Feature.CLIENT_ORDERS]: { key: 'clientOrders', section: 'sale' },
	[Feature.CLIENT_OPPORTUNITIES]: { key: 'clientOpportunities', section: 'sale' },
	[Feature.CLIENT_ACTIVITIES]: { key: 'clientActivities', section: 'sale' },
	[Feature.CLIENT_MARKETING]: { key: 'clientMarketing', section: 'sale' },
	[Feature.CLIENT_DOCUMENTS]: { key: 'clientDocuments', section: 'sale' },
	[Feature.CLIENT_ESIGNS]: { key: 'clientEsigns', section: 'sale' },
	[Feature.CLIENT_CARD_RELATIONS]: { key: 'clientCardRelations', section: 'sale' },
	[Feature.CUSTOMER_SUPPORT]: { key: 'customerSupport', section: 'sale' },
	[Feature.PROSPECTING_BULK]: { key: 'prospectingBulk', section: 'sale' },
	[Feature.SET_DISCOUNT]: { key: 'setDiscount', section: 'sale' }
};

export const productMap = {
	[Product.CRM]: { key: 'crm' },
	[Product.MA]: {
		key: (accountSelf: AccountSelf) => {
			return accountSelf.products.ma ||
				accountSelf.boughtAddons[Addon.MARKETING_AUTOMATION] ||
				accountSelf.boughtAddons[Addon.MARKETING_AUTOMATION_ENTERPRISE] ||
				accountSelf.boughtAddons[Addon.GROUP_MAIL] ||
				accountSelf.boughtAddons[Addon.SOCIAL_EVENTS] ||
				accountSelf.boughtAddons[Addon.ADS] ||
				accountSelf.boughtAddons[Addon.BRANDS]
				? true
				: false;
		}
	}
};

export const hasSoftDeployAccess =
	(feature: string): AppThunk<boolean> =>
	(d, getStore) => {
		const { accountSelf } = getStore().App;

		if (!accountSelf) {
			return false;
		}

		// Special haxx for billing addons
		if (feature === 'BILLING_ADDONS' && accountSelf.version !== 'Starter') {
			return false;
		}

		const unreleasedFeatures = accountSelf.unreleasedFeatures;
		return (unreleasedFeatures && unreleasedFeatures[feature]) || false;
	};

export const isAvailableProduct =
	(product: ProductName): AppThunk<boolean> =>
	(d, getStore) => {
		const { accountSelf } = getStore().App;
		const productObj = productMap[product];

		/* API:et verkar inte returna de featurs man inte har i self, därav
		  false om det är undefined */
		if (!accountSelf || productObj === undefined) {
			return false;
		}

		const key = productObj.key;

		if (typeof key === 'string') {
			return accountSelf?.products[key] ? true : false;
		}
		if (typeof key === 'function') {
			return key(accountSelf);
		}
		return false;
	};

export function isAvailable(feature: FeatureName, searchAccount?: boolean): AppThunk<boolean> {
	return (dispatch, getStore) => {
		const { accountSelf } = getStore().App;
		const featureObj = FeatureMap[feature];

		/* API:et verkar inte returna de featurs man inte har i self, därav
			  false om det är undefined */
		if (featureObj === undefined) {
			return false;
		}

		if (featureObj.fn && typeof featureObj.fn === 'function') {
			return dispatch(featureObj.fn());
		}
		const key = featureObj.key;

		if (accountSelf?.features[key]) {
			return true;
		} else if (searchAccount) {
			const hasAccountFeature = accountSelf?.versionData.features.find(accountFeature => accountFeature === key);
			return hasAccountFeature ? true : false;
		} else if (featureObj.betaKey && accountSelf?.unreleasedFeatures[featureObj.betaKey]) {
			return true;
		}

		return false;
	};
}

export function isAvailableAddon(addon: Addon): AppThunk<boolean> {
	return (d, getStore) => {
		const { accountSelf } = getStore().App;
		return !!accountSelf?.boughtAddons[addon];
	};
}

export function getOngoingTrialAddons(): AppThunk<RootState['Billing']['ongoingTrialAddons']> {
	return (d, getStore) => {
		const { ongoingTrialAddons } = getStore().Billing;
		return ongoingTrialAddons;
	};
}

export function canBuyAddon(feature: FeatureName): AppThunk<boolean> {
	return (d, getStore) => {
		const { accountSelf } = getStore().App;
		const featureObj = FeatureMap[feature];

		if (!featureObj) {
			return false;
		}
		return (accountSelf?.availableAddons ?? []).indexOf(featureObj.key) !== -1 ? true : false;
	};
}

export function hasSoftDeployAccessUser(feature: string): AppThunk<boolean> {
	return (d, getStore) => {
		const { accountSelf } = getStore().App;

		if (!feature) {
			return false;
		} else {
			return accountSelf?.userActivatedFeatures?.[feature]?.active || false;
		}
	};
}
