import React, { useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Text, Card, Textarea, Button, OutsideClick, Link, Flex, Icon } from '@upsales/components';
import { NewSubscriptionTracker } from 'Helpers/Tracker';
import T from 'Components/Helpers/translate';

import './BetaBanner.scss';

type Props = {
	close: () => void;
	agreementId?: number;
	orderId?: number;
};

const BetaBanner = ({ close, agreementId, orderId }: Props) => {
	const [showPopup, setShowPopup] = useState(false);
	const [feedback, setFeedback] = useState('');

	const changeEditor = () => {
		NewSubscriptionTracker.trackBetaBanner(feedback);
		const opts = { id: agreementId, createFromOrder: !!orderId, orderId };
		Tools.$upModal.open('editAgreement', opts);
		close();
	};

	const hasSplitFlag = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');

	const createLinkId = '9405704844817';
	const splitCreateLinkId = '13373936335889';
	const editLinkId = '11005263803537';

	let supportLink = 'https://support.upsales.com/hc/en-us/articles/';
	if (hasSplitFlag) {
		if (agreementId) {
			supportLink += editLinkId; // Update articleId when EditSplitArticle exists
		} else {
			supportLink += splitCreateLinkId;
		}
	} else {
		if (agreementId) {
			supportLink += editLinkId;
		} else {
			supportLink += createLinkId;
		}
	}

	const classes = new bemClass('BetaBanner');
	return (
		<div className={classes.b()}>
			<Flex
				className={classes.elem('wrapper').b()}
				alignItems="center"
				justifyContent="space-between"
				space="mrl mll"
				gap={8}
			>
				<Flex className={classes.elem('leftSide').b()} gap={8} alignItems="center" flex={1}>
					<Icon name="info-circle" color="blue" />
					<Text color="blue" bold>
						{T('agreement.betaBanner.title')}
					</Text>
					<Text color="blue">{T('agreement.betaBanner.subtitle')}</Text>
				</Flex>
				<Link href={supportLink} target="_blank">
					<Icon name="question-circle" color="green" />
				</Link>
				<Link color="green" onClick={() => setShowPopup(!showPopup)}>
					{T('agreement.betaBanner.changeToOldEditor')}
				</Link>
			</Flex>
			{showPopup ? (
				<OutsideClick outsideClick={() => setShowPopup(false)} targetClass={classes.elem('popup').b()}>
					<Card className={classes.elem('popup').b()}>
						<Text bold>{T('agreement.betaBanner.areYouSure')}</Text>
						<Text>{T('agreement.betaBanner.weCouldAppreciate')}</Text>
						<Textarea
							placeholder={T('agreement.betaBanner.feedback')}
							value={feedback}
							onChange={e => setFeedback(e.target.value)}
						/>
						<Flex space="mtl" justifyContent="flex-end" gap={4}>
							<Button onClick={changeEditor}>{T('agreement.betaBanner.changeToOldEditor')}</Button>
							<Button type="link" onClick={() => setShowPopup(false)}>
								{T('cancel')}
							</Button>
						</Flex>
					</Card>
				</OutsideClick>
			) : null}
		</div>
	);
};

export default BetaBanner;
