import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Block,
	Button,
	FullscreenModal,
	Icon,
	Headline,
	ModalSidebar,
	ModalHeader,
	ModalContent,
	Table,
	TableHeader,
	TableHeaderColumn,
	Text,
	Flex,
	DropDownMenu,
	Card,
	Tooltip
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import _Order from 'App/resources/Model/Order';
import {
	OrderRowsProvider,
	OrderRow as _OrderRow,
	BundleRow,
	useOrderRowsSelector,
	useOrderRowsDispatch
} from 'App/components/OrderRows/Context/OrderContext';
import { appendOrderRow, getRecurringValue, resetBundle } from 'App/components/OrderRows/Context/OrderContextHelpers';
import BundleRows from './BundleRows';
import { getDisplaySettings, DisplaySettings, mapBundleRowsAsOrderRows } from './helpers';
import { OrderSummary, BundleSummary } from './SummaryComponents';
import { getMetadata } from 'App/babel/store/selectors/AppSelectors';
import { BundleTracker } from 'App/babel/helpers/Tracker';
import { ClientIdName } from 'App/resources/Model/Client';
import { ModalProps } from 'App/components/Modals/Modals';
import { hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { useMetadata } from '../hooks/appHooks';
import { useSoftDeployAccess } from '../hooks';
import { getProduct } from '../hooks/editListener/updaters/orderRowUpdater';

import './EditProductBundleOrder.scss';
import Product from 'App/resources/Model/Product';
import { DangerButton, DefaultButton } from '@upsales/components/Buttons';

type OrderRow = Required<_OrderRow>;
type Order = Omit<PartialPick<_Order, 'currency' | 'recurringInterval'>, 'client' | 'orderRow'> & {
	client: ClientIdName;
	orderRow: OrderRow[];
};

type EditProductBundleOrderProps = ModalProps<void> & {
	uuid: number;
	isAgreement: boolean;
	client: ClientIdName;
	currency: string;
	displaySettings: DisplaySettings;
	bundleOrderRow: OrderRow;
	orderOrderRows: Order['orderRow'];
	orderInterval: number;
	priceListId: number;
	save: (bundleOrderRow: Product) => void;
};
const EditProductBundleOrder = (props: EditProductBundleOrderProps) => {
	const classes = new BemClass('EditProductBundleOrder', props.className);
	const {
		isAgreement,
		currency,
		client,
		displaySettings,
		save,
		close,
		bundleOrderRow,
		orderOrderRows,
		orderInterval,
		priceListId
	} = props;

	const { orderRows } = useOrderRowsSelector(s => ({
		orderRows: s.orderRows
	}));
	const dispatch = useOrderRowsDispatch();

	const metadata = useMetadata();
	const { SalesModel } = metadata?.params || {};
	const usingRRWithCM = hasRRWithCM(metadata!);
	const usingCM = SalesModel === 'cm';
	const showCost = usingCM || usingRRWithCM;

	const hasBundleAdjustable = useSoftDeployAccess('BUNDLE_ADJUSTABLE');
	const hasSwitchBundleProducts = useSoftDeployAccess('SWITCH_BUNDLE_PRODUCTS');
	const bundleFixedPrice = bundleOrderRow.bundleFixedPrice;
	const bundleEditable = hasSwitchBundleProducts && bundleOrderRow.product.bundleEditable && bundleFixedPrice;
	const productCount = orderRows.filter(row => row.product && row.quantity).length;

	const lang = useMemo(
		() => ({
			title: T(isAgreement ? 'agreement.withCompany' : 'order.opportunityWithCompany', {
				companyName: client.name
			}),
			save: T('default.save'),
			close: T('cancel'),
			quantity: T('default.quantity'),
			product: T('default.product'),
			sum: T('default.sum'),
			bundleDiscount: T('bundle.discount'),
			bundleCost: T('bundle.cost'),
			netPrice: `${T('default.price')} (${T('default.netAmount')})`,
			nameAndCount: T('bundle.nameAndCount', {
				name: bundleOrderRow.product.name,
				count: productCount
			}),
			productCost: T('bundle.productCost'),
			bundlePrice: T('admin.products.bundlePrice'),
			addProduct: T('admin.products.addProduct'),
			resetBundle: T('bundle.resetBundle'),
			resetBundleConfirm: T('bundle.resetBundleConfirm'),
			resetBundleTitle: T('bundle.resetBundleTitle'),
			resetBundleDescription: T('bundle.resetBundleDescription'),
			cannotSaveEmpty: T('bundle.cannotSaveEmpty')
		}),
		[productCount]
	);

	/*
		We must take it from here and not look at product.bundlePriceAdjustment as
		that one may change, but the price adjustment on the orders should not.
		The exception is if the bundle is reset, then we should reset the price adjustment as well.
	*/
	const resetPriceAdjustment = React.useRef(false);
	const priceAdjustment = useMemo(() => {
		if (resetPriceAdjustment.current === true) {
			return bundleOrderRow.product.bundlePriceAdjustment ?? 1;
		}

		if (!bundleOrderRow.bundleRows.length) {
			return 1;
		}
		const bundleRow = bundleOrderRow.bundleRows.find(br => br.listPrice !== 0) ?? bundleOrderRow.bundleRows[0];
		const rate = bundleRow.listPrice !== 0 ? bundleRow.price / bundleRow.listPrice : 1;
		return parseFloat(rate.toFixed(2));
	}, [resetPriceAdjustment.current]);

	const productHasPriceAdjustment = bundleOrderRow.product.bundlePriceAdjustment !== 1;

	const onSave = useCallback(() => {
		// Convert type from OrderRow and filter empty rows
		const typedBundleRows = orderRows.filter(row => row.product) as BundleRow[];

		const newProduct = {
			...bundleOrderRow.product,
			bundle: typedBundleRows.map(
				({
					quantity,
					tierQuantity,
					product,
					productId,
					adjustable,
					bundleRowPrice,
					bundleRowCost,
					resetOrderDiscount,
					price,
					purchaseCost
				}) => ({
					quantity,
					tierQuantity,
					product: product!,
					productId,
					adjustable,
					bundleRowPrice,
					bundleRowCost,
					resetOrderDiscount,
					price,
					purchaseCost
				})
			)
		};

		BundleTracker.track(BundleTracker.events.ORDER_ROW_CHANGE_SAVED);
		save(newProduct);
		close();
	}, [orderRows]);

	const [bundleProduct, setBundleProduct] = React.useState<Product | null>(null);
	useEffect(() => {
		BundleTracker.track(BundleTracker.events.ORDER_ROW_CHIP_CLICKED);

		getProduct(bundleOrderRow.product.id)
			.then(product => {
				setBundleProduct(product);
			})
			.catch(e => {
				console.error(e);
			});
	}, []);

	const bundleContentHasChanged = useMemo(() => {
		if (!bundleProduct) {
			return false;
		}

		const bundleRows = orderRows as BundleRow[];

		if (bundleProduct.bundle.length !== bundleRows.length) {
			return true;
		}

		const price = bundleRows.reduce((sum, row) => sum + row.price, 0);
		if (bundleProduct.bundlePriceAdjustment !== priceAdjustment && price > 0) {
			return true;
		}

		const bundleProductRows = bundleProduct.bundle.map(({ productId, quantity }) => ({
			productId,
			quantity
		}));

		const bundleRowsRows = bundleRows.map(({ productId, quantity }) => ({
			productId,
			quantity
		}));

		return !bundleProductRows.every(
			({ productId, quantity }) =>
				bundleRowsRows.find(row => row.productId === productId && row.quantity === quantity) !== undefined
		);
	}, [orderRows, bundleProduct]);

	return (
		<FullscreenModal className={classes.b()} withSidebar headerAtTop>
			<ModalHeader
				title={lang.title}
				icon={isAgreement ? 'recurring-order' : 'opportunity'}
				onClose={close}
				alignContent="right"
			>
				<Button className={classes.elem('actionButtons').b()} onClick={() => close()} type="link">
					<Text color="grey-11">{lang.close}</Text>
				</Button>
				<Tooltip title={lang.cannotSaveEmpty} disabled={!!productCount} position="left">
					<Button
						className={classes.elem('actionButtons').b()}
						onClick={onSave}
						color="green"
						disabled={!productCount}
					>
						<Text space="mrl" bold color="white">
							{lang.save}
						</Text>
						<Icon name="check" color="white" />
					</Button>
				</Tooltip>
			</ModalHeader>
			<ModalSidebar placement="top" align="left">
				<Block space="mbxl">
					<BundleSummary
						displaySettings={displaySettings}
						currency={currency}
						isFixedPriceBundle={bundleFixedPrice}
						priceData={
							bundleFixedPrice
								? {
										totalAmount: bundleOrderRow.listPrice,
										totalRecurringValue: bundleOrderRow.product.isRecurring
											? getRecurringValue(bundleOrderRow.listPrice, orderInterval)
											: 0
								  }
								: undefined
						}
					/>
				</Block>
				<Block>
					<OrderSummary displaySettings={displaySettings} currency={currency} orderRows={orderOrderRows} />
				</Block>
			</ModalSidebar>
			<ModalContent>
				<Block className={classes.elem('Content').b()} space="pbm">
					<Flex justifyContent="space-between" alignItems="center" space="pbl ptl pll prl">
						<Flex className={classes.elem('ContentHeadline').b()} wrap="nowrap" alignItems="center">
							<Icon name="bundle" />
							<Headline size="sm">{lang.nameAndCount}</Headline>
						</Flex>
						{hasSwitchBundleProducts && bundleContentHasChanged ? (
							<DropDownMenu
								align="right"
								renderTrigger={(expanded, setExpanded) => (
									<DefaultButton
										icon="refresh"
										onClick={setExpanded}
									>{`${lang.resetBundle}`}</DefaultButton>
								)}
							>
								{setExpanded => (
									<Card space="ptl pbl pll prl" borderRadius>
										<Flex direction="column" gap="u2">
											<Text bold>{lang.resetBundleTitle}</Text>
											<Text>{lang.resetBundleDescription}</Text>
											<DangerButton
												onClick={() => {
													dispatch(
														resetBundle(
															bundleOrderRow,
															orderInterval,
															currency,
															priceListId
														)
													);
													setExpanded();
													resetPriceAdjustment.current = true;
												}}
											>{`${lang.resetBundleConfirm}`}</DangerButton>
										</Flex>
									</Card>
								)}
							</DropDownMenu>
						) : null}
					</Flex>
					<Table>
						<TableHeader>
							<TableHeaderColumn column={{ title: lang.product }} />
							<TableHeaderColumn column={{ title: lang.quantity }} />
							{productHasPriceAdjustment ? (
								<TableHeaderColumn
									column={{
										title: priceAdjustment > 1 ? lang.bundleCost : lang.bundleDiscount
									}}
								/>
							) : null}
							<TableHeaderColumn column={{ title: lang.netPrice }} />
							{hasBundleAdjustable && showCost ? (
								<TableHeaderColumn column={{ title: lang.productCost }} />
							) : null}
							<TableHeaderColumn column={{ title: lang.sum }} />
							{bundleEditable ? <TableHeaderColumn column={{ title: '' }} /> : null}
						</TableHeader>
						<BundleRows
							productHasPriceAdjustment={productHasPriceAdjustment}
							priceAdjustment={priceAdjustment}
							displaySettings={displaySettings}
							currency={currency}
							showCost={showCost}
							editable={bundleEditable}
							fixedPrice={bundleFixedPrice}
							onlyRecurringProducts={!!bundleOrderRow.product.isRecurring}
						/>
					</Table>
					{bundleEditable ? (
						<DefaultButton
							onClick={() => dispatch(appendOrderRow())}
							className={classes.elem('add-product-btn').b()}
						>
							<Icon name="plus" space="mrm" />
							{lang.addProduct}
						</DefaultButton>
					) : null}
				</Block>
			</ModalContent>
		</FullscreenModal>
	);
};

type Props = ModalProps<void> & {
	uuid: number;
	order: Order;
	isAgreement: boolean;
	save: (product: Product) => void;
};
export default (props: Props) => {
	const {
		order: { recurringInterval, orderRow, client, currency },
		uuid,
		...passOnProps
	} = props;

	const priceListId = orderRow[0]?.priceListId;
	const orderInterval = recurringInterval ?? 0;
	const metadata = getMetadata()!;
	const displaySettings = getDisplaySettings(metadata);

	const bundleOrderRow = orderRow.find(orderRow => orderRow.uuid === uuid) as OrderRow;
	const hasBundleAdjustable = useSoftDeployAccess('BUNDLE_ADJUSTABLE');

	const bundleRows = useMemo(
		() => mapBundleRowsAsOrderRows(bundleOrderRow, currency, orderInterval, hasBundleAdjustable),
		[]
	);

	return (
		<OrderRowsProvider orderInterval={orderInterval} initialRows={bundleRows} priceListId={priceListId}>
			<EditProductBundleOrder
				{...passOnProps}
				client={client}
				uuid={uuid}
				currency={currency}
				displaySettings={displaySettings}
				bundleOrderRow={bundleOrderRow}
				orderOrderRows={props.order.orderRow}
				orderInterval={orderInterval}
				priceListId={priceListId}
			/>
		</OrderRowsProvider>
	);
};
