import React from 'react';
import translate from 'Components/Helpers/translate';

const textRenderType = ({ row, config, object, getUser, runStandardIntegration, getIcon, runClick }) => {
	var innerSpan = (
		<span>
			{row.text}
			{row.translateKey ? translate(row.translateKey) : ''}
		</span>
	);
	var textProps = {
		className: ['widget-text']
	};

	if (row.style) {
		textProps.className.push(row.style);
	}

	if (row.click) {
		textProps.onClick = event => {
			if (event && event.stopPropagation) {
				event.stopPropagation();
			}

			if (row.click.type === 'widgetRow') {
				runStandardIntegration({
					type: 'widgetRow',
					data: { object: object, user: getUser() },
					typeId: row.click.name,
					integrationId: config.integrationId
				});
			} else {
				runClick(row);
			}
		};
	}

	if (row.icon) {
		innerSpan = (
			<span>
				{getIcon(row.icon, row.style)}
				{row.text}
				{row.translateKey ? translate(row.translateKey) : ''}
			</span>
		);
	}

	textProps.className = textProps.className.join(' ').trim();
	return <div className="widget-text-wrapper">{React.createElement('span', textProps, innerSpan)}</div>;
};

export default textRenderType;
