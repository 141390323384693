import { ButtonSelect, Flex, Input, Row, Select, NumberInput, Text, Label, Textarea, Help } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState } from 'react';
import T from 'Components/Helpers/translate';
import type { BundleState } from '../ProductBundleDrawer';
import { PriceAdjustmentTypes } from '../ProductBundleDrawer';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';
import RoleSelect from 'Components/RoleSelect';
import BundlePrice from './BundlePrice';

const clamp = (value: number): number => {
	return Math.max(0, Math.min(value, 100));
};

type Props = {
	classes: BemClass;
	bundle: BundleState;
	updateBundle: React.Dispatch<Partial<BundleState>>;
	selectAnchor: Element | null;
};

const BundleSettings = ({ classes, bundle, updateBundle, selectAnchor }: Props) => {
	const allRoles = Tools.AppService.getRoles();
	const roleOptions = allRoles.map(({ id, name }) => ({ id, title: name }));
	const categoryOptions = Tools.AppService.getProductCategories().map(({ id, name }) => ({ id, title: name }));
	const hasMaxDiscountFeature = useFeatureAvailable(Feature.MAX_DISCOUNT);
	const hasMaxDiscount = useSoftDeployAccess('MAX_DISCOUNT') && hasMaxDiscountFeature;
	const hasTreeSelect = useSoftDeployAccess('TREE_SELECT');
	const hasSwitchBundleProducts = useSoftDeployAccess('SWITCH_BUNDLE_PRODUCTS');

	const {
		roles,
		category,
		priceAdjustmentType,
		priceAdjustmentPercent,
		articleNo,
		articleNoActive,
		articleNoRequired,
		description,
		descriptionActive,
		descriptionRequired,
		maxDiscount
	} = bundle;

	const [priceModifierInput, setPriceModifierInput] = useState<number | undefined>(priceAdjustmentPercent || 0);

	const changeMaxDiscount = (value: number | undefined) => {
		if (isNaN(Number(value))) {
			return;
		}

		const newMaxDiscount = value === 0 ? null : Number(value) / 100;

		updateBundle({ maxDiscount: newMaxDiscount });
	};

	const displayMaxDiscount = (maxDiscount: number | null) => {
		if (maxDiscount === null || maxDiscount === undefined) {
			return '';
		}

		return +(maxDiscount * 100).toFixed(2);
	};

	return (
		<div className={classes.elem('settings').b()}>
			<div>
				<Row>
					<Label
						required
						maxLength={255}
						value={bundle.name}
						maxLengthReachedText={T('default.characterLimitReached')}
					>
						{T('admin.products.bundleName')}
					</Label>
				</Row>
				<Input maxLength={255} value={bundle.name} onChange={e => updateBundle({ name: e.target.value })} />
			</div>

			<Flex gap="u4">
				<Flex flex={1} direction="column">
					<Label>{T('default.roles')}</Label>
					{hasTreeSelect ? (
						<RoleSelect
							roles={allRoles}
							selectedRoles={roles.map(({ id, title }) => ({ id, name: title }))}
							onChange={roles =>
								updateBundle({ roles: roles.map(({ id, name }) => ({ id, title: name })) })
							}
						/>
					) : (
						<Select
							multi
							anchor={selectAnchor}
							placeholder={T('default.allRoles')}
							options={roleOptions}
							value={roles}
							onChange={value => updateBundle({ roles: [...roles, value] })}
							onRemove={id => updateBundle({ roles: roles.filter(role => role.id !== id) })}
							onClear={() => updateBundle({ roles: [] })}
						/>
					)}
				</Flex>

				<Flex flex={1} direction="column">
					<Label>{T('admin.products.placeInCategory')}</Label>
					<Select
						anchor={selectAnchor}
						placeholder={T('default.noCategory')}
						options={categoryOptions}
						value={category}
						onChange={value => updateBundle({ category: value })}
						onClear={() => updateBundle({ category: null })}
					/>
				</Flex>
			</Flex>

			<Flex gap="u4">
				<Flex flex={1} direction="column">
					{articleNoActive ? (
						<>
							<Row>
								<Label
									required={articleNoRequired}
									maxLength={100}
									value={articleNo ? articleNo : undefined}
									maxLengthReachedText={T('default.characterLimitReached')}
								>
									{T('product.articleNo')}
								</Label>
							</Row>
							<Input
								maxLength={100}
								value={articleNo ?? ''}
								onChange={e => updateBundle({ articleNo: e.target.value })}
							/>
						</>
					) : null}
				</Flex>

				<Flex flex={1} direction="column">
					{hasMaxDiscount ? (
						<div className={classes.elem('max-discount').b()}>
							<Row>
								<Label>{T('product.maxDiscount')}</Label>
								<Help articleId={1473} />
							</Row>
							<NumberInput
								decimals={2}
								value={Number(displayMaxDiscount(maxDiscount))}
								onChange={e => changeMaxDiscount(e)}
							/>
							<Text className={classes.elem('percent').b()}>%</Text>
						</div>
					) : null}
				</Flex>
			</Flex>

			{descriptionActive ? (
				<div>
					<Row>
						<Label
							required={descriptionRequired}
							maxLength={512}
							value={description ? description : undefined}
							maxLengthReachedText={T('default.characterLimitReached')}
						>
							{T('default.description')}
						</Label>
					</Row>
					<Textarea
						maxLength={512}
						value={description ?? ''}
						onChange={e => updateBundle({ description: e.target.value })}
					/>
				</div>
			) : null}

			{hasSwitchBundleProducts ? (
				<BundlePrice
					classes={classes}
					bundle={bundle}
					updateBundle={updateBundle}
					selectAnchor={selectAnchor}
				/>
			) : (
				<>
					<div>
						<Label>{T('admin.products.priceAdjustmentTypeLabel')}</Label>
						<ButtonSelect
							value={priceAdjustmentType}
							className={classes.elem('price-adjustment-type').b()}
							options={[
								{
									value: PriceAdjustmentTypes.UNCHANGED,
									title: T('admin.products.useAsPrice')
								},
								{
									value: PriceAdjustmentTypes.DECREASE,
									title: T('admin.products.addDiscount')
								},
								{
									value: PriceAdjustmentTypes.INCREASE,
									title: T('admin.products.addExtraCost')
								}
							]}
							onChange={value => {
								updateBundle({
									priceAdjustmentType: value
								});
							}}
						/>
					</div>
					{priceAdjustmentType !== PriceAdjustmentTypes.UNCHANGED ? (
						<div className={classes.elem('price-adjustment-input').b()}>
							<Label>
								{priceAdjustmentType === PriceAdjustmentTypes.DECREASE
									? T('admin.products.bundleDiscount')
									: T('admin.products.bundleCost')}
							</Label>
							<NumberInput
								decimals={2}
								value={priceModifierInput}
								onChange={value => {
									setPriceModifierInput(value || 0);
									// If statement to prevent updateBundle to unnecessarily fire.
									if (clamp(value || 0) !== priceAdjustmentPercent) {
										updateBundle({ priceAdjustmentPercent: clamp(value || 0) });
									}
								}}
								onBlur={() => setPriceModifierInput(clamp(priceModifierInput || 0))}
							/>
							<Text className={classes.elem('percent').b()}>%</Text>
						</div>
					) : null}
				</>
			)}
		</div>
	);
};

export default BundleSettings;
